import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { WindowSize } from "../lib-atomic-functions";
import ListItem from "@material-ui/core/ListItem";

const useStyles = ({ windowWidth }) => {
  const styleFormatted = {
    labelContainer: {
      backgroundColor: "transparent",
      height: 35,
      paddingLeft: 7,
      paddingRight: 7,
      paddingTop: 5,
      paddingBottom: 5,
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: "solid",
      borderColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        // borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        // color: "red"
        // borderColor: "red",
        // borderStyle: "solid",
        // transitionProperty: "color",
        // transition: "color 0.5s ease"
      },
    },
    label: {
      backgroundColor: "transparent",
      fontWeight: 400,
      color: "#666",
      borderColor: "transparent",
      fontSize:
        windowWidth < 435
          ? 9
          : windowWidth < 500
          ? 11
          : windowWidth < 600
          ? 13
          : 16,
      "&:hover": {
        color: "#000",
        // transition: "color 0.5s ease",
        textDecoration: "none",
      },
    },
    labelContainer2: {
      backgroundColor: "transparent",
      height: 35,
      paddingLeft: 7,
      paddingRight: 7,
      paddingTop: 5,
      paddingBottom: 5,
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: "solid",
      borderColor: "transparent",
      "&:hover": {
        cursor: "default",
        backgroundColor: "transparent",
        // borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        // color: "red"
        // borderColor: "red",
        // borderStyle: "solid",
        // transitionProperty: "color",
        // transition: "color 0.5s ease"
      },
    },
    label2: {
      backgroundColor: "transparent",
      fontWeight: 400,
      color: "#666",
      borderColor: "transparent",
      justifyContent: "center",
      textAlign: "center",
      lineHeight: 1.1,
      fontSize:
        windowWidth < 435
          ? 9
          : windowWidth < 500
          ? 11
          : windowWidth < 600
          ? 13
          : 16,
      "&:hover": {
        cursor: "default",
        color: "#666",
        // transition: "color 0.5s ease",
        textDecoration: "none",
      },
    },
  };
  return makeStyles((theme) => styleFormatted)();
};
const App = (props) => {
  const { width, height } = WindowSize();

  const classes = useStyles({ windowWidth: width });
  const url = props.match ? props.match.url : "";
  const { label, prefix } = props;
  if (prefix !== undefined) {
    return (
      <div>
        <ListItem
          className={classes.labelContainer}
          onClick={() => {
            window.open(prefix, "_blank");
          }}
        >
          <Link className={classes.label} to={"#"}>
            {label.toUpperCase()}
          </Link>
        </ListItem>
      </div>
    );
  } else {
    return (
      <div>
        <ListItem className={classes.labelContainer2}>
          <div className={classes.label2}>{label.toUpperCase()}</div>
        </ListItem>
      </div>
    );
  }
};

export default App;
