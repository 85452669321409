import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'


/* module  */
import ContactForm from '../form/contact';
/* module  */

const index = observer((props) => {
  
  /* data props */
  const formData = { props };
  /* data props */

  return <ContactForm {...formData} />

})

export default index;
