import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleTitle,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import Container from "../../app-module-account-profile-container/default";
import OrderContainer from "../../app-module-order-container/default";
/* container */

/* module */
import CMSHomeHeader from "../../app-module-cms-section-home-header/default";
import AccountProfileTransactionItem from "../../app-module-account-profile-section-transaction-item/default";
/* module */

const index = (props) => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSHomeHeader {...props} />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <OrderContainer {...props}>
            <Container.TransactionList {...props}>
              <ModuleTitle>Transaction History</ModuleTitle>
              <ModuleFormWrapper>
                <AccountProfileTransactionItem {...props} />
              </ModuleFormWrapper>
            </Container.TransactionList>
          </OrderContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
