import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_NZBN: "FETCH_NZBN",
  RESET: "RESET"
};
const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false
  };
  return state;
};
const fetchNZBN = async (state, nzbnsearch) => {
  state = {
    ...state,
    isStatus: nzbnsearch.status,
    expireToken: sanitizeExpire({
      status: nzbnsearch.status,
      data: nzbnsearch.data,
      name: "nzbndetail"
    }),
    data: sanitizeData({
      status: nzbnsearch.status,
      data: nzbnsearch.data,
      name: "nzbndetail"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NZBN:
      return fetchNZBN(state, action.nzbnsearch);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const nzbnsearch = {
  init,
  actionTypes,
  reducer
};

export default nzbnsearch;
