import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";

import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CARDS = CONFIG.setup.assets.cards;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_cards;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
const MINMAXVALUE = CONFIG.details.options.cardValueRule;
const MINMAXQTY = CONFIG.details.options.cardQtyRule;
/* CONSTANTS */

const index = (props) => {
  const [productEditIndex, productEditIndexSet] = useState(0);
  const [productEditName, productEditNameSet] = useState("");
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  const isProductsLS =
    LocalStorage.getLS().products && LocalStorage.getLS().products.length > 0
      ? LocalStorage.getLS().products
      : LocalStorage.addLS("products", []);
  /* state */
  const [isProductCard, setIsProductCard] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* --- product name useState */
  /* -------------- */
  const [productName, setProductName] = useState(380172);
  // const [totalValue, setTotalValue] = useState(totalvalue);
  // const [totalQuantity, setTotalQuantity] = useState(totalquantity);
  /* --- product name useState */
  /* -------------- */
  /* --- product */
  /* -------------- */

  // IF USER IS TRUE
  // SET DELIVERY ADDRES LS TO USER BILLING ADDRESS
  // SET BILLING ADDRESS LS TO USER BILLING ADDRESS

  const userLS = LocalStorage.getLS().user;
  if (userLS) {
    const userBillingAddress = {
      companyName: userLS.name,
      firstname: userLS.firstName + " " + userLS.lastName,
      physicalAddress1: userLS.physicalAddress1,
      physicalCity: userLS.physicalCity,
      physicalState: userLS.physicalState,
      physicalSuburb: userLS.physicalSuburb,
      physicalPostcode: userLS.physicalPostcode,
    };
    console.log("testtt", userLS)
    LocalStorage.addLS("billingAddress", userBillingAddress);
    LocalStorage.addLS("deliveryAddress", userBillingAddress);
  }

  const { productall, productbreak, product, fees, payment, notes, address } =
    useContext(GlobalContext);

  const execProductAddLS = (item) => {
    product.dispatch({
      type: product.actionTypes.ADD_PRODUCT_LS,
      product: item,
    });
  };
  const execProductAdd = (item) => {
    product.dispatch({
      type: product.actionTypes.ADD_PRODUCT,
      product: item,
    });
  };
  const execProductEditUpdate = (item, productIndex) => {
    product.dispatch({
      type: product.actionTypes.EDITUPDATE_PRODUCT,
      product: item,
      productIndex,
    });
  };
  const execProductEditCancel = (item) => {
    product.dispatch({
      type: product.actionTypes.EDITCANCEL_PRODUCT,
      product: item,
    });
  };
  const execFeesAdd = (feesselected) => {
    fees.dispatch({
      type: fees.actionTypes.ADD_FEES,
      fees: feesselected,
    });
  };
  const execPaymentAdd = (paymentselected) => {
    payment.dispatch({
      type: payment.actionTypes.ADD_PAYMENT,
      payment: paymentselected,
    });
  };
  const execNotesAdd = (customnotes) => {
    notes.dispatch({
      type: notes.actionTypes.ADD_NOTES,
      notes: customnotes,
    });
  };
  const execAddressAdd = (customaddress) => {
    address.dispatch({
      type: address.actionTypes.ADD_ADDRESS,
      address: customaddress,
    });
  };
  useEffect(() => {
    // fetch local storage and put it inside context
    const productsLS = LocalStorage.getLS().products;
    if (productsLS) {
      execProductAddLS(productsLS);
      setIsProductCard(true);
    }
  }, []);
  useEffect(() => {
    // fetch local storage and put it inside context
    const feesLS = LocalStorage.getLS().fees;
    if (feesLS) {
      execFeesAdd(feesLS);
    }
  }, []);
  useEffect(() => {
    // fetch local storage and put it inside context
    const paymentLS = LocalStorage.getLS().payment;
    if (paymentLS) {
      execPaymentAdd({ paymentoption: paymentLS });
    }
  }, []);
  useEffect(() => {
    // fetch local storage and put it inside context
    const notesLS = LocalStorage.getLS().notes;
    if (notesLS) {
      execNotesAdd({ ordernotes: notesLS });
    }
  }, []);
  useEffect(() => {
    if (product.state.dataf && isTriggerCreate) {
      LocalStorage.addLS("products", product.state.data.productlist);
      setIsTriggerCreate(false);
      setIsProductCard(true);
    }
  }, [product]);
  useEffect(() => {
    const x =
      LocalStorage.getLS().products && LocalStorage.getLS().products.length > 0
        ? LocalStorage.getLS().products
        : [];
    if (isProductsLS.toString() === x.toString()) {
      setIsProductCard(isProductsLS);
    }
  }, [isProductsLS]);
  useEffect(() => {
    const addressLS = LocalStorage.getLS().address;
    if (addressLS) {
      execAddressAdd({ deliveryadress: addressLS });
    }
  }, []);

  /* -------------- */
  /* --- product */
  /* state */

  const handleCreate = async () => {
    // IF EDIT START
    const isEdit = product.state.isEdit;
    if (isEdit) {
      const selectedproduct = productbreak.state.data.mapped.filter(
        (product) => product.card.productId === productEditName
      )[0];
      const card_image = selectedproduct.card.path;
      const carrier_image = selectedproduct.carrier.path;
      const carrier = selectedproduct.carrier;
      const carriernone = selectedproduct.carriernone;
      const carriernone_image = selectedproduct.carriernone.path;
      const item = {
        ...values,
        productIndex: productEditIndex,
        product: productName,
        classification: "card",
        image: card_image,
      };
      const item_carrier = {
        ...values,
        productIndex: productEditIndex,
        product: values.carrieroption === "1" ? 724988 : carriernone.productId,
        classification: "carrier",
        image: values.carrieroption === "1" ? carrier_image : carriernone_image,
        value: 0,
      };
      execProductEditUpdate([item, item_carrier], productEditIndex);
      onReset(PRODUCTOBJECT);
      setIsTriggerCreate(true);
      return;
    }
    // IF EDIT END
    const productIndex = Date.now();
    const selectedproduct = productbreak.state.data.mapped.filter(
      (product) => product.card.productId === productName
    )[0];
    const card_image = selectedproduct.card.path;
    const carrier_image = selectedproduct.carrier.path;
    const carrier = selectedproduct.carrier;
    const carriernone = selectedproduct.carriernone;
    const carriernone_image = selectedproduct.carriernone.path;
    const item = {
      ...values,
      productIndex,
      product: productName,
      classification: "card",
      image: card_image,
    };
    const item_carrier = {
      ...values,
      productIndex,
      product: values.carrieroption === "1" ? 724988 : carriernone.productId,
      classification: "carrier",
      image: values.carrieroption === "1" ? carrier_image : carriernone_image,
      value: 0,
    };
    execProductAdd([item, item_carrier]);
    // execProductAdd([item_carrier]);
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
  };

  const handleEditCancel = async ({ item }) => {
    setProductName(380172);
    execProductEditCancel();
    onReset(PRODUCTOBJECT);
  };

  const { values, onReset, onChange, onSubmit, setValues, error } =
    FormHook.useFormHook(handleCreate, PRODUCTOBJECT);

  /* data props */
  const getTotalValues = () => {
    let totalValue = 0;
    let totalQuantity = 0;
    LocalStorage.getLS().products.map((product) => {
      if (parseFloat(product.value) > 0) {
        totalValue =
          totalValue + parseFloat(product.value) * parseInt(product.quantity);
        totalQuantity = totalQuantity + parseInt(product.quantity);
        // setTotalValue(totalvalue);
        // setTotalQuantity(totalquantity);
      }
    });
    return { totalValue, totalQuantity };
  };
  const getTotalValuesV2 = () => {
    // consider current value as well
    let totalValue = 0;
    let totalQuantity = 0;
    LocalStorage.getLS().products.map((product) => {
      if (parseFloat(parseInt(product.value)) > 0) {
        totalValue =
          totalValue +
          parseFloat(parseInt(product.value)) * parseInt(product.quantity) 
          //   parseFloat(parseInt(product.value)) * parseInt(product.quantity) +
          // parseFloat(parseInt(values.value || "0")) *
          //   parseInt(values.quantity || "0");
        totalQuantity =
          totalQuantity +
          parseInt(product.quantity) +
          parseInt(values.quantity);
        // setTotalValue(totalvalue);
        // setTotalQuantity(totalquantity);
      }
    });
    // console.log(values.value, values.quantity, " 1")
    // console.log({totalValue}, " totalValue")
    // if (values.value && values.quantity) {
    //   totalValue =
    //     totalValue + parseFloat(values.value) * parseInt(values.quantity);
    //     console.log({totalValue}, " totalValue 2")
    // }
    if (values.quantity) {
      totalQuantity = totalQuantity + parseInt(values.quantity);
    }
    return { totalValue, totalQuantity };
  };

  // const {totalValue,totalQuantity } = getTotalValuesV2()
  // PRODUCTOBJECT.value.totalValue = totalValue
  // PRODUCTOBJECT.quantity.totalValue = totalQuantity
  const carrieroption = {
    ...PRODUCTOBJECT.carrieroption,
    error: error.carrieroption,
    value: values.carrieroption,
    onChange,
  };
  const value = {
    ...PRODUCTOBJECT.value,
    error: error.value,
    value: values.value,
    onChange,
  };
  const quantity = {
    ...PRODUCTOBJECT.quantity,
    error: error.quantity,
    value: values.quantity,
    onChange,
  };
  const actionButton = { size: "large" };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  // const productcardsMap = {
  //   380172: "df",
  //   724986: "hb",
  //   724985: "ty",
  // };
  // const productcardsMap = {
  //   380172: "df",
  //   724984: "hb",
  //   724985: "ty",
  // };

  const productcardsMap = CONFIG.setup.setup.productMapper;
  const productcards = productall.state.data;
  const { mapped } = productbreak.state.data;
  /* data props */

  // IS EDITING MODE
  const isEdit = product.state.isEdit;
  let productEditValue = { ...value };
  let productEditQuantity = { ...quantity };
  let productEditCarrier = { ...carrieroption };

  useEffect(() => {
    if (isEdit) {
      productEditNameSet(product.state.data.productEditList[0].product);
      productEditIndexSet(product.state.data.productEditList[0].productIndex);
      setValues({
        ...values,
        value: product.state.data.productEditList.filter(
          (item) => item.classification === "card"
        )[0].value,
        quantity: product.state.data.productEditList.filter(
          (item) => item.classification === "card"
        )[0].quantity,
        carrieroption: product.state.data.productEditList.filter(
          (item) => item.classification === "card"
        )[0].carrieroption,
      });
    }
  }, [isEdit, product, productName]);

  const LIMIT = CONFIG.details.options.cardValueRule.totalMax;
  const ORDERVALUE = getTotalValuesV2().totalValue;
  const CURRENTORDERVALUE = parseFloat(parseInt(values.value)) * parseInt(values.quantity)
  // const VALUE = ORDERVALUE ? ORDERVALUE : CURRENTORDERVALUE
  const VALUE = ORDERVALUE + CURRENTORDERVALUE
  const isMAXTOTALLS = VALUE > LIMIT ? true : false;

  return (
    <form
      onSubmit={
        isMAXTOTALLS
          ? (e) => {
              e.preventDefault();
              return;
            }
          : onSubmit
      }
      noValidate
    >
      <ModulePaperWrapper>
        {isEdit && (
          <ModuleFormWrapper>
            <div>Editing {productEditIndex}</div>
          </ModuleFormWrapper>
        )}

        {/* EDIT MODE */}
        {isEdit && (
          <ModuleFormWrapper>
            {/* line */}
            {mapped.map((product, index) => {
              let classactive = "";
              if (productEditName === product.card.productId) {
                classactive = productcardsMap[productEditName];
              }

              return (
                <ModuleFormItemWrapper key={index}>
                  <div
                    onClick={() => setProductName(product.card.productId)}
                    className={
                      "productSelect " +
                      "productSelect-active-" +
                      // productSelectActiveDf
                      classactive
                    }
                  >
                    <ModulePaperWrapper>
                      <img src={product.card.path} width="100%" />
                    </ModulePaperWrapper>
                  </div>
                </ModuleFormItemWrapper>
              );
            })}
            {/* line */}
          </ModuleFormWrapper>
        )}

        {/* ADD MODE */}
        {!isEdit && (
          <ModuleFormWrapper>
            {/* line */}
            {mapped.map((product, index) => {
              let classactive = "";
              if (productName === product.card.productId)
                classactive = productcardsMap[productName];
              return (
                <ModuleFormItemWrapper key={index}>
                  <div
                    onClick={() => setProductName(product.card.productId)}
                    className={
                      "productSelect " +
                      "productSelect-active-" +
                      // productSelectActiveDf
                      classactive
                    }
                  >
                    <ModulePaperWrapper>
                      <img src={product.card.path} width="100%" />
                    </ModulePaperWrapper>
                  </div>
                </ModuleFormItemWrapper>
              );
            })}
            {/* line */}
          </ModuleFormWrapper>
        )}

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            {isEdit && <Inputs.Text {...productEditQuantity} />}
            {!isEdit && <Inputs.Text {...quantity} />}
            <small>
              Quantity from {MINMAXQTY.prefix}
              {MINMAXQTY.min} to {MINMAXQTY.prefix}
              {MINMAXQTY.max}
            </small>
          </ModuleFormItemWrapper>
          <ModuleFormItemWrapper>
            {isEdit && <Inputs.Text {...productEditValue} />}
            {!isEdit && <Inputs.Text {...value} />}
            <small>
              Value from {MINMAXVALUE.prefix}
              {MINMAXVALUE.min} to {MINMAXVALUE.prefix}
              {MINMAXVALUE.max}
            </small>
          </ModuleFormItemWrapper>

          {isMAXTOTALLS ? (
            <ModuleFormItemLongWrapper>
              <Invalid
                error={[
                  `Cannot submit $${VALUE} because you exceed the max of $${LIMIT}.`,
                ]}
              />
            </ModuleFormItemLongWrapper>
          ) : null}
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            {isEdit && <Inputs.Select {...productEditCarrier} />}
            {!isEdit && <Inputs.Select {...carrieroption} />}
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <div style={{ display: "flex" }}>
              <Buttons.Normal {...actionButton}>
                {isEdit ? "Update" : "Add"}
              </Buttons.Normal>
              {isEdit && (
                <>
                  &nbsp;&nbsp;
                  <Buttons.NormalButton
                    onClick={handleEditCancel}
                    {...actionButton}
                  >
                    Cancel
                  </Buttons.NormalButton>
                </>
              )}
            </div>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            {/* <Buttons.Text {...backButton}>Back</Buttons.Text> */}
            {isProductCard && (
              <Buttons.Normal {...nextButton}>Next</Buttons.Normal>
            )}
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
