import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  padded: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(15),
  },
}));
/* CONSTANTS */

const wrapper = props => {

  /* mui */
  const classes = useStyles();
  /* mui */

  return <div className={classes.padded}>
    { props.children }
  </div>
}

export default wrapper;
