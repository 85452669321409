import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold,
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

const index = (props) => {
  console.log("in update succes");
  const handleBack = () => {
    props.history.push("/corporate");
  };
  /* data props */
  const backButton = { size: "medium", onClick: () => handleBack() };

  return (
    <ModuleFormItemWrapper>
      <ModulePaperWrapper>
        <ModuleTitle>
          You have created a new password for your account
        </ModuleTitle>
        <br />
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...backButton}>HOME</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemWrapper>
  );
};

export default index;
