import React from 'react';

/* mui */
import Container from '@material-ui/core/Container';
/* mui */

const container_xl = props => {
  return <Container maxWidth="lg">
    { props.children }
  </Container>
}

export default container_xl;
