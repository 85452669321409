import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Activation";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    activation: {
      api: {
        endpoints: {
          account_activation: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/registration/activation",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/registration/activation",
            method: "POST",
          },
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.guest.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/auth/token",
            method: "POST",
          },
          memberenquiry: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/clients",
            method: "GET",
          },
          transactions: {
            headers: {
              "Content-Type": "application/json",
              appName: "corp",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "GET",
          },
        },
      },
    },
  },
  routes: {
    label: "Forgot Password",
    prefix: "/corporate/forgot_password",
  },
};

export default config;
