import React from 'react'
import { ModuleMenuBtnExternal } from '../lib-atomic-design-section'
import CONFIG from './config';

const App = props => {
  return <div>
    <ModuleMenuBtnExternal {...{props, ...CONFIG.routes}} />
    </div>
}

export default App;
