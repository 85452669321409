import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_ADDRESS_WITH_ID: "FETCH_ADDRESS_WITH_ID",
  RESET: "RESET"
};
const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false
  };
  return state;
};
const fetchAddyWithId = async (state, addysearchwithid) => {
  state = {
    ...state,
    isStatus: addysearchwithid.status,
    expireToken: sanitizeExpire({
      status: addysearchwithid.status,
      data: addysearchwithid.data,
      name: "addysearchwithidobj"
    }),
    data: sanitizeData({
      status: addysearchwithid.status,
      data: addysearchwithid.data,
      name: "addysearchwithidobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADDRESS_WITH_ID:
      return fetchAddyWithId(state, action.addysearchwithid);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const addysearchwithid = {
  init,
  actionTypes,
  reducer
};

export default addysearchwithid;
