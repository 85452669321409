import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Guest Info";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          generatepayment: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "https://tr4ns10.tr4ns.com/tx-payment/payment",
            url:
              setup.setup.api.tranxactor_service.url[environment] +
              "/tx-payment/stripe",
            method: "POST",
          },
          checkout: {
            headers: {
              appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "PUT",
          },
          getorder: {
            headers: {
              // appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "GET",
          },
          register: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.guest.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/registration",
            method: "PUT",
          },
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.guest.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/auth/token",
            method: "POST",
          },
          memberenquiry: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/clients",
            method: "GET",
          },
          create: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "POST",
          },
          search: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: "https://api.business.govt.nz/services/v4/nzbn/entities",
            method: "GET",
          },
          searchaddy: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "https://api.addy.co.nz",
            url: "https://api.addy.co.nz/search",
            method: "GET",
          },
          searchaddywithid: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "https://api.addy.co.nz",
            url: "https://api.addy.co.nz/address",
            method: "GET",
          },
        },
      },
      objects: {
        companyname: {
          name: "companyname",
          initial: "",
          label: "Company Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        nzbn: {
          name: "nzbn",
          initial: "",
          label: "New Zealand Business Number",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
        email: {
          name: "email",
          initial: "",
          label: "Email",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        mobile: {
          name: "mobile",
          initial: "",
          label: "Mobile",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_address_1: {
          name: "billing_address_1",
          initial: "",
          label: "Street Number & Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_suburb_1: {
          name: "billing_suburb_1",
          initial: "",
          label: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_city_1: {
          name: "billing_city_1",
          initial: "",
          label: "City",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_postcode_1: {
          name: "billing_postcode_1",
          initial: "",
          label: "Postal Code",
          helperText: false,
          rules: ["isNotNull"],
        },
        toggledelivery: {
          name: "toggledelivery",
          initial: false,
          label: "Delivery address is the same as billing address",
          helperText: false,
          rules: false,
        },
        billing_address_2: {
          name: "billing_address_2",
          initial: "",
          label: "Street Number & Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_suburb_2: {
          name: "billing_suburb_2",
          initial: "",
          label: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_city_2: {
          name: "billing_city_2",
          initial: "",
          label: "City",
          helperText: false,
          rules: ["isNotNull"],
        },
        billing_postcode_2: {
          name: "billing_postcode_2",
          initial: "",
          label: "Postal Code",
          helperText: false,
          rules: ["isNotNull"],
        },
      },
      responses: {
        fail: false,
        emailexist: "The email already exist.",
        traderexist: "NZBN already registered.",
      },
    },
  },
  routes: {
    label: "Order Guest Info",
    prefix: "/corporate/order/form/guest/info",
  },
  setup,
};

export default config;
