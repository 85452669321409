import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* module */
import OrderItem from "../item";
/* module */

const index = props => {
  return <OrderItem {...props} />;
};

export default index;
