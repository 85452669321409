import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_STRIPE_PAYMENT: "FETCH_STRIPE_PAYMENT",
};

const fetchCheckoutStripe = async (state, stripepayment) => {
  state = {
    ...state,
    isStatus: stripepayment.status,
    expireToken: sanitizeExpire({
      status: stripepayment.status,
      data: stripepayment.data,
      name: "stripepaymentobj",
    }),
    data: sanitizeData({
      status: stripepayment.status,
      data: stripepayment.data,
      name: "stripepaymentobj",
    }),
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STRIPE_PAYMENT:
      return fetchCheckoutStripe(state, action.stripepayment);
    default:
      return state;
  }
};

const stripepayment = {
  init,
  actionTypes,
  reducer,
};

export default stripepayment;
