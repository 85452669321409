import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
/* packages */

/* module */
import CMSActivateHeader from "../../app-module-cms-section-activate-header/default";
import CardActivationContainer from "../../app-module-card-activation-container/default";
import CardActivationForm from "../../app-module-card-activation-form-card/default";
/* module */

const index = observer(props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSActivateHeader />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <CardActivationContainer>
            <CardActivationForm {...props} />
          </CardActivationContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
});

export default index;
