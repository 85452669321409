import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleTitle,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import Container from "../../app-module-account-profile-container/default";
/* container */

/* module */
import CMSHomeHeader from "../../app-module-cms-section-home-header/default";
/* module */
import Content from "../contents/contents";
const index = (props) => {
  console.log(props.location.state);
  if (
    !props.location.state ||
    (props.location.state && !props.location.state.orderid)
  ) {
    props.history.push("/corporate/transactions");
    return;
  }
  const { orderid } = props.location.state;
  const [previewDataReady, setPreviewDataReady] = useState(false);
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSHomeHeader {...props} />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          {/* <OrderContainer {...props}> */}
          <Container.TransactionDetail
            {...{ ...props, ...{ previewDataReady, setPreviewDataReady } }}
          >
            <ModuleTitle>
              <b>Order Details - {orderid}</b>
            </ModuleTitle>
            <ModuleFormWrapper>
              <Content
                {...{
                  ...props,
                  ...{ previewDataReady, setPreviewDataReady },
                }}
              />
              {/* <AccountProfileTransactionItem {...props} /> */}
            </ModuleFormWrapper>
          </Container.TransactionDetail>
          {/* </OrderContainer> */}
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
