import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemLongWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold,
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

/* module */
import CMSActivateHeader from "../../app-module-cms-section-activate-header/default";
/* module */

const index = observer((props) => {
  const handleOrder = () => {
    props.history.push("/corporate/order/items");
  };

  const handleDetail = () => {
    props.history.push("/corporate/account_profile/update");
  };

  const handleTransaction = () => {
    props.history.push("/corporate/account_profile/transactions");
  };

  const handleActivate = () => {
    props.history.push("/corporate/card_activation");
  };

  const handleUpdatePassword = () => {
    props.history.push("/corporate/account_profile/update_pw");
  };
  const handleChildAccount = () => {
    props.history.push("/corporate/account_profile/manage_child");
  };

  /* data props */
  const orderButton = { size: "medium", onClick: handleOrder };
  const detailButton = { size: "medium", onClick: handleDetail };
  const transactionButton = { size: "medium", onClick: handleTransaction };
  const activateButton = { size: "medium", onClick: handleActivate };
  const childAccountsButton = { size: "medium", onClick: handleChildAccount };
  const updatePasswordButton = {
    size: "medium",
    onClick: handleUpdatePassword,
  };

  return (
    <Fragment>
      <div style={{ marginBottom: 20 }}>
        <ModuleFormItemLongWrapper>
          <Buttons.Normal {...orderButton}>Order Now</Buttons.Normal>
          <Buttons.Normal {...detailButton}>Update Details</Buttons.Normal>
          <Buttons.Normal {...childAccountsButton}>
            Child Accounts
          </Buttons.Normal>
          <Buttons.Normal {...transactionButton}>
            View Transactions
          </Buttons.Normal>
          <Buttons.Normal {...activateButton}>Activate Cards</Buttons.Normal>
          <Buttons.Normal {...updatePasswordButton}>
            Update Password
          </Buttons.Normal>
        </ModuleFormItemLongWrapper>
      </div>
    </Fragment>
  );
});

export default index;
