import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSRegisterHeader from "../../app-module-cms-section-register-header/default";
import RegisterForm from "../../app-module-register-form-register/default";
/* module */

const index = observer(props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSRegisterHeader />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <RegisterForm {...props} />
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
});

export default index;
