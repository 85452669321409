import React, { useContext } from "react";

/* packages */
import Fetch from "../fetch";
import FetchBlob from "../fetch-blob";
import FetchJson from "../fetch-json";
import AxiosJson from "../axios-json";
import AxiosFormData from "../axios-form-data";
/* packages */

const index = ({ url, method, headers, responseType }) => {
  // const funcContext  = useContext(contextStore);
  const funcMethod = async ({ body, query, newheaders, state }, store) => {
    // funcContext.setLoading({loading: true, status: true, data: {blob: false, json: false} });
    store.state = { ...store.state, isLoading: true };
    var new_url = JSON.parse(JSON.stringify(url));
    if (query) new_url = new_url + query;
    if (newheaders) headers = newheaders;
    let fetch = null;
    if (responseType === "blob") {
      // blob
      fetch = await FetchBlob({
        url: new_url,
        config: {
          method,
          headers,
          body,
        },
      });
    } else if (responseType === "formdata") {
      // blob
      fetch = await AxiosFormData({
        url: new_url,
        formData: body,
        config: {
          method,
          headers,
        },
      });
    } else {
      // json
      console.log(new_url, method, headers, body);
      if (body) {
        fetch = await AxiosJson({
          url: new_url,
          config: {
            method,
            headers,
            data: body,
          },
        });
      } else {
        fetch = await AxiosJson({
          url: new_url,
          config: {
            method,
            headers,
          },
        });
      }
    }
    store.state = { ...store.state, isLoading: false };
    return { status: fetch[0], data: fetch[1] };
    // return dispatch({
    //   ...dispatch_params,
    //   data: { status: fetch[0], data: fetch[1] }
    // });
    // console.log(fetch)
    // funcContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  };
  return [funcMethod];
};

export default index;
