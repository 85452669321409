import React, { Fragment } from "react";

import TextField from "@material-ui/core/TextField";

/* atoms */
import { Valid, Invalid } from "../feedbacks";
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const password = (props) => {
  return (
    <Fragment>
      <TextField
        {...props}
        fullWidth
        variant="outlined"
        margin="dense"
        type="password"
      />
      {props.value.length > 0 ? <Valid {...props} /> : null}
      <Invalid {...props} />
    </Fragment>
  );
};

export default password;
