// import { useState, useEffect } from "react";

/* packages */
// import { WindowSize } from "../../lib-atomic-functions";
/* packages */

const layoutSize = (width, height) => {
  const sizes = [
    { minWidth: 900, maxWidth: 100000, layoutCols: 4 },
    { minWidth: 800, maxWidth: 900, layoutCols: 4 },
    { minWidth: 700, maxWidth: 800, layoutCols: 4 },
    { minWidth: 600, maxWidth: 700, layoutCols: 4 },
    { minWidth: 500, maxWidth: 600, layoutCols: 2 },
    { minWidth: 400, maxWidth: 500, layoutCols: 1 },
    { minWidth: 300, maxWidth: 400, layoutCols: 1 },
    { minWidth: 1, maxWidth: 300, layoutCols: 1 },
  ]

  const bracket = sizes.filter(
    size => width >= size.minWidth && width <= size.maxWidth
  )[0]

  const getFullWidth = platform => {
    return platform === 'mob' ? (width * 35) / 100 : (width * 50) / 100
  }

  const getLayoutWidth = bracket => {
    let bracketWidth = bracket.minWidth
    if (bracketWidth < 400) bracketWidth = bracket.maxWidth
    return bracketWidth
  }

  const getLayoutHeight = bracket => {
    //return (1 / bracket.layoutCols) * 1200;
    return (1.6 / bracket.layoutCols) * getLayoutWidth(bracket)
  }

  const getColsWidth = boxWidth => {
    return parseInt(boxWidth) <= bracket.layoutCols
      ? parseInt(boxWidth)
      : bracket.layoutCols
  }

  const getColsHeight = boxHeight => {
    if (bracket.layoutCols < 2) return getLayoutHeight(bracket)
    return parseInt(boxHeight) * getLayoutHeight(bracket)
  }

  const getColsWidthHeight = ({ width, height }) => {
    return ([width, height] = [getColsWidth(width), getColsHeight(height)])
  }

  const layout = {
    layoutWidth: getLayoutWidth(bracket),
    layoutHeight: getLayoutHeight(bracket),
    layoutCols: bracket.layoutCols,
    width,
    height,
    getColsWidth,
    getColsHeight,
    getColsWidthHeight,
    getFullWidth,
  }

  return layout
}

export default layoutSize
