import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Guest Landing";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          list: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://172.16.1.115:1337/pages/1",
            //url: "/Westfield-Reporting/report21?",
            url: "http://172.16.1.115:1337/pages/1",
            method: "GET",
          },
          create: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "POST",
          },
          promo: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
              moduleCode:
                setup.setup.westfield.corp.guest.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/promo-code",
            url: setup.setup.api.tranxactor.url[environment] + "/orders/promo-code?",
            // url: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/promo-code?",
            method: "POST",
          }
        },
      },
      objects_promocode: {
        promoCode: {
          name: "promoCode",
          initial: "",
          placeholder: "Enter Code",
          helperText: false,
          rules: false,
        },
      },
      responses: {
        promoFail: "The promo code is invalid",
        promoSuccess: "Promo code applied!",
      },
    },
  },
  routes: {
    label: "Order Registered Landing",
    prefix: "/corporate/order/items",
  },
  setup,
};

export default config;
