import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
/* packages */

const index = (props) => {
  console.log(props);
  const productLS = LocalStorage.getLS().products;

  const { assets, location } = props;
  const { pathname } = location;
  const { handleDelete, handleEdit, item } = props;
  console.log(handleEdit, " handleEdit");
  // const { image } = assets[item.product];
  const { image, value, quantity, carrieroption } = item;
  const iscarrier = carrieroption === "1" ? true : false;
  const total = value * quantity;
  const displayValueQuantity =
    total < 1
      ? "x " + quantity
      : "$" +
        parseFloat(value).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) +
        " x " +
        quantity;
  const displayTotal =
    total < 1
      ? "None"
      : "$" +
        parseFloat(total).toLocaleString("en-US", { maximumFractionDigits: 2 });

  return (
    <ModuleFormItemWrapper>
      <ModulePaperWrapper>
        <div style={{ minHeight: 150 }}>
          <ModuleFormWrapper>
            <ModuleFormItemWrapper>
              <img
                src={image}
                width="100%"
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              {iscarrier && (
                <small style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <CardGiftcardIcon /> with greeting
                </small>
              )}
            </ModuleFormItemWrapper>

            <ModuleFormItemWrapper>
              <ModuleText>{displayValueQuantity}</ModuleText>
              <ModuleText>
                <b>{displayTotal}</b>
              </ModuleText>
              {pathname === "/corporate/order/summary" ? null : (
                <ModuleText>
                  <small>
                    <b>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(item);
                        }}
                      >
                        REMOVE
                      </a>
                    </b>
                  </small>
                </ModuleText>
              )}
              {pathname === "/corporate/order/summary" ? null : (
                <ModuleText>
                  <small>
                    <b>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEdit(item);
                        }}
                      >
                        EDIT
                      </a>
                    </b>
                  </small>
                </ModuleText>
              )}
            </ModuleFormItemWrapper>
          </ModuleFormWrapper>
        </div>
      </ModulePaperWrapper>
    </ModuleFormItemWrapper>
  );
};

export default index;
