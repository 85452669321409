import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Activate Form";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cardactivate: {
      api: {
        endpoints: {
          activate: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/orders/",
            method: "POST",
          },
        },
      },
      objects: {
        cardNumber: {
          name: "cardNumber",
          initial: "",
          label: "Card Number",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
        emailAddress: {
          name: "emailAddress",
          initial: "",
          label: "Email Address",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        orderNumber: {
          name: "orderNumber",
          initial: "",
          label: "Order Number",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
      },
      responses: {
        // fail: "Incorrect email or password",
        unverified: "Please tick the recaptcha to proceed.",
      },
    },
  },
  routes: {
    label: "Activate Form",
    prefix: "/corporate/card_activation/form",
  },
  setup,
};

export default config;
