import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "CMS";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          pages: {
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + "/pages",
            method: "GET",
          },
        },
      },
    },
  },
  routes: {
    label: "CMS",
    prefix: "/corporate/cms",
  },
};

export default config;
