import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../loader/register";
import Marked from "marked";
import { LocalStorage } from "../../lib-atomic-functions";

/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Buttons } from "../../lib-atomic";
/* packages */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingBottom: 150,
  },
  paper1: {
    height: 440,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#000",
  },
  title: {
    color: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const index = (props) => {
  const classes = useStyles();

  /* state */
  /* --- cms */
  /* -------------- */
  const { cms } = useContext(GlobalContext);
  /* -------------- */
  /* --- cms */
  /* state */

  const isResultReady =
    !cms.state.data || cms.state.data.length < 1 || cms.state.isLoading
      ? false
      : true;

  /* data loader */
  if (!isResultReady) {
    return <Spinner />;
  }
  /* data loader */

  /* data props */
  const home = cms.state.data.pages[0];
  const mediaPath = CONFIG.setup.assets.media;
  const media_01 = home.media_01;
  const media_02 = home.media_02;
  const media_03 = home.media_03;
  const media_04 = home.media_04;
  const media_05 = home.media_05;
  const description_01 = { __html: Marked(home.description_01) };
  const description_02 = { __html: Marked(home.description_02) };
  const description_03 = { __html: Marked(home.description_03) };
  const description_04 = { __html: Marked(home.description_04) };
  const description_05 = { __html: Marked(home.description_05) };
  const backgroundImage1 = "url(" + mediaPath + media_01.url + ")";
  const backgroundImage2 = "url(" + mediaPath + media_02.url + ")";
  const backgroundImage3 = "url(" + mediaPath + media_03.url + ")";
  const backgroundImage4 = "url(" + mediaPath + media_04.url + ")";
  const backgroundImage5 = "url(" + mediaPath + media_05.url + ")";
  const { login } = LocalStorage.getLS();
  const isLoggedIn = login.token !== undefined;
  const objects = [
    {
      linkTo: "/corporate/register",
      linkText: "REGISTER NOW",
      title: "Corporate Gift Card Accounts",
      description: description_02,
      backgroundImage: backgroundImage2,
    },
    {
      linkTo: "/corporate/card_activation",
      linkText: "ACTIVATE NOW",
      title: "Activate Gift Cards",
      description: description_03,
      backgroundImage: backgroundImage3,
    },
    {
      linkTo: "/corporate/login",
      linkText: "LOG IN",
      title: "LOG IN",
      description: description_04,
      backgroundImage: backgroundImage4,
    },
    {
      linkTo: "/corporate/faq",
      linkText: "FIND OUT MORE",
      title: "Gift Card Customer Support",
      description: description_05,
      backgroundImage: backgroundImage5,
    },
  ];
  const objects2 = [
    {
      linkTo: "/corporate/order/items",
      linkText: "BUY NOW",
      title: "Order Gift Cards",
      description: description_02,
      backgroundImage: backgroundImage2,
    },
    {
      linkTo: "/corporate/card_activation",
      linkText: "ACTIVATE NOW",
      title: "Activate Gift Cards",
      description: description_03,
      backgroundImage: backgroundImage3,
    },
    {
      linkTo: "/corporate/login",
      linkText: "LOG IN",
      title: "LOG IN",
      description: description_04,
      backgroundImage: backgroundImage4,
    },
    {
      linkTo: "/corporate/faq",
      linkText: "FIND OUT MORE",
      title: "Gift Card Customer Support",
      description: description_05,
      backgroundImage: backgroundImage5,
    },
  ];

  /* data props */
  const handleLink = (linkTo) => {
    props.history.push(linkTo);
  };
  if (!isLoggedIn) {
    return (
      <Fragment>
        <div className={classes.root}>
          <Grid container spacing={3}>
            {objects.map((cardItem, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <div
                    className={classes.paper1}
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))," +
                        cardItem.backgroundImage,
                      backgroundSize: "cover",
                      width: "100%",
                      height: 340,
                    }}
                  >
                    <section className={classes.title}>
                      <div dangerouslySetInnerHTML={cardItem.description}></div>
                      <p style={{ marginTop: 40 }}>
                        <div className="btnWrap">
                          <Buttons.Normal
                            {...{
                              size: "large",
                              onClick: handleLink.bind(this, cardItem.linkTo),
                            }}
                          >
                            {cardItem.linkText}
                          </Buttons.Normal>
                        </div>
                      </p>
                    </section>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className={classes.root}>
          <Grid container spacing={3}>
            {objects2.map((cardItem, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <div
                    className={classes.paper1}
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))," +
                        cardItem.backgroundImage,
                      backgroundSize: "cover",
                      width: "100%",
                      height: 340,
                    }}
                  >
                    <section className={classes.title}>
                      <div dangerouslySetInnerHTML={cardItem.description}></div>
                      <p style={{ marginTop: 40 }}>
                        <div className="btnWrap">
                          <Buttons.Normal
                            {...{
                              size: "large",
                              onClick: handleLink.bind(this, cardItem.linkTo),
                            }}
                          >
                            {cardItem.linkText}
                          </Buttons.Normal>
                        </div>
                      </p>
                    </section>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Fragment>
    );
  }
};

export default index;
