import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Marked from "marked";

/* packages */
import { WindowSize } from "../../lib-atomic-functions";
import { GlobalContext } from "../../lib-atomic-context";
import { ModuleContainerLg } from "../../lib-atomic-design-section";
/* packages */

/* module */
import Loader from "../loader";
/* module */

/* stores */
import { CMSFetch } from "../../app-module-cms-container/stores";
/* stores */

import CONFIG from "../config";

const index = observer((props) => {
  /* state */
  /* --- cms */
  /* -------------- */
  const { cms } = useContext(GlobalContext);
  /* -------------- */
  /* --- cms */
  /* state */
  const { width, height } = WindowSize();

  /* data props */
  const faqPage = cms.state.data.pages[1];
  const mediaPath = CONFIG.setup.assets.media;
  const media_01 = faqPage.media_01;
  const description_01 = {
    __html: faqPage.description_01 ? Marked(faqPage.description_01) : "",
  };
  const backgroundImage = "url(" + mediaPath + media_01.url + ")";
  /* data props */

  const fontResizer =
    width > 800
      ? "banner size1"
      : width > 500
      ? "banner size2"
      : "banner size3";
  return (
    <Fragment>
      <div style={{ backgroundImage }} className="bannerWrap">
        <section className={fontResizer}>
          <ModuleContainerLg>
            <div dangerouslySetInnerHTML={description_01}></div>
          </ModuleContainerLg>
        </section>
      </div>
    </Fragment>
  );
});

export default index;
