import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

/* module */
import PDFFeesItem from "./fees-item";
/* module */

const productsBody = ({ products, isCardNumberExist }) => {
  return products.map((product, index) => {
    return (
      <PDFFeesItem
        key={index}
        {...product}
        {...{ isCardNumberExist: isCardNumberExist }}
      />
    );
  });
};

export default productsBody;
