import React, { useState, useReducer, createContext } from "react";

import useAsyncReducer from "./useAsyncReducer";

import {
  cmsReducer,
  tokenReducer,
  nzbnSearchReducer,
  nzbnKeyReducer,
  registerReducer,
  activateReducer,
  addressSearchWithAddressReducer,
  addressSearchWithIdReducer,
  productReducer,
  paymentReducer,
  feesReducer,
  notesReducer,
  paymentReasonReducer,
  productallReducer,
  productBreakReducer,
  checkoutReducer,
  generatePaymentReducer,
  memberEnquiryReducer,
  registerActivationReducer,
  updateDetailReducer,
  transactionsReducer,
  transactionsDetailReducer,
  createContactReducer,
  getContactReducer,
  orderDetailReducer,
  forgotPasswordReducer,
  forgotPasswordUpdateReducer,
  checkPaymentReducer,
  sendAttachmentReducer,
  checkStripePaymentReducer,
  updatePasswordReducer,
  sendContactUsReducer,
  childAccountsReducer,
  activateChildReducer,
  terminateChildReducer,
  deliveryAddressReducer,
  promoCodeReducer,
} from "./reducers";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // const [masterSetup, setMasterSetup] = useState([]);
  // const [masterContent, setMasterContent] = useState([]);

  /* cms reducer */
  const [cmsState, cmsDispatch] = useAsyncReducer(cmsReducer.reducer, {
    ...cmsReducer.init,
  });

  /* token reducer */
  const [tokenState, tokenDispatch] = useAsyncReducer(tokenReducer.reducer, {
    ...tokenReducer.init,
  });

  /* register reducer */
  const [registerState, registerDispatch] = useAsyncReducer(
    registerReducer.reducer,
    {
      ...registerReducer.init,
    },
  );

  /* nzbn search reducer */
  const [nzbnSearchState, nzbnSearchDispatch] = useAsyncReducer(
    nzbnSearchReducer.reducer,
    {
      ...nzbnSearchReducer.init,
    },
  );

  /* nzbn key fetcher reducer */
  const [nzbnKeyState, nzbnKeyDispatch] = useAsyncReducer(
    nzbnKeyReducer.reducer,
    {
      ...nzbnKeyReducer.init,
    },
  );

  /* activate account reducer */
  const [activateState, activateDispatch] = useAsyncReducer(
    activateReducer.reducer,
    {
      ...activateReducer.init,
    },
  );

  /* address search reducer */
  const [addressSearchWithAddressState, addressSearchWithAddressDispatch] =
    useAsyncReducer(addressSearchWithAddressReducer.reducer, {
      ...addressSearchWithAddressReducer.init,
    });

  /* address search with id reducer */
  const [addressSearchWithIdState, addressSearchWithIdDispatch] =
    useAsyncReducer(addressSearchWithIdReducer.reducer, {
      ...addressSearchWithIdReducer.init,
    });

  /* product reducer */
  const [productState, productDispatch] = useAsyncReducer(
    productReducer.reducer,
    {
      ...productReducer.init,
    },
  );

  /* payment reducer */
  const [paymentState, paymentDispatch] = useAsyncReducer(
    paymentReducer.reducer,
    {
      ...paymentReducer.init,
    },
  );

  /* fees reducer */
  const [feesState, feesDispatch] = useAsyncReducer(feesReducer.reducer, {
    ...feesReducer.init,
  });

  /* notes reducer */
  const [notesState, notesDispatch] = useAsyncReducer(notesReducer.reducer, {
    ...notesReducer.init,
  });

  /* payment reason reducer */
  const [paymentReasonState, paymentReasonDispatch] = useAsyncReducer(
    paymentReasonReducer.reducer,
    {
      ...paymentReasonReducer.init,
    },
  );

    /* promo cdde reducer */
    const [promoCodeState, promoCodeDispatch] = useAsyncReducer(
      promoCodeReducer.reducer,
      {
        ...promoCodeReducer.init,
      },
    );  

  /* product all reducer */
  const [productallState, productallDispatch] = useAsyncReducer(
    productallReducer.reducer,
    {
      ...productallReducer.init,
    },
  );

  /* product all reducer */
  const [productBreakState, productBreakDispatch] = useAsyncReducer(
    productBreakReducer.reducer,
    {
      ...productBreakReducer.init,
    },
  );

  /* checkout reducer */
  const [checkoutState, checkoutDispatch] = useAsyncReducer(
    checkoutReducer.reducer,
    {
      ...checkoutReducer.init,
    },
  );

  /* generate payment reducer */
  const [generatePaymentState, generatePaymentDispatch] = useAsyncReducer(
    generatePaymentReducer.reducer,
    {
      ...generatePaymentReducer.init,
    },
  );
  /* check payment reducer */
  const [checkPaymentState, checkPaymentDispatch] = useAsyncReducer(
    checkPaymentReducer.reducer,
    {
      ...checkPaymentReducer.init,
    },
  );

  /* member enquiry reducer */
  const [memberEnquiryState, memberEnquiryDispatch] = useAsyncReducer(
    memberEnquiryReducer.reducer,
    {
      ...memberEnquiryReducer.init,
    },
  );

  /* transactions reducer */
  const [transactionsState, transactionsDispatch] = useAsyncReducer(
    transactionsReducer.reducer,
    {
      ...transactionsReducer.init,
    },
  );

  /* transactions detail reducer */
  const [transactionsDetailState, transactionsDetailDispatch] = useAsyncReducer(
    transactionsDetailReducer.reducer,
    {
      ...transactionsDetailReducer.init,
    },
  );

  /* register account activation reducer */
  const [registerActivationState, registerActivationDispatch] = useAsyncReducer(
    registerActivationReducer.reducer,
    {
      ...registerActivationReducer.init,
    },
  );

  /* register account activation reducer */
  const [updateDetailState, updateDetailDispatch] = useAsyncReducer(
    updateDetailReducer.reducer,
    {
      ...updateDetailReducer.init,
    },
  );

  /* create contact reducer */
  const [createContactState, createContactDispatch] = useAsyncReducer(
    createContactReducer.reducer,
    {
      ...createContactReducer.init,
    },
  );

  /* get contact reducer */
  const [getContactState, getContactDispatch] = useAsyncReducer(
    getContactReducer.reducer,
    {
      ...getContactReducer.init,
    },
  );

  /* get order detail reducer */
  const [orderDetailState, orderDetailDispatch] = useAsyncReducer(
    orderDetailReducer.reducer,
    {
      ...orderDetailReducer.init,
    },
  );

  /* forgot password reducer */
  const [forgotPasswordState, forgotPasswordDispatch] = useAsyncReducer(
    forgotPasswordReducer.reducer,
    {
      ...forgotPasswordReducer.init,
    },
  );
  /* forgot password update reducer */
  const [forgotPasswordUpdateState, forgotPasswordUpdateDispatch] =
    useAsyncReducer(forgotPasswordUpdateReducer.reducer, {
      ...forgotPasswordUpdateReducer.init,
    });

  /* send attachment reducer */
  const [sendAttachmentState, sendAttachmentDispatch] = useAsyncReducer(
    sendAttachmentReducer.reducer,
    {
      ...sendAttachmentReducer.init,
    },
  );

  /* check stripe payment reducer */
  const [checkStripePaymentState, checkStripePaymentDispatch] = useAsyncReducer(
    checkStripePaymentReducer.reducer,
    {
      ...checkStripePaymentReducer.init,
    },
  );

  /* update password reducer */
  const [updatePasswordState, updatePasswordDispatch] = useAsyncReducer(
    updatePasswordReducer.reducer,
    {
      ...updatePasswordReducer.init,
    },
  );

  /* send contact form reducer */
  const [sendContactUsState, sendContactUsDispatch] = useAsyncReducer(
    sendContactUsReducer.reducer,
    {
      ...sendContactUsReducer.init,
    },
  );

  /* child account reducer */
  const [childAccountsState, childAccountsDispatch] = useAsyncReducer(
    childAccountsReducer.reducer,
    {
      ...childAccountsReducer.init,
    },
  );

  /* activate child reducer */
  const [activateChildState, activateChildDispatch] = useAsyncReducer(
    activateChildReducer.reducer,
    {
      ...activateChildReducer.init,
    },
  );
  /* terminate child reducer */
  const [terminateChildState, terminateChildDispatch] = useAsyncReducer(
    terminateChildReducer.reducer,
    {
      ...terminateChildReducer.init,
    },
  );
  /* delivery address reducer */
  const [deliveryAddressState, deliveryAddressDispatch] = useAsyncReducer(
    deliveryAddressReducer.reducer,
    {
      ...deliveryAddressReducer.init,
    }
  );

  const store = {
    cms: {
      state: cmsState,
      dispatch: cmsDispatch,
      actionTypes: cmsReducer.actionTypes,
    },
    token: {
      state: tokenState,
      dispatch: tokenDispatch,
      actionTypes: tokenReducer.actionTypes,
    },
    register: {
      state: registerState,
      dispatch: registerDispatch,
      actionTypes: registerReducer.actionTypes,
    },
    nzbnsearch: {
      state: nzbnSearchState,
      dispatch: nzbnSearchDispatch,
      actionTypes: nzbnSearchReducer.actionTypes,
    },
    nzbnkey: {
      state: nzbnKeyState,
      dispatch: nzbnKeyDispatch,
      actionTypes: nzbnKeyReducer.actionTypes,
    },
    activate: {
      state: activateState,
      dispatch: activateDispatch,
      actionTypes: activateReducer.actionTypes,
    },
    addysearch: {
      state: addressSearchWithAddressState,
      dispatch: addressSearchWithAddressDispatch,
      actionTypes: addressSearchWithAddressReducer.actionTypes,
    },
    addysearchwithid: {
      state: addressSearchWithIdState,
      dispatch: addressSearchWithIdDispatch,
      actionTypes: addressSearchWithIdReducer.actionTypes,
    },
    product: {
      state: productState,
      dispatch: productDispatch,
      actionTypes: productReducer.actionTypes,
    },
    payment: {
      state: paymentState,
      dispatch: paymentDispatch,
      actionTypes: paymentReducer.actionTypes,
    },
    fees: {
      state: feesState,
      dispatch: feesDispatch,
      actionTypes: feesReducer.actionTypes,
    },
    notes: {
      state: notesState,
      dispatch: notesDispatch,
      actionTypes: notesReducer.actionTypes,
    },
    paymentreason: {
      state: paymentReasonState,
      dispatch: paymentReasonDispatch,
      actionTypes: paymentReasonReducer.actionTypes,
    },
    promocode: {
      state: promoCodeState,
      dispatch: promoCodeDispatch,
      actionTypes: promoCodeReducer.actionTypes,
    },
    productall: {
      state: productallState,
      dispatch: productallDispatch,
      actionTypes: productallReducer.actionTypes,
    },
    productbreak: {
      state: productBreakState,
      dispatch: productBreakDispatch,
      actionTypes: productBreakReducer.actionTypes,
    },
    checkout: {
      state: checkoutState,
      dispatch: checkoutDispatch,
      actionTypes: checkoutReducer.actionTypes,
    },
    generatepayment: {
      state: generatePaymentState,
      dispatch: generatePaymentDispatch,
      actionTypes: generatePaymentReducer.actionTypes,
    },
    memberenquiry: {
      state: memberEnquiryState,
      dispatch: memberEnquiryDispatch,
      actionTypes: memberEnquiryReducer.actionTypes,
    },
    transactions: {
      state: transactionsState,
      dispatch: transactionsDispatch,
      actionTypes: transactionsReducer.actionTypes,
    },
    transactionsdetails: {
      state: transactionsDetailState,
      dispatch: transactionsDetailDispatch,
      actionTypes: transactionsDetailReducer.actionTypes,
    },
    registeractivation: {
      state: registerActivationState,
      dispatch: registerActivationDispatch,
      actionTypes: registerActivationReducer.actionTypes,
    },
    updatedetail: {
      state: updateDetailState,
      dispatch: updateDetailDispatch,
      actionTypes: updateDetailReducer.actionTypes,
    },
    createcontact: {
      state: createContactState,
      dispatch: createContactDispatch,
      actionTypes: createContactReducer.actionTypes,
    },
    getcontact: {
      state: getContactState,
      dispatch: getContactDispatch,
      actionTypes: getContactReducer.actionTypes,
    },
    orderdetail: {
      state: orderDetailState,
      dispatch: orderDetailDispatch,
      actionTypes: orderDetailReducer.actionTypes,
    },
    forgotpassword: {
      state: forgotPasswordState,
      dispatch: forgotPasswordDispatch,
      actionTypes: forgotPasswordReducer.actionTypes,
    },
    forgotpasswordupdate: {
      state: forgotPasswordUpdateState,
      dispatch: forgotPasswordUpdateDispatch,
      actionTypes: forgotPasswordUpdateReducer.actionTypes,
    },
    checkpayment: {
      state: checkPaymentState,
      dispatch: checkPaymentDispatch,
      actionTypes: checkPaymentReducer.actionTypes,
    },
    sendattachment: {
      state: sendAttachmentState,
      dispatch: sendAttachmentDispatch,
      actionTypes: sendAttachmentReducer.actionTypes,
    },
    stripepayment: {
      state: checkStripePaymentState,
      dispatch: checkStripePaymentDispatch,
      actionTypes: checkStripePaymentReducer.actionTypes,
    },
    updatepassword: {
      state: updatePasswordState,
      dispatch: updatePasswordDispatch,
      actionTypes: updatePasswordReducer.actionTypes,
    },
    sendcontactus: {
      state: sendContactUsState,
      dispatch: sendContactUsDispatch,
      actionTypes: sendContactUsReducer.actionTypes,
    },
    childaccounts: {
      state: childAccountsState,
      dispatch: childAccountsDispatch,
      actionTypes: childAccountsReducer.actionTypes,
    },
    activatechild: {
      state: activateChildState,
      dispatch: activateChildDispatch,
      actionTypes: activateChildReducer.actionTypes,
    },
    terminatechild: {
      state: terminateChildState,
      dispatch: terminateChildDispatch,
      actionTypes: terminateChildReducer.actionTypes,
    },
    deliveryaddress: {
      state: deliveryAddressState,
      dispatch: deliveryAddressDispatch,
      actionTypes: deliveryAddressReducer.actionTypes,
    }
  };

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};
