import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import QueryString from "query-string";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold,
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

/* module */
import CMSActivateHeader from "../../app-module-cms-section-activate-header/default";
import CardActivationContainer from "../../app-module-card-activation-container/default";
/* module */
const prepareTexts = (props) => {
  const { state } = props.location;
  if (state === undefined) {
    const parsed = QueryString.parse(props.location.search);
    const { orderid } = parsed;
    if (orderid !== undefined) {
      return {
        // title: "Sorry, we are unable to proccess Order " + orderid,
        // text:
        //   "Your order cannot be proccess at this moment. Please try again later.",
        title: "Sorry, payment was not successful",
        text: "Please try again",
      };
    } else {
      return {
        title: "Sorry, we are unable to proccess order",
        text:
          "Your order cannot be proccess at this moment. Please try again later.",
      };
    }
  } else {
    const { orderIdPassed, isTokenExpire, isPaymentFailed, returnUrl } = state;
    if (isTokenExpire) {
      return {
        title: "Sorry, your Session has ended.",
        text: "Please login and try again",
      };
    } else {
      if (isPaymentFailed) {
        return {
          title: "Sorry, payment was not successful",
          text: "Please try again",
        };
      } else {
        return {
          title: "Sorry, we are unable to proccess order",
          text:
            "Your order cannot be proccess at this moment. Please try again later.",
        };
      }
    }
  }
};
const index = (props) => {
  console.log(props);
  const { title, text } = prepareTexts(props);

  const handleButton = () => {
    const { state } = props.location;
    if (state === undefined) {
      // ?
      props.history.push("/corporate/order/summary");
    } else {
      const {
        orderIdPassed,
        returnUrl,
        isTokenExpire,
        isPaymentFailed,
      } = state;
      if (isTokenExpire) {
        if (orderIdPassed) {
          props.history.push("/corporate/login", { orderIdPassed });
        } else {
          props.history.push("/corporate/login", { returnUrl });
        }
      } else if (isPaymentFailed) {
        props.history.push("/corporate/order/summary");
      } else {
        props.history.push(returnUrl);
      }
    }
  };
  /* data props */
  const backButton = { size: "medium", onClick: handleButton };
  return (
    <ModuleFormItemWrapper>
      <ModulePaperWrapper>
        <ModuleTitle>{title}</ModuleTitle>
        <ModuleText>{text}</ModuleText>
        <br />
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            {props.location.state && props.location.state.isTokenExpire ? (
              <Buttons.Normal {...backButton}>Login & Continue</Buttons.Normal>
            ) : (
              <Buttons.Normal {...backButton}>Continue</Buttons.Normal>
            )}
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemWrapper>
  );
};

export default index;
