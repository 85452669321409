import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { LocalStorage, FetchContextApiV2 } from "../../lib-atomic-functions";

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;
const GETCHILDACCOUNTSCONFIG = {
  url: ENDPOINTS.childaccounts.url,
  method: ENDPOINTS.childaccounts.method,
  headers: ENDPOINTS.childaccounts.headers,
  responseType: "json",
};
const mockChildren = {
  status: true,
  data: {
    blob: false,
    json: {
      outcomeCode: "0",
      outcomeMessage: "Success!",
      outcomeDeveloperMessage: "",
      clients: [
        {
          id: 32263078,
          externalId: "94290338360657",
          name: "testCompany111111122255",
          firstName: "James",
          lastName: "Bond",
          phone1: "125263647",
          mobileNumber: "36735845",
          titleId: 1,
          emailAddress: "lewis.park123@tranxactor.com",
          physicalAddress1: "43 Abbotsford Terrace",
          physicalAddress2: null,
          physicalSuburb: "Devonport",
          physicalCity: "Auckland",
          physicalState: null,
          physicalPostcode: "0624",
          address1: null,
          address2: null,
          suburb: null,
          city: null,
          state: null,
          postCode: null,
          custom1: null,
          custom2: null,
          custom3: null,
          statusId: 4,
          contactId: 84552,
          mailPref: 1,
          status: "pending",
        },
        {
          id: 32263079,
          externalId: "94290338360658",
          name: "testCompany2",
          firstName: "James2",
          lastName: "Bond2",
          phone1: "125263647",
          mobileNumber: "36735845",
          titleId: 1,
          emailAddress: "lewis.park2@tranxactor.com",
          physicalAddress1: "43 Abbotsford Terrace",
          physicalAddress2: null,
          physicalSuburb: "Devonport",
          physicalCity: "Auckland",
          physicalState: null,
          physicalPostcode: "0624",
          address1: null,
          address2: null,
          suburb: null,
          city: null,
          state: null,
          postCode: null,
          custom1: null,
          custom2: null,
          custom3: null,
          statusId: 4,
          contactId: 84552,
          mailPref: 1,
          status: "approved",
        },
        {
          id: 32263080,
          externalId: "94290338360659",
          name: "testCompany3",
          firstName: "James3",
          lastName: "Bond3",
          phone1: "125263647",
          mobileNumber: "36735845",
          titleId: 1,
          emailAddress: "lewis.park3@tranxactor.com",
          physicalAddress1: "43 Abbotsford Terrace",
          physicalAddress2: null,
          physicalSuburb: "Devonport",
          physicalCity: "Auckland",
          physicalState: null,
          physicalPostcode: "0624",
          address1: null,
          address2: null,
          suburb: null,
          city: null,
          state: null,
          postCode: null,
          custom1: null,
          custom2: null,
          custom3: null,
          statusId: 4,
          contactId: 84552,
          mailPref: 1,
          status: "declined",
        },
        {
          id: 32263081,
          externalId: "94290338360659",
          name: "testCompany4",
          firstName: "James4",
          lastName: "Bond4",
          phone1: "125263647",
          mobileNumber: "36735845",
          titleId: 1,
          emailAddress: "lewis.park3@tranxactor.com",
          physicalAddress1: "43 Abbotsford Terrace",
          physicalAddress2: null,
          physicalSuburb: "Devonport",
          physicalCity: "Auckland",
          physicalState: null,
          physicalPostcode: "0624",
          address1: null,
          address2: null,
          suburb: null,
          city: null,
          state: null,
          postCode: null,
          custom1: null,
          custom2: null,
          custom3: null,
          statusId: 4,
          contactId: 84552,
          mailPref: 1,
          status: "pending",
        },
      ],
    },
  },
};

const index = (props) => {
  const isNormUser = LocalStorage.getLS() && LocalStorage.getLS().user;
  const login = LocalStorage.getLS() && LocalStorage.getLS().login;
  // this should be returning Route to home instead of null
  if (!isNormUser) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  if (!login || Object.keys(isNormUser).length === 0) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  console.log(isNormUser);
  if (isNormUser && isNormUser.hasSecondary !== 1) {
    return <Redirect to="/corporate" />;
  }
  const { childaccounts } = useContext(GlobalContext);

  /* --- member enquiry  & transactions */
  const [fetchChildAccountsObj] = FetchContextApiV2(GETCHILDACCOUNTSCONFIG);
  const fetchChildAccountsExecute = async ({ newheaders }) => {
    // mock data
    // console.log(mockChildren);
    // childaccounts.dispatch({
    //   type: childaccounts.actionTypes.FETCH_CHILD_ACCOUNTS,
    //   childaccounts: mockChildren,
    // });
    childaccounts.dispatch({
      type: childaccounts.actionTypes.FETCH_CHILD_ACCOUNTS,
      childaccounts: await fetchChildAccountsObj({ newheaders }, childaccounts),
    });
  };

  useEffect(() => {
    const newheaders = {
      ...GETCHILDACCOUNTSCONFIG.headers,
      Authorization: login.token,
      // appName: "corp",
    };
    fetchChildAccountsExecute({ newheaders });
  }, []);

  useEffect(() => {
    if (childaccounts.state.data) {
      console.log(childaccounts);
      // go to 'something went wrong' page when status is not valid
      if (!childaccounts.state.isStatus) {
        LocalStorage.removeLS();
        return <Redirect to="/corporate/login" />;
      }
      /////////////////////////////////////
      const { childaccountsobj } = childaccounts.state.data;
      console.log(childaccountsobj);
    }
  }, [childaccounts.state]);

  const isChildAccountsReady =
    !childaccounts.state.isLoading &&
    childaccounts.state.data &&
    childaccounts.state.data.childaccountsobj &&
    childaccounts.state.isStatus
      ? true
      : false;

  if (!isChildAccountsReady) return <Spinner />;

  return props.children;
};

export default index;
