import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_SEND_ATTACHMENT: "FETCH_SEND_ATTACHMENT"
};

const fetchSendAttachment = async (state, sendattachment) => {
  state = {
    ...state,
    isStatus: sendattachment.status,
    expireToken: sanitizeExpire({
      status: sendattachment.status,
      data: sendattachment.data,
      name: "sendattachmentobj"
    }),
    data: sanitizeData({
      status: sendattachment.status,
      data: sendattachment.data,
      name: "sendattachmentobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEND_ATTACHMENT:
      return fetchSendAttachment(state, action.sendattachment);
    default:
      return state;
  }
};

const sendattachment = {
  init,
  actionTypes,
  reducer
};

export default sendattachment;
