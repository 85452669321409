import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "registrations";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        endpoints: {
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/auth/token",
            method: "POST",
          },
          memberenquiry: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/clients",
            method: "GET",
          },
        },
        objects: {
          username: {
            name: "username",
            initial: "bryan.ttlloy",
            label: "Username",
            helperText: false,
            rules: ["isNotNull"],
          },
          password: {
            name: "password",
            initial: "bryan0611",
            label: "Password",
            helperText: false,
            rules: false,
          },
        },
      },
      responses: {
        fail: "No such username or password",
        unverified: "Please verify your account by ticking the recaptcha.",
        inactiveuser: "Please activate the account. Please check your email.",
      },
    },
  },
  routes: {
    label: "login",
    prefix: "/corporate/login",
  },
  setup,
};

export default config;
