import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import { Buttons } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
import {
  ModuleText,
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormItemWrapper,
  ModuleSmallTitle,
} from "../../lib-atomic-design-section";
/* packages */

import OrderContainer from "../../app-module-order-container/default";
/* container */

/* module */
import CMSBuynowHeader from "../../app-module-cms-section-buynow-header/default";
import OrderForm from "../../app-module-order-form-order/default";
import OrderList from "../../app-module-order-section-list/default";
import OrderInfo from "../../app-module-order-section-info/default";
import FeesMethod from "../../app-module-order-section-fees/default";
import OrderTotal from "../../app-module-order-section-total/default";
import PaymentMethod from "../../app-module-order-section-payment/default";
import Notes from "../../app-module-order-section-notes/default";
import PaymentReason from "../../app-module-order-section-payment-reason/default";
import Checkout from "../../app-module-order-section-checkout/default";
import Company from "../../app-module-order-section-company/default";
import DeliveryAddress from "../../app-module-order-section-deliveryAddress/default";
/* module */

const index = (props) => {
  return (
    <GlobalProvider>
      <OrderContainer {...props}>
        <ModuleContainerLg>
          <ModulePaddedWrapperLg>
            <ModuleFormItemWrapper>
              <ModuleSmallTitle>
                <b>Order Summary</b>
              </ModuleSmallTitle>
              <Company {...props} />
              <br />
              <OrderList {...props} />
              <br />
              <DeliveryAddress {...props} />
              <br />
              <FeesMethod {...props} />
              <br />
              <OrderTotal {...props} />
              <br />
              <PaymentMethod {...props} />
              <br />
              <Notes {...props} />
              <br />
              <PaymentReason {...props} />
              <br />
              <Checkout {...props} />
            </ModuleFormItemWrapper>
          </ModulePaddedWrapperLg>
        </ModuleContainerLg>
      </OrderContainer>
    </GlobalProvider>
  );
};

export default index;
