import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_PAYMENT_REASON: "ADD_PAYMENT_REASON",
};

const addPR = async (state, paymentreason) => {
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: paymentreason,
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_PAYMENT_REASON:
      return addPR(state, action.paymentreason);
    default:
      return state;
  }
};

const index = {
  init,
  actionTypes,
  reducer,
};

export default index;
