import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Reporting";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    onlineorder: {
      api: {
        endpoints: {
          productall: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/products",
            method: "GET",
          },
          nzbnkey: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_service.url[environment] +
              "/tx-nzbn/loginAPI",
            method: "GET",
          },
        },
      },
    },
    setup,
  },
  routes: {
    label: "Buy Now",
    prefix: "/corporate/order",
  },
};

export default config;
