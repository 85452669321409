// import TokenExpiredNew from "../../../app-module-token-expire";
/* home module */
import Home from "../../../app-module-home-page-landing";

/* login module */
import Login from "../../../app-module-login-page-landing";

/* logout module */
import Logout from "../../../app-module-logout-page-landing";

/* order(buy now) module */
import OrderLanding from "../../../app-module-order-page-landing";
import OrderForm from "../../../app-module-order-form-order";
// guest
import OrderGuestInfo from "../../../app-module-order-page-guest-info";
import OrderGuestItems from "../../../app-module-order-page-guest-items";
import OrderRegisteredItems from "../../../app-module-order-page-registered-items";
// order fail success pages
import OrderSuccess from "../../../app-module-order-page-popup-success";
import OrderFail from "../../../app-module-order-page-popup-fail";
import OrderFinalCheckout from "../../../app-module-order-page-checkout-final-summary";

/* register module */
import RegisterLanding from "../../../app-module-register-page-landing";
import RegisterForm from "../../../app-module-register-form-register";
import RegisterPopUpSuccess from "../../../app-module-register-page-popup-success";
import RegisterPopUpFail from "../../../app-module-register-page-popup-fail";

/* register activation module */
import RegisterActivationLanding from "../../../app-module-register-activation-page-landing";
import RegisterActivationPopUp from "../../../app-module-register-activation-page-popup";
/* contact */
import ContactLanding from "../../../app-module-contact-page-landing";
import ContactForm from "../../../app-module-contact-form-contact";

/* activate cards module */
import CardActivationLanding from "../../../app-module-card-activation-page-landing";
import CardActivationPopUpSuccess from "../../../app-module-card-activation-page-popup-success";
import CardActivationPopUpFail from "../../../app-module-card-activation-page-popup-fail";

/* faq module */
import FaqLanding from "../../../app-module-faq-page-landing";

/* account profile module */
import AccountProfileLanding from "../../../app-module-account-profile-page-landing";
import AccountProfileTransaction from "../../../app-module-account-profile-page-transaction";
import AccountProfileTransactionDetail from "../../../app-module-account-profile-page-transaction-detail";
import AccountProfileUpdate from "../../../app-module-account-profile-page-update";
import AccountProfileUpdateSuccess from "../../../app-module-account-profile-page-update-success";
import AccountProfileUpdateFail from "../../../app-module-account-profile-page-update-fail";
import AccountProfilePasswordUpdate from "../../../app-module-account-profile-page-update-password";
import AccountProfilePasswordUpdateSuccess from "../../../app-module-account-profile-page-update-password-success";
import AccountProfilePasswordUpdateFail from "../../../app-module-account-profile-page-update-password-fail";
import AccountProfileChidlManage from "../../../app-module-account-profile-manage-list";
/* forgot password module */
import ForgotPasswordSubmit from "../../../app-module-forgot-password-landing";
import ForgotPasswordSubmitChange from "../../../app-module-forgot-password-submit-change";
import ForgotPasswordPopup from "../../../app-module-forgot-password-submit-popup";
import ForgotPasswordSuccess from "../../../app-module-forgot-password-success";
import ForgotPasswordFail from "../../../app-module-forgot-password-fail";

/* accepting retailer module */
import AcceptingRetailerLanding from "../../../app-module-accepting-retailer-page-landing";

/* home */
const homeMoudles = [Home];

/* login */
const loginMoudles = [Login];

/* logout */
const logoutModules = [Logout];

/* order */
const orderModules = [
  OrderLanding,
  OrderForm,
  // guest`
  // OrderGuestInfo,
  // OrderGuestItems,
  // registered
  OrderRegisteredItems,
  OrderSuccess,
  OrderFail,
  OrderFinalCheckout,
];

/* register */
const registerModules = [
  RegisterLanding,
  RegisterForm,
  RegisterPopUpSuccess,
  RegisterPopUpFail,
];

/* register */
const registerActivationModules = [
  RegisterActivationLanding,
  RegisterActivationPopUp,
];

/* contact */
const contactModules = [ContactLanding, ContactForm];

/* card activate */
const cardActivationModules = [
  CardActivationLanding,
  CardActivationPopUpSuccess,
  CardActivationPopUpFail,
];

/* faq */
const faqModules = [FaqLanding];

/* account profile */
const accountProfileModules = [
  AccountProfileLanding,
  AccountProfileTransaction,
  AccountProfileTransactionDetail,
  AccountProfileUpdate,
  AccountProfilePasswordUpdate,
  AccountProfileUpdateSuccess,
  AccountProfileUpdateFail,
  AccountProfilePasswordUpdateSuccess,
  AccountProfilePasswordUpdateFail,
  AccountProfileChidlManage,
];

/* forgot password */
const forgotPasswordModules = [
  ForgotPasswordSubmit,
  ForgotPasswordSubmitChange,
  ForgotPasswordPopup,
  ForgotPasswordSuccess,
  ForgotPasswordFail,
];
const acceptingRetailerModules = [AcceptingRetailerLanding];

const modules = {
  home: homeMoudles,
  login: loginMoudles,
  logout: logoutModules,
  order: orderModules,
  register: registerModules,
  account_activation: registerActivationModules,
  card_activation: cardActivationModules,
  contact: contactModules,
  faq: faqModules,
  account_profile: accountProfileModules,
  forgot_password: forgotPasswordModules,
  accepting_retailers: acceptingRetailerModules,
};

export default modules;
