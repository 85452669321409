import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Child List";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    api: {
      endpoints: {
        account_activation: {
          headers: {
            "Content-Type": "application/json",
            moduleCode: setup.setup.westfield.corp.norm.moduleCode[environment],
          },
          url:
            setup.setup.api.tranxactor_westfield_corp.url[environment] +
            "/secondary",
          method: "POST",
        },
        account_terminate: {
          headers: {
            "Content-Type": "application/json",
            moduleCode: setup.setup.westfield.corp.norm.moduleCode[environment],
          },
          url:
            setup.setup.api.tranxactor_westfield_corp.url[environment] +
            "/secondary",
          method: "POST",
        },
      },
    },
  },
  routes: {
    label: "Child List",
    prefix: "/corporate/account_profile/manage_sub",
  },
  setup,
};

export default config;
