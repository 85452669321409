import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from '@material-ui/system';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  text: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: "#eee",
    borderRadius: 8,
    textAlign: "center",
    fontWeight: 800
  },
}));
/* CONSTANTS */

const text = props => {

  /* mui */
  const classes = useStyles();
  /* mui */  

  return <Typography variant="subtitle1" className={classes.text}>
    { props.children }
  </Typography>

}

export default text;
