const sanitizeData = ({status, data, name}) => {
    if (!status && !data.json) return { [name]: false };
    return { [name]: data.json };
}

const sanitizeExpire = ({status, data, name}) => {
    const expireLookup = [203];
    const sanitize_data = sanitizeData({status, data, name});
    if (expireLookup.includes(sanitize_data[name].status)) return true;
    return false; 
}

export {
    sanitizeData,
    sanitizeExpire
}