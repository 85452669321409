import role0 from './role0';
import role1 from './role1';
import role2 from './role2';




export default [
    role0,
    role1,
    role2,
]