import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  LocalStorage,
  FetchContextApiV2,
  WindowSize,
} from "../../lib-atomic-functions";

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;

const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json",
};
const TRANSACTIONSCONFIG = {
  url: ENDPOINTS.transactions.url,
  method: ENDPOINTS.transactions.method,
  headers: ENDPOINTS.transactions.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  //   const isGuestUser = LocalStorage.getLS() && LocalStorage.getLS().guest;
  const isNormUser = LocalStorage.getLS() && LocalStorage.getLS().user;
  const login = LocalStorage.getLS() && LocalStorage.getLS().login;
  // this should be returning Route to home instead of null
  if (!isNormUser) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  if (!login || Object.keys(isNormUser).length === 0) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }

  const { memberenquiry, transactions } = useContext(GlobalContext);
  /* --- member enquiry  & transactions */
  const [fetchMemberEnquiryObj] = FetchContextApiV2(MEMBERENQUIRYCONFIG);
  const [fetchTransactionsObj] = FetchContextApiV2(TRANSACTIONSCONFIG);
  const fetchMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.FETCH_MEMBER_ENQUIRY,
      memberenquiry: await fetchMemberEnquiryObj({ newheaders }, memberenquiry),
    });
  };
  const fetchTransactionsExecute = async ({ newheaders }) => {
    console.log("dispatching...XX");
    const original = await fetchTransactionsObj({ newheaders }, transactions);
    if (original.status) {
      let filtered = original;
      filtered.data.json.orders = original.data.json.orders.slice(0, 50);
      console.log(filtered);
      transactions.dispatch({
        type: transactions.actionTypes.FETCH_TRANSACTIONS,
        transactions: filtered,
      });
    } else {
      transactions.dispatch({
        type: transactions.actionTypes.FETCH_TRANSACTIONS,
        transactions: original,
      });
    }
  };
  /* --- member enquiry & transactions */
  useEffect(() => {
    const newheaders = {
      ...MEMBERENQUIRYCONFIG.headers,
      // Authorization: `Bearer ${tokenobject.token}`
      Authorization: login.token,
      appName: "corp",
    };
    const newheaders2 = {
      ...TRANSACTIONSCONFIG.headers,
      // Authorization: `Bearer ${tokenobject.token}`
      Authorization: login.token,
      appName: "corp",
    };
    console.log(newheaders);
    fetchMemberEnquiryExecute({ newheaders });
    fetchTransactionsExecute({ newheaders: newheaders2 });
  }, []);

  useEffect(() => {
    if (memberenquiry.state.data && transactions.state.data) {
      // go to 'something went wrong' page when status is not valid
      console.log(memberenquiry, transactions);
      if (!memberenquiry.state.isStatus) {
        LocalStorage.removeLS();
        return <Redirect to="/corporate/login" />;
      }
      if (!transactions.state.isStatus) {
        LocalStorage.removeLS();
        return <Redirect to="/corporate/login" />;
      }
      /////////////////////////////////////
      const { memberenquiryobj } = memberenquiry.state.data;
      LocalStorage.addLS("user", { ...memberenquiryobj.client });
      LocalStorage.addLS("guest", false);
    }
  }, [memberenquiry.state, transactions.state]);

  const isMemberDataReady =
    !memberenquiry.state.isLoading &&
    memberenquiry.state.data &&
    memberenquiry.state.data.memberenquiryobj &&
    memberenquiry.state.isStatus
      ? true
      : false;
  const isTransactionDataReady =
    !transactions.state.isLoading &&
    transactions.state.data &&
    transactions.state.data.transactionsobj &&
    transactions.state.isStatus
      ? true
      : false;

  if (!isMemberDataReady || !isTransactionDataReady) return <Spinner />;

  return props.children;
};

export default index;
