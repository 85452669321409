import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_FEES: "ADD_FEES"
};

const addFees = async (state, fees) => {
  const feesoption = fees;
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: feesoption
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_FEES:
      return addFees(state, action.fees);
    default:
      return state;
  }
};

const fees = {
  init,
  actionTypes,
  reducer
};

export default fees;
