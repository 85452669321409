import React, { Fragment } from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

/* atoms */
import { Invalid } from "../feedbacks";
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
options
*/

const options = ({ options }) => {
  return options.map((option, index) => {
    return (
      <MenuItem key={index} value={option.value}>
        {option.name}
      </MenuItem>
    );
  });
};

const select = (props) => {
  const selectOptions = options(props);
  return (
    <Fragment>
      <TextField
      style={{overflow: "hidden"}}
        {...props}
        select
        fullWidth
        variant="outlined"
        margin="dense"
        InputLabelProps={{
          style: { color: "grey", textOverflow: "ellipsis !important" },
        }}
      >
        {selectOptions}
      </TextField>
      <Invalid {...props} />
    </Fragment>
  );
};

export default select;
