import React, { useContext } from "react";

import { GlobalContext } from "../../lib-atomic-context";

import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const index = (props) => {
  const qAndAItems = [
    // {
    //   q: "Do you offer discount for corporate gift card orders?",
    //   a:
    //     "In terms of discounts, we are not able to discount the face value of Westfield Gift cards.",
    // },
    // {
    //   q: "For which amounts can I purchase a Gift Card?",
    //   a:
    //     "Westfield Gift Cards purchased as part of a corporate order can be loaded with any value between $10 and $995.",
    // },
    // {
    //   q: "Is there a minimum card I need to purchase in a corporate order?",
    //   a:
    //     "No, there is no minimum order quantity, maximum card quantity is 500.",
    // },
    // {
    //   q: "Where can my recipients spend their Gift Card?",
    //   a:
    //     "Westfield Gift Cards are welcomed at hundreds of participating stores in Westfield shopping centres in New Zealand.<br/><br/>For a list of Westfield Centres in New Zealand and full product Terms and Conditions, visit westfield.co.nz.",
    // },
    // {
    //   q:
    //     "What are my payment options and how will I know when my payment has been received?",
    //   a:
    //     "We accept payment via Direct Credit or Credit Card (Visa, Mastercard and American Express). If you would like to pay via Direct Credit, we will issue a quote directly to you via email, for you to arrange payment. An email confirmation will be sent once payment has been received by us. Please note that your order will not be dispatched until payment has been received.",
    // },
    // {
    //   q: "What are the delivery options?",
    //   a:
    //     "We have one option of standard delivery within New Zealand only, available up to 2-3 working days, tracking details provided.<br/><br/>Please note that delivery costs depend on the size of your order and will be calculated at the check-out before your order is confirmed.",
    // },
    // {
    //   q: "How can I track the status of my order?",
    //   a:
    //     "Once your order has been dispatched you will receive an email containing the tracking details.",
    // },
    // {
    //   q: "Can I collect my order from a Westfield centre?",
    //   a:
    //     "Your online corporate order will be delivered to your nominated delivery address and cannot be collected in-centre.<br/><br/>If you’d like to place a corporate order directly through your local Westfield Centre, please contact the centre directly. Centre contact details can be found at westfield.co.nz.",
    // },
    // {
    //   q: "What should I do if my order has not arrived as expected?",
    //   a:
    //     "Please email us at <a href='mailto:giftcardsnzcorporate@scentregroup.com'>giftcardsnzcorporate@scentregroup.com</a> or call us on 0800 937 834 during business hours (9am-5pm, Monday to Friday). So that we can assist you, please be sure to provide your invoice number.",
    // },
    // {
    //   q: "Is my corporate Gift Card order secure?",
    //   a:
    //     "The security of your order is important to us. All corporate orders are dispatched inactive and gift cards cannot be spent until the order has been activated by the purchaser. To activate your order, visit <a href='https://giftcards.westfield.co.nz/' target='blank'>giftcards.westfield.co.nz/corporate</a> and enter your email address, invoice number and the card number of any one card from your order. Note: Entering the details of a single gift card will activate all cards in the order, you do not need to enter the details of each card.",
    // },
    // {
    //   q:
    //     "What should I do if one of the Gift Cards from my order is lost/stolen?",
    //   a:
    //     "Unlike other cards, Westfield Gift Cards can be replaced if lost, stolen or damaged. Please email us at <a href='mailto:giftcardsnzcorporate@scentregroup.com'>giftcardsnzcorporate@scentregroup.com</a> or call us on 0800 937 834 during business hours (9am-5pm, Monday to Friday). View Westfield Gift Card Terms and Conditions <a href='https://www.westfield.co.nz/terms-and-conditions' target='blank'>here</a>",
    // },
    {
      q:
        "How can I register for a corporate account?",
      a:
        "Visit <a href='https://giftcards.westfield.co.nz/' target='blank'>giftcards.westfield.co.nz/corporate</a> and complete the steps to register.<br/><br/>An NZBN number is mandatory. For overseas customers, please contact us directly.<br/><br/>Should you encounter any issues, please get in touch with us at 0800 937 834 during business hours (9 am -5 pm NZT, Monday to Friday) or email us at <a href='mailto:giftcardsnzcorporate@scentregroup.com'>giftcardsnzcorporate@scentregroup.com</a>",
    },
    {
      q:
        "What are the limits for purchasing gift cards in a corporate order?",
      a:
        "There is a maximum total of $20,000 per order. There is no minimum order quantity; the maximum card quantity is 500 cards per order.",
    },
    {
      q:
        "What are my payment options, and how will I know when my payment has been received?",
      a:
        "We accept payment via Direct Credit or Credit Card (Visa, Mastercard and American Express). If you would like to pay via Direct Credit, please select this please select this payment option in your online order. We will issue a quote directly to you via email for you to arrange payment.<br/><br/>An email confirmation will be sent once payment has been received by us. Please note that your order will only be dispatched once payment has been received.",
    },
    {
      q:
        "What are the delivery options?",
      a:
        "We have one standard delivery option within New Zealand, available within 2-3 working days. Tracking details will be provided.",
    },
    {
      q:
        "How can I track the status of my order?",
      a:
        "You will receive an email with the tracking details once your order has been dispatched.",
    },
    {
      q:
        "Can I collect my order from a Westfield centre?",
      a:
        "Your online corporate order will be delivered to your nominated delivery address and cannot be collected in-centre. Gift Cards are available for purchase in-centre.",
    },
    {
      q:
        "What should I do if my order has yet to arrive as expected?",
      a:
        "Please email us at <a href='mailto:giftcardsnzcorporate@scentregroup.com'>giftcardsnzcorporate@scentregroup.com</a> or call us on 0800 937 834 during business hours (9 am-5 pm, Monday to Friday). So that we can assist you, please provide your Order number.",
    },
    {
      q:
        "Is my corporate Gift Card order secure?",
      a:
        "The security of your order is important to us. All corporate orders are dispatched inactive, and gift cards can only be spent once the purchaser has activated the order.",
    },
    {
      q:
        "How can I activate my order?",
      a:
        "To activate your order, visit <a href='https://giftcards.westfield.co.nz/' target='blank'>giftcards.westfield.co.nz/corporate</a> and enter your email address, invoice number and the card number of any one card from your order.<br/><br/>Note: Entering the details of a single gift card will activate all cards in the order. You do not need to enter the details of each card.",
    },
    {
      q:
        "Do you offer any other services for corporate gift card orders",
      a:
        "We offer complimentary greeting cards as an available option with each order. If you would like to receive a complimentary greeting card, please select this option as part of your purchase.",
    },
    {
      q:
        "Can corporate orders be sent overseas?",
      a:
        "Gift Cards can either be sent to you or your recipient. You can only select one delivery address per online order. Please note, we can only deliver to addresses within New Zealand (excluding PO boxes) and cannot be delivered overseas.",
    },
    {
      q:
        "Why is there a fee charged?",
      a:
        "A handling fee of $0.35 applies to all Westfield Gift Card orders, to cover the processing and handling costs associated with issuance.<br/><br/><em>For 2023, these costs have been waived as a special launch offer and will be introduced from 1 January 2024.</em>",
    },
    {
      q:
        "Do you offer digital corporate gift cards?",
      a:
        "We currently do not offer a digital gift card product. However, it is something we are considering in our long-term strategy.",
    },
  ];
  return (
    <Container
      component={Box}
      my={5}
      maxWidth="lg"
      disableGutters
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      <Typography color="primary" variant="h4" gutterBottom>
        FAQs{" "}
      </Typography>
      <Box my={5}>
        {qAndAItems.map(({ q, a }, index) => (
          <ExpansionPanel key={index}>
            <ExpansionPanelSummary
              expandIcon={<AddIcon />}
              aria-controls={`q${index}bh-content`}
              id={`q${index}bh-header`}
            >
              <Typography color="primary" variant="h6">
                <a
                  name={index + 1}
                  style={{
                    display: "block",
                    position: "relative",
                    top: -90,
                    visibility: "hidden",
                  }}
                />
                <div dangerouslySetInnerHTML={{ __html: q }} />
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography
                variant="body2"
                style={{
                  "white-space": "pre-wrap",
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: a }} />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Box>
    </Container>
  );
};

export default index;
