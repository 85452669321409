import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Registration Form";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          updatepassword: {
            headers: {
              "Content-Type": "application/json",
              // moduleCode:
              //   setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/ident",
            method: "POST",
          },
        },
      },
      objects: {
        email: {
          name: "email",
          initial: "",
          label: "Email",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        password: {
          name: "password",
          initial: "",
          label: "Password",
          helperText: false,
          rules: ["isNotNull", "isPasswordStrength"],
        },
        passwordconfirm: {
          name: "passwordconfirm",
          initial: "",
          label: "Confirm password",
          helperText: false,
          rules: ["isNotNull", "isConfirm"],
          confirm_to: "password",
          confirm_suffix: "password",
        },
      },
      responses: {
        fail: "Incorrect email or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Profile Update",
    prefix: "/corporate/account_profile/update_pw",
  },
  setup,
};

export default config;
