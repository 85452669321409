import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_CONTACT: "FETCH_CONTACT"
};

const fetchContact = async (state, getcontact) => {
  state = {
    ...state,
    isStatus: getcontact.status,
    expireToken: sanitizeExpire({
      status: getcontact.status,
      data: getcontact.data,
      name: "getcontactobj"
    }),
    data: sanitizeData({
      status: getcontact.status,
      data: getcontact.data,
      name: "getcontactobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTACT:
      return fetchContact(state, action.getcontact);
    default:
      return state;
  }
};

const getcontact = {
  init,
  actionTypes,
  reducer
};

export default getcontact;
