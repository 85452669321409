import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { LocalStorage } from "../../lib-atomic-functions";

const index = (props) => {
  const isNormUser = LocalStorage.getLS().user;
  const login = LocalStorage.getLS().login;
  const tokenLS = login.token;
  if (isNormUser && tokenLS) {
    return <Redirect to="/corporate" />;
  }

  return props.children;
};

export default index;
