import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

import { GlobalContext } from "../../lib-atomic-context";

const index = (props) => {
  const addressLS = LocalStorage.getLS().deliveryAddress;
  const infoLS = LocalStorage.getLS().user;
  const { address } = useContext(GlobalContext);

  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Delivery Details</b>
            </ModuleSubTitle>
            <ModuleText>
              <b>{addressLS.companyName}</b>
            </ModuleText>
            {/* {addressLS.companyName ? (
              <ModuleText>
                <b>{addressLS.companyName}</b>
              </ModuleText>
            ) : null} */}
            {addressLS.firstname ? (
              <ModuleText>{addressLS.firstname}</ModuleText>
            ) : null}
            {addressLS.lastname ? (
              <ModuleText>{addressLS.lastname}</ModuleText>
            ) : null}
            <ModuleText>{addressLS.physicalAddress1}</ModuleText>
            <ModuleText>{addressLS.physicalSuburb}</ModuleText>
            <ModuleText>{addressLS.physicalCity}</ModuleText>
            <ModuleText>{addressLS.physicalPostcode}</ModuleText>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
