import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { LocalStorage, FetchContextApiV2 } from "../../lib-atomic-functions";

import {
  PDFBlobProviderInvoice,
  PDFPreviewProviderInvoice,
  PDFBlobProviderQuote,
  PDFPreviewProviderQuote,
} from "../../app-module-order-section-invoice/default";
/* packages */

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;

const CHECKSTRIPEPAYMENTCONFIG = {
  url: ENDPOINTS.checkstripepayment.url,
  method: ENDPOINTS.checkstripepayment.method,
  headers: ENDPOINTS.checkstripepayment.headers,
  responseType: "json",
};
const PRODUCTALLCONFIG = {
  url: ENDPOINTS.productall.url,
  method: ENDPOINTS.productall.method,
  headers: ENDPOINTS.productall.headers,
  responseType: "json",
};

const CHECKPAYMENTCONFIG = {
  url: ENDPOINTS.checkpayment.url,
  method: ENDPOINTS.checkpayment.method,
  headers: ENDPOINTS.checkpayment.headers,
  responseType: "json",
};
const GETORDERCONFIG = {
  url: ENDPOINTS.getorder.url,
  method: ENDPOINTS.getorder.method,
  headers: ENDPOINTS.getorder.headers,
  responseType: "json",
};
const GETCONTACTCONFIG = {
  url: ENDPOINTS.getcontact.url,
  method: ENDPOINTS.getcontact.method,
  headers: ENDPOINTS.getcontact.headers,
  responseType: "json",
};
const SENDATTACHMENT = {
  url: ENDPOINTS.sendattachment.url,
  method: ENDPOINTS.sendattachment.method,
  headers: ENDPOINTS.sendattachment.headers,
  responseType: "formdata",
};
/* CONSTANTS */

const index = (props) => {
  const login = LocalStorage.getLS().login;
  const isGuestUser = LocalStorage.getLS().guest;
  const isNormUser = LocalStorage.getLS().user;
  const allproductsLS = LocalStorage.getLS().productall;
  const productsLS = LocalStorage.getLS().products;
  const feesLS = LocalStorage.getLS().fees;
  const notesLS = LocalStorage.getLS().notes;
  const paymentLS = LocalStorage.getLS().payment;

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [previewDataReady, setPreviewDataReady] = useState(false);
  // if (!login || Object.keys(isUserLS).length === 0) {
  //   LocalStorage.removeLS();
  //   return <Redirect to="/login" />;
  // }
  console.log(props);
  // correct query params found

  const {
    productall,
    checkpayment,
    checkstripepayment,
    orderdetail,
    getcontact,
    sendattachment,
    stripepayment,
  } = useContext(GlobalContext);

  const [fetchCheckStripePayment] = FetchContextApiV2(CHECKSTRIPEPAYMENTCONFIG);
  const execCheckFetchStripePayment = async ({ newheaders, query }) => {
    stripepayment.dispatch({
      type: stripepayment.actionTypes.FETCH_STRIPE_PAYMENT,
      stripepayment: await fetchCheckStripePayment(
        { newheaders, query },
        stripepayment
      ),
    });
  };

  /* product all */
  const [fetchProductAll] = FetchContextApiV2(PRODUCTALLCONFIG);
  const execProductAll = async () => {
    productall.dispatch({
      type: productall.actionTypes.FETCH_PRODUCT_ALL,
      productall: await execProductAllCompound(),
    });
  };
  const execProductAllCompound = async () => {
    let productAll = [];
    const newheaders = {
      ...PRODUCTALLCONFIG.headers,
      Authorization: login.token,
      // Authorization:
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
    };
    try {
      const cat2 = await fetchProductAll(
        { query: "?categoryId=2", newheaders },
        productall
      );
      const cat3 = await fetchProductAll(
        { query: "?categoryId=3", newheaders },
        productall
      );
      const cat5 = await fetchProductAll(
        { query: "?categoryId=5", newheaders },
        productall
      );
      cat2.data.json.products.push(...cat3.data.json.products);
      cat2.data.json.products.push(...cat5.data.json.products);
      return cat2;
    } catch (e) {
      console.log(e);
      if (
        props.location.state !== undefined &&
        props.location.state.orderid !== undefined
      ) {
        const { orderid } = props.location.state;
        props.history.push("/corporate/order/fail", {
          orderIdPassed: orderid,
          isTokenExpire: true,
          returnUrl: "/corporate/order/success",
        });
      }
      const parsed = QueryString.parse(props.location.search);
      const { result, userid } = parsed;
      if (result !== undefined && userid !== undefined) {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: true,
          returnUrl:
            props.location.pathname + "?result=" + result + "&userid=" + userid,
        });
      }
    }
    //return productAll;
  };

  /* --- check payment */
  const [fetchCheckPayment] = FetchContextApiV2(CHECKPAYMENTCONFIG);
  const fetchCheckPaymentExecute = async ({ newheaders, query }) => {
    checkpayment.dispatch({
      type: checkpayment.actionTypes.FETCH_CHECK_PAYMENT,
      checkpayment: await fetchCheckPayment(
        { newheaders, query },
        checkpayment
      ),
    });
  };

  /* order detail */
  const [fetchOrderObj] = FetchContextApiV2(GETORDERCONFIG);
  const fetchOrderObjExecute = async ({ newheaders, query }) => {
    orderdetail.dispatch({
      type: orderdetail.actionTypes.FETCH_ORDER_DETAIL,
      orderdetail: await fetchOrderObj({ newheaders, query }, orderdetail),
    });
  };

  /* --- get contact */
  const [fetchContact] = FetchContextApiV2(GETCONTACTCONFIG);
  const fetchContactExecute = async ({ newheaders, query }) => {
    getcontact.dispatch({
      type: getcontact.actionTypes.FETCH_CONTACT,
      getcontact: await fetchContact({ newheaders, query }, getcontact),
    });
  };

  /* --- sending attachment */
  const [fetchSendAttachment] = FetchContextApiV2(SENDATTACHMENT);
  const fetchSendAttachmentExecute = async ({ newheaders, formData }) => {
    sendattachment.dispatch({
      type: sendattachment.actionTypes.FETCH_SEND_ATTACHMENT,
      sendattachment: await fetchSendAttachment(
        { newheaders, body: formData },
        sendattachment
      ),
    });
  };

  // start checking .... 1
  const parsed = QueryString.parse(props.location.search);
  if (Object.keys(parsed).length === 0) {
    // no parameter found; either Direct Credit OR fail
    if (
      props.location.state !== undefined &&
      props.location.state.orderid !== undefined
    ) {
      const { orderid } = props.location.state;

      useEffect(() => {
        execProductAll();
      }, []);

      useEffect(() => {
        if (productall.state.data) {
          const newheaders = {
            ...GETORDERCONFIG.headers,
            Authorization: login.token,
            // Authorization:
            //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A",
            appName: "corp",
          };
          fetchOrderObjExecute({
            newheaders,
            query: "/" + orderid,
          });
        }
      }, [productall.state]);

      useEffect(() => {
        if (orderdetail.state.data) {
          if (!orderdetail.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              orderIdPassed: orderid,
              isTokenExpire: true,
              returnUrl: "/corporate/order/success",
            });
          }
          const id = isNormUser ? isNormUser.id : isGuestUser.id;
          const newheaders = {
            ...GETCONTACTCONFIG.headers,
            Authorization: login.token,
          };
          fetchContactExecute({
            newheaders,
            query: "/" + id + "/contacts",
          });
        }
      }, [orderdetail.state]);

      useEffect(() => {
        if (getcontact.state.data) {
          if (!getcontact.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              orderIdPassed: orderid,
              isTokenExpire: true,
              returnUrl: "/corporate/order/success",
            });
          } else {
            const order = orderdetail.state.data.orderdetailobj;
            // console.log(order);
            const products = order.detail.map((product) => {
              return {
                value: product.value,
                quantity: product.quantity,
                startCardNumber: null,
                productId: product.productId,
              };
            });
            const prductDetails = products.map((product) => {
              product["details"] =
                productall.state.data.productallobj.products.filter(
                  (productdetail) =>
                    product.productId === productdetail.productId
                )[0];
              return product;
            });
            const feesData = order.detail.filter(
              (product) => product.subClassId === 1 || product.subClassId === 4
            );
            const previewData = {
              // pdfFilename: orderIdCreated + ".pdf",
              pdfFilename: order.id + ".pdf",
              pdfPreviewData: {
                orderInfoData: order,
                companyInfoData: {
                  clients: isNormUser ? isNormUser : isGuestUser,
                },
                contactInfoData: getcontact.state.data.getcontactobj,
                productsDetailsData: prductDetails,
                feesDetailsData: feesData,
              },
              pdfPreviewSize: {
                width: 450,
                height: 700,
              },
            };
            async function prepareBlob(previewData) {
              // setPreviewDataReady(previewData);
              const blobPrepared = await PDFBlobProviderQuote(previewData);
              return blobPrepared;
            }
            prepareBlob(previewData).then((blobPrepared) => {
              let formData = new FormData(); //formdata
              formData.append("file", blobPrepared); //append the values with key, value pair
              const fullName =
                previewData.pdfPreviewData.companyInfoData.clients.firstName +
                " " +
                previewData.pdfPreviewData.companyInfoData.clients.lastName;
              const emailAddress =
                previewData.pdfPreviewData.companyInfoData.clients.emailAddress;
              // //direct credit template id
              const templateIdForDC = "8dea200e-ae21-4506-9065-12a2a4b057ac";
              // //credit card template id
              const templateIdForCC = "5282bac6-38e6-4903-8a21-76302578d972";
              const data = {
                fileName:
                  order.paymentType === 22 ? "Quote.pdf" : "Invoice.pdf",
                fullName,
                emailAddress,
                templateId:
                  order.paymentType === 22 ? templateIdForDC : templateIdForCC,
                params: {
                  order_number: order.id,
                  name: fullName,
                  order_value:
                    previewData.pdfPreviewData.orderInfoData.totalAmount,
                },
              };
              formData.append("data", JSON.stringify(data));
              const newheaders = {
                ...SENDATTACHMENT.headers,
                // Authorization: login.token
                // Authorization:
                //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
              };
              fetchSendAttachmentExecute({ newheaders, formData });
            });
          }
          // handleClearLS();
        }
      }, [getcontact.state]);

      useEffect(() => {
        if (sendattachment.state.data) {
          if (!getcontact.state.isStatus) {
            // should tell user sending email failed login and ask to try again
            props.history.push("/corporate/order/fail", {
              orderIdPassed: orderid,
              isTokenExpire: true,
              returnUrl: "/corporate/order/success",
            });
          }
          console.log(sendattachment.state.data);
        }
      }, [sendattachment.state]);

      const { productallobj } = productall.state.data;
      const { orderdetailobj } = orderdetail.state.data;
      const { getcontactobj } = getcontact.state.data;

      const checkResult = () => {
        const productDetail =
          !productallobj || productallobj.length < 1 ? false : true;
        const orderDetail =
          !orderdetailobj || orderdetailobj.length < 1 ? false : true;
        const contactDetail =
          !getcontactobj || getcontactobj.length < 1 ? false : true;
        return !productDetail || !orderDetail || !contactDetail ? false : true;
      };

      if (
        !checkResult() ||
        productall.state.isLoading ||
        orderdetail.state.isLoading ||
        getcontact.state.isLoading
      ) {
        return <Spinner />;
      }
    } else {
      // wrong query params or failed
      return props.children;
    }
  } else {
    // there is parameter;
    const { result, userid } = parsed;
    if (result !== undefined && userid !== undefined) {
      // there is correct parameter; BUT it could be success/fail
      useEffect(() => {
        const newheaders = {
          ...CHECKPAYMENTCONFIG.headers,
          Authorization: login.token,
          // Authorization:
          //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
        };
        const query = "?result=" + result + "&userid=" + userid;
        fetchCheckPaymentExecute({ newheaders, query });
      }, []);

      useEffect(() => {
        if (checkpayment.state.data) {
          if (!checkpayment.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              isTokenExpire: true,
              returnUrl:
                props.location.pathname +
                "?result=" +
                result +
                "&userid=" +
                userid,
            });
          } else {
            const { checkpaymentobj } = checkpayment.state.data;
            const { merchantRef, outComeCode } = checkpaymentobj;
            if (outComeCode !== "0") {
              // unsuccessful?
              props.history.push("/corporate/order/fail", {
                isTokenExpire: false,
                isPaymentFailed: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            }
            const newheaders = {
              ...GETORDERCONFIG.headers,
              Authorization: login.token,
              appName: "corp",
            };
            fetchOrderObjExecute({
              newheaders,
              query: "/" + merchantRef,
            });
          }
        }
      }, [checkpayment.state]);

      useEffect(() => {
        if (orderdetail.state.data) {
          if (!orderdetail.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              isTokenExpire: true,
              returnUrl:
                props.location.pathname +
                "?result=" +
                result +
                "&userid=" +
                userid,
            });
          }
          execProductAll();
        }
      }, [orderdetail.state]);

      useEffect(() => {
        if (productall.state.data) {
          if (!productall.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              isTokenExpire: true,
              returnUrl:
                props.location.pathname +
                "?result=" +
                result +
                "&userid=" +
                userid,
            });
          }
          const id = isNormUser ? isNormUser.id : isGuestUser.id;
          const newheaders = {
            ...GETCONTACTCONFIG.headers,
            Authorization: login.token,
          };
          fetchContactExecute({
            newheaders,
            query: "/" + id + "/contacts",
          });
        }
      }, [productall.state]);

      useEffect(() => {
        if (getcontact.state.data) {
          if (!getcontact.state.isStatus) {
            props.history.push("/corporate/order/fail", {
              isTokenExpire: true,
              returnUrl:
                props.location.pathname +
                "?result=" +
                result +
                "&userid=" +
                userid,
            });
          } else {
            const order = orderdetail.state.data.orderdetailobj;

            const products = order.detail.map((product) => {
              return {
                value: product.value,
                quantity: product.quantity,
                startCardNumber: null,
                productId: product.productId,
              };
            });
            const prductDetails = products.map((product) => {
              product["details"] =
                productall.state.data.productallobj.products.filter(
                  (productdetail) =>
                    product.productId === productdetail.productId
                )[0];
              return product;
            });
            const feesData = order.detail.filter(
              (product) => product.subClassId === 1 || product.subClassId === 4
            );
            const previewData = {
              // pdfFilename: orderIdCreated + ".pdf",
              pdfFilename: order.id + ".pdf",
              pdfPreviewData: {
                orderInfoData: order,
                companyInfoData: {
                  clients: isNormUser ? isNormUser : isGuestUser,
                },
                contactInfoData: getcontact.state.data.getcontactobj,
                productsDetailsData: prductDetails,
                feesDetailsData: feesData,
              },
              pdfPreviewSize: {
                width: 450,
                height: 700,
              },
            };
            async function prepareBlob(previewData) {
              // setPreviewDataReady(previewData);
              const blobPrepared = await PDFBlobProviderQuote(previewData);
              return blobPrepared;
            }
            prepareBlob(previewData).then((blobPrepared) => {
              let formData = new FormData(); //formdata
              formData.append("file", blobPrepared); //append the values with key, value pair
              const fullName =
                previewData.pdfPreviewData.companyInfoData.clients.firstName +
                " " +
                previewData.pdfPreviewData.companyInfoData.clients.lastName;
              const emailAddress =
                previewData.pdfPreviewData.companyInfoData.clients.emailAddress;
              // //direct credit template id
              const templateIdForDC = "8dea200e-ae21-4506-9065-12a2a4b057ac";
              // //credit card template id
              const templateIdForCC = "5282bac6-38e6-4903-8a21-76302578d972";
              const data = {
                fileName:
                  order.paymentType === 22 ? "Quote.pdf" : "Invoice.pdf",
                fullName,
                emailAddress,
                templateId:
                  order.paymentType === 22 ? templateIdForDC : templateIdForCC,
                params: {
                  order_number: order.id,
                  name: fullName,
                  order_value:
                    previewData.pdfPreviewData.orderInfoData.totalAmount,
                },
              };
              formData.append("data", JSON.stringify(data));
              const newheaders = {
                ...SENDATTACHMENT.headers,
                // Authorization: login.token
                // Authorization:
                //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
              };
              fetchSendAttachmentExecute({ newheaders, formData });
            });
          }
          // handleClearLS();
        }
      }, [getcontact.state]);

      useEffect(() => {
        if (sendattachment.state.data) {
          if (!getcontact.state.isStatus) {
            // should tell user sending email failed login and ask to try again
            props.history.push("/corporate/order/fail", {
              isTokenExpire: true,
              returnUrl:
                props.location.pathname +
                "?result=" +
                result +
                "&userid=" +
                userid,
            });
          }
          console.log(sendattachment.state.data);
        }
      }, [sendattachment.state]);

      const { productallobj } = productall.state.data;
      const { checkpaymentobj } = checkpayment.state.data;
      const { orderdetailobj } = orderdetail.state.data;
      const { getcontactobj } = getcontact.state.data;

      const checkResult = () => {
        const productDetail =
          !productallobj || productallobj.length < 1 ? false : true;
        const paymentCheck =
          !checkpaymentobj || checkpaymentobj.length < 1 ? false : true;
        const orderDetail =
          !orderdetailobj || orderdetailobj.length < 1 ? false : true;
        const contactDetail =
          !getcontactobj || getcontactobj.length < 1 ? false : true;
        return !productDetail || !paymentCheck || !orderDetail || !contactDetail
          ? false
          : true;
      };

      if (
        !checkResult() ||
        productall.state.isLoading ||
        checkpayment.state.isLoading ||
        orderdetail.state.isLoading ||
        getcontact.state.isLoading
      ) {
        return <Spinner />;
      }
    } else {
      // wrong query params
      console.log("wrong query params");
      const parsed = QueryString.parse(props.location.search);
      const { session_id } = parsed;
      if (session_id) {
        useEffect(() => {
          const newheaders = {
            ...CHECKSTRIPEPAYMENTCONFIG.headers,
            Authorization: login.token,
          };
          const query = "?sessionId=" + session_id;
          execCheckFetchStripePayment({ newheaders, query });
        }, []);
        useEffect(() => {
          if (stripepayment.state.data) {
            if (!stripepayment.state.isStatus) {
              // should tell user sending email failed login and ask to try again
              props.history.push("/corporate/order/fail", {
                returnUrl: "/corporate/order/items",
              });
            }
            console.log(stripepayment);

            const { stripepaymentobj } = stripepayment.state.data;
            const { merchantRef, outComeCode } = stripepaymentobj;
            if (outComeCode !== "0") {
              // unsuccessful?
              props.history.push("/corporate/order/fail", {
                isTokenExpire: false,
                isPaymentFailed: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            }
            const newheaders = {
              ...GETORDERCONFIG.headers,
              Authorization: login.token,
              appName: "corp",
            };
            fetchOrderObjExecute({
              newheaders,
              query: "/" + merchantRef,
            });
          }
        }, [stripepayment.state]);

        useEffect(() => {
          if (orderdetail.state.data) {
            if (!orderdetail.state.isStatus) {
              props.history.push("/corporate/order/fail", {
                isTokenExpire: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            }
            execProductAll();
          }
        }, [orderdetail.state]);

        useEffect(() => {
          if (productall.state.data) {
            if (!productall.state.isStatus) {
              props.history.push("/corporate/order/fail", {
                isTokenExpire: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            }
            const id = isNormUser ? isNormUser.id : isGuestUser.id;
            const newheaders = {
              ...GETCONTACTCONFIG.headers,
              Authorization: login.token,
            };
            fetchContactExecute({
              newheaders,
              query: "/" + id + "/contacts",
            });
          }
        }, [productall.state]);

        useEffect(() => {
          if (getcontact.state.data) {
            if (!getcontact.state.isStatus) {
              props.history.push("/corporate/order/fail", {
                isTokenExpire: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            } else {
              const order = orderdetail.state.data.orderdetailobj;

              const products = order.detail.map((product) => {
                return {
                  value: product.value,
                  quantity: product.quantity,
                  startCardNumber: null,
                  productId: product.productId,
                };
              });
              const prductDetails = products.map((product) => {
                product["details"] =
                  productall.state.data.productallobj.products.filter(
                    (productdetail) =>
                      product.productId === productdetail.productId
                  )[0];
                return product;
              });
              const feesData = order.detail.filter(
                (product) =>
                  product.subClassId === 1 || product.subClassId === 4
              );
              const previewData = {
                // pdfFilename: orderIdCreated + ".pdf",
                pdfFilename: order.id + ".pdf",
                pdfPreviewData: {
                  orderInfoData: order,
                  companyInfoData: {
                    clients: isNormUser ? isNormUser : isGuestUser,
                  },
                  contactInfoData: getcontact.state.data.getcontactobj,
                  productsDetailsData: prductDetails,
                  feesDetailsData: feesData,
                },
                pdfPreviewSize: {
                  width: 450,
                  height: 700,
                },
              };
              async function prepareBlob(previewData) {
                // setPreviewDataReady(previewData);
                const blobPrepared = await PDFBlobProviderInvoice(previewData);
                return blobPrepared;
              }
              prepareBlob(previewData).then((blobPrepared) => {
                let formData = new FormData(); //formdata
                formData.append("file", blobPrepared); //append the values with key, value pair
                const fullName =
                  previewData.pdfPreviewData.companyInfoData.clients.firstName +
                  " " +
                  previewData.pdfPreviewData.companyInfoData.clients.lastName;
                const emailAddress =
                  previewData.pdfPreviewData.companyInfoData.clients
                    .emailAddress;
                // //direct credit template id
                const templateIdForDC = "8dea200e-ae21-4506-9065-12a2a4b057ac";
                // //credit card template id
                const templateIdForCC = "5282bac6-38e6-4903-8a21-76302578d972";
                const data = {
                  fileName:
                    order.paymentType === 22 ? "Quote.pdf" : "Invoice.pdf",
                  fullName,
                  emailAddress,
                  templateId:
                    order.paymentType === 22
                      ? templateIdForDC
                      : templateIdForCC,
                  params: {
                    order_number: order.id,
                    name: fullName,
                    order_value:
                      previewData.pdfPreviewData.orderInfoData.totalAmount,
                  },
                };
                formData.append("data", JSON.stringify(data));
                const newheaders = {
                  ...SENDATTACHMENT.headers,
                  // Authorization: login.token
                  // Authorization:
                  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
                };
                fetchSendAttachmentExecute({ newheaders, formData });
              });
            }
            // handleClearLS();
          }
        }, [getcontact.state]);

        useEffect(() => {
          if (sendattachment.state.data) {
            if (!getcontact.state.isStatus) {
              // should tell user sending email failed login and ask to try again
              props.history.push("/corporate/order/fail", {
                isTokenExpire: true,
                returnUrl:
                  props.location.pathname +
                  "?result=" +
                  result +
                  "&userid=" +
                  userid,
              });
            }
          }
        }, [sendattachment.state]);

        const { productallobj } = productall.state.data;
        // const { checkpaymentobj } = checkpayment.state.data;
        const { orderdetailobj } = orderdetail.state.data;
        const { getcontactobj } = getcontact.state.data;

        const checkResult = () => {
          const productDetail =
            !productallobj || productallobj.length < 1 ? false : true;
          // const paymentCheck =
          //   !checkpaymentobj || checkpaymentobj.length < 1 ? false : true;
          const orderDetail =
            !orderdetailobj || orderdetailobj.length < 1 ? false : true;
          const contactDetail =
            !getcontactobj || getcontactobj.length < 1 ? false : true;
          return !productDetail || !orderDetail || !contactDetail
            ? false
            : true;
        };

        if (
          !checkResult() ||
          productall.state.isLoading ||
          checkpayment.state.isLoading ||
          orderdetail.state.isLoading ||
          getcontact.state.isLoading
        ) {
          return <Spinner />;
        }
      }
      // return props.children;
    }
  }
  if (previewDataReady) {
    return (
      <div>
        <PDFPreviewProviderInvoice {...previewDataReady} />
        {props.children}
      </div>
    );
  } else {
    return props.children;
  }
};

export default index;
