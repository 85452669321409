import Normal from "./normal";
import NormalButton from "./normal-button";
import NormalSplit from "./normalSplit";
import NormalRed from "./normalRed";
import Primary from "./primary";
import Text from "./text";
import Box from "./box";
import DropDown from "./dropDown";

export default {
  Normal,
  NormalButton,
  NormalSplit,
  NormalRed,
  Primary,
  Text,
  Box,
  DropDown,
};
