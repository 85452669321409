import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const PRODUCTOBJECT = CONFIG.application.buynow.objects;
/* CONSTANTS */

const index = (props) => {
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  const isPRLS = LocalStorage.getLS().paymentreason ? true : false;
  /* state */
  const [isPR, setIsPR] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- paymentreason */
  /* -------------- */
  const { paymentreason } = useContext(GlobalContext);

  const execAddPaymentReason = (reason) => {
    paymentreason.dispatch({
      type: paymentreason.actionTypes.ADD_PAYMENT_REASON,
      paymentreason: reason,
    });
  };
  useEffect(() => {
    // fetch local storage and put it inside context
    const paymentReasonLS = LocalStorage.getLS().paymentreason;
    if (paymentReasonLS) {
      execAddPaymentReason({ paymentreason: paymentReasonLS });
    }
  }, []);
  useEffect(() => {
    if (paymentreason.state.data && isTriggerCreate) {
      LocalStorage.addLS(
        "paymentreason",
        paymentreason.state.data.paymentreason,
      );
      setIsTriggerCreate(false);
    }
  }, [paymentreason]);
  useEffect(() => {
    setIsPR(isPRLS);
  }, [isPRLS]);

  /* -------------- */
  /* --- product */
  /* state */

  const handleCreate = async () => {
    if (values.paymentreason) {
      if (values.paymentreason === "Other (please specify)") {
        execAddPaymentReason({ paymentreason: values.othernotes });
        LocalStorage.addLS("paymentreason", values.othernotes);
      } else {
        execAddPaymentReason({ paymentreason: values.paymentreason });
        LocalStorage.addLS("paymentreason", values.paymentreason);
      }
    }
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
  };

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    PRODUCTOBJECT,
  );

  const paymentreasonoption = {
    ...PRODUCTOBJECT.paymentreason,
    error: error.paymentreason,
    value: values.paymentreason,
    onChange,
  };

  const othernotes = {
    ...PRODUCTOBJECT.othernotes,
    error: error.othernotes,
    value: values.othernotes,
    onChange,
  };

  const actionButton = { size: "large" };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  // const productcardsMap = {
  //   380172: "df",
  //   724986: "hb",
  //   724985: "ty",
  // };
  // const productcardsMap = {
  //   380172: "df",
  //   724984: "hb",
  //   724985: "ty",
  // };

  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Inputs.Select {...paymentreasonoption} />
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
        {values.paymentreason &&
          values.paymentreason === "Other (please specify)" && (
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemLongWrapper>
                <Inputs.Text {...othernotes} />
              </ModuleFormItemLongWrapper>
              {/* line */}
            </ModuleFormWrapper>
          )}
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Buttons.Normal {...actionButton}>Add</Buttons.Normal>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Buttons.Text {...backButton}>Back</Buttons.Text>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
