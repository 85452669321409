import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FORGOT_PASSWORD_UPDATE: "FORGOT_PASSWORD_UPDATE"
};

const fetchForgotPasswordUpdate = async (state, forgotpasswordupdate) => {
  state = {
    ...state,
    isStatus: forgotpasswordupdate.status,
    expireToken: sanitizeExpire({
      status: forgotpasswordupdate.status,
      data: forgotpasswordupdate.data,
      name: "forgotpasswordupdateobj"
    }),
    data: sanitizeData({
      status: forgotpasswordupdate.status,
      data: forgotpasswordupdate.data,
      name: "forgotpasswordupdateobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_UPDATE:
      return fetchForgotPasswordUpdate(state, action.forgotpasswordupdate);
    default:
      return state;
  }
};

const forgotpasswordupdate = {
  init,
  actionTypes,
  reducer
};

export default forgotpasswordupdate;
