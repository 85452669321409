import React from "react";
import { Link } from "react-router-dom";

/* packages */
import Privilege from "../../app-conf-privilege";
import { LocalStorage } from "../../lib-atomic-functions";

import { ModuleContainerSm } from "../../lib-atomic-design-section";
import { ModulePaperWrapper } from "../../lib-atomic-design-section";
import {
  ModuleTitle,
  ModuleLink,
  ModuleText,
  ModuleImage,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

/* CONTANTS */
const LANDINGGRAPHICS = CONFIG.setup.assets.logo;
/* CONTANTS */
const linkBack = (module, url, role) => {
  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[
    process.env.REACT_APP_CLIENT
  ];
  const availableModules = rolesHandler(LocalStorage, rolesModules);
  const containerLevelPass =
    Object.keys(availableModules).indexOf(module) > -1 ? true : false;
  if (containerLevelPass) {
    // correct container
    return url;
  } else {
    // incorrect container => redirect to pages that are available to the given user role
    return grabPageUrlToBeRedirected(role, url);
  }
};
const grabPageUrlToBeRedirected = (roleNum, url) => {
  if (roleNum === 0) {
    return "/corporate";
  }
  if (roleNum === 1) {
    return "/corporate";
  }
  if (roleNum === 2) {
    return "/corporate";
  }
  return "/corporate";
};
const index = (props) => {
  const { login, info } = LocalStorage.getLS();
  const { flHeadOffice } = info;
  const linkBackResult = linkBack(
    props.match.params.module,
    props.match.url,
    flHeadOffice
  );
  // if (CONFIG.routes.prefix === "reporting/404") {
  return (
    <ModuleContainerSm>
      <div style={{marginTop:50, marginBottom:50}}>
      {/* <ModulePaperWrapper> */}
        <ModuleImage>
          <img src={LANDINGGRAPHICS} width="50%" padding="50" />
        </ModuleImage>
        <ModuleTitle>Sorry!</ModuleTitle>
        <ModuleText>Page Does Not Exist.</ModuleText>
        <br />
        <ModuleLink>
          <Link to={linkBackResult}>Back</Link>
        </ModuleLink>
      {/* </ModulePaperWrapper> */}</div>
    </ModuleContainerSm>
  );
  // } else {
  //   return null;
  // }
};

export default index;
