import React from "react";

import { Skeletons } from "../../lib-atomic";

/* packages */
import { ModuleContainerLg } from "../../lib-atomic-design-section";
/* packages */

const loader = props => {
  return (
    <div className="bannerWrap-loader">
      <section className="banner">
        <br />
        <br />
        <ModuleContainerLg>
          <Skeletons.Medium />
          <Skeletons.Xlarge />
        </ModuleContainerLg>
      </section>
    </div>
  );
};

export default loader;
