import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_ADDRESS: "FETCH_ADDRESS",
  RESET: "RESET"
};
const fetchReset = async state => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false
  };
  return state;
};
const fetchAddy = async (state, addysearch) => {
  state = {
    ...state,
    isStatus: addysearch.status,
    expireToken: sanitizeExpire({
      status: addysearch.status,
      data: addysearch.data,
      name: "addysearchobj"
    }),
    data: sanitizeData({
      status: addysearch.status,
      data: addysearch.data,
      name: "addysearchobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADDRESS:
      return fetchAddy(state, action.addysearch);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const addysearch = {
  init,
  actionTypes,
  reducer
};

export default addysearch;
