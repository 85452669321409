import React, { useContext, Fragment, useState, useEffect } from "react";
import moment from "moment";
/* package */

import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { makeStyles } from "@material-ui/core/styles";
import { WindowSize } from "../../lib-atomic-functions";
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2, LocalStorage } from "../../lib-atomic-functions";

import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";

/* package */
import { Buttons } from "../../lib-atomic";

import {
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleSubMenuBtn,
} from "../../lib-atomic-design-section";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckPopup from "../popup";
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* modules */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.api.endpoints;
const ACCOUNTACTIVATECONFIG = {
  url: ENDPOINTS.account_activation.url,
  method: ENDPOINTS.account_activation.method,
  headers: ENDPOINTS.account_activation.headers,
  responseType: "json",
};
const ACCOUNTTERMINATECONFIG = {
  url: ENDPOINTS.account_terminate.url,
  method: ENDPOINTS.account_terminate.method,
  headers: ENDPOINTS.account_terminate.headers,
  responseType: "json",
};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    marginBottom: 20,
  },
  table: {
    marginBottom: 20,
  },
  horizontal: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "scroll",
    margin: 0,
    elevation: 0,
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperLoading: {
    backgroundColor: "#cccccc",
    height: "100%",
  },
  root2: {
    // "&:hover": {
    //   cursor: "pointer",
    // },
    height: 60,
  },
  upper: {
    textTransform: "uppercase",
  },
  camel: {
    textTransform: "capitalize",
  },
}));

const index = (props) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  const [expandedRowCategory, setExpandedRowCategory] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [filteredList, setFilteredList] = useState({
    active: [],
    pendingActivation: [],
    pendingSecondaryApproval: [],
    terminated: [],
  });
  const [filterSelected, setFilterSelected] = useState("All");
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [activationInAction, setActivationInAction] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [idInAction, setIdInAction] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { login } = LocalStorage.getLS();
  const { width, height } = WindowSize();
  const classes = useStyles();
  const { childaccounts, activatechild, terminatechild } =
    useContext(GlobalContext);

  /* --- activate account call */
  const [fetchActivateAccount] = FetchContextApiV2(ACCOUNTACTIVATECONFIG);
  const fetchActivateAccountExe = async ({ newheaders, query }) => {
    activatechild.dispatch({
      type: activatechild.actionTypes.ACTIVATE_CHILD,
      activatechild: await fetchActivateAccount(
        { newheaders, query },
        activatechild,
      ),
    });
  };

  /* --- activate account call */
  const [fetchTerminateAccount] = FetchContextApiV2(ACCOUNTTERMINATECONFIG);
  const fetchTerminateAccountExe = async ({ newheaders, query }) => {
    terminatechild.dispatch({
      type: terminatechild.actionTypes.TERMINATE_CHILD,
      terminatechild: await fetchTerminateAccount(
        { newheaders, query },
        terminatechild,
      ),
    });
  };
  const reloadPage = () => {
    props.history.go(0);
  };
  const statusMapper = {
    active: 1,
    pendingActivation: 3,
    pendingSecondaryApproval: 13,
    terminated: 5,
  };
  useEffect(() => {
    const originalChildrenList = JSON.parse(
      JSON.stringify(
        childaccounts.state.data.childaccountsobj.secondaryAccounts,
      ),
    );
    const filtered = originalChildrenList.filter(
      (child) =>
        child.thorStatusId === statusMapper.active ||
        child.thorStatusId === statusMapper.pendingActivation ||
        child.thorStatusId === statusMapper.pendingSecondaryApproval,
    );
    console.log(filtered);
    setRawData(originalChildrenList);
  }, []);

  useEffect(() => {
    setExpandedRowIndex(-1);
    setExpandedRowCategory(null);
    console.log(rawData, rawData && rawData.length > 0);
    if (rawData && rawData.length > 0) {
      const filtered = {
        active: [],
        pendingActivation: [],
        pendingSecondaryApproval: [],
        terminated: [],
      };
      console.log(rawData);
      rawData.map((child) => {
        if (filterSelected === "All") {
          if (child.thorStatusId === statusMapper.active) {
            // is approved; check if they are declined
            filtered.active.push(child);
          } else if (child.thorStatusId === statusMapper.pendingActivation) {
            // is pendingActivation
            filtered.pendingActivation.push(child);
          } else if (
            child.thorStatusId === statusMapper.pendingSecondaryApproval
          ) {
            // is approved; check if they are declined
            filtered.pendingSecondaryApproval.push(child);
          } else {
            filtered.terminated.push(child);
          }
        } else if (filterSelected === "Active") {
          if (child.thorStatusId === statusMapper.active) {
            filtered.active.push(child);
          }
        } else if (filterSelected === "Pending Activation") {
          if (child.thorStatusId === statusMapper.pendingActivation) {
            // is pendingActivation
            filtered.pendingActivation.push(child);
          }
        } else if (filterSelected === "Pending Secondary Approval") {
          if (child.thorStatusId === statusMapper.pendingSecondaryApproval) {
            // is pendingSecondaryApproval
            filtered.pendingSecondaryApproval.push(child);
          }
        } else {
          if (child.thorStatusId === statusMapper.terminated) {
            // is terminated
            filtered.terminated.push(child);
          }
        }
      });
      console.log(filtered);
      filtered.active.length > 0 &&
        filtered.active.sort((a, b) => {
          return a["id"] - b["id"];
        });
      filtered.pendingActivation.length > 0 &&
        filtered.pendingActivation.sort((a, b) => {
          return a["id"] - b["id"];
        });
      filtered.pendingSecondaryApproval.length > 0 &&
        filtered.pendingSecondaryApproval.sort((a, b) => {
          return a["id"] - b["id"];
        });
      filtered.terminated.length > 0 &&
        filtered.terminated.sort((a, b) => {
          return a["id"] - b["id"];
        });
      setFilteredList(filtered);
    }
  }, [rawData, filterSelected]);

  const activateChildFunc = async (rowData) => {
    setLoadingApproval(true);
    // setLoadingDelete(true);

    const newheaders = {
      ...ACCOUNTACTIVATECONFIG.headers,
      Authorization: login.token,
      // appName: "corp",
    };
    await fetchActivateAccountExe({
      query: "/" + rowData.id + "/approval",
      newheaders,
    });
  };

  useEffect(() => {
    if (
      activatechild.state.isStatus &&
      !activatechild.state.isLoading &&
      loadingApproval
    ) {
      reloadPage();
    }
  }, [activatechild.state]);

  const declineReviewFunc = async () => {
    setDeletePopup(!deletePopup);
    setLoadingDelete(true);

    const newheaders = {
      ...ACCOUNTTERMINATECONFIG.headers,
      Authorization: login.token,
      // appName: "corp",
    };
    await fetchTerminateAccountExe({
      query: "/" + idInAction.id + "/decline",
      newheaders,
    });
  };

  useEffect(() => {
    if (
      terminatechild.state.isStatus &&
      !terminatechild.state.isLoading &&
      loadingDelete
    ) {
      setIdInAction(null);
      reloadPage();
    }
  }, [terminatechild.state]);

  useEffect(() => {
    if (!deletePopup && idInAction !== null) {
      setDeletePopup(!deletePopup);
    }
  }, [idInAction]);

  const propsForDeletePopup = {
    ...props,
    toggle: deletePopup,
    setToggle: setDeletePopup,
    action: declineReviewFunc,
    cancelAction: () => {
      setIdInAction(null);
      setDeletePopup(!deletePopup);
    },
  };

  const TableChunk = ({ obj }) => {
    console.log(obj);
    return (
      <Fragment>
        <p
          style={{
            fontFamily: "SF-Pro-Display-Bold",
            fontWeight: 700,
            color: "#000",
            fontSize: 24,
            padding: 0,
            marginBottom: 20,
          }}
        >
          {obj.text} Subsidiary Accounts
        </p>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ borderBottom: 0, minWidth: 150 }}
                  align={"left"}
                >
                  STATUS
                </TableCell>
                <TableCell
                  colSpan={5}
                  style={{ borderBottom: 0 }}
                  align={"left"}
                >
                  NAME
                </TableCell>
                <TableCell style={{ borderBottom: 0 }} align={"left"}>
                  TRADER&nbsp;ID
                </TableCell>
                <TableCell
                  colSpan={2}
                  align={"left"}
                  style={{ borderBottom: 0, paddingRight: 0 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {obj.items.map((row, index) => {
                const colorPrepared =
                  obj.id === statusMapper.active
                    ? "#1fc8be"
                    : obj.id === statusMapper.terminated
                    ? "#bbbbbb"
                    : "#ffffff";
                const backgroundColorPrepared =
                  obj.id === statusMapper.active
                    ? "#edf9f9"
                    : obj.id === statusMapper.terminated
                    ? "#f8f8f8"
                    : obj.id === statusMapper.pendingActivation
                    ? "#8bc53f"
                    : "#ffc14d";
                // const showApprovedData =
                //   row.thorStatusId === "APPROVED" ||
                //   (row.thorStatusId === "RE_REQUESTED" &&
                //     row.promotion)
                //     ? true
                //     : false;

                const propsForEdit = {
                  ...props,
                  id: row.id,

                  mainButton: {
                    text:
                      loadingApproval && activationInAction === row.id ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#ffffff" }}
                        />
                      ) : (
                        <ArrowDropDownIcon />
                      ),
                  },
                  rightButtons: [
                    {
                      text: "ACTIVATE",
                      onClick: (e) => {
                        e.stopPropagation();
                        setActivationInAction(row.id);
                        activateChildFunc(row);
                      },
                      disabled:
                        row.thorStatusId === statusMapper.active ||
                        row.thorStatusId === statusMapper.terminated ||
                        row.thorStatusId === statusMapper.pendingActivation
                          ? true
                          : false,
                      isLoading:
                        loadingApproval && activationInAction === row.id,
                      style: {
                        fontSize: 10,
                        width: "auto",
                      },
                    },
                    {
                      text: "TERMINATE",
                      onClick: (e) => {
                        e.stopPropagation();
                        setIdInAction(row);
                      },
                      disabled:
                        row.thorStatusId === statusMapper.active ||
                        row.thorStatusId === statusMapper.terminated ||
                        row.thorStatusId === statusMapper.pendingActivation
                          ? true
                          : false,
                      style: {
                        fontSize: 10,
                        width: "auto",
                      },
                    },
                  ],
                };
                return (
                  <Fragment key={index}>
                    <TableRow
                      key={row.id}
                      // hover
                      tabIndex={-1}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          color: "black",
                          fontWeight: 600,
                          borderBottomWidth:
                            (obj.items.length - 1 === index ||
                              row.thorStatusId === statusMapper.terminated) &&
                            0,
                        }}
                      >
                        <div
                          style={{
                            color: colorPrepared,
                            backgroundColor: backgroundColorPrepared,
                            padding: 8,
                            marginRight: 8,
                            borderRadius: 4,
                            textAlign: "center",
                            fontSize: 10,
                            position: "relative",
                          }}
                        >
                          {obj.text}
                        </div>
                      </TableCell>
                      <TableCell
                        colSpan={5}
                        align="left"
                        style={{
                          color: "black",
                          fontWeight: 400,
                          borderBottomWidth:
                            (obj.items.length - 1 === index ||
                              row.thorStatusId === statusMapper.terminated) &&
                            0,
                          maxWidth: 400,
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "black",
                          fontWeight: 400,
                          borderBottomWidth:
                            (obj.items.length - 1 === index ||
                              row.thorStatusId === statusMapper.terminated) &&
                            0,
                        }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingLeft: 3,
                          paddingRight: 3,
                          borderBottomWidth:
                            (obj.items.length - 1 === index ||
                              row.thorStatusId === statusMapper.terminated) &&
                            0,
                        }}
                      >
                        {expandedRowIndex === index &&
                        expandedRowCategory === obj.id ? (
                          <KeyboardArrowUpIcon
                            onClick={(e) => {
                              if (expandedRowIndex === index) {
                                setExpandedRowIndex(-1);
                                setExpandedRowCategory(null);
                              } else {
                                setExpandedRowCategory(obj.id);
                                setExpandedRowIndex(index);
                              }
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            onClick={(e) => {
                              if (expandedRowIndex === index) {
                                setExpandedRowIndex(-1);
                                setExpandedRowCategory(null);
                              } else {
                                setExpandedRowCategory(obj.id);
                                setExpandedRowIndex(index);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          paddingLeft: 3,
                          paddingRight: 3,
                          borderBottomWidth:
                            (obj.items.length - 1 === index ||
                              row.thorStatusId === "RE_REQUESTED") &&
                            0,
                        }}
                      >
                        <Buttons.DropDown {...propsForEdit} />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        display:
                          expandedRowIndex === index &&
                          expandedRowCategory === obj.id
                            ? null
                            : "none",
                      }}
                    >
                      <TableCell
                        colSpan={10}
                        style={{
                          padding: 30,
                          paddingLeft: 80,
                          paddingRight: 80,
                          borderBottomWidth:
                            obj.items.length - 1 === index && 0,
                        }}
                      >
                        <div
                          style={{
                            display: width < 600 ? "block" : "flex",
                            flexDirection: "row",
                          }}
                        >
                          {"NAME: " + row.name}
                        </div>
                        <div
                          style={{
                            display: width < 600 ? "block" : "flex",
                            flexDirection: "row",
                          }}
                        >
                          {"EMAIL: " + row.emailAddress}
                        </div>
                        <div
                          style={{
                            display: width < 600 ? "block" : "flex",
                            flexDirection: "row",
                          }}
                        >
                          {"MOBILE: " + row.mobileNumber}
                        </div>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  };

  const filterBtns = [
    {
      label: "All",
      isActive: filterSelected === "All",
      onClick: (e) => {
        e.preventDefault();
        setFilterSelected("All");
      },
    },
    {
      label: "Active",
      isActive: filterSelected === "Active",
      onClick: (e) => {
        e.preventDefault();
        setFilterSelected("Active");
      },
    },
    {
      label: "Pending Activation",
      isActive: filterSelected === "Pending Activation",
      onClick: (e) => {
        e.preventDefault();
        setFilterSelected("Pending Activation");
      },
    },
    {
      label: "Pending Secondary Approval",
      isActive: filterSelected === "Pending Secondary Approval",
      onClick: (e) => {
        e.preventDefault();
        setFilterSelected("Pending Secondary Approval");
      },
    },
    // {
    //   label: "Terminated",
    //   //   style: {
    //   //     color: filterSelected === "Declined" ? "#385AE8" : "#eee",
    //   //     paddingLeft: 25,
    //   //     paddingRight: 25,
    //   //     height: 44,
    //   //     backgroundColor: "transparent",
    //   //     borderBottomWidth: 4,
    //   //     borderBottomStyle: "solid",
    //   //     borderRadius: 0,
    //   //     borderBottomColor:
    //   //       filterSelected === "Declined" ? "#385AE8" : "transparent",
    //   //   },
    //   onClick: (e) => {
    //     e.preventDefault();
    //     setFilterSelected("Terminated");
    //   },
    // },
  ];

  return (
    <Fragment>
      {rawData && rawData.length === 0 ? (
        <Container maxWidth="lg" className={classes.root}>
          <div
            style={{
              width: width < 600 ? "100%" : "50%",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                zIndex: 99,
              }}
            >
              <p
                style={{
                  fontFamily: "SF-Pro-Display-Bold",
                  fontWeight: 800,
                  color: "#000",
                  fontSize: 40,
                  padding: 0,
                  paddingRight: 10,
                  marginBottom: 0,
                }}
              >
                Manage Subsidiary Accounts
              </p>
            </div>
            <p
              style={{
                fontFamily: "SF-Pro-Display-Regular",
                fontWeight: 400,
                color: "#BBBBBB",
                fontSize: 18,
                padding: 0,
                paddingTop: 20,
                paddingRight: 10,
                paddingBottom: 20,
                marginBottom: 0,
              }}
            >
              There seems no accounts allocated to you.
            </p>
          </div>
        </Container>
      ) : (
        <Fragment>
          <Container maxWidth="lg" className={classes.root}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: width < 500 ? 15 : null,
                marginBottom: 50,
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <p
                  style={{
                    fontFamily: "SF-Pro-Display-Bold",
                    fontWeight: 800,
                    color: "#000",
                    fontSize: 40,
                    padding: 0,
                    paddingRight: 10,
                    marginBottom: 0,
                  }}
                >
                  Manage Subsidiary Accounts
                </p>
              </div>
              {/* <Buttons.Normal {...submitButton}>
                {width < 500 ? "+" : "+ New Promotion"}
              </Buttons.Normal> */}
            </div>
            <Grid>
              {loadingDelete ? (
                <Spinner />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottomWidth: 1,
                      borderBottomColor: "#eeeeee",
                      borderBottomStyle: "solid",
                      marginBottom: 40,
                    }}
                  >
                    {filterBtns.map((btnProps, index) => {
                      return (
                        <ModuleSubMenuBtn key={index} {...btnProps} />
                        // <Buttons.Normal key={index} {...btnProps}>
                        //   {btnProps.text}
                        // </Buttons.Normal>
                      );
                    })}
                  </div>
                  {((filterSelected === "Active" &&
                    filteredList.active.length === 0) ||
                    (filterSelected === "Terminated" &&
                      filteredList.terminated.length === 0) ||
                    (filterSelected === "Pending Activation" &&
                      filteredList.pendingActivation.length === 0) ||
                    (filterSelected === "Pending Secondary Approval" &&
                      filteredList.pendingSecondaryApproval.length === 0) ||
                    (filterSelected === "All" &&
                      filteredList.active.length === 0 &&
                      filteredList.terminated.length === 0 &&
                      filteredList.pendingActivation.length === 0 &&
                      filteredList.pendingSecondaryApproval.length === 0)) && (
                    <div>
                      <div
                        style={{
                          width: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "SF-Pro-Display-Bold",
                            fontWeight: 700,
                            color: "#000",
                            fontSize: 24,
                            padding: 0,
                            marginTop: 50,
                            marginBottom: 20,
                          }}
                        >
                          No accounts found..
                        </p>
                        {/* <p
                      style={{
                        fontFamily: "SF-Pro-Display-Regular",
                        fontWeight: 400,
                        color: "#BBBBBB",
                        fontSize: 18,
                        padding: 0,
                        paddingTop: 0,
                        paddingBottom: 20,
                        marginBottom: 0,
                      }}
                    >
                      Just because it’s powerful, that doesn’t mean it’s
                      complicated. Try promoting your business for free.
                    </p> */}
                      </div>
                    </div>
                  )}

                  {/* active(active) */}
                  {filteredList.active && filteredList.active.length > 0 && (
                    <TableChunk
                      {...props}
                      obj={{
                        id: 1,
                        text: "Active",
                        items: filteredList.active,
                      }}
                    />
                  )}
                  {/* pending activation */}
                  {filteredList.pendingActivation &&
                    filteredList.pendingActivation.length > 0 && (
                      <TableChunk
                        {...props}
                        obj={{
                          id: 3,
                          text: "Pending Activation",
                          items: filteredList.pendingActivation,
                        }}
                      />
                    )}
                  {/* pending secondary approval */}
                  {filteredList.pendingSecondaryApproval &&
                    filteredList.pendingSecondaryApproval.length > 0 && (
                      <TableChunk
                        {...props}
                        obj={{
                          id: 13,
                          text: "Pending Approval",
                          items: filteredList.pendingSecondaryApproval,
                        }}
                      />
                    )}
                  {/* terminated */}
                  {/* {filteredList.declined && filteredList.declined.length > 0 && (
                    <TableChunk
                      {...props}
                      obj={{
                        id: 5,
                        text: "Terminated",
                        items: filteredList.declined,
                      }}
                    />
                  )} */}
                  <CheckPopup {...propsForDeletePopup} />
                </>
              )}
            </Grid>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default index;
