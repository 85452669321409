export default {
  project: "Thq-Westfield-Corporate",
  name: "Thq-Westfield-Corporate",
  url: "https://giftcards.westfield.co.nz",
  reducerMapper: {
    380172: 724988,
    724984: 724986,
    724985: 724987,
  },
  productMapper: {
    380172: "df",
    724984: "hb",
    724985: "ty",
  },
  auth_landing_module: "/ordering",
  idle_limit: 1800000, //180000,
  thor: {
    moduleCode: {
      production: "WSH2kdh2bcks",
      development: "WSH2kdh2bcks",
    },
    programId: null,
  },
  westfield: {
    corp: {
      guest: {
        moduleCode: {
          production: "JEKWDGKDPIS",
          development: "JEKWDGKDPIS",
        },
      },
      norm: {
        moduleCode: {
          production: "WSH2kdh2bcks",
          development: "WSH2kdh2bcks",
        },
      },
    },
    bulk: {
      moduleCode: {
        production: "SWv2CTHE3H",
        development: "SWv2CTHE3H",
        // production: "WGMB",
        // development: "WGMB",
      },
    },
  },
  api: {
    tranxactor: {
      url: {
        production:
          "https://westfield.transactortech.co.nz/Westfield-BulkOrder",
        development:
          "https://westfield.transactortech.co.nz/Westfield-BulkOrder",
        // production: "https://tranxactor-uat.txweb.site",
        // development: "https://tranxactor-uat.txweb.site",
      },
    },
    tranxactor_westfield_corp: {
      url: {
        production:
          "https://westfield.transactortech.co.nz/Westfield-Corporate",
        development:
          "https://westfield.transactortech.co.nz/Westfield-Corporate",
        // production: "https://tr4ns10.tr4ns.com/Westfield-Corporate",
        // development: "https://tr4ns10.tr4ns.com/Westfield-Corporate",
      },
    },
    tranxactor_westfield_bulkorder: {
      url: {
        development:
          "https://westfield.transactortech.co.nz/Westfield-BulkOrder",
        production:
          "https://westfield.transactortech.co.nz/Westfield-BulkOrder",
        // production: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder",
        // development: "https://tr4ns10.tr4ns.com/Westfield-BulkOrder",
      },
    },
    tranxactor_service: {
      url: {
        production: "https://westfield.transactortech.co.nz",
        development: "https://westfield.transactortech.co.nz",
        // production: "https://tr4ns10.tr4ns.com",
        // development: "https://tr4ns10.tr4ns.com",
      },
    },
    cms: {
      url: {
        production: "https://strapi.tr4ns.com",
        development: "https://strapi.tr4ns.com",
      },
    },
  },
};
