import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "registrations";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          list: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "GET",
          },
          create: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "POST",
          },
        },
      },
      objects: {
        companyname: {
          name: "companyname",
          initial: "",
          placeholder: "Company Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        abn: {
          name: "abn",
          initial: "",
          placeholder: "ABN",
          helperText: false,
          rules: ["isNotNull"],
        },
        firstname: {
          name: "firstname",
          initial: "",
          placeholder: "First Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        lastname: {
          name: "lastname",
          initial: "",
          placeholder: "Last Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        contactno: {
          name: "contactno",
          initial: "",
          placeholder: "Contact No",
          helperText: false,
          rules: ["isNotNull"],
        },
        mobileno: {
          name: "mobileno",
          initial: "",
          placeholder: "Mobile No",
          helperText: false,
          rules: ["isNotNull"],
        },
        jobtitle: {
          name: "jobtitle",
          initial: "",
          placeholder: "Job Title",
          helperText: false,
          rules: ["isNotNull"],
        },

        email: {
          name: "email",
          initial: "",
          placeholder: "Email",
          helperText: false,
          rules: ["isNotNull"],
        },
        password: {
          name: "password",
          initial: "",
          placeholder: "Password",
          helperText: false,
          rules: ["isNotNull"],
        },
        passwordconfirm: {
          name: "passwordconfirm",
          initial: "",
          placeholder: "Confirm password",
          helperText: false,
          rules: ["isNotNull", "isConfirm"],
          confirm_to: "password",
          confirm_suffix: "password",
        },

        address1: {
          name: "address1",
          initial: "",
          placeholder: "Street Number & Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        suburb: {
          name: "suburb",
          initial: "",
          placeholder: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        state: {
          name: "state",
          initial: "",
          type: "select",
          placeholder: "State",
          options: options.preference_options,
          helpText: "Please select a preference",
          rules: ["isNotNull"],
        },
        postcode: {
          name: "postcode",
          initial: "",
          placeholder: "Post Code",
          helperText: false,
          rules: ["isNotNull"],
        },
      },
      responses: {
        fail: "Incorrect email or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Accepting Retailers Landing",
    prefix: "/corporate/accepting_retailers",
  },
  setup,
};

export default config;
