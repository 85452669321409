import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";

/*
: properties
children
size
fullWidth
*/

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      display: "flex !important",
      flexDirection: "column !important",
    },
  },
}));

const normal = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupDisplayId, setPopupDisplayId] = useState(null);

  const open = Boolean(anchorEl);
  const idOpen = open ? "simple-popover" : undefined;

  const handleOpen = (event, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPopupDisplayId(id);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setPopupDisplayId(null);
  };

  const { mainButton, rightButtons, id } = props;

  return (
    <Fragment>
      <ButtonGroup
        style={{ boxShadow: "none" }}
        variant="contained"
        color="primary"
        aria-label="split button"
      >
        <Button
          disableElevation={true}
          color="primary"
          onClick={(e) => {
            handleOpen(e, id);
          }}
          style={{
            ...mainButton.style,
            ...{
              margin: 0,
              padding: 4,
              minWidth: 0,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },
          }}
        >
          {mainButton.text}
        </Button>
      </ButtonGroup>
      <Popover
        id={idOpen}
        open={open && id === popupDisplayId}
        anchorEl={anchorEl}
        onClose={(e) => {
          handleClose(e);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.root}
      >
        {/* <Paper style={{ padding: 0, margin: 0 }}> */}
        {rightButtons.map((option, index) => (
          <Button
            {...option}
            key={index}
            disableElevation={true}
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => {
              handleClose(e);
              option.onClick(e);
            }}
            style={{
              ...option.style,
              borderTopLeftRadius: index === 0 ? 4 : 0,
              borderBottomLeftRadius: index === rightButtons.length - 1 ? 4 : 0,
              borderTopRightRadius: index === 0 ? 4 : 0,
              borderBottomRightRadius:
                index === rightButtons.length - 1 ? 4 : 0,
              borderTopWidth: index === 0 ? 0 : 0.25,
              borderBottomWidth: index === rightButtons.length - 1 ? 0 : 0.25,
              borderStyle: "solid",
              borderColor: "#ffffff",
            }}
            disableElevation={true}
          >
            {option.isLoading ? (
              <CircularProgress size={25} style={{ color: "#ffffff" }} />
            ) : (
              option.text
            )}
          </Button>
        ))}
        {/* </Paper> */}
      </Popover>
    </Fragment>
  );
};

export default normal;
