import React from "react";
import { ModuleMenuBtn } from "../lib-atomic-design-section";
import CONFIG from "./config";

const App = props => {
  return (
    <div>
      <ModuleMenuBtn {...{ props, ...CONFIG.routes }} />
    </div>
  );
};

export default App;
