import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_REGISTER_ACTIVATION: "ADD_REGISTER_ACTIVATION"
};

const addRegisterActivation = async (state, registeractivation) => {
  state = {
    ...state,
    isStatus: registeractivation.status,
    expireToken: sanitizeExpire({
      status: registeractivation.status,
      data: registeractivation.data,
      name: "registrationactivationobj"
    }),
    data: sanitizeData({
      status: registeractivation.status,
      data: registeractivation.data,
      name: "registrationactivationobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_REGISTER_ACTIVATION:
      return addRegisterActivation(state, action.registeractivation);
    default:
      return state;
  }
};

const registeractivation = {
  init,
  actionTypes,
  reducer
};

export default registeractivation;
