import React, { Fragment, useEffect, useContext, useState } from "react";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import ForgotPasswordContainer from "../../app-module-forgot-password-container/default";
/* container */

/* module */
import CMSHomeHeader from "../../app-module-cms-section-home-header/default";
import ProfileUpdateForm from "../form/updateProfile";
import AccountProfileMenu from "../../app-module-account-profile-section-menu/default";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSHomeHeader {...props} />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ForgotPasswordContainer {...props}>
            <ModuleFormWrapper>
              <ProfileUpdateForm {...props} />
            </ModuleFormWrapper>
          </ForgotPasswordContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
