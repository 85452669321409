const isPassword = (data) => {
  let value = data.value;
  value = value.trim();
  const specialChars = "[$@$!%*#?&_]";
  const upperChars = "[A-Z]";
  const lowerChars = "[a-z]";
  const numberChars = "[0-9]";
  const minChars = 10;

  const matchedCase = new Array();
  matchedCase.push(specialChars); // Special Charector
  matchedCase.push(upperChars); // Uppercase Alpabates
  matchedCase.push(lowerChars); // Lowercase Alphabates
  matchedCase.push(numberChars); // Numbers
  let ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(value)) {
      ctr++;
    }
    if (i === matchedCase.length - 1 && value.length > 6) {
      ctr++;
    }
  }

  const isSpecialChars = new RegExp(specialChars).test(value);
  const isUpperChars = new RegExp(upperChars).test(value);
  const isLowerChars = new RegExp(lowerChars).test(value);
  const isNumberChars = new RegExp(numberChars).test(value);
  const isMinChars = value.length >= minChars;
  if (!isSpecialChars) ctr = 11;
  if (!isUpperChars) ctr = 12;
  if (!isLowerChars) ctr = 13;
  if (!isNumberChars) ctr = 14;
  if (!isMinChars) ctr = 15;

  console.log({ ctr });

  let isValid = false;
  let color = "";
  let strength = "";
  switch (ctr) {
    case 1:
      strength = "password is weak";
      break;
    case 2:
      strength = "password is weak";
      break;
    case 3:
      strength = "password is weak";
      // color = "orange";
      break;
    case 4:
      strength = "password is weak";
      // color = "orange";
      break;
    case 11:
      strength = "needs special characters";
      // color = "orange";
      break;
    case 12:
      strength = "needs upper case characters";
      // color = "orange";
      break;
    case 13:
      strength = "needs lower case characters";
      // color = "orange";
      break;
    case 14:
      strength = "needs numeric characters";
      // color = "orange";
      break;
    case 15:
      strength = `needs to be minimum of ${minChars}`;
      // color = "orange";
      break;
    case 5:
      isValid = true;
      strength = "password is strong";
      break;
    default:
  }
  return isValid ? true : strength;
};

export default isPassword;
