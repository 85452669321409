// NOT LOGGED IN USER
import home from "../../../app-module-home-container";
import register from "../../../app-module-register-container";
import retailer from "../../../app-module-retailer-container";
import account_activation from "../../../app-module-register-activation-container";
import card_activation from "../../../app-module-card-activation-container";
import contact from "../../../app-module-contact-container";
import faq from "../../../app-module-faq-container";
import order from "../../../app-module-order-container";
import login from "../../../app-module-login-container";
import logout from "../../../app-module-logout-container";
import account_profile from "../../../app-module-account-profile-container";
import forgot_password from "../../../app-module-forgot-password-container";
import accepting_retailers from "../../../app-module-accepting-retailer-container";

const Modules = {
  home,
  login,
  logout,
  order,
  card_activation,
  register,
  accepting_retailers,
  account_activation,
  faq,
  contact,
  account_profile,
  forgot_password,
};
export default Modules;
