import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_PRODUCT_ALL: "FETCH_PRODUCT_ALL"
};

const fetchProductAll = async (state, productall) => {
  console.log(productall, "SATE.");
  state = {
    ...state,
    isStatus: productall.status,
    expireToken: sanitizeExpire({
      status: productall.status,
      data: productall.data,
      name: "productallobj"
    }),
    data: sanitizeData({
      status: productall.status,
      data: productall.data,
      name: "productallobj"
    })
  };
  console.log(state);
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_ALL:
      return fetchProductAll(state, action.productall);
    default:
      return state;
  }
};

const productall = {
  init,
  actionTypes,
  reducer
};

export default productall;
