import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* package */
import { ModuleStepperContainer } from "../../lib-atomic-design-section";
/* package */

/* module  */
import CardForm from "../form/card";
import CarrierForm from "../form/carrier";
import CarrierSelectForm from "../form/carrierSelect";
import FeesForm from "../form/fees";
import PaymentForm from "../form/payment";
import NotesForm from "../form/notes";
import PaymentReason from "../form/paymentReason";
import DeliveryAddress from "../form/deliveryAddress";
/* module  */

const getSteps = () => {
  return [
    "Select Gift Cards",
    // "Select Greeting Cards",
    // "Select a Courier",
    "Payment Options",
    "Delivery Address",
    "PO Number (optional)",
    "Reason for Order",
  ];
};

const index = observer((props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const step_0 = activeStep === 0 ? true : false;
  // const step_1 = activeStep === 1 ? true : false;
  const step_1 = activeStep === 1 ? true : false;
  const step_2 = activeStep === 2 ? true : false;
  const step_3 = activeStep === 3 ? true : false;
  const step_4 = activeStep === 4 ? true : false;
  // const step_4 = activeStep === 4 ? true : false;

  /* data props */
  const formData = {
    ...props,
    handleSteps: { handleNext, handleBack, handleReset },
  };
  const stepperData = { steps, activeStep };
  /* data props */

  return (
    <ModuleStepperContainer {...stepperData}>
      {step_0 && <CardForm {...formData} />}
      {/* {step_1 && <CarrierSelectForm {...formData} />} */}
      {/* {step_1 && <FeesForm {...formData} />} */}
      {step_1 && <PaymentForm {...formData} />}
      {/* {step_2 && <DeliveryAddress {...formData} />} */}
      {step_2 && <DeliveryAddress {...formData} />}
      {step_3 && <NotesForm {...formData} />}
      {step_4 && <PaymentReason {...formData} />}
      {/* {step_5 && <CardForm {...formData} />} */}
    </ModuleStepperContainer>
  );
});

export default index;
