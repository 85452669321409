import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_NOTES: "ADD_NOTES"
};

const addNotes = async (state, notes) => {
  const odernotes = notes;
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: odernotes
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTES:
      return addNotes(state, action.notes);
    default:
      return state;
  }
};

const notes = {
  init,
  actionTypes,
  reducer
};

export default notes;
