import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_CREATE_CONTACT: "FETCH_CREATE_CONTACT"
};

const fetchCreateContact = async (state, createcontact) => {
  state = {
    ...state,
    isStatus: createcontact.status,
    expireToken: sanitizeExpire({
      status: createcontact.status,
      data: createcontact.data,
      name: "createcontactobj"
    }),
    data: sanitizeData({
      status: createcontact.status,
      data: createcontact.data,
      name: "createcontactobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CREATE_CONTACT:
      return fetchCreateContact(state, action.createcontact);
    default:
      return state;
  }
};

const createcontact = {
  init,
  actionTypes,
  reducer
};

export default createcontact;
