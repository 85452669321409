import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_CARD_ACTIVATE: "FETCH_CARD_ACTIVATE"
};

const fetchCardActivate = async (state, activate) => {
  state = {
    ...state,
    isStatus: activate.status,
    expireToken: sanitizeExpire({
      status: activate.status,
      data: activate.data,
      name: "activatedetail"
    }),
    data: sanitizeData({
      status: activate.status,
      data: activate.data,
      name: "activatedetail"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CARD_ACTIVATE:
      return fetchCardActivate(state, action.activate);
    default:
      return state;
  }
};

const activate = {
  init,
  actionTypes,
  reducer
};

export default activate;
