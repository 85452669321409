import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */
import postcodelist from "./postcodelist";

const index = (props) => {
  const { product, productall, fees, productbreak } = useContext(GlobalContext);

  const execProductDelete = async ({ item }) => {
    product.dispatch({
      type: product.actionTypes.DELETE_PRODUCT,
      product: item,
    });
  };

  const carrierLS = LocalStorage.getLS().carrieroption
    ? LocalStorage.getLS().carrieroption
    : null;
  const [isCarrier, setIsCarrier] = useState(false);
  // console.log(postcodelist);
  useEffect(() => {
    console.log("aa", product);
  }, [product]);
  useEffect(() => {
    const carrierLS = LocalStorage.getLS().carrieroption
      ? LocalStorage.getLS().carrieroption
      : null;
    if (carrierLS) {
      setIsCarrier(carrierLS);
    } else {
      setIsCarrier(false);
    }
  }, [LocalStorage.getLS().carrieroption]);

  const handleDelete = () => {
    LocalStorage.addLS("carrieroption", null);
    const { productlist } = product.state.data;
    console.log(productlist);
    productlist &&
      productlist.map((productItem) => {
        if (productItem.classification === "carrier") {
          console.log("delete: ", productItem);
          execProductDelete({ item: productItem });
        }
      });
    console.log(productlist);
    setIsCarrier(false);
  };
  if (!isCarrier) return <ModuleFormItemWrapper></ModuleFormItemWrapper>;
  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Carrier Option</b>
            </ModuleSubTitle>
            <ModuleText>
              <b>
                {isCarrier && isCarrier === "1"
                  ? "With Greeting Card"
                  : "Without Greeting Card"}
              </b>
            </ModuleText>
            <ModuleText>
              <small>
                <b>
                  <a
                    style={{ cursor: "pointer", padding: 5 }}
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    REMOVE
                  </a>
                </b>
              </small>
            </ModuleText>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
