import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold,
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

/* module */
import CMSActivateHeader from "../../app-module-cms-section-activate-header/default";
import CardActivationContainer from "../../app-module-card-activation-container/default";
/* module */

const index = (props) => {
  const { activate } = useContext(GlobalContext);

  const handleBack = () => {
    props.history.push("/corporate/card_activation");
  };
  /* data props */
  const backButton = { size: "medium", onClick: handleBack };

  return (
    <ModuleFormItemWrapper>
      <ModulePaperWrapper>
        <ModuleTitle>
          Apologies you have entered incorrect information
        </ModuleTitle>
        <ModuleText>
          Please try again contact Gift Card at “0800 WESTFIELD” or 0800 937 834
        </ModuleText>
        <br />
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...backButton}>BACK</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemWrapper>
  );
};

export default index;
