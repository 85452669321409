import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "registrations";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    contact: {
      api: {
        endpoints: {
          list: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "GET",
          },
          create: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "POST",
          },
          sendcontactform: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_service.url[environment] +
              "/Tx-Contact-Us/contact",
            method: "POST",
          },
        },
      },
      objects: {
        yourname: {
          name: "yourname",
          initial: "",
          label: "Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        phonenumber: {
          name: "phonenumber",
          initial: "",
          label: "Phone Number",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
        emailaddress: {
          name: "emailaddress",
          initial: "",
          label: "Email Address",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        subject: {
          name: "subject",
          initial: "",
          label: "Subject",
          helperText: false,
          rules: ["isNotNull"],
        },
        message: {
          name: "message",
          initial: "",
          label: "Message",
          helperText: false,
          rules: ["isNotNull"],
          multiline: true,
          rows: 6,
          rowsMax: 6,
        },
      },
      responses: {
        fail: "Incorrect email or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Contact Us Form",
    prefix: "/corporate/contact/form",
  },
  setup,
};

export default config;
