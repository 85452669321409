import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

/*
: properties
children
size
fullWidth
*/
const redTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#ed6664",
      main: "#ed6664",
      dark: "#cf5857",
      contrastText: "#fff",
    },
  },
});
const normalRed = (props) => {
  return (
    <ThemeProvider theme={redTheme}>
      <Button
        {...props}
        disableElevation={true}
        variant="contained"
        color="primary"
        type="submit"
      >
        {props.children}
      </Button>
    </ThemeProvider>
  );
};

export default normalRed;
