import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Registration Form";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          updatedetail: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/clients",
            method: "PUT",
          },
          memberenquiry: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/clients",
            method: "GET",
          },
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/token",
            url:
              setup.setup.api.tranxactor_service.url[environment] +
              "/tx-auth/auth/token",
            method: "POST",
          },
          search: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: "https://api.business.govt.nz/services/v4/nzbn/entities",
            method: "GET",
          },
          searchaddy: {
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer 833fcb62037d4f0e27a0a9933328643f"
            },
            doc: "https://api.addy.co.nz",
            url: "https://api.addy.co.nz/search",
            method: "GET",
          },
          searchaddywithid: {
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer 833fcb62037d4f0e27a0a9933328643f"
            },
            doc: "https://api.addy.co.nz",
            url: "https://api.addy.co.nz/address",
            method: "GET",
          },
        },
      },
      objects: {
        companyname: {
          name: "companyname",
          initial: "",
          label: "Company Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        nzbn: {
          name: "nzbn",
          initial: "",
          label: "NZBN",
          placeholder: "Search with your business number or name",
          helperText: false,
          rules: ["isNotNull", "isNumber"],
        },
        firstname: {
          name: "firstname",
          initial: "",
          label: "First Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        lastname: {
          name: "lastname",
          initial: "",
          label: "Last Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        contactno: {
          name: "contactno",
          initial: "",
          label: "Contact No",
          helperText: false,
          rules: ["isNotNull"],
        },
        mobileno: {
          name: "mobileno",
          initial: "",
          label: "Mobile No",
          helperText: false,
          rules: ["isNotNull"],
        },
        jobtitle: {
          name: "jobtitle",
          initial: "",
          label: "Job Title",
          helperText: false,
          rules: false,
        },
        gdpr: {
          name: "gdpr",
          initial: "",
          label: "Mail Preference",
          rules: false,
        },

        email: {
          name: "email",
          initial: "",
          label: "Email",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
        // password: {
        //   name: "password",
        //   initial: "",
        //   label: "Password",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        // passwordconfirm: {
        //   name: "passwordconfirm",
        //   initial: "",
        //   label: "Confirm password",
        //   helperText: false,
        //   rules: ["isNotNull", "isConfirm"],
        //   confirm_to: "password",
        //   confirm_suffix: "password"
        // },

        address1: {
          name: "address1",
          initial: "",
          label: "Street Number & Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        suburb: {
          name: "suburb",
          initial: "",
          label: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        city: {
          name: "city",
          initial: "",
          label: "City",
          helperText: false,
          rules: ["isNotNull"],
        },
        // state: {
        //   name: "state",
        //   initial: "",
        //   type: "select",
        //   label: "State",
        //   options: options.preference_options,
        //   helpText: "Please select a preference",
        //   rules: ["isNotNull"]
        // },
        postcode: {
          name: "postcode",
          initial: "",
          label: "Post Code",
          helperText: false,
          rules: ["isNotNull"],
        },
        contactId: {
          name: "contactId",
          initial: "",
          label: "Contact ID",
          helperText: false,
          rules: false,
        },
        statusId: {
          name: "statusId",
          initial: "",
          label: "Status ID",
          helperText: false,
          rules: false,
        },
        titleId: {
          name: "titleId",
          initial: "",
          label: "Title ID",
          helperText: false,
          rules: false,
        },

        // tandc: { name: "tandc", initial: "", label: "", rules: false }
      },
      responses: {
        fail: "Incorrect email or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Profile Update",
    prefix: "/corporate/account_profile/update",
  },
  setup,
};

export default config;
