import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_NZBN_KEY: "FETCH_NZBN_KEY",
};

const fetchNZBNKey = async (state, nzbnkey) => {
  state = {
    ...state,
    isStatus: nzbnkey.status,
    expireToken: sanitizeExpire({
      status: nzbnkey.status,
      data: nzbnkey.data,
      name: "nzbnkeyobj",
    }),
    data: sanitizeData({
      status: nzbnkey.status,
      data: nzbnkey.data,
      name: "nzbnkeyobj",
    }),
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NZBN_KEY:
      return fetchNZBNKey(state, action.nzbnkey);
    default:
      return state;
  }
};

const nzbnkey = {
  init,
  actionTypes,
  reducer,
};

export default nzbnkey;
