import React, { useContext, useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../lib-atomic-context";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubNote,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.config.objects;
const ENDPOINTS = CONFIG.application.config.api.endpoints;
const UPDATECONFIG = {
  url: ENDPOINTS.updatepassword.url,
  method: ENDPOINTS.updatepassword.method,
  headers: ENDPOINTS.updatepassword.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.config.responses.unverified;
/* CONSTANTS */

const updateProfile = (props) => {
  const userDetail = LocalStorage.getLS().user;
  const login = LocalStorage.getLS().login;

  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);

  const classes = useStyles();

  const findMatchFromConfig = (key) => {
    if (key === "email") {
      return "emailAddress";
    } else {
      return key;
    }
  };
  // assign value to fields
  Object.keys(CONFIGOBJECT).map((key) => {
    const keyForClientFetched = findMatchFromConfig(key);
    CONFIGOBJECT[key].initial = userDetail[keyForClientFetched];

    return null;
  });

  const { updatepassword } = useContext(GlobalContext);

  /* --- update */
  const [fetchUpdatePassword] = FetchContextApiV2(UPDATECONFIG);
  const fetchUpdatePasswordExecute = async ({ body, newheaders }) => {
    updatepassword.dispatch({
      type: updatepassword.actionTypes.FETCH_UPDATE_PASSWORD,
      updatepassword: await fetchUpdatePassword(
        { body, newheaders },
        updatepassword
      ),
    });
  };
  /* --- update */

  const handleCreate = async (e) => {
    setLoaded(true);
    var body = {
      password: values.password,
    };
    const newheaders = {
      ...UPDATECONFIG.headers,
      Authorization: login.token,
    };
    await fetchUpdatePasswordExecute({ body, newheaders });
  };
  const {
    values,
    onReset,
    onCheck,
    onChange,
    onPasswordChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONFIGOBJECT);

  useEffect(() => {
    if (updatepassword.state.data) {
      if (!updatepassword.state.isStatus) {
        props.history.push("/corporate/account_profile/update_pw/fail");
      }
      const { updatepasswordobj } = updatepassword.state.data;
      if (updatepasswordobj.outcomeCode === "0") {
        props.history.push("/corporate/account_profile/update_pw/success");
        return;
      }
    }
  }, [updatepassword.state]);

  /* data props */
  const email = {
    ...CONFIGOBJECT.email,
    error: error.email,
    value: values.email,
    disabled: true,
    onChange,
  };
  const password = {
    ...CONFIGOBJECT.password,
    error: error.password,
    value: values.password,
    onChange,
  };
  const passwordconfirm = {
    ...CONFIGOBJECT.passwordconfirm,
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
  };

  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  const submitButton = { size: "medium" };
  const submitDisabledButton = { size: "medium", disabled: true };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Update Password</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Text {...email} style={{ marginTop: -10, marginBottom: 3 }} />
        </ModuleFormItemLongWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Password
            {...password}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Password
            {...passwordconfirm}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        <ModuleFormItemWrapper>
          <ModuleSubNote>
            The password must be a minimum of 10 characters and contain a
            combination of uppercase letters, lowercase letters, numbers and
            symbols.
          </ModuleSubNote>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <br />
      <br />
      {true ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>Update</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>Update</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default updateProfile;
