import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { WindowSize } from "../../lib-atomic-functions";

import { GlobalProvider, GlobalContext } from "../../lib-atomic-context";

import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import AccountProfileContainer from "../../app-module-account-profile-container/default";
/* container */

/* module */
import CMSRegisterHeader from "../../app-module-cms-section-home-header/default";
import AccountProfileMenu from "../../app-module-account-profile-section-menu/default";
/* module */

import appConf from "../../app-conf";

import CONFIG from "../config";

////////
// const mapImage = appConf[process.env.REACT_APP_CLIENT].assets.corporate_map;
const index = (props) => {
  // const { cms } = useContext(GlobalContext);
  // const retailersPage = cms.state.data.pages[8];
  // const mediaPath = CONFIG.setup.assets.media;
  // const media_01 = retailersPage.media_01;
  // const mapImage = "url(" + mediaPath + media_01.url + ")";
  const mapImage = "https://strapi.tr4ns.com/uploads/a6d4c58e6a474548b0ff77f3fa56dcb2.png";
  const { width, height } = WindowSize();
  return (
    <div style={{}}>
      {/* header */}
      <div
        style={{
          justifyContent: "center",
          width: "80%",
          alignItems: "center",
          alignSelf: "center",
          margin: "auto",
          paddingTop: 10,
          marginBottom: 40,
        }}
      >
        <div
          style={{
            backgroundColor: "transparent",
            fontWeight: 600,
            color: "black",
            borderColor: "transparent",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: 1.1,
            fontSize: 27,
          }}
        >
          Where can you spend your Westfield Gift Card?
        </div>
        <div
          style={{
            backgroundColor: "transparent",
            fontWeight: 400,
            color: "black",
            borderColor: "transparent",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: 1.1,
            fontSize: 17,
            paddingTop: 30,
          }}
        >
          Click on your local centre to find out
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          alignItems: "center",
          alignSelf: "center",
          float: "right",
        }}
      >
        <div
          style={{ position: "relative", width: width < 900 ? "100%" : "50%" }}
        >
          <img
            src={mapImage}
            style={{ marginTop: 10 }}
            width="100%"
            alt="map"
          />
          {/* Albany */}
          <a
            className="accepting-retailer-link"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "33.1%",
              left: "12%",
              width: "10%",
              height: "3.5%",
            }}
            href="https://www.westfield.co.nz/albany/browse-stores?gift-cards-accepted=true"
            target="blank"
          />
          {/* Manukau */}
          <a
            className="accepting-retailer-link"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "36.6%",
              left: "12%",
              width: "19.5%",
              height: "3.5%",
            }}
            href="https://www.westfield.co.nz/manukau/browse-stores?gift-cards-accepted=true"
            target="blank"
          />
          {/* St Lukes */}
          <a
            className="accepting-retailer-link"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "40.1%",
              left: "12%",
              width: "12.5%",
              height: "3.5%",
            }}
            href="https://www.westfield.co.nz/stlukes/browse-stores?gift-cards-accepted=true"
            target="blank"
          />
          {/* New Market */}
          <a
            className="accepting-retailer-link"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "43.6%",
              left: "12%",
              width: "17%",
              height: "3.5%",
            }}
            href="https://www.westfield.co.nz/newmarket/browse-stores?gift-cards-accepted=true"
            target="blank"
          />
          {/* Riccarton */}
          <a
            className="accepting-retailer-link"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "75.3%",
              left: "72%",
              width: "14%",
              height: "3.5%",
            }}
            href="https://www.westfield.co.nz/riccarton/browse-stores?gift-cards-accepted=true"
            target="blank"
          />
        </div>
      </div>
    </div>
  );
};

export default index;
