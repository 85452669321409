import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_GENERATE_PAYMENT: "FETCH_GENERATE_PAYMENT"
};

const fetchGeneratePayment = async (state, generatepayment) => {
  state = {
    ...state,
    isStatus: generatepayment.status,
    expireToken: sanitizeExpire({
      status: generatepayment.status,
      data: generatepayment.data,
      name: "generatepaymentobject"
    }),
    data: sanitizeData({
      status: generatepayment.status,
      data: generatepayment.data,
      name: "generatepaymentobject"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GENERATE_PAYMENT:
      return fetchGeneratePayment(state, action.generatepayment);
    default:
      return state;
  }
};

const generatepayment = {
  init,
  actionTypes,
  reducer
};

export default generatepayment;
