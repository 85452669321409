import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSBuynowHeader from "../../app-module-cms-section-buynow-header/default";
import OrderContainer from "../../app-module-order-container/default";
import GuestInfoForm from "../../app-module-order-form-guest-info/default";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSBuynowHeader />
      </CMSContainer>
      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <OrderContainer {...props}>
            <GuestInfoForm {...props} />
          </OrderContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
