import React from 'react';

/* mui */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const title = props => {
  
  /* mui */
  const classes = useStyles();
  /* mui */  

  return <Typography variant="h4" className={classes.title}>
    <b>{ props.children }</b>
  </Typography>

}

export default title;
