import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_REGISTER: "FETCH_REGISTER",
  RESET: "RESET",
};

const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  };
  return state;
};

const fetchRegister = async (state, register) => {
  state = {
    ...state,
    isStatus: register.status,
    expireToken: sanitizeExpire({
      status: register.status,
      data: register.data,
      name: "registerdetail",
    }),
    data: sanitizeData({
      status: register.status,
      data: register.data,
      name: "registerdetail",
    }),
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REGISTER:
      return fetchRegister(state, action.register);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const register = {
  init,
  actionTypes,
  reducer,
};

export default register;
