import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

const index = (props) => {
  const prLS = LocalStorage.getLS().paymentreason;
  const { paymentreason } = useContext(GlobalContext);
  let prdata = "";
  if (prLS) {
    prdata = prLS;
  } else {
    return null;
  }
  // if (paymentreason.state.data) {
  //   prdata = paymentreason.state.data.paymentreason;
  // } else {
  //   return null;
  // }

  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Reason for Order</b>
            </ModuleSubTitle>
            <ModuleText>{prdata}</ModuleText>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
