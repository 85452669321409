import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "registrations";
const server = null;
const service = null;

const options = {
  payment_options: [
    { name: "CREDIT CARD", value: "CC" },
    { name: "DIRECT CREDIT", value: "DC" },
  ],
  fees_options: [
    { name: "STANDARD ($8.95)", value: "8.95" },
    { name: "EXPRESS ($15.95)", value: "DC" },
  ],
  carrier_option: [
    { name: "With Greeting Card", value: "1" },
    { name: "Without Greeting Card", value: "0" },
  ],
  cardValueRule: {
    min: 10,
    max: 500,
    prefix: "$",
    totalMax: 20000,
  },
  cardQtyRule: {
    min: 1,
    max: 500,
    prefix: "",
    totalMax: 500,
  },
  paymentReason: [
    {
      name: "Acquisition and Retention Programs",
      value: "Acquisition and Retention Programs",
    },
    { name: "Client Loyalty and Rewards", value: "Client Loyalty and Rewards" },
    { name: "Community Programs", value: "Community Programs" },
    { name: "Community Allowances", value: "Community Allowances" },
    { name: "Competition Prizes", value: "Competition Prizes" },
    { name: "Employee Commission", value: "Employee Commission" },
    { name: "Employee Expenses", value: "Employee Expenses" },
    {
      name: "Employee Incentive Programs",
      value: "Employee Incentive Programs",
    },
    {
      name: "Employee Rewards and Recognition",
      value: "Employee Rewards and Recognition",
    },
    {
      name: "Occasion Gifts (e.g. Birthday, Farewell, Congratulations etc)",
      value: "Occasion Gifts (e.g. Birthday, Farewell, Congratulations etc)",
    },
    { name: "Promotions and Offers", value: "Promotions and Offers" },
    {
      name: "Research Program Participation ",
      value: "Research Program Participation ",
    },
    { name: "Other (please specify)", value: "Other (please specify)" },
  ],
};

const config = {
  details: {
    project,
    server,
    environment,
    service,
    options,
  },
  application: {
    buynow: {
      api: {
        endpoints: {
          list: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "GET",
          },
          create: {
            headers: {
              "Content-Type": "application/json",
              Authorization: null,
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/auth/token",
            url: setup.setup.api.tranxactor.url[environment] + "/configs",
            method: "POST",
          },
        },
      },
      objects: {
        paymentreason: {
          name: "paymentreason",
          initial: "",
          label: "Reason for Order",
          helperText: false,
          options: options.paymentReason,
          rules: ["isNotNull"],
        },
        othernotes: {
          name: "othernotes",
          initial: "",
          label: "Other Reason",
          helperText: false,
          rules: false,
        },
      },
      objects_cards: {
        // product: {
        //   name: "product",
        //   initial: "",
        //   label: "Product",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        carrieroption: {
          name: "carrieroption",
          initial: "",
          label: "Greeting Card Options",
          helperText: false,
          options: options.carrier_option,
          rules: ["isNotNull"],
        },
        value: {
          defaultValue: "",
          name: "value",
          initial: "",
          label: "Value",
          helperText: false,
          rules: [
            "isNotNull",
            "isNumber",
            "isMaxVal",
            "isMinVal",
            // "isMaxTotalLS",
          ],
          maxLimit: options.cardValueRule.max,
          minLimit: options.cardValueRule.min,
          isMaxTotalLimitLS: options.cardValueRule.totalMax,
          suffix: "$",
        },
        quantity: {
          name: "quantity",
          initial: "",
          label: "Quantity",
          helperText: false,
          rules: [
            "isNotNull",
            "isNumber",
            "isMaxVal",
            "isMinVal",
            // "isMaxTotalLS",
          ],
          maxLimit: options.cardQtyRule.max,
          minLimit: options.cardQtyRule.min,
          isMaxTotalLimitLS: options.cardQtyRule.totalMax,
          suffix: "",
        },
      },
      objects_carriers: {
        // product: {
        //   name: "product",
        //   initial: "",
        //   label: "Product",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        // value: {
        //   name: "value",
        //   initial: 0,
        //   label: "Value",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        quantity: {
          name: "quantity",
          initial: "",
          label: "Quantity",
          helperText: false,
          rules: ["isMinVal", "isNumber", "isMaxVal"],
          minLimit: 1,
          maxLimit: 1,
          suffix: "",
        },
      },
      objects_fees: {
        // product: {
        //   name: "product",
        //   initial: "",
        //   label: "Product",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        // value: {
        //   name: "value",
        //   initial: 0,
        //   label: "Value",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        feesoption: {
          name: "feesoption",
          initial: "",
          label: "Fees",
          helperText: false,
          options: options.fees_options,
          rules: ["isNotNull"],
        },
      },
      objects_payment: {
        // product: {
        //   name: "product",
        //   initial: "",
        //   label: "Product",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        // value: {
        //   name: "value",
        //   initial: 0,
        //   label: "Value",
        //   helperText: false,
        //   rules: ["isNotNull"]
        // },
        paymentoption: {
          name: "paymentoption",
          initial: "",
          label: "Payment Option",
          helperText: false,
          options: options.payment_options,
          rules: ["isNotNull"],
        },
      },
      objects_notes: {
        ordernotes: {
          name: "ordernotes",
          initial: "",
          label: "Purchase Order No.",
          helperText: false,
          rules: false,
        },
      },
      objects_address: {
        firstname: {
          name: "firstname",
          initial: "",
          label: "Recipient's Name",
          helperText: false,
          rules: ["isNotNull"],
        },
        // lastname: {
        //   name: "lastname",
        //   initial: "",
        //   label: "Recipient's Lastname",
        //   helperText: false,
        //   rules: ["isNotNull"],
        // },
        companyName: {
          name: "companyName",
          initial: "",
          label: "Company Name*",
          helperText: false,
          rules: ["isNotNull"],
        },
        street: {
          name: "street",
          initial: "",
          label: "Street",
          helperText: false,
          rules: ["isNotNull"],
        },
        suburb: {
          name: "suburb",
          initial: "",
          label: "Suburb",
          helperText: false,
          rules: ["isNotNull"],
        },
        postcode: {
          name: "postcode",
          initial: "",
          label: "Postcode",
          helperText: false,
          rules: ["isNotNull"],
        },
        city: {
          name: "city",
          initial: "",
          label: "City",
          helperText: false,
          rules: ["isNotNull"],
        },
        // state: {
        //   name: "state",
        //   initial: "",
        //   label: "State",
        //   helperText: false,
        //   rules: false,
        // },
        // gdpr: {
        //   name: "gdpr",
        //   initial: "",
        //   label: "Use billing address as delivery address",
        //   helperText: false,
        //   rules: false,
        // },
      },

      responses: {
        fail: "No such username or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Order Form",
    prefix: "/corporate/order/form/items",
  },
  setup,
};

export default config;
