import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_DELIVERY_ADDRESS: "ADD_DELIVERY_ADDRESS",
};

const addDeliveryAddress = async (state, deliveryAdress) => {
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: deliveryAdress,
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_DELIVERY_ADDRESS:
      return addDeliveryAddress(state, action.deliveryAdress);
    default:
      return state;
  }
};

const index = {
  init,
  actionTypes,
  reducer,
};

export default index;
