const environment = process.env.NODE_ENV;
const project = "Reporting";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: "Contact Us",
    prefix: "https://support.westfield.co.nz/hc/en-us/requests/new?ticket_form_id=33684856247193",
  },
};

export default config;
