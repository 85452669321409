import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { LocalStorage, FetchContextApiV2 } from "../../lib-atomic-functions";
/* packages */

/* module */
/* module */
import Loader from "../loader/accountProfileLoader";
import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;
const REGISTERACTIVATIONCONFIG = {
  url: ENDPOINTS.account_activation.url,
  method: ENDPOINTS.account_activation.method,
  headers: ENDPOINTS.account_activation.headers,
  responseType: "json"
};
const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json"
};
const TRANSACTIONSCONFIG = {
  url: ENDPOINTS.transactions.url,
  method: ENDPOINTS.transactions.method,
  headers: ENDPOINTS.transactions.headers,
  responseType: "json"
};
/* CONSTANTS */

const index = props => {
  // const isUserLS = LocalStorage.getLS().user;
  // const login = LocalStorage.getLS().login;
  // // this should be returning Route to home instead of null
  // if (!isUserLS) return null;
  // if (!login || Object.keys(isUserLS).length === 0) {
  //   LocalStorage.removeLS();
  //   return <Redirect to="/login" />;
  // }

  return props.children;
};

export default index;
