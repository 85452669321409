import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal
} from "../../lib-atomic-design-section";
/* packages */

/* module */
import OrderItem from "../../app-module-order-section-list-item/default";
/* module */

import CONFIG from "../config";

const index = props => {
  const { items } = props;

  return (
    <ModuleFormWrapper>
      {items.map(item => {
        const itemData = { ...props, item };
        return <OrderItem {...itemData} />;
      })}
    </ModuleFormWrapper>
  );
};

export default index;
