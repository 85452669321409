import React from "react";

import "./style.css";

const xlarge = props => {
  return (
    <div className="skeleton-container-wide">
      <div className="line xl h336"></div>
    </div>
  );
};

export default xlarge;
