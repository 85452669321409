import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_CMS: "FETCH_CMS"
};

const fetchCMS = async ({ state, dataResult }) => {
  state = {
    ...state,
    isStatus: dataResult.status,
    expireToken: sanitizeExpire({
      status: dataResult.status,
      data: dataResult.data,
      name: "pages"
    }),
    data: sanitizeData({
      status: dataResult.status,
      data: dataResult.data,
      name: "pages"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CMS:
      return fetchCMS({ state, dataResult: action.cms });
    default:
      return state;
  }
};

const boxes = {
  init,
  actionTypes,
  reducer
};

export default boxes;
