import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { ModuleFooterThinBtn } from "../../components/lib-atomic-design-section";

const useStyles = makeStyles((theme) => ({
  appBar: {
    flex: 1,
    marginBottom: 15,
    backgroundColor: "#ffffff",
    borderBottomColor: "transparent",
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const importNewNav = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.appBar}>
      <br />
      <Toolbar style={{ marginLeft: 0, paddingLeft: 1 }}>
        <Grid container>
          <Grid>
            <div
              id="thq-sidebar-modules"
              style={{
                flex: 1,
                display: "flex",
                alignSelf: "center",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <ModuleFooterThinBtn
                  {...{
                    props,
                    ...{
                      label: "SCENTRE GROUP",
                      prefix: "http://www.scentregroup.com/",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  width: 2,
                  backgroundColor: "#666",
                  height: "auto",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 7,
                  marginBottom: 7,
                }}
              />
              <div>
                <ModuleFooterThinBtn
                  {...{
                    props,
                    ...{
                      label: "PRIVACY POLICY",
                      prefix: "https://www.westfield.co.nz/privacy-policy",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  width: 2,
                  backgroundColor: "#666",
                  height: "auto",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 7,
                  marginBottom: 7,
                }}
              />
              <div>
                <ModuleFooterThinBtn
                  {...{
                    props,
                    ...{
                      label: "TERMS + CONDITIONS",
                      prefix:
                        "https://www.westfield.co.nz/terms-and-conditions#westfield-gift-card-terms-and-conditions",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  width: 2,
                  backgroundColor: "#666",
                  height: "auto",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 7,
                  marginBottom: 7,
                }}
              />
              <div>
                <ModuleFooterThinBtn
                  {...{
                    props,
                    ...{
                      label: "©2020 SCENTRE GROUP",
                      // prefix: "/corporate/order"
                    },
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
};

export default importNewNav;
