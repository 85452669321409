import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import OrderList from "../list";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ASSETS = CONFIG.setup.assets;
/* CONSTANTS */

const index = (props) => {
  const productLS = LocalStorage.getLS().products;

  /* state */
  /* --- product */
  /* -------------- */
  const { product, productall, productbreak } = useContext(GlobalContext);
  const [isProductDeleted, setIsProductDeleted] = useState(false);
  const [isDelete, setIsDelete] = useState([]);

  const updateProductItems = async ({ newData }) => {
    product.dispatch({
      type: product.actionTypes.ADD_PRODUCT_LS,
      product: newData,
    });
  };

  const execProductDelete = async ({ item }) => {
    product.dispatch({
      type: product.actionTypes.DELETE_PRODUCT,
      product: item,
    });
  };

  const execProductEdit = async ({ item }) => {
    product.dispatch({
      type: product.actionTypes.EDIT_PRODUCT,
      product: item,
    });
  };

  /* -------------- */
  /* --- product */
  /* state */

  const handleDelete = (item) => {
    //product.state.data.productlist.splice(item.index, 1);
    execProductDelete({ item });
    if (
      product.state.data.productlist.length === 2 ||
      product.state.data.productlist.length === 1
    ) {
      LocalStorage.addLS("products", []);
    }
    // LocalStorage.addLS("products", product.state.data.productlist);
    // setIsProductDeleted(true);
  };

  const handleEdit = (item) => {
    execProductEdit({ item });    
  };

  /* data props */
  const cardData = {
    ...props,
    items: [],
    assets: ASSETS["cards"],
    handleDelete,
    handleEdit
  };
  const carrierData = {
    ...props,
    items: [],
    assets: ASSETS["carrie rs"],
    handleDelete,
    handleEdit
  };
  /* data props */
  if (product.state.data) {
    product.state.data.productlist.map((items, index) => {
      items.index = index;
      if (items.classification === "card") {
        cardData.items = [...cardData.items, items];
      } else {
        carrierData.items = [...carrierData.items, items];
      }
      if (product.state.data.productlist.length - 1 === index) {
        // remove carrier item that should not exist
        LocalStorage.addLS("products", [
          ...cardData.items,
          ...carrierData.items,
        ]);
      }
    });
  } else {
    const productsLS = LocalStorage.getLS().products;
    const productIndex = Date.now();
    const guestLocalProduct = LocalStorage.getLS().tempGuestCheckoutBody;

    productsLS &&
      productsLS.map((items, index) => {

        if (items && items.classification === "card") {
          cardData.items = [...cardData.items, items];
        }
        if (items && items.classification === "carrier") {
          carrierData.items = [...carrierData.items, items];
        }
        // const selectedproduct = productbreak.state.data.mapped.filter(
        //   (product) =>
        //     (product.card &&
        //       product.card.productId &&
        //       product.card.productId.toString() === items.product.toString()) ||
        //     (product.carrier &&
        //       product.carrier.productId &&
        //       product.carrier.productId.toString() ===
        //         items.product.toString()) ||
        //     (product.carriernone &&
        //       product.carriernone.productId &&
        //       product.carriernone.productId.toString() ===
        //         items.product.toString())
        // )[0];
        // console.log(selectedproduct, productall, items);
        // if (selectedproduct) {
        //   // carrier found?
        //   const itemsFound = productall.state.data.productallobj.products.filter(
        //     (i) =>
        //       i.productId &&
        //       items.product &&
        //       i.productId.toString() === items.product.toString()
        //   )[0];
        //   console.log("passed ", itemsFound);
        //   // itemsFound.index = index;
        //   const itemPrepared = {
        //     ...itemsFound,
        //     // productIndex,
        //     // product: itemsFound.productId,
        //     // classification:
        //     //   itemsFound && itemsFound.subclassId === 3 ? "card" : "carrier",
        //     // image: itemsFound.path,
        //     // value: items.value,
        //     // quantity: items.quantity,
        //     // carrieroption: items.carrieroption,
        //   };
        //   console.log("passed2");
        //   if (itemsFound && itemsFound.subclassId === 3) {
        //     cardData.items = [...cardData.items, itemsFound];
        //   }
        //   if (itemsFound && itemsFound.subclassId === 2) {
        //     carrierData.items = [...carrierData.items, itemsFound];
        //   }
        // }
        // console.log("passed3");
        // if (productsLS.length - 1 === index) {
        //   // remove carrier item that should not exist
        //   // LocalStorage.addLS("products", [
        //   //   ...cardData.items,
        //   //   ...carrierData.items,
        //   // ]);
        //   console.log("passed4", [...cardData.items, ...carrierData.items]);
        //   // updateProductItems({
        //   //   newData: [...cardData.items, ...carrierData.items],
        //   // });
        //   console.log("passed5");
        // }
      });
    LocalStorage.addLS("products", productLS);
    // return null;
  }
  return (
    <Fragment>
      <OrderList {...cardData} />
      {/* <OrderList {...carrierData} /> */}
    </Fragment>
  );
};

export default index;
