import React from "react";

import TransactionListContainer from "../sub-container/transaction-list-container";
import TransactionDetailContainer from "../sub-container/transaction-detail-container";
import AccountUpdateContainer from "../sub-container/account-update-container";
import AccountManageContainer from "../sub-container/account-manage-container";

const TransactionList = (props) => {
  return (
    <TransactionListContainer {...props}>
      {props.children}
    </TransactionListContainer>
  );
};
const TransactionDetail = (props) => {
  return (
    <TransactionDetailContainer {...props}>
      {props.children}
    </TransactionDetailContainer>
  );
};
const AccountUpdate = (props) => {
  return (
    <AccountUpdateContainer {...props}>{props.children}</AccountUpdateContainer>
  );
};
const AccountManage = (props) => {
  return (
    <AccountManageContainer {...props}>{props.children}</AccountManageContainer>
  );
};

export default {
  TransactionList,
  TransactionDetail,
  AccountUpdate,
  AccountManage,
};
