import React, { useState } from "react";

import Container from "@material-ui/core/Container";

// apps
import NewNav from "./imports-new-nav";
import Body from "./imports-body";
import Footer from "./imports-footer";

import appConf from "../../components/app-conf";

////////
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;
const Main = (props) => {
  return (
    <Themes>
      <div>
        <Container>
          <NewNav {...props} />
        </Container>
        <Body {...props} />
        <Container>
          <Footer {...props} />
        </Container>
      </div>
    </Themes>
  );
};

export default Main;
