import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";

/* packages */
import { loadStripe } from "@stripe/stripe-js";

import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
  FetchContextApiV2,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
/* packages */

import { GlobalContext } from "../../lib-atomic-context";
/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "55%",
    minWidth: 300,
    maxWidth: 500,
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  paper3: {
    // display: 'relative',
    width: "100%",
    height: "40%",
    backgroundColor: "white",
    border: "0px solid #000",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: "auto",
    textAlign: "center",
  },
  menuItem: {
    backgroundColor: "transparent",
    "&hover": {
      backgroundColor: "transparent",
    },
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const INFOOBJECT = CONFIG.application.config.objects;
const GUESTPOINTS = CONFIG.application.config.api.endpoints;

const GENERATEPAYMENTCONFIG = {
  url: GUESTPOINTS.generatepayment.url,
  method: GUESTPOINTS.generatepayment.method,
  headers: GUESTPOINTS.generatepayment.headers,
  responseType: "json",
};
const GETORDERCONFIG = {
  url: GUESTPOINTS.getorder.url,
  method: GUESTPOINTS.getorder.method,
  headers: GUESTPOINTS.getorder.headers,
  responseType: "json",
};
const CHECKOUTCONFIG = {
  url: GUESTPOINTS.checkout.url,
  method: GUESTPOINTS.checkout.method,
  headers: GUESTPOINTS.checkout.headers,
  responseType: "json",
};
const REGISTERCONFIG = {
  url: GUESTPOINTS.register.url,
  method: GUESTPOINTS.register.method,
  headers: GUESTPOINTS.register.headers,
  responseType: "json",
};
const TOKENCONFIG = {
  url: GUESTPOINTS.auth.url,
  method: GUESTPOINTS.auth.method,
  headers: GUESTPOINTS.auth.headers,
  responseType: "json",
};
const MEMBERENQUIRYCONFIG = {
  url: GUESTPOINTS.memberenquiry.url,
  method: GUESTPOINTS.memberenquiry.method,
  headers: GUESTPOINTS.memberenquiry.headers,
  responseType: "json",
};
const INFOCONFIG = {
  url: GUESTPOINTS.create.url,
  method: GUESTPOINTS.create.method,
  headers: GUESTPOINTS.create.headers,
  responseType: "json",
};
const SEARCHCONFIG = {
  url: GUESTPOINTS.search.url,
  method: GUESTPOINTS.search.method,
  headers: GUESTPOINTS.search.headers,
  responseType: "json",
};
const SEARCHADDYCONFIG = {
  url: GUESTPOINTS.searchaddy.url,
  method: GUESTPOINTS.searchaddy.method,
  headers: GUESTPOINTS.searchaddy.headers,
  responseType: "json",
};
const SEARCHADDYWITHIDCONFIG = {
  url: GUESTPOINTS.searchaddywithid.url,
  method: GUESTPOINTS.searchaddywithid.method,
  headers: GUESTPOINTS.searchaddywithid.headers,
  responseType: "json",
};
/* CONSTANTS */
const randomString =
  Math.random().toString(36).substring(2, 35) +
  Math.random().toString(36).substring(2, 35);

const EMAILALREADYEXISTMSG = CONFIG.application.config.responses.emailexist;
const TRADERALREADYEXIST = CONFIG.application.config.responses.traderexist;

const register = (props) => {
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [hideNzbn, setHideNzbn] = useState(false);
  const [nzbnSelected, setNzbnSelected] = useState(false);
  const [nzbnChanged, setNzbnChanged] = useState(false);

  const [resetNzbnSearch, setResetNzbnSearch] = useState(false);

  const [hideBilling, setHideBilling] = useState(false);
  const [addySelected, setAddySelected] = useState(false);
  const [addyChanged, setAddyChanged] = useState(false);
  const [addressAssigned, setAddressAssigned] = useState(false);

  const [toggleDeliveryField, setToggleDeliveryField] = useState(false);

  const [hideDelivery, setHideDelivery] = useState(false);
  const [addySelectedForDelivery, setAddySelectedForDelivery] = useState(false);
  const [addyChangedForDelivery, setAddyChangedForDelivery] = useState(false);
  const [addressAssignedForDelivery, setAddressAssignedForDelivery] = useState(
    false
  );
  const [resetAddySearch, setResetAddySearch] = useState(false);

  const classes = useStyles();

  const {
    token,
    register,
    nzbnsearch,
    nzbnkey,
    addysearch,
    addysearchwithid,
    memberenquiry,
    checkout,
    orderdetail,
    generatepayment,
  } = useContext(GlobalContext);

  const handleClearLS = () => {
    LocalStorage.addLS("products", false);
    LocalStorage.addLS("payment", false);
    LocalStorage.addLS("fees", false);
    LocalStorage.addLS("notes", false);
  };
  /* -------------- */
  /* --- generate payment */
  /* -------------- */
  const [fetchGeneratePayment] = FetchContextApiV2(GENERATEPAYMENTCONFIG);
  const execGeneratePayment = async ({ newheaders, body }) => {
    generatepayment.dispatch({
      type: generatepayment.actionTypes.FETCH_GENERATE_PAYMENT,
      generatepayment: await fetchGeneratePayment(
        { newheaders, body },
        generatepayment
      ),
    });
  };
  /* -------------- */
  /* --- orders */
  /* -------------- */
  const [fetchOrderDetail] = FetchContextApiV2(GETORDERCONFIG);
  const execOrderDetail = async ({ newheaders, query }) => {
    orderdetail.dispatch({
      type: orderdetail.actionTypes.FETCH_ORDER_DETAIL,
      orderdetail: await fetchOrderDetail({ newheaders, query }, orderdetail),
    });
  };
  /* -------------- */
  /* --- checkout */
  /* -------------- */
  const [fetchCheckout] = FetchContextApiV2(CHECKOUTCONFIG);
  const fetchCheckoutExec = async ({ newheaders, body }) => {
    checkout.dispatch({
      type: checkout.actionTypes.FETCH_CHECKOUT,
      checkout: await fetchCheckout({ newheaders, body }, checkout),
    });
  };

  /* --- register */
  const [fetchRegister] = FetchContextApiV2(REGISTERCONFIG);
  const fetchRegisterExecute = async ({ body }) => {
    register.dispatch({
      type: register.actionTypes.FETCH_REGISTER,
      register: await fetchRegister({ body }, register),
    });
  };
  const resetRegisterExecute = () => {
    register.dispatch({
      type: register.actionTypes.RESET,
    });
  };

  /* --- register */

  /* --- login token */
  const [fetchToken] = FetchContextApiV2(TOKENCONFIG);
  const fetchTokenExecute = async ({ body }) => {
    token.dispatch({
      type: token.actionTypes.FETCH_TOKEN,
      token: await fetchToken({ body }, token),
    });
  };
  /* --- login token */

  /* --- member enquiry */
  const [fetchMemberEnquiryObj] = FetchContextApiV2(MEMBERENQUIRYCONFIG);
  const fetchMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.FETCH_MEMBER_ENQUIRY,
      memberenquiry: await fetchMemberEnquiryObj({ newheaders }, memberenquiry),
    });
  };
  /* --- member enquiry */

  /* --- nzbn */
  const [fetchNzbnSearch] = FetchContextApiV2(SEARCHCONFIG);
  const fetchNzbnSearchExecute = async ({ query, newheaders }) => {
    nzbnsearch.dispatch({
      type: nzbnsearch.actionTypes.FETCH_NZBN,
      nzbnsearch: await fetchNzbnSearch({ query, newheaders }, nzbnsearch),
    });
  };
  /* --- nzbn */

  /* --- addy */
  const [fetchAddySearch] = FetchContextApiV2(SEARCHADDYCONFIG);
  const fetchAddySearchExecute = async ({ query }) => {
    addysearch.dispatch({
      type: addysearch.actionTypes.FETCH_ADDRESS,
      addysearch: await fetchAddySearch({ query }, addysearch),
    });
  };
  /* --- addy */

  /* --- addy with id */
  const [fetchAddySearchWithId] = FetchContextApiV2(SEARCHADDYWITHIDCONFIG);
  const fetchAddySearchWithIdExecute = async ({ query }) => {
    addysearchwithid.dispatch({
      type: addysearchwithid.actionTypes.FETCH_ADDRESS_WITH_ID,
      addysearchwithid: await fetchAddySearchWithId(
        { query },
        addysearchwithid
      ),
    });
  };
  /* --- addy with id */

  const handleAddyClick = async (entity) => {
    const apikeyforaddy = "c9a6e01e81624d8283fa9e4a186b6dfa";
    await fetchAddySearchWithIdExecute({
      query: "/" + entity.id + "?key=" + apikeyforaddy,
    });
  };

  const handleCreate = async (e) => {
    console.log(values);
    setWarningMessage("");
    setWarningMessageVisible(false);
    var body = {
      name: values.companyname,
      externalId: values.nzbn,
      firstName: "",
      lastName: "",
      phone1: "",
      mobileNumber: values.mobile,
      titleId: null,
      mailPref: 0,
      emailAddress: values.email,
      password: randomString,
      address1: values.billing_address_1,
      address2: "",
      suburb: values.billing_suburb_1,
      city: values.billing_city_1,
      state: "",
      postcode: values.billing_postcode_1,
      physicalAddress1: toggleDeliveryField
        ? values.billing_address_1
        : values.billing_address_2,
      physicalAddress2: "",
      physicalSuburb: toggleDeliveryField
        ? values.billing_suburb_1
        : values.billing_suburb_2,
      physicalCity: toggleDeliveryField
        ? values.billing_city_1
        : values.billing_city_2,
      physicalState: "",
      physicalPostcode: toggleDeliveryField
        ? values.billing_postcode_1
        : values.billing_postcode_2,
    };
    console.log(body);
    await fetchRegisterExecute({ body });
  };

  const handleCancel = async () => {
    props.history.push("/corporate");
  };

  const {
    values,
    onFirstSub,
    onReset,
    onChange,
    onCheck,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, INFOOBJECT);

  useEffect(() => {
    if (register.state.data) {
      if (
        !register.state.isStatus ||
        (register.state.data.registerdetail &&
          register.state.data.registerdetail.outcomeCode !== "0")
      ) {
        const { registerdetail } = register.state.data;
        if (
          registerdetail.outcomeCode
            .split("↵")
            .join("")
            .split("\n")
            .join("") === "WSF:CRT_CORP_MMB:5" &&
          registerdetail.outcomeDeveloperMessage ===
            "This email address is already registered"
        ) {
          setWarningMessage("The email already exist");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else if (
          registerdetail.outcomeCode
            .split("↵")
            .join("")
            .split("\n")
            .join("") === "WSF:CRT_CORP_MMB:3" &&
          registerdetail.outcomeDeveloperMessage ===
            "Invalid email address format"
        ) {
          setWarningMessage("Invalid email address format");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else if (
          registerdetail.outcomeCode.replace(/[\r\n]+/gm, "") ===
          "WSF:CRT_CORP_MMB:6WSF:INS_MMB:7TRD:INSTRD:10"
        ) {
          setWarningMessage("NZBN already registered");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else {
          resetRegisterExecute({});
          props.history.push("/corporate/order/fail");
        }
      } else {
        const authBody = {
          username: values.email.toLowerCase(),
          password: randomString,
        };
        fetchTokenExecute({ body: authBody });
      }
    }
  }, [register.state]);

  useEffect(() => {
    if (token.state.data) {
      if (!token.state.isStatus) props.history.push("/corporate/order/fail");
      const { tokenobject } = token.state.data;
      const newheaders = {
        ...MEMBERENQUIRYCONFIG.headers,
        // Authorization: `Bearer ${tokenobject.token}`
        Authorization: tokenobject.token,
      };
      fetchMemberEnquiryExecute({ newheaders });
    }
  }, [token.state]);

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  const preparePayment = async (orderdetailobj) => {
    let x = [];
    await asyncForEach(orderdetailobj.detail, async (item) => {
      if (item.value !== 0 && item.value !== "0") {
        x.push({
          name: item.productId,
          description: "subclassId: " + item.subClassId,
          amount: item.value,
          currency: "NZD",
          quantity: item.quantity,
        });
      }
    });
    const urlToRedirect = CONFIG.setup.setup.url;
    // https://giftcards.westfield.co.nz
    // http://thq-westfield-corporate-uat.s3-website-ap-southeast-2.amazonaws.com
    const body = {
      merchantRef: orderdetailobj.id,
      successUrl: urlToRedirect + "/corporate/order/success",
      failUrl:
        urlToRedirect +
        "/corporate/order/fail" +
        "?orderid=" +
        orderdetailobj.id,
      emailAddress: orderdetailobj.clientEmail,
      items: x,
    };

    const { login } = LocalStorage.getLS();
    const newheaders = {
      ...GENERATEPAYMENTCONFIG.headers,
      Authorization: login.token,
    };
    execGeneratePayment({ newheaders, body });
  };

  const goToPaymentPage = async (obj) => {
    const stripePromise = loadStripe(obj.publicKey);

    const stripe = await stripePromise;

    stripe.redirectToCheckout({
      sessionId: obj.sessionId,
    });
  };
  useEffect(() => {
    try {
      const { isStatus, data } = generatepayment.state;
      const { generatepaymentobject } = data;
      console.log(generatepaymentobject);
      if (!generatepaymentobject) throw { status: isStatus, err: false };
      const { outComeCode } = generatepaymentobject;
      if (outComeCode !== "0")
        throw { status: isStatus, err: generatepaymentobject };
      //handleClearLS();
      // window.location.replace(generatepaymentobject.uri);
      goToPaymentPage(generatepaymentobject);
    } catch (e) {
      execRedirects(e);
      if (!e.status) handleClearLS();
    }
  }, [generatepayment.state]);
  useEffect(() => {
    try {
      const { isStatus, data } = orderdetail.state;
      const { orderdetailobj } = data;

      if (!orderdetailobj) throw { status: isStatus, err: false };
      const { outcomeCode } = orderdetailobj;
      if (outcomeCode !== "0") throw { status: isStatus, err: orderdetailobj };

      preparePayment(orderdetailobj);
    } catch (e) {
      execRedirects(e);
      if (!e.status) handleClearLS();
    }
  }, [orderdetail.state]);
  /* -------------- */
  /* --- redirects: handle fail redirects */
  /* -------------- */
  const execRedirects = (e) => {
    if (!e.status && e.err) {
      if (e.err.status === 400) {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: true,
          returnUrl: "/corporate/order/items",
        });
      } else {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: false,
          returnUrl: "/corporate/order/items",
        });
      }
    } else {
      console.log("default", e);
    }
  };

  /* -------------- */
  /* --- effects: checkout */
  /* -------------- */
  useEffect(() => {
    try {
      const { isStatus, data } = checkout.state;
      const { checkoutobject } = data;
      if (!checkoutobject) throw { status: isStatus, err: false };
      const { outcomeCode } = checkoutobject;
      if (outcomeCode !== "0") throw { status: isStatus, err: checkoutobject };
      // start: if payment is not CREDIT CARD
      const paymentLS = LocalStorage.getLS().payment;
      if (paymentLS !== "CC") {
        handleClearLS();
        props.history.push("/corporate/order/success", {
          orderid: checkoutobject.orderId,
        });
      } else {
        // end: if payment is not CREDIT CARD
        const { login } = LocalStorage.getLS();

        const newheaders = {
          ...GETORDERCONFIG.headers,
          Authorization: login.token,
          appName: "corp",
        };
        execOrderDetail({
          newheaders,
          query: "/" + checkoutobject.orderId,
        });
      }
    } catch (e) {
      execRedirects(e);
    }
  }, [checkout.state]);

  const prepareOrder = async () => {
    const { tempGuestCheckoutBody, guest, login } = LocalStorage.getLS();
    const checkoutBody = tempGuestCheckoutBody;
    const clientContactId = guest.contactId;
    const newheaders = {
      ...CHECKOUTCONFIG.headers,
      Authorization: login.token,
    };
    const body = {
      ...checkoutBody,
      clientContactId,
    };
    fetchCheckoutExec({ newheaders, body });
  };
  useEffect(() => {
    if (memberenquiry.state.data) {
      if (!memberenquiry.state.isStatus)
        props.history.push("/corporate/order/fail");
      const { tokenobject } = token.state.data;
      const { memberenquiryobj } = memberenquiry.state.data;
      const { statusId } = memberenquiryobj.client;

      LocalStorage.addLS("login", { ...tokenobject });
      LocalStorage.addLS("guest", { ...memberenquiryobj.client });
      LocalStorage.addLS("user", false);
      props.history.push("/corporate/order/summary");
      // prepareOrder();
      // props.history.push("/corporate/order/guest/items");
    }
  }, [memberenquiry.state]);

  useEffect(() => {
    if (!nzbnSelected) setNzbnChanged(true);
    if (nzbnChanged) {
      const newheaders = {
        ...SEARCHCONFIG.headers,
        Authorization: "Bearer " + nzbnkey.state.data.nzbnkeyobj.accessToken,
      };
      fetchNzbnSearchExecute({
        query: "?search-term=" + values.nzbn,
        newheaders,
      });
    }
  }, [values.nzbn]);

  useEffect(() => {
    if (resetNzbnSearch) {
      setResetAddySearch(false);
      nzbnsearch.dispatch({
        type: nzbnsearch.actionTypes.RESET,
      });
    }
  }, [resetNzbnSearch]);

  useEffect(() => {
    if (!addyChanged) setAddyChanged(true);
    const apikeyforaddy = "c9a6e01e81624d8283fa9e4a186b6dfa";
    if (
      addyChanged &&
      values.billing_address_1.replace(" ", "").toString().length > 1
    ) {
      fetchAddySearchExecute({
        query: "?key=" + apikeyforaddy + "&s=" + values.billing_address_1,
      });
    }
  }, [hideBilling, values.billing_address_1]);

  /////////////////////////

  useEffect(() => {
    if (!addyChangedForDelivery) setAddyChangedForDelivery(true);
    const apikeyforaddy = "c9a6e01e81624d8283fa9e4a186b6dfa";
    if (
      addyChangedForDelivery &&
      values.billing_address_2.replace(" ", "").toString().length > 1
    ) {
      fetchAddySearchExecute({
        query: "?key=" + apikeyforaddy + "&s=" + values.billing_address_2,
      });
    }
  }, [hideDelivery, values.billing_address_2]);

  useEffect(() => {
    if (resetAddySearch) {
      setResetAddySearch(false);
      addysearchwithid.dispatch({
        type: addysearchwithid.actionTypes.RESET,
      });
      addysearch.dispatch({
        type: addysearch.actionTypes.RESET,
      });
    }
  }, [resetAddySearch]);

  useEffect(() => {
    if (addysearchwithid.state.data) {
      if (addyChanged) {
        const { addysearchwithidobj } = addysearchwithid.state.data;
        values.billing_address_1 = addysearchwithidobj.address1;
        values.billing_suburb_1 = addysearchwithidobj.suburb;
        values.billing_city_1 = addysearchwithidobj.city;
        values.billing_postcode_1 = addysearchwithidobj.postcode;
        setAddressAssigned(true);
        setAddressAssignedForDelivery(false);
        setResetAddySearch(true);
      }
      if (addyChangedForDelivery) {
        const { addysearchwithidobj } = addysearchwithid.state.data;
        values.billing_address_2 = addysearchwithidobj.address1;
        values.billing_suburb_2 = addysearchwithidobj.suburb;
        values.billing_city_2 = addysearchwithidobj.city;
        values.billing_postcode_2 = addysearchwithidobj.postcode;
        setAddressAssigned(false);
        setAddressAssignedForDelivery(true);
        setResetAddySearch(true);
      }
    }
  }, [addysearchwithid]);

  useEffect(() => {
    if (addressAssigned) {
      setAddressAssigned(false);
      setAddyChanged(false);
      setAddySelected(true);
    }
  }, [addressAssigned]);

  useEffect(() => {
    if (addressAssignedForDelivery) {
      setAddressAssignedForDelivery(false);
      setAddyChangedForDelivery(false);
      setAddySelectedForDelivery(true);
    }
  }, [addressAssignedForDelivery]);

  useEffect(() => {
    setToggleDeliveryField(values.toggledelivery);
    if (toggleDeliveryField) {
      values.billing_address_2 = values.billing_address_1;
      values.billing_suburb_2 = values.billing_suburb_1;
      values.billing_city_2 = values.billing_city_1;
      values.billing_postcode_2 = values.billing_postcode_1;
    } else {
      values.billing_address_2 = "";
      values.billing_suburb_2 = "";
      values.billing_city_2 = "";
      values.billing_postcode_2 = "";
    }
  }, [values.toggledelivery]);

  useEffect(() => {
    setToggleDeliveryField(values.toggledelivery);
    if (!toggleDeliveryField) {
      values.billing_address_2 = values.billing_address_1;
      values.billing_suburb_2 = values.billing_suburb_1;
      values.billing_city_2 = values.billing_city_1;
      values.billing_postcode_2 = values.billing_postcode_1;
    } else {
      values.billing_address_2 = "";
      values.billing_suburb_2 = "";
      values.billing_city_2 = "";
      values.billing_postcode_2 = "";
    }
  }, [values.toggledelivery]);

  useEffect(() => {
    if (toggleDeliveryField) {
      values.billing_address_2 = values.billing_address_1;
      values.billing_suburb_2 = values.billing_suburb_1;
      values.billing_city_2 = values.billing_city_1;
      values.billing_postcode_2 = values.billing_postcode_1;
    }
  }, [
    values.billing_address_1,
    values.billing_suburb_1,
    values.billing_city_1,
    values.billing_postcode_1,
  ]);

  const { nzbndetail } = nzbnsearch.state.data;
  const { addysearchobj } = addysearch.state.data;

  const checkResult = () => {
    return !nzbndetail ||
      nzbndetail.items === undefined ||
      nzbndetail.length < 1
      ? false
      : true;
  };
  const checkAddressSearchResult = () => {
    return !addysearchobj || addysearchobj.length < 1 ? false : true;
  };

  /* data props */
  const companyname = {
    ...INFOOBJECT.companyname,
    inputRef: useRef(null),
    error: error.companyname,
    value: values.companyname,
    onChange,
  };
  const nzbn = {
    ...INFOOBJECT.nzbn,
    inputRef: useRef(null),
    autoComplete: "off",
    error: error.nzbn,
    value: values.nzbn,
    onChange,
  };
  const email = {
    ...INFOOBJECT.email,
    inputRef: useRef(null),
    error: error.email,
    value: values.email,
    onChange,
  };
  const mobile = {
    ...INFOOBJECT.mobile,
    inputRef: useRef(null),
    error: error.mobile,
    value: values.mobile,
    onChange,
  };
  const billing_address_1 = {
    ...INFOOBJECT.billing_address_1,
    inputRef: useRef(null),
    autoComplete: "off",
    error: error.billing_address_1,
    value: values.billing_address_1,
    onChange,
  };
  const billing_suburb_1 = {
    ...INFOOBJECT.billing_suburb_1,
    inputRef: useRef(null),
    error: error.billing_suburb_1,
    value: values.billing_suburb_1,
    onChange,
  };
  const billing_city_1 = {
    ...INFOOBJECT.billing_city_1,
    inputRef: useRef(null),
    error: error.billing_city_1,
    value: values.billing_city_1,
    onChange,
  };
  const billing_postcode_1 = {
    ...INFOOBJECT.billing_postcode_1,
    inputRef: useRef(null),
    error: error.billing_postcode_1,
    value: values.billing_postcode_1,
    onChange,
  };

  const toggledelivery = {
    ...INFOOBJECT.toggledelivery,
    inputRef: useRef(null),
    error: error.toggledelivery,
    value: values.toggledelivery,
    onChange: onCheck,
  };

  const billing_address_2 = {
    ...INFOOBJECT.billing_address_2,
    inputRef: useRef(null),
    autoComplete: "off",
    error: error.billing_address_2,
    value: values.billing_address_2,
    onChange,
  };
  const billing_suburb_2 = {
    ...INFOOBJECT.billing_suburb_2,
    inputRef: useRef(null),
    error: error.billing_suburb_2,
    value: values.billing_suburb_2,
    onChange,
  };
  const billing_city_2 = {
    ...INFOOBJECT.billing_city_2,
    inputRef: useRef(null),
    error: error.billing_city_2,
    value: values.billing_city_2,
    onChange,
  };
  const billing_postcode_2 = {
    ...INFOOBJECT.billing_postcode_2,
    inputRef: useRef(null),
    error: error.billing_postcode_2,
    value: values.billing_postcode_2,
    onChange,
  };
  const formObjOld = {
    companyname,
    nzbn,
    email,
    mobile,
    billing_address_1,
    billing_suburb_1,
    billing_city_1,
    billing_postcode_1,
  };
  const formObj = toggledelivery.value
    ? { ...formObjOld }
    : {
        ...formObjOld,
        billing_address_2,
        billing_suburb_2,
        billing_city_2,
        billing_postcode_2,
      };
  const actionButton = {
    size: "large",
    onClick: () => {
      onFirstSub();
      const fieldNameList = Object.keys(error);
      const firstErrorField =
        fieldNameList.length > 0 ? fieldNameList[0] : null;
      if (firstErrorField !== null) {
        formObj[firstErrorField].inputRef.current.focus();
      }
      // textInput.current.focus();
    },
  };
  const cancelButton = { size: "large", onClick: handleCancel };
  const warningMsg = { error: [EMAILALREADYEXISTMSG] };
  const warningMsgTraderExist = { error: [TRADERALREADYEXIST] };
  /* data props */

  const nzbnSearchClick = (e, entity) => {
    // values.nzbn = entity.nzbn;
    e.target.name = "nzbn";
    e.target.value = entity.nzbn;
    nzbn.onChange(e);
    setResetNzbnSearch(true);
    setNzbnChanged(false);
    setNzbnSelected(true);
    setHideNzbn(true);
  };
  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Company Information</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text {...companyname} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...nzbn}
            onFocus={() => {
              setHideNzbn(false);
              setNzbnChanged(true);
              setNzbnSelected(false);
              setResetNzbnSearch(true);
            }}
            onBlur={() => {
              setNzbnSelected(true);
            }}
          />
          {values.nzbn.length > 0 &&
          !hideNzbn &&
          (nzbnChanged || !nzbnSelected) ? (
            <Paper className={classes.paper} square>
              {!checkResult() || nzbnsearch.state.isLoading ? (
                <CircularProgress
                  className={classes.throbber}
                  size={30}
                  thickness={5}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  top: -30,
                  right: 2,
                  backgroundColor: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
                onClick={() => {
                  setHideNzbn(true);
                  setResetNzbnSearch(true);
                }}
              >
                <CloseIcon
                  style={{
                    color: "black",
                    fontSize: 25,
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </div>
              {checkResult() &&
                nzbnsearch.state.isStatus &&
                !nzbnsearch.state.isLoading &&
                nzbnsearch.state.data.nzbndetail &&
                nzbnsearch.state.data.nzbndetail.items !== undefined &&
                nzbnsearch.state.data.nzbndetail.items.map((entity, index) => {
                  return (
                    <MenuItem
                      key={entity.nzbn}
                      component="div"
                      style={{
                        fontWeight: 400,
                      }}
                      onClick={(e) => nzbnSearchClick(e, entity)}
                    >
                      {entity.entityName}({entity.nzbn})
                    </MenuItem>
                  );
                })}
              {checkResult() &&
              !nzbnsearch.state.isLoading &&
              nzbnsearch.state.data.nzbndetail &&
              nzbnsearch.state.data.nzbndetail.items.length === 0 ? (
                <MenuItem
                  component="div"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  No matching NZBN found
                </MenuItem>
              ) : null}
            </Paper>
          ) : null}
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text {...email} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...mobile} />
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Billing Address</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...billing_address_1}
            onFocus={() => {
              setAddyChanged(false);
              setHideBilling(true);
              setAddySelected(false);
              setResetAddySearch(true);
            }}
            onBlur={() => {
              setAddySelected(true);
            }}
          />
          {values.billing_address_1.length > 0 &&
          hideBilling &&
          (addyChanged || !addySelected) ? (
            <Paper className={classes.paper2} square>
              {!checkAddressSearchResult() || addysearch.state.isLoading ? (
                <CircularProgress
                  className={classes.throbber}
                  size={30}
                  thickness={5}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  top: -30,
                  right: 2,
                  backgroundColor: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
                onClick={() => {
                  if (addySelected) {
                    setHideBilling(false);
                    setAddySelected(false);
                    setResetAddySearch(true);
                  }
                }}
              >
                <CloseIcon
                  style={{
                    color: "black",
                    fontSize: 25,
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </div>
              {checkAddressSearchResult() &&
                !addysearch.state.isLoading &&
                addysearch.state.data.addysearchobj &&
                addysearch.state.data.addysearchobj.addresses.map(
                  (entity, index) => {
                    return (
                      <MenuItem
                        key={entity.id}
                        component="div"
                        style={{
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          if (addySelected) {
                            setHideBilling(false);
                            setAddySelected(false);
                            setAddyChanged(true);
                            setAddyChangedForDelivery(false);
                            handleAddyClick(entity);
                          }
                        }}
                      >
                        {entity.a}
                      </MenuItem>
                    );
                  }
                )}
              {checkAddressSearchResult() &&
              !addysearch.state.isLoading &&
              addysearch.state.data.addysearchobj &&
              addysearch.state.data.addysearchobj.addresses.length === 0 ? (
                <MenuItem
                  component="div"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  No matching Address found
                </MenuItem>
              ) : null}
            </Paper>
          ) : null}
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...billing_suburb_1} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...billing_city_1} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...billing_postcode_1} />
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Delivery Address</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>
      {toggleDeliveryField ? null : (
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Inputs.Text
              {...billing_address_2}
              onFocus={() => {
                setAddyChangedForDelivery(false);
                setHideDelivery(true);
                setAddySelectedForDelivery(false);
                setResetAddySearch(true);
              }}
              onBlur={() => {
                setAddySelectedForDelivery(true);
              }}
            />
            {values.billing_address_2.length > 0 &&
            hideDelivery &&
            (addyChangedForDelivery || !addySelectedForDelivery) ? (
              <Paper className={classes.paper2} square>
                {!checkAddressSearchResult() || addysearch.state.isLoading ? (
                  <CircularProgress
                    className={classes.throbber}
                    size={30}
                    thickness={5}
                  />
                ) : null}

                {checkAddressSearchResult() &&
                  !addysearch.state.isLoading &&
                  addysearch.state.data.addysearchobj &&
                  addysearch.state.data.addysearchobj.addresses.map(
                    (entity, index) => {
                      return (
                        <MenuItem
                          key={entity.id}
                          component="div"
                          style={{
                            fontWeight: 400,
                          }}
                          onClick={() => {
                            if (addySelectedForDelivery) {
                              setHideDelivery(false);
                              setAddySelectedForDelivery(false);
                              setAddyChanged(false);
                              setAddyChangedForDelivery(true);
                              handleAddyClick(entity);
                            }
                          }}
                        >
                          {entity.a}
                        </MenuItem>
                      );
                    }
                  )}
                <div
                  style={{
                    position: "absolute",
                    top: -30,
                    right: 2,
                    backgroundColor: "white",
                    borderRadius: 10,
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    margin: "auto",
                    alignContent: "center",
                  }}
                  onClick={() => {
                    if (addySelectedForDelivery) {
                      setHideDelivery(false);
                      setAddySelectedForDelivery(false);
                      setResetAddySearch(true);
                    }
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "black",
                      fontSize: 25,
                      margin: "auto",
                      textAlign: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  />
                </div>
                {checkAddressSearchResult() &&
                !addysearch.state.isLoading &&
                addysearch.state.data.addysearchobj &&
                addysearch.state.data.addysearchobj.addresses.length === 0 ? (
                  <MenuItem
                    component="div"
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    No matching Address found
                  </MenuItem>
                ) : null}
              </Paper>
            ) : null}
          </ModuleFormItemWrapper>
          <ModuleFormItemWrapper>
            <Inputs.Text {...billing_suburb_2} />
          </ModuleFormItemWrapper>
          <ModuleFormItemWrapper>
            <Inputs.Text {...billing_city_2} />
          </ModuleFormItemWrapper>
          <ModuleFormItemWrapper>
            <Inputs.Text {...billing_postcode_2} />
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      )}

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Checkbox {...toggledelivery} />
          <br />
          <br />
        </ModuleFormItemLongWrapper>
        {/* line */}
      </ModuleFormWrapper>

      {/* <div style={{ alignSelf: "left", paddingLeft: 10 }}>
        {warningMessageVisible && warningMessage === "The email already exist" && (
          <Fragment>
            <Invalid {...warningMsg} />
            <br />
          </Fragment>
        )}

        {warningMessageVisible && warningMessage === "NZBN already registered" && (
          <Fragment>
            <Invalid {...warningMsgTraderExist} />
            <br />
          </Fragment>
        )}
      </div> */}

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Buttons.Normal {...actionButton}>Next</Buttons.Normal>
          <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      {/* modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={warningMessageVisible}
        onClose={() => {
          setWarningMessageVisible(!warningMessageVisible);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 700,
        }}
      >
        <Fade in={warningMessageVisible}>
          <div className={classes.paper3}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <div>
                  <img
                    src={logo}
                    style={{
                      width: '40%',
                      paddingBottom: 20,
                      paddingRight: 10,
                    }}
                    alt="logo"
                  />
                </div> */}
              <div
                style={{
                  position: "fixed",
                  // width: '55%',
                  width: "55%",
                  minWidth: 300,
                  maxWidth: 500,
                  textAlign: "right",
                  paddingRight: 40,
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  color="white"
                  onClick={() => {
                    setWarningMessageVisible(!warningMessageVisible);
                  }}
                />
              </div>
            </div>
            <p
              style={{
                color: "#696969",
                fontSize: 20,
                textAlign: "center",
                fontWeight: 800,
                marginTop: 20,
                marginBottom: 30,
                height: "20%",
              }}
              className="subway-footlong"
            >
              Sorry
            </p>
            <p
              style={{
                color: "#696969",
                fontSize: 17,
                lineHeight: 1.4,
                marginTop: 10,
                height: "30%",
              }}
              className="subway-six-inch"
            >
              {warningMessage}
            </p>
            <div
              style={{
                width: 150,
                // margin: 'auto',
                marginBottom: 50,
              }}
            ></div>

            <div
              style={{
                width: "100%",
                // margin: 'auto',
                marginBottom: 30,
                textAlign: "right",
                height: "20%",
              }}
            >
              <Buttons.Normal
                onClick={() => {
                  setWarningMessageVisible(!warningMessageVisible);
                  setWarningMessage("");
                  props.history.push("/corporate/login");
                }}
              >
                Go to Login
              </Buttons.Normal>
            </div>
          </div>
        </Fade>
      </Modal>
    </form>
  );
};

export default register;
