import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { WindowSize } from "../lib-atomic-functions";
import ListItem from "@material-ui/core/ListItem";

const useStyles = ({ windowWidth }) => {
  const styleFormatted = {
    labelContainer: {
      backgroundColor: "transparent",
      color: "black",
      height: 35,
      paddingLeft: 7,
      paddingRight: 7,
      paddingTop: 5,
      paddingBottom: 5,
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: "solid",
      borderColor: "transparent",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        transitionProperty: "width",
        transition: "border-color 0.5s ease",
      },
    },
    label: {
      backgroundColor: "transparent",
      fontWeight: 600,
      color: "black",
      borderColor: "transparent",
      justifyContent: "center",
      textAlign: "center",
      lineHeight: 1.1,
      fontSize:
        windowWidth < 435
          ? 10
          : windowWidth < 500
          ? 10
          : windowWidth < 600
          ? 14
          : 17,
      "&:hover": {
        color: "black",
        textDecoration: "none",
      },
    },
  };
  return makeStyles((theme) => styleFormatted)();
};
const App = (props) => {
  const { width, height } = WindowSize();

  const classes = useStyles({ windowWidth: width });
  const url = props.match ? props.match.url : "";
  const { label, prefix, link } = props;
  return (
    <div>
      <ListItem
        className={classes.labelContainer}
        onClick={() => {
          if (prefix === "") {
            window.open(link, "_blank");
          } else {
            props.props.history.push(prefix);
          }
        }}
      >
        <div className={classes.label}>{label.toUpperCase()}</div>
      </ListItem>
    </div>
  );
};

export default App;
