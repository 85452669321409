import React, { Fragment, useEffect, useContext, useState } from "react";

import {
  PDFBlobProviderInvoice,
  PDFPreviewProviderInvoice,
  PDFLinkInvoice,
  PDFBlobProviderQuote,
  PDFPreviewProviderQuote,
  PDFLinkQuote,
} from "../../app-module-order-section-invoice/default";
const index = (props) => {
  const { previewDataReady } = props;
  console.log(previewDataReady);
  return (
    <div style={{ padding: 10 }}>
      {previewDataReady && previewDataReady.pdfPreviewData ? (
        previewDataReady.pdfPreviewData.orderInfoData.paymentType !== 22 ? (
          <PDFPreviewProviderInvoice {...previewDataReady} />
        ) : (
          <PDFPreviewProviderQuote {...previewDataReady} />
        )
      ) : null}
    </div>
  );
};

export default index;
