const environment = process.env.NODE_ENV;
const project = "Reporting";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: "FAQ",
    prefix: "https://support.westfield.co.nz/hc/en-us/categories/32870172304281-Westfield-Gift-Cards",
  },
};

export default config;
