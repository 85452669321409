import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import { loadStripe } from "@stripe/stripe-js";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2 } from "../../lib-atomic-functions";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import postcodelist from "../../app-module-order-section-fees/default/postcodelist";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.onlineorder.api.endpoints;
const CHECKOUTCONFIG = {
  url: ENDPOINTS.checkout.url,
  method: ENDPOINTS.checkout.method,
  headers: ENDPOINTS.checkout.headers,
  responseType: "json",
};
const GETORDERCONFIG = {
  url: ENDPOINTS.getorder.url,
  method: ENDPOINTS.getorder.method,
  headers: ENDPOINTS.getorder.headers,
  responseType: "json",
};
const GENERATEPAYMENTCONFIG = {
  url: ENDPOINTS.generatepayment.url,
  method: ENDPOINTS.generatepayment.method,
  headers: ENDPOINTS.generatepayment.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const [acceptTNC, acceptTNCSet] = useState(false);
  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(false); // State to manage button disabled status
  const [isLoading, setIsLoading] = useState(false);

  const tokenLS = LocalStorage.getLS().login.token;
  const allproductsLS = LocalStorage.getLS().productall;
  console.log(allproductsLS);
  const productsLS = LocalStorage.getLS().products;
  const feesLS = LocalStorage.getLS().fees;
  const notesLS = LocalStorage.getLS().notes;
  const paymentLS = LocalStorage.getLS().payment;
  const paymentReasonLS = LocalStorage.getLS().paymentreason;
  const isGuestUser = LocalStorage.getLS().guest;
  const isNormUser = LocalStorage.getLS().user;
  const checkoutReady =
    productsLS && productsLS.length > 0 && paymentLS ? true : false;
  CHECKOUTCONFIG.headers.Authorization = tokenLS;
  GETORDERCONFIG.headers.Authorization = tokenLS;
  GENERATEPAYMENTCONFIG.headers.Authorization = tokenLS;

  /* state */
  const { checkout, orderdetail, generatepayment, productall } =
    useContext(GlobalContext);
  /* -------------- */
  /* --- generate payment */
  /* -------------- */
  const [fetchGeneratePayment] = FetchContextApiV2(GENERATEPAYMENTCONFIG);
  const execGeneratePayment = async ({ headers, body }) => {
    generatepayment.dispatch({
      type: generatepayment.actionTypes.FETCH_GENERATE_PAYMENT,
      generatepayment: await fetchGeneratePayment(
        { newheaders: headers, body },
        generatepayment
      ),
    });
  };
  /* -------------- */
  /* --- orders */
  /* -------------- */
  const [fetchOrderDetail] = FetchContextApiV2(GETORDERCONFIG);
  const execOrderDetail = async ({ headers, query }) => {
    orderdetail.dispatch({
      type: orderdetail.actionTypes.FETCH_ORDER_DETAIL,
      orderdetail: await fetchOrderDetail(
        { newheaders: headers, query },
        orderdetail
      ),
    });
  };
  const [fetchOrderList] = FetchContextApiV2(GETORDERCONFIG);
  const sanitizeData = ({ status, data, name }) => {
    if (!status && !data.json) return { [name]: false };
    return { [name]: data.json };
  };
  const execOrderList = async ({ headers, query }) => {
    // NOT USING REDUCER
    const results = await fetchOrderList(
      { newheaders: headers, query },
      {
        status: false,
        isLoading: false,
        isStatus: true,
        expireToken: false,
        data: false,
      }
    );
    return sanitizeData({
      status: results.status,
      data: results.data,
      name: "orderListObj",
    });
  };
  /* -------------- */
  /* --- checkout */
  /* -------------- */
  const [fetchCheckout] = FetchContextApiV2(CHECKOUTCONFIG);
  const execOrderCheckout = async () => {
    if (props.location.pathname === "/corporate/order/summary") {
      const infoLS = LocalStorage.getLS().user
        ? LocalStorage.getLS().user
        : LocalStorage.getLS().guest;
      const isRural = postcodelist.some((i) => i === infoLS.physicalPostcode);
      const products = productsLS.map((product) => {
        return {
          value: product.value,
          quantity: product.quantity,
          startCardNumber: null,
          productId: product.product,
        };
      });
      const feesCalculated = LocalStorage.getLS().feesDetails;
      console.log(feesCalculated);
      // standard: 725010, express: 725011
      const idFoundForCourier = allproductsLS.filter((prod) =>
        isRural
          ? prod.productName === "Courier Rural"
          : prod.productName === "Courier Standard"
      );
      if (idFoundForCourier.length === 1) {
        console.log("test", idFoundForCourier)
        products.push({
          value: parseFloat(feesCalculated.courier),
          quantity: 1,
          startCardNumber: null,
          productId: idFoundForCourier[0].productId,
        });
      }

      const idForPackagingAndHandling = allproductsLS.filter(
        (prod) => prod.productName === "Packaging & Handling"
      );
      if (idForPackagingAndHandling.length === 1) {
        products.push({
          value: parseFloat(feesCalculated.pAndH),
          quantity: 1,
          startCardNumber: null,
          productId: idForPackagingAndHandling[0].productId,
        });
      }
      const clientContactId = isNormUser
        ? isNormUser.contactId
        : isGuestUser.contactId;
      // const clientContactId = isNormUser ? isNormUser.contactId : null;
      const promocode = LocalStorage.getLS().promocode
      const deliveryAddress = LocalStorage.getLS().deliveryAddress;
      const isDeliveryAddress = deliveryAddress.physicalAddress1 || false;
      const deliveryDetails = isDeliveryAddress
        ? {
            deliveryFirstName: deliveryAddress.firstname || "",
            deliveryLastName: "",
            deliveryAddress1: deliveryAddress.physicalAddress1,
            deliveryAddress2: "",
            deliverySuburb: deliveryAddress.physicalSuburb,
            deliveryCity: deliveryAddress.physicalCity,
            deliveryState: deliveryAddress.physicalState || "",
            deliveryPostcode: deliveryAddress.physicalPostcode,
            deliveryCompanyName: deliveryAddress.companyName || "",
          }
        : {};
      const body = {
        notes: notesLS,
        purchaseOrder: null,
        clientContactId,
        paymentType: paymentLS === "DC" ? 22 : 23,
        products,
        paymentReason: paymentReasonLS,
        promoCode: promocode.promoCode,
        ...deliveryDetails,
      };
      checkout.dispatch({
        type: checkout.actionTypes.FETCH_CHECKOUT,
        checkout: await fetchCheckout({ body }, checkout),
      });
      setIsOrderButtonDisabled(true); // Disable button after click
      setIsLoading(true); // Set loading state
    } else {
      const { login } = LocalStorage.getLS();
      if (login.username && login.username === "guest") {
        // LocalStorage.addLS("tempGuestCheckoutBody", body);
        props.history.push("/corporate/order/guest/infos");
      } else {
        props.history.push("/corporate/order/summary");
      }
    }
  };
  /* -------------- */
  /* --- effects: checkout */
  /* -------------- */
  useEffect(() => {
    try {
      const { isStatus, data } = checkout.state;
      const { checkoutobject } = data;
      if (!checkoutobject) throw { status: isStatus, err: false };
      const { outcomeCode } = checkoutobject;
      if (outcomeCode !== "0") throw { status: isStatus, err: checkoutobject };
      // start: if payment is not CREDIT CARD
      if (paymentLS !== "CC") {
        handleClearLS();
        props.history.push("/corporate/order/success", {
          orderid: checkoutobject.orderId,
        });
      } else {
        // end: if payment is not CREDIT CARD
        const headers = {
          ...GETORDERCONFIG.headers,
          appName: "corp",
        };
        execOrderDetail({
          headers,
          query: "/" + checkoutobject.orderId,
        });
      }
    } catch (e) {
      execRedirects(e);
    }
  }, [checkout.state]);

  /* -------------- */
  /* --- effects: order details */
  /* -------------- */
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  const preparePayment = async (orderdetailobj) => {
    let itemsList = [];
    console.log(allproductsLS);
    const transListHeaders = {
      ...GETORDERCONFIG.headers,
      appName: "corp",
    };
    const ordersMadeByUser = await execOrderList({
      headers: transListHeaders,
      query: "/?clientName=" + orderdetailobj.clientName,
    });
    const { orderListObj } = ordersMadeByUser;
    console.log("ordersMadeByUser ", ordersMadeByUser);
    await asyncForEach(orderdetailobj.detail, async (item) => {
      if (item.value !== 0 && item.value !== "0") {
        console.log(item);
        const productName = allproductsLS.filter((i) => {
          return i.productId === item.productId;
        });
        if (productName && productName.length > 0) {
          itemsList.push({
            name: item.productId + " - " + productName[0].productName,
            description: "subclassId: " + item.subClassId,
            amount: item.value,
            currency: "NZD",
            quantity: item.quantity,
          });
        }
      }
    });
    const urlToRedirect = CONFIG.setup.setup.url;
    // const urlToRedirect = "http://localhost:3000"
    // https://giftcards.westfield.co.nz
    // http://thq-westfield-corporate-uat.s3-website-ap-southeast-2.amazonaws.com
    const body = {
      merchantRef: orderdetailobj.id,
      successUrl: urlToRedirect + "/corporate/order/success",
      failUrl:
        urlToRedirect +
        "/corporate/order/fail" +
        "?orderid=" +
        orderdetailobj.id,
      emailAddress: orderdetailobj.clientEmail,
      items: itemsList,
      hasPGC: true,
      channelId: 2,
      numberOfPastOrders: orderListObj.orders.length,
    };

    const headers = {
      ...GENERATEPAYMENTCONFIG.headers,
      Authorization: tokenLS,
    };
    console.log(body);
    execGeneratePayment({ headers, body });
  };

  useEffect(() => {
    try {
      const { isStatus, data } = orderdetail.state;
      const { orderdetailobj } = data;
      if (!orderdetailobj) throw { status: isStatus, err: false };
      const { outcomeCode } = orderdetailobj;
      if (outcomeCode !== "0") throw { status: isStatus, err: orderdetailobj };
      // {
      //   "merchantRef":"order-1-12",
      //   "successUrl":"https://example.com/success",
      //   "failUrl":"https://example.com/cancel",
      //   "emailAddress":"james.bond@m16.gov.uk",
      //   "items":[
      //     {
      //       "name":"Test Card",
      //       "description":"Gift card test",
      //       "amount":5.00,
      //       "currency":"NZD",
      //       "quantity":50
      //     }
      //   ]
      // }
      preparePayment(orderdetailobj);
    } catch (e) {
      execRedirects(e);
      if (!e.status) handleClearLS();
    }
  }, [orderdetail.state]);

  /* -------------- */
  /* --- effects: generate payment */
  /* -------------- */
  // STRIPE PAYMENT REDIRECT
  const goToPaymentPage = async (obj) => {
    const stripePromise = loadStripe(obj.publicKey);

    const stripe = await stripePromise;

    stripe.redirectToCheckout({
      sessionId: obj.sessionId,
    });
  };
   // STRIPE PAYMENT REDIRECT

  useEffect(() => {
    try {
      const { isStatus, data } = generatepayment.state;
      const { generatepaymentobject } = data;
      if (!generatepaymentobject) throw { status: isStatus, err: false };
      const { outComeCode } = generatepaymentobject;
      if (outComeCode !== "0")
        throw { status: isStatus, err: generatepaymentobject };
      //handleClearLS();
      // window.location.replace(generatepaymentobject.uri);
      goToPaymentPage(generatepaymentobject);
    } catch (e) {
      execRedirects(e);
      if (!e.status) handleClearLS();
    }
  }, [generatepayment.state]);
  /* -------------- */
  /* --- redirects: handle fail redirects */
  /* -------------- */
  const execRedirects = (e) => {
    if (!e.status && e.err) {
      if (e.err.status === 400) {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: true,
          returnUrl: "/corporate/order/items",
        });
      } else {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: false,
          returnUrl: "/corporate/order/items",
        });
      }
    } else {
      console.log("default", e);
    }
  };

  /* -------------- */
  /* --- checkout */
  /* state */

  const handleClearLS = () => {
    LocalStorage.addLS("products", false);
    LocalStorage.addLS("payment", false);
    LocalStorage.addLS("fees", false);
    LocalStorage.addLS("feesDetails", false);
    LocalStorage.addLS("notes", false);
    LocalStorage.addLS("paymentreason", false);
    LocalStorage.addLS("promocode", false);
  };

  /* data props */
  const actionButton = {
    size: "large",
    onClick: () => {
      execOrderCheckout();
    },
    disabled: isOrderButtonDisabled, // Disable button based on state
  };
  const cancelButton = {
    size: "large",
    onClick: () => {
      handleClearLS();
      if (isNormUser) {
        props.history.push("/corporate");
      } else {
        props.history.push("/corporate/order");
      }
    },
  };
  /* data props */

  const handleTickTNC = useCallback(() => {
    acceptTNCSet(!acceptTNC);
  }, [acceptTNC, acceptTNCSet]);

  const tnc = {
    name: "tnc",
    helperText: false,
    rules: false,
    value: acceptTNC,
    onChange: handleTickTNC,
  };

  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          {props.location.pathname === "/corporate/order/summary" ? (
            <>
              <ModuleFormItemLongWrapper>
                <Inputs.Checkbox {...tnc} />
                By Placing this Order, I agree to the Westfield Gift Card{" "}
                <a
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#3898ff",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.westfield.co.nz/terms-and-conditions#westfield-gift-card-terms-and-conditions",
                      "_blank"
                    );
                  }}
                >
                  Terms and Conditions
                </a>
                {" "}and represent and warrant that each of the Westfield Gift Cards
                purchased through our Corporate Portal is intended for a
                different final recipient.
              </ModuleFormItemLongWrapper>

              <div className="ml-3 mr-3 mt-3 mb-4">
                The personal information you provide as part of this process
                will be used to fulfil and deliver your Gift Card Order. General
                information about how Scentre Group handles personal information
                can be viewed in its privacy policy here -{" "}
                <a
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#3898ff",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.westfield.co.nz/privacy-policy",
                      "_blank"
                    );
                  }}
                >
                  {" "}
                  https://www.westfield.co.nz/privacy-policy
                </a>
              </div>

              {acceptTNC ? (
                <ModuleFormItemLongWrapper>
                  {checkoutReady && (
                    <Buttons.Normal {...actionButton}>
                      {isOrderButtonDisabled ? "PLEASE WAIT..." : "PLACE ORDER"}
                    </Buttons.Normal>
                  )}
                  <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
                </ModuleFormItemLongWrapper>
              ) : null}
            </>
          ) : null}

          {props.location.pathname === "/corporate/order/items" ? (
            <ModuleFormItemLongWrapper>
              {checkoutReady && (
                <Buttons.Normal {...actionButton}>
                  PROCEED TO CHECKOUT
                </Buttons.Normal>
              )}
              <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
            </ModuleFormItemLongWrapper>
          ) : null}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
