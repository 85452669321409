import React, { useContext, useState, Fragment, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../lib-atomic-context";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSmallTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.config.objects;
const ENDPOINTS = CONFIG.application.config.api.endpoints;
const UPDATECONFIG = {
  url: ENDPOINTS.updatedetail.url,
  method: ENDPOINTS.updatedetail.method,
  headers: ENDPOINTS.updatedetail.headers,
  responseType: "json",
};
const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json",
};
const TOKENCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: "json",
};
const SEARCHCONFIG = {
  url: ENDPOINTS.search.url,
  method: ENDPOINTS.search.method,
  headers: ENDPOINTS.search.headers,
  responseType: "json",
};
const SEARCHADDYCONFIG = {
  url: ENDPOINTS.searchaddy.url,
  method: ENDPOINTS.searchaddy.method,
  headers: ENDPOINTS.searchaddy.headers,
  responseType: "json",
};
const SEARCHADDYWITHIDCONFIG = {
  url: ENDPOINTS.searchaddywithid.url,
  method: ENDPOINTS.searchaddywithid.method,
  headers: ENDPOINTS.searchaddywithid.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.config.responses.unverified;
/* CONSTANTS */

const updateProfile = (props) => {
  const login = LocalStorage.getLS().login;

  const [updatePressed, setUpdatePressed] = useState(false);
  const [updatePressed2, setUpdatePressed2] = useState(false);

  const [hideNzbn, setHideNzbn] = useState(false);
  const [nzbnSelected, setNzbnSelected] = useState(false);
  const [nzbnChanged, setNzbnChanged] = useState(false);

  const [resetNzbnSearch, setResetNzbnSearch] = useState(false);

  const [hideBilling, setHideBilling] = useState(false);
  const [addySelected, setAddySelected] = useState(false);
  const [addyChanged, setAddyChanged] = useState(false);
  const [addressAssigned, setAddressAssigned] = useState(false);

  const [resetAddySearch, setResetAddySearch] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);

  const classes = useStyles();

  const findMatchFromConfig = (key) => {
    if (key === "companyname") {
      return "name";
    } else if (key === "nzbn") {
      return "externalId";
    } else if (key === "firstname") {
      return "firstName";
    } else if (key === "lastname") {
      return "lastName";
    } else if (key === "contactno") {
      return "phone1";
    } else if (key === "mobileno") {
      return "mobileNumber";
    } else if (key === "gdpr") {
      return "mailPref";
    } else if (key === "email") {
      return "emailAddress";
    } else if (key === "address1") {
      return "physicalAddress1";
    } else if (key === "suburb") {
      return "physicalSuburb";
    } else if (key === "city") {
      return "physicalCity";
    } else if (key === "postcode") {
      return "physicalPostcode";
    } else {
      return key;
    }
  };
  const {
    token,
    updatedetail,
    memberenquiry,
    nzbnsearch,
    addysearch,
    addysearchwithid,
    nzbnkey,
  } = useContext(GlobalContext);
  const { memberenquiryobj } = memberenquiry.state.data;
  // assign value to fields
  Object.keys(CONFIGOBJECT).map((key) => {
    const keyForClientFetched = findMatchFromConfig(key);
    CONFIGOBJECT[key].initial = memberenquiryobj.client[keyForClientFetched];
  });
  useEffect(() => {
    setHideNzbn(true);
    setResetNzbnSearch(true);
  }, []);
  /* --- login token */
  const [fetchToken] = FetchContextApiV2(TOKENCONFIG);
  const fetchTokenExecute = async ({ body }) => {
    token.dispatch({
      type: token.actionTypes.FETCH_TOKEN,
      token: await fetchToken({ body }, token),
    });
  };
  /* --- login token */

  /* --- nzbn */
  const [fetchNzbnSearch] = FetchContextApiV2(SEARCHCONFIG);
  const fetchNzbnSearchExecute = async ({ query, newheaders }) => {
    nzbnsearch.dispatch({
      type: nzbnsearch.actionTypes.FETCH_NZBN,
      nzbnsearch: await fetchNzbnSearch({ query, newheaders }, nzbnsearch),
    });
  };
  /* --- nzbn */

  /* --- addy */
  const [fetchAddySearch] = FetchContextApiV2(SEARCHADDYCONFIG);
  const fetchAddySearchExecute = async ({ query }) => {
    addysearch.dispatch({
      type: addysearch.actionTypes.FETCH_ADDRESS,
      addysearch: await fetchAddySearch({ query }, addysearch),
    });
  };
  /* --- addy */

  /* --- addy with id */
  const [fetchAddySearchWithId] = FetchContextApiV2(SEARCHADDYWITHIDCONFIG);
  const fetchAddySearchWithIdExecute = async ({ query }) => {
    addysearchwithid.dispatch({
      type: addysearchwithid.actionTypes.FETCH_ADDRESS_WITH_ID,
      addysearchwithid: await fetchAddySearchWithId(
        { query },
        addysearchwithid
      ),
    });
  };
  /* --- addy with id */

  /* --- update */
  const [fetchUpdate] = FetchContextApiV2(UPDATECONFIG);
  const fetchUpdateExecute = async ({ body, newheaders }) => {
    updatedetail.dispatch({
      type: updatedetail.actionTypes.FETCH_UPDATE_DETAIL,
      updatedetail: await fetchUpdate({ body, newheaders }, updatedetail),
    });
  };
  /* --- update */

  /* --- member enquiry */
  const [fetchMemberEnquiryObj] = FetchContextApiV2(MEMBERENQUIRYCONFIG);
  const fetchMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.FETCH_MEMBER_ENQUIRY,
      memberenquiry: await fetchMemberEnquiryObj({ newheaders }, memberenquiry),
    });
  };
  const resetMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.RESET,
    });
  };
  /* --- member enquiry */

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleCreate = async (e) => {
    // setLoaded(true);
    // if (verified) {
    console.log(values);
    var body = {
      name: values.companyname,
      externalId: values.nzbn,
      firstName: values.firstname,
      lastName: values.lastname,
      phone1: values.contactno,
      mobileNumber: values.mobileno,
      titleId: null,
      // mailPref: values.gdpr ? 1 : 0,
      mailPref: values.gdpr ? 1 : 0,
      emailAddress: values.email,
      // password: values.password,
      physicalAddress1: values.address1,
      physicalAddress2: "",
      physicalSuburb: values.suburb,
      physicalCity: values.city,
      physicalState: "",
      physicalPostcode: values.postcode,
      statusId: values.statusId,
      contactId: values.contactId,
    };
    const newheaders = {
      ...UPDATECONFIG.headers,
      // Authorization: `Bearer ${tokenobject.token}`
      Authorization: login.token,
    };
    console.log(body);
    await fetchUpdateExecute({ body, newheaders });
    setUpdatePressed(true);
  };

  const handleCancel = async () => {
    props.history.push("/corporate/config");
  };
  const handleAddyClick = async (entity) => {
    const apikeyforaddy = "c9a6e01e81624d8283fa9e4a186b6dfa";
    await fetchAddySearchWithIdExecute({
      query: "/" + entity.id + "?key=" + apikeyforaddy,
    });
  };
  const {
    values,
    onReset,
    onCheck,
    onChange,
    onPasswordChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONFIGOBJECT);

  useEffect(() => {
    if (updatePressed2) {
      setUpdatePressed2(false);
      if (!memberenquiry.state.isStatus) {
        props.history.push("/corporate/account_profile/update/fail");
      }
      const { memberenquiryobj } = memberenquiry.state.data;
      const { statusId } = memberenquiryobj.client;
      if (statusId !== undefined && statusId === 4) {
        // statusId = 4 means the user has been activated
        LocalStorage.addLS("user", { ...memberenquiryobj.client });
        LocalStorage.addLS("guest", false);
        props.history.push("/corporate/account_profile/update/success");
      }
    }
  }, [updatePressed2]);
  const secondFunc = async () => {
    if (!updatedetail.state.isStatus)
      props.history.push("/corporate/account_profile/update/fail");
    const { updatedetailobj } = updatedetail.state.data;
    const newheaders = {
      ...MEMBERENQUIRYCONFIG.headers,
      // Authorization: `Bearer ${tokenobject.token}`
      Authorization: login.token,
      appName: "corp",
    };
    await fetchMemberEnquiryExecute({ newheaders });
    setUpdatePressed2(true);
  };
  useEffect(() => {
    if (updatePressed) {
      setUpdatePressed(false);
      secondFunc();
    }
  }, [updatePressed]);
  useEffect(() => {
    if (!nzbnSelected) setNzbnChanged(true);
    if (nzbnChanged) {
      const newheaders = {
        ...SEARCHCONFIG.headers,
        Authorization: "Bearer " + nzbnkey.state.data.nzbnkeyobj.accessToken,
      };
      fetchNzbnSearchExecute({
        query: "?search-term=" + values.nzbn,
        newheaders,
      });
    }
  }, [values.nzbn]);

  useEffect(() => {
    if (resetNzbnSearch) {
      setResetAddySearch(false);
      nzbnsearch.dispatch({
        type: nzbnsearch.actionTypes.RESET,
      });
    }
  }, [resetNzbnSearch]);

  useEffect(() => {
    if (!addyChanged) setAddyChanged(true);
    const apikeyforaddy = "c9a6e01e81624d8283fa9e4a186b6dfa";
    if (addyChanged && values.address1.replace(" ", "").toString().length > 1) {
      fetchAddySearchExecute({
        query: "?key=" + apikeyforaddy + "&s=" + values.address1,
      });
    }
  }, [hideBilling, values.address1]);

  useEffect(() => {
    if (resetAddySearch) {
      setResetAddySearch(false);
      addysearchwithid.dispatch({
        type: addysearchwithid.actionTypes.RESET,
      });
      addysearch.dispatch({
        type: addysearch.actionTypes.RESET,
      });
    }
  }, [resetAddySearch]);

  useEffect(() => {
    if (addysearchwithid.state.data) {
      const { addysearchwithidobj } = addysearchwithid.state.data;
      values.address1 = addysearchwithidobj.address1;
      values.suburb = addysearchwithidobj.suburb;
      values.city = addysearchwithidobj.city;
      values.postcode = addysearchwithidobj.postcode;
      setAddressAssigned(true);
    }
  }, [addysearchwithid]);

  useEffect(() => {
    if (addressAssigned) {
      setAddressAssigned(false);
      setAddyChanged(false);
      setAddySelected(true);
    }
  }, [addressAssigned]);

  const { nzbndetail } = nzbnsearch.state.data;
  const { addysearchobj } = addysearch.state.data;

  const checkResult = () => {
    console.log(nzbnsearch, nzbndetail);
    return !nzbndetail || nzbndetail.length < 1 ? false : true;
  };
  const checkAddressSearchResult = () => {
    return !addysearchobj || addysearchobj.length < 1 ? false : true;
  };

  /* data props */
  const companyname = {
    ...CONFIGOBJECT.companyname,
    error: error.companyname,
    value: values.companyname,
    onChange,
  };
  const nzbn = {
    ...CONFIGOBJECT.nzbn,
    autoComplete: "off",
    error: error.nzbn,
    value: values.nzbn,
    onChange,
  };
  const firstname = {
    ...CONFIGOBJECT.firstname,
    error: error.firstname,
    value: values.firstname,
    onChange,
  };
  const lastname = {
    ...CONFIGOBJECT.lastname,
    error: error.lastname,
    value: values.lastname,
    onChange,
  };
  const contactno = {
    ...CONFIGOBJECT.contactno,
    error: error.contactno,
    value: values.contactno,
    onChange,
  };
  const mobileno = {
    ...CONFIGOBJECT.mobileno,
    error: error.mobileno,
    value: values.mobileno,
    onChange,
  };
  const jobtitle = {
    ...CONFIGOBJECT.jobtitle,
    error: error.jobtitle,
    value: values.jobtitle,
    onChange,
  };
  const gdpr = {
    ...CONFIGOBJECT.gdpr,
    error: error.gdpr,
    value: values.gdpr === 1 ? true : false,
    onChange: onCheck,
  };

  const email = {
    ...CONFIGOBJECT.email,
    error: error.email,
    value: values.email,
    disabled: true,
    onChange,
  };
  const password = {
    ...CONFIGOBJECT.password,
    error: error.password,
    value: values.password,
    onChange,
  };
  const passwordconfirm = {
    ...CONFIGOBJECT.passwordconfirm,
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
  };

  const address1 = {
    ...CONFIGOBJECT.address1,
    autoComplete: "off",
    error: error.address1,
    value: values.address1,
    onChange,
  };
  const suburb = {
    ...CONFIGOBJECT.suburb,
    error: error.suburb,
    value: values.suburb,
    onChange,
  };
  const city = {
    ...CONFIGOBJECT.city,
    error: error.city,
    value: values.city,
    onChange,
  };
  // const state = {
  //   ...CONFIGOBJECT.state,
  //   error: error.state,
  //   value: values.state,
  //   onChange
  // };
  const postcode = {
    ...CONFIGOBJECT.postcode,
    error: error.postcode,
    value: values.postcode,
    onChange,
  };

  const tandc = {
    ...CONFIGOBJECT.tandc,
    error: error.tandc,
    value: values.tandc,
    onChange: onCheck,
  };

  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  const submitButton = { size: "medium" };
  const submitDisabledButton = { size: "medium", disabled: true };
  const cancelButton = { size: "medium", onClick: handleCancel };
  /* data props */

  const nzbnSearchClick = (e, entity) => {
    // values.nzbn = entity.nzbn;
    e.target.name = "nzbn";
    e.target.value = entity.nzbn;
    nzbn.onChange(e);
    setResetNzbnSearch(true);
    setNzbnChanged(false);
    setNzbnSelected(true);
    setHideNzbn(true);
  };
  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleSmallTitle>Contact Details</ModuleSmallTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>
      <ModuleFormWrapper>
        {/* line */}
        
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...firstname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...lastname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...contactno}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...mobileno}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        {/* <ModuleFormItemWrapper>
          <Inputs.Text
            {...jobtitle}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper> */}
        {/* line */}

        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Checkbox {...gdpr} />
        </ModuleFormItemLongWrapper>
        {/* line */}
      </ModuleFormWrapper>

      {/* <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Account Details</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>

        <ModuleFormItemLongWrapper>
          <Inputs.Text {...email} style={{ marginTop: -10, marginBottom: 3 }} />
        </ModuleFormItemLongWrapper>



        <ModuleFormItemWrapper>
          <Inputs.Password
            {...password}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Password
            {...passwordconfirm}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>

      </ModuleFormWrapper> */}

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleSmallTitle>Billing Details</ModuleSmallTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Text
            {...address1}
            onFocus={() => {
              setAddyChanged(false);
              setHideBilling(true);
              setAddySelected(false);
              setResetAddySearch(true);
            }}
            onBlur={() => {
              setAddySelected(true);
            }}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
          {values.address1.length > 0 &&
          hideBilling &&
          (addyChanged || !addySelected) ? (
            <Paper className={classes.paper2} square>
              {!checkAddressSearchResult() || addysearch.state.isLoading ? (
                <CircularProgress
                  className={classes.throbber}
                  size={30}
                  thickness={5}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  top: -30,
                  right: 2,
                  backgroundColor: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
                onClick={() => {
                  if (addySelected) {
                    setHideBilling(false);
                    setAddySelected(false);
                    setResetAddySearch(true);
                  }
                }}
              >
                <CloseIcon
                  style={{
                    color: "black",
                    fontSize: 25,
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </div>
              {checkAddressSearchResult() &&
                !addysearch.state.isLoading &&
                addysearch.state.data.addysearchobj &&
                addysearch.state.data.addysearchobj.addresses.map(
                  (entity, index) => {
                    return (
                      <MenuItem
                        key={entity.id}
                        component="div"
                        style={{
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          setHideBilling(false);
                          setAddySelected(false);
                          setAddyChanged(true);
                          handleAddyClick(entity);
                        }}
                      >
                        {entity.a}
                      </MenuItem>
                    );
                  }
                )}
              {checkAddressSearchResult() &&
              !addysearch.state.isLoading &&
              addysearch.state.data.addysearchobj &&
              addysearch.state.data.addysearchobj.addresses.length === 0 ? (
                <MenuItem
                  component="div"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  No matching Address found
                </MenuItem>
              ) : null}
            </Paper>
          ) : null}
        </ModuleFormItemLongWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...suburb}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...city} style={{ marginTop: -10, marginBottom: 3 }} />
        </ModuleFormItemWrapper>

        <ModuleFormItemShortWrapper>
          <Inputs.Text
            {...postcode}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemShortWrapper>
        {/* line */}
      </ModuleFormWrapper>
      <br />
      <br />
      {true ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>Update</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>Update</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default updateProfile;
