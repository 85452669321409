import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ACTIVATE_CHILD: "ACTIVATE_CHILD",
  RESET: "RESET",
};

const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  };
  return state;
};

const addPR = async (state, activatechild) => {
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: activatechild,
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_CHILD:
      return addPR(state, action.activatechild);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const index = {
  init,
  actionTypes,
  reducer,
};

export default index;
