import axios from "axios";

const useFetch = async ({ url, config }) => {
  try {
    const response = await axios(url, config);
    const { data, status } = response;
    // if (!response.ok) throw data;
    // if (!status >= 200 || !status <= 299) throw data;
    if (status < 200 || status >= 300) throw data;
    return [true, { blob: false, json: data }];
  } catch (e) {
    console.log(e);
    return [false, { blob: false, json: e.response.data }];
  }
};

export default useFetch;
