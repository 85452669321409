import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  LocalStorage,
  FetchContextApiV2,
  WindowSize,
} from "../../lib-atomic-functions";

import {
  PDFBlobProviderInvoice,
  PDFPreviewProviderInvoice,
  PDFLinkInvoice,
  PDFBlobProviderQuote,
  PDFPreviewProviderQuote,
  PDFLinkQuote,
} from "../../app-module-order-section-invoice/default";
/* packages */

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;
const REGISTERACTIVATIONCONFIG = {
  url: ENDPOINTS.account_activation.url,
  method: ENDPOINTS.account_activation.method,
  headers: ENDPOINTS.account_activation.headers,
  responseType: "json",
};
const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json",
};
const TRANSACTIONSCONFIG = {
  url: ENDPOINTS.transactions.url,
  method: ENDPOINTS.transactions.method,
  headers: ENDPOINTS.transactions.headers,
  responseType: "json",
};

const PRODUCTALLCONFIG = {
  url: ENDPOINTS.productall.url,
  method: ENDPOINTS.productall.method,
  headers: ENDPOINTS.productall.headers,
  responseType: "json",
};
const GETORDERCONFIG = {
  url: ENDPOINTS.getorder.url,
  method: ENDPOINTS.getorder.method,
  headers: ENDPOINTS.getorder.headers,
  responseType: "json",
};
const GETCONTACTCONFIG = {
  url: ENDPOINTS.getcontact.url,
  method: ENDPOINTS.getcontact.method,
  headers: ENDPOINTS.getcontact.headers,
  responseType: "json",
};
const NZBNKEYCONFIG = {
  url: ENDPOINTS.nzbnkey.url,
  method: ENDPOINTS.nzbnkey.method,
  headers: ENDPOINTS.nzbnkey.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { width, height } = WindowSize();
  const { previewDataReady, setPreviewDataReady } = props;
  const isGuestUser = LocalStorage.getLS() && LocalStorage.getLS().guest;
  const isNormUser = LocalStorage.getLS() && LocalStorage.getLS().user;
  const login = LocalStorage.getLS() && LocalStorage.getLS().login;
  // this should be returning Route to home instead of null
  if (!isNormUser) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  if (!login || Object.keys(isNormUser).length === 0) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }

  const { productall, orderdetail, getcontact } = useContext(GlobalContext);

  /* product all */
  const [fetchProductAll] = FetchContextApiV2(PRODUCTALLCONFIG);
  const execProductAll = async () => {
    productall.dispatch({
      type: productall.actionTypes.FETCH_PRODUCT_ALL,
      productall: await execProductAllCompound(),
    });
  };
  const execProductAllCompound = async () => {
    const newheaders = {
      ...PRODUCTALLCONFIG.headers,
      Authorization: login.token,
      // Authorization:
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A"
    };
    try {
      const cat2 = await fetchProductAll(
        { query: "?categoryId=2", newheaders },
        productall
      );
      const cat3 = await fetchProductAll(
        { query: "?categoryId=3", newheaders },
        productall
      );
      const cat5 = await fetchProductAll(
        { query: "?categoryId=5", newheaders },
        productall
      );
      cat2.data.json.products.push(...cat3.data.json.products);
      cat2.data.json.products.push(...cat5.data.json.products);
      return cat2;
    } catch (e) {
      console.log(e);
      if (
        props.location.state !== undefined &&
        props.location.state.orderid !== undefined
      ) {
        const { orderid } = props.location.state;
        props.history.push("/corporate/order/fail", {
          orderIdPassed: orderid,
          isTokenExpire: true,
          returnUrl: "/corporate/order/success",
        });
      }
      const parsed = QueryString.parse(props.location.search);
      const { result, userid } = parsed;
      if (result !== undefined && userid !== undefined) {
        props.history.push("/corporate/order/fail", {
          isTokenExpire: true,
          returnUrl:
            props.location.pathname + "?result=" + result + "&userid=" + userid,
        });
      }
    }
    //return productAll;
  };

  /* order detail */
  const [fetchOrderObj] = FetchContextApiV2(GETORDERCONFIG);
  const fetchOrderObjExecute = async ({ newheaders, query }) => {
    orderdetail.dispatch({
      type: orderdetail.actionTypes.FETCH_ORDER_DETAIL,
      orderdetail: await fetchOrderObj({ newheaders, query }, orderdetail),
    });
  };

  /* --- get contact */
  console.log({ GETCONTACTCONFIG });
  const [fetchContact] = FetchContextApiV2(GETCONTACTCONFIG);
  const fetchContactExecute = async ({ newheaders, query }) => {
    getcontact.dispatch({
      type: getcontact.actionTypes.FETCH_CONTACT,
      getcontact: await fetchContact({ newheaders, query }, getcontact),
    });
  };
  // no parameter found; either Direct Credit OR fail

  if (
    props.location.state !== undefined &&
    props.location.state.orderid !== undefined
  ) {
    const { orderid } = props.location.state;
    useEffect(() => {
      execProductAll();
    }, []);

    useEffect(() => {
      if (productall.state.data) {
        const newheaders = {
          ...GETORDERCONFIG.headers,
          Authorization: login.token,
          // Authorization:
          //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtb2R1bGVDb2RlIjoiV0dNQiIsImlzcyI6IlRyYW54YWN0b3IgTHRkIiwidXNlclR5cGUiOiIzIiwiZXhwIjoxNTgyMDcxMDc1LCJ0cmFkZXJJZCI6IjMyMjYyOTE2IiwidXNlck5hbWUiOiIzMjI2MjkxNiIsImlhdCI6MTU4MjA2NzQ3NSwidXNlcklkIjoiMzIyNjI5MTYiLCJwcm9ncmFtSWQiOjExMX0.6NUnDoTgVR1CWQA9LTofN8iUyMjTi4qVa8lNZ_Kp22A",
          appName: "corp",
        };
        console.log(productall);
        fetchOrderObjExecute({
          newheaders,
          query: "/" + orderid,
        });
      }
    }, [productall.state]);

    useEffect(() => {
      if (orderdetail.state.data) {
        if (!orderdetail.state.isStatus) {
          console.log("nop");
          props.history.push("/corporate/login");
        }
        // const id = isNormUser ? isNormUser.id : isGuestUser.id;
        const newheaders = {
          ...GETCONTACTCONFIG.headers,
          Authorization: login.token,
        };
        fetchContactExecute({
          newheaders,
          query: "",
        });
      }
    }, [orderdetail.state]);

    useEffect(() => {
      if (getcontact.state.data) {
        // if (!getcontact.state.isStatus) {
        //   props.history.push("/corporate/order/fail", {
        //     orderIdPassed: orderid,
        //     isTokenExpire: true,
        //     returnUrl: "/corporate/order/success",
        //   });
        // }
        const order = orderdetail.state.data.orderdetailobj;
        const products = order.detail.map((product) => {
          return {
            value: product.value,
            quantity: product.quantity,
            startCardNumber: null,
            productId: product.productId,
          };
        });
        const prductDetails = products.map((product) => {
          product["details"] =
            productall.state.data.productallobj.products.filter(
              (productdetail) => product.productId === productdetail.productId
            )[0];
          return product;
        });
        console.log(order);
        const feesData = order.detail.filter(
          (product) => product.subClassId === 1 || product.subClassId === 4
        );
        const previewData = {
          // pdfFilename: orderIdCreated + ".pdf",
          pdfFilename: order.id + ".pdf",
          pdfPreviewData: {
            orderInfoData: order,
            companyInfoData: {
              clients: isNormUser ? isNormUser : isGuestUser,
            },
            contactInfoData: getcontact.state.data.getcontactobj,
            productsDetailsData: prductDetails,
            feesDetailsData: feesData,
          },
          pdfPreviewSize: {
            width: width < 500 ? width - 20 : 600,
            height: width < 500 ? ((width - 20) * 3) / 2 : 900,
          },
        };
        setPreviewDataReady(previewData);
      }
    }, [getcontact.state]);

    const { productallobj } = productall.state.data;
    const { orderdetailobj } = orderdetail.state.data;
    const { getcontactobj } = getcontact.state.data;

    const checkResult = () => {
      const productDetail =
        !productallobj || productallobj.length < 1 ? false : true;
      const orderDetail =
        !orderdetailobj || orderdetailobj.length < 1 ? false : true;
      const contactDetail =
        !getcontactobj || getcontactobj.length < 1 ? false : true;
      return !productDetail || !orderDetail || !contactDetail ? false : true;
    };

    if (
      !checkResult() ||
      productall.state.isLoading ||
      orderdetail.state.isLoading ||
      getcontact.state.isLoading
    ) {
      return <Spinner />;
    }
    return props.children;
  }
};

export default index;
