import React, { useState, useEffect, useContext } from "react";
import { LocalStorage } from "../../components/lib-atomic-functions";
// import { GlobalContext } from "../../components/lib-atomic-context";
import { GlobalContext } from "../../components/lib-atomic-context";

// new imports

import App from "./imports-app";

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ShoppingcartIcon from "@material-ui/icons/ShoppingCartOutlined";
import Badge from "@material-ui/core/Badge";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

import { ModuleAccountProfileToggleBtn } from "../../components/lib-atomic-design-section";

import appConf from "../../components/app-conf";

////////
const logo = appConf[process.env.REACT_APP_CLIENT].assets.logo;

const useStyles = makeStyles((theme) => ({
  username: {
    color: "grey",
    fontSize: 16,
    margin: "auto",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  signOutBtn: {
    color: "#171717",
    fontSize: 12,
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "#171717",
      backgroundColor: "white",
    },
  },
  appBar: {
    backgroundColor: "#ffffff",
    borderBottomColor: "transparent",
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const importNewNav = (props) => {
  const classes = useStyles();
  const isUserLS = LocalStorage.getLS().user ? true : false;
  const usernameLS = LocalStorage.getLS().user
    ? LocalStorage.getLS().user.firstName
    : null;
  const hasSecondaryAccounts = LocalStorage.getLS().user
    ? LocalStorage.getLS().user.hasSecondary === 1
    : false;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  const [itemCount, itemCountSet] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (LocalStorage.getLS().products) {
      const interval = setInterval(() => {
        const count = LocalStorage.getLS().products.length;
        itemCountSet(count);
      }, 100);
      return () => clearInterval(interval);
    }
    console.log("example ...");
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const menuItmes = [
    {
      text: "Order Now",
      link: () => {
        handleClose();
        props.history.push("/corporate/order/items");
      },
    },
    {
      text: "Update Details",
      link: () => {
        handleClose();
        props.history.push("/corporate/account_profile/update");
      },
    },
    // {
    //   text: "Manage Subsidiary Accounts",
    //   link: () => {
    //     handleClose();
    //     props.history.push("/corporate/account_profile/manage_sub");
    //   },
    // },
    {
      text: "View Transactions",
      link: () => {
        handleClose();
        props.history.push("/corporate/account_profile/transactions");
      },
    },
    {
      text: "Activate Cards",
      link: () => {
        handleClose();
        props.history.push("/corporate/card_activation");
      },
    },
    {
      text: "Update Password",
      link: () => {
        handleClose();
        props.history.push("/corporate/account_profile/update_pw");
      },
    },
  ].filter((item) => {
    if (item.text === "Manage Subsidiary Accounts") {
      return hasSecondaryAccounts;
    } else {
      return true;
    }
  });

  setInterval(() => {
    if (LocalStorage.getLS().products) {
      const count = LocalStorage.getLS().products.length;
      itemCountSet(count);
    }
  }, 1000);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ marginLeft: 0, paddingLeft: 0 }}>
        <Grid
          style={{
            paddingTop: 10,
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Typography
              style={{ marginLeft: 10, marginRight: 10 }}
              variant="h8"
            >
              <img
                onClick={() => {
                  props.history.push("/corporate");
                }}
                src={logo}
                style={{ marginTop: 10, cursor: "pointer" }}
                width="100"
                alt="logo"
              />
            </Typography>
          </Grid>
          <Grid style={{ textAlign: "right" }}>
            {isUserLS && (
              <Button onClick={handleClick} className={classes.username}>
                <PersonOutlineOutlinedIcon style={{ paddingBottom: 2 }} />
                &nbsp;Welcome {usernameLS}&nbsp;
                <KeyboardArrowDownOutlinedIcon />
              </Button>
            )}
            {isUserLS && (
              <Button
                onClick={() => {
                  props.history.push("/corporate/logout");
                }}
                className={classes.signOutBtn}
              >
                &nbsp;Sign out&nbsp;
              </Button>
            )}
            {isUserLS && (
              <Button
                onClick={() => {
                  props.history.push("/corporate/order/items");
                }}
                className={classes.signOutBtn}
              >
                <Badge badgeContent={itemCount/2} showzero color="secondary">
                  <ShoppingcartIcon style={{ paddingBottom: 2 }} />
                </Badge>
              </Button>
            )}
            {!isUserLS && (
              <Button
                onClick={() => {
                  props.history.push("/corporate/register");
                }}
                className={classes.signOutBtn}
              >
                <b>Register</b>
              </Button>
            )}
            {!isUserLS && (
              <Button
                onClick={() => {
                  props.history.push("/corporate/login");
                }}
                className={classes.signOutBtn}
              >
                <b>Log In</b>
              </Button>
            )}

            <Grid style={{ margin: 0 }}>
              {isUserLS && (
                <div style={{ display: "block", marginBottom: 30 }}>{"\n"}</div>
              )}
              {!isUserLS && (
                <Button
                  onClick={() => {
                    window.open("https://giftcards.westfield.co.nz", "_blank");
                    // props.history.push("/corporate/register");
                  }}
                  className={classes.signOutBtn}
                >
                  &nbsp;Not a Corporate customer?&nbsp;
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* <Toolbar style={{margin:0}}> */}

      {/* </Toolbar> */}
      <Toolbar style={{ marginLeft: 0, paddingLeft: 1 }}>
        <Grid container>
          <Grid>
            <div
              id="thq-sidebar-modules"
              style={{
                flex: 1,
                display: "flex",
                alignSelf: "center",
                justifyContent: "flex-start",
              }}
            >
              <App {...props} />
            </div>
          </Grid>
        </Grid>
      </Toolbar>

      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menuItmes.map((menuItem) => {
            return (
              <div
                style={{
                  // padding: 10,
                  height: "auto",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <ModuleAccountProfileToggleBtn
                    {...{
                      props,
                      ...{
                        label: menuItem.text,
                        link: menuItem.link,
                      },
                    }}
                  />
                </div>
              </div>
            );
          })}
        </Popover>
      </div>
    </AppBar>
  );
};

export default importNewNav;
