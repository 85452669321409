import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_PAYMENT: "ADD_PAYMENT"
};

const addPayment = async (state, payment) => {
  const paymentoption = payment;
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: paymentoption
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_PAYMENT:
      return addPayment(state, action.payment);
    default:
      return state;
  }
};

const payment = {
  init,
  actionTypes,
  reducer
};

export default payment;
