import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

/* modules */
import Feeds from "./feeds";
/* modules */

const Valid = ({ error }) => {
  if (error) return null;
  const data = { error };
  console.log(data);
  return (
    <FormHelperText>
      <Typography
        style={{ color: "#4fd14f" }}
        variant="caption"
        display="block"
        gutterBottom
      >
        Good Password
      </Typography>
    </FormHelperText>
  );
};

export default Valid;
