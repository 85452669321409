import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
import { GlobalProvider } from "../../lib-atomic-context";
import LoginContainer from "../../app-module-login-container/default";
/* packages */

/* module */
import LoginForm from "../../app-module-login-form-login/default";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <LoginContainer>
        <ModuleContainerLg>
          <ModulePaddedWrapperLg>
            <LoginForm {...props} />
          </ModulePaddedWrapperLg>
        </ModuleContainerLg>
      </LoginContainer>
    </GlobalProvider>
  );
};

export default index;
