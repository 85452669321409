const CLIENT = process.env.REACT_APP_CLIENT;
export default {
  media: "https://strapi.tr4ns.com",
  // logo: "/assets/" + CLIENT + "/logo-red.png",
  logo: "https://strapi.tr4ns.com/uploads/aade54731bef4762bb06f070038cb0bc.png",
  logo_lg_pdf: "/assets/" + CLIENT + "/logo-lg-pdf.jpg",
  footer_pdf: "/corporate/assets/" + CLIENT + "/footer-pdf.png",
  logo_pdf: "/corporate/assets/" + CLIENT + "/logo-pdf.png",
  corporate_map: "/assets/" + CLIENT + "/corporateMap.png",
  landing: {
    default: "/assets/" + CLIENT + "/logo.png",
  },
  cards: {
    df: {
      name: "default",
      image: "/assets/" + CLIENT + "/cards/724975.jpg",
    },
    hb: {
      name: "happy birthday",
      // image: "/assets/" + CLIENT + "/cards/724986.png", // for uat
      image: "/assets/" + CLIENT + "/cards/724984.png",
    },
    ty: {
      name: "thank you",
      image: "/assets/" + CLIENT + "/cards/724985.png",
    },
  },
  carriers: {
    df: {
      name: "default",
      image: "/assets/" + CLIENT + "/cards/724988.png",
    },
    hb: {
      name: "happy birthday",
      // image: "/assets/" + CLIENT + "/cards/724989.png", // for uat
      image: "/assets/" + CLIENT + "/cards/724986.png",
    },
    ty: {
      name: "thank you",
      image: "/assets/" + CLIENT + "/cards/724987.png",
    },
  },
};
