import React, { useState, useEffect } from "react";

/* packages */
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

const index = (props) => {
  useEffect(() => {
    LocalStorage.removeLS();
    props.history.push("/corporate");
  }, []);

  //window.location.replace("/u/login");

  return null;
};

export default index;
