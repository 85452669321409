import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";
const { logo, logo_lg_pdf } = CONFIG.setup.assets;

const header = ({ order }) => {
  console.log(order);
  return (
    <Fragment>
      <View style={[styles.container, styles.headerWrapper]}>
        <View style={styles.headerContainer}>
          <Image style={styles.logoHeader} src={logo} />
        </View>
        <View style={styles.headerRightContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.textAlignRight}>
              {order.enrolmentTraderName}
            </Text>
            <Text style={styles.textAlignRight}>
              {order.enrolmentTraderAddress1}
            </Text>
            <Text style={styles.textAlignRight}>
              {order.enrolmentTraderSuburb}
            </Text>
            <Text style={styles.textAlignRight}>
              {order.enrolmentTraderCity} {order.enrolmentTraderPostCode}
            </Text>
            {/* <Text style={styles.textAlignRight}>GST No 57-630-167</Text> */}
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default header;
