import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

import { NumberCurrency } from "../../../../lib-atomic-functions";

const productsItem = (product) => {
  const prodValueFloat = parseFloat(product.value);
  const total = NumberCurrency((product.quantity * prodValueFloat).toFixed(2));
  const value_currency = NumberCurrency(prodValueFloat.toFixed(2));
  console.log(product);
  if (product.details.subclassId === 5) {
    return (
      <View style={[styles.container]}>
        <View style={styles.itemContainer}>
          <Text></Text>
        </View>
        <View style={styles.totalContainer}>
          <Text></Text>
        </View>
        <View
          style={[styles.courierTotalContainer, styles.alignRightContainer]}
        >
          <View style={styles.transparentline}></View>
          <Text>{product.details.productName}*:&nbsp;&nbsp;</Text>
        </View>
        <View
          style={[styles.courierTotalContainer2, styles.alignRightContainer]}
        >
          <View style={styles.line}></View>
          <Text style={styles.TotalTitle}>${total}</Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={[styles.container]}>
        <View style={styles.qtyContainer}>
          <Text>{product.quantity}</Text>
        </View>
        <View style={[styles.otherContainer]}>
          <Text style={[styles.alignRightContainer, { paddingRight: 20 }]}>
            ${value_currency}
          </Text>
        </View>
        <View style={styles.itemContainer}>
          <Text>{product.details.productName}</Text>
        </View>
        <View style={[styles.totalContainer, styles.alignRightContainer]}>
          <Text>${total}</Text>
        </View>
      </View>
    );
  }
};

export default productsItem;
