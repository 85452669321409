import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  LocalStorage,
  FetchContextApiV2,
  WindowSize,
} from "../../lib-atomic-functions";

import {
  PDFBlobProviderInvoice,
  PDFPreviewProviderInvoice,
  PDFLinkInvoice,
  PDFBlobProviderQuote,
  PDFPreviewProviderQuote,
  PDFLinkQuote,
} from "../../app-module-order-section-invoice/default";
/* packages */

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.activation.api.endpoints;
const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json",
};
const NZBNKEYCONFIG = {
  url: ENDPOINTS.nzbnkey.url,
  method: ENDPOINTS.nzbnkey.method,
  headers: ENDPOINTS.nzbnkey.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  //   const isGuestUser = LocalStorage.getLS() && LocalStorage.getLS().guest;
  const isNormUser = LocalStorage.getLS() && LocalStorage.getLS().user;
  const login = LocalStorage.getLS() && LocalStorage.getLS().login;
  // this should be returning Route to home instead of null
  if (!isNormUser) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  if (!login || Object.keys(isNormUser).length === 0) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }

  const { memberenquiry, nzbnkey } = useContext(GlobalContext);

  /* --- member enquiry  & transactions */
  const [fetchMemberEnquiryObj] = FetchContextApiV2(MEMBERENQUIRYCONFIG);
  const fetchMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.FETCH_MEMBER_ENQUIRY,
      memberenquiry: await fetchMemberEnquiryObj({ newheaders }, memberenquiry),
    });
  };
  // NO NEED FOR NZBN API KEY CALL - TO BE REMOVED
  // /* --- nzbn key */
  // const [fetchNzbnKey] = FetchContextApiV2(NZBNKEYCONFIG);
  // const fetchNzbnKeyExe = async ({}) => {
  //   nzbnkey.dispatch({
  //     type: nzbnkey.actionTypes.FETCH_NZBN_KEY,
  //     nzbnkey: await fetchNzbnKey({}, nzbnkey),
  //   });
  // };
  // /* --- nzbn key */
  /* --- member enquiry & transactions */
  useEffect(() => {
    const newheaders = {
      ...MEMBERENQUIRYCONFIG.headers,
      // Authorization: `Bearer ${tokenobject.token}`
      Authorization: login.token,
      // appName: "corp",
    };
    fetchMemberEnquiryExecute({ newheaders });
    // fetchNzbnKeyExe({});
  }, []);

  useEffect(() => {
    if (memberenquiry.state.data) {
      // go to 'something went wrong' page when status is not valid
      if (!memberenquiry.state.isStatus) {
        LocalStorage.removeLS();
        return <Redirect to="/corporate/login" />;
      }
      /////////////////////////////////////
      const { memberenquiryobj } = memberenquiry.state.data;
      LocalStorage.addLS("user", { ...memberenquiryobj.client });
      LocalStorage.addLS("guest", false);
    }
  }, [memberenquiry.state]);

  // NO NEED FOR NZBN API KEY CALL - TO BE REMOVED
  // const isNzbnKeyReady =
  //   !nzbnkey.state.isLoading &&
  //   nzbnkey.state.data &&
  //   nzbnkey.state.data.nzbnkeyobj &&
  //   nzbnkey.state.isStatus
  //     ? true
  //     : false;
  const isMemberDataReady =
    !memberenquiry.state.isLoading &&
    memberenquiry.state.data &&
    memberenquiry.state.data.memberenquiryobj &&
    memberenquiry.state.isStatus
      ? true
      : false;

  if (!isMemberDataReady) return <Spinner />;

  return props.children;
};

export default index;
