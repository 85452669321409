import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  loading = false;
  data = false;
  status = true;
  expireToken = false;

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = this.clean(status, data);
    this.expireToken = data.json.status === 203 ? true : false;
  }

  clean = (status, data) => {
    if (!status || !data.json) return false;
    const pages = {};
    if (data.json) {
      data.json.map((page, index) => {
        pages[page.name] = page;
      });
    }
    return pages;
  }  

}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable,
})


export default createContext(new Store())
