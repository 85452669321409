import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Registration Form";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    config: {
      api: {
        endpoints: {
          forgotpassword: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "https://tr4ns10.tr4ns.com/Westfield-Reporting",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_corp.url[environment] +
              "/password",
            method: "POST",
          },
          auth: {
            headers: {
              "Content-Type": "application/json",
              moduleCode:
                setup.setup.westfield.corp.norm.moduleCode[environment],
            },
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/auth/token",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/auth/token",
            method: "POST",
          },
        },
      },
      objects: {
        email: {
          name: "email",
          initial: "",
          label: "Email",
          helperText: false,
          rules: ["isNotNull", "isEmail"],
        },
      },
      responses: {
        fail: "No such username or password",
        unverified: "Please verify your account by ticking the recaptcha.",
      },
    },
  },
  routes: {
    label: "Forgot Password",
    prefix: "/corporate/forgot_password/request",
  },
  setup,
};

export default config;
