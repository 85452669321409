import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* module  */
import RegisterForm from "../form/register";
import { GlobalProvider } from "../../lib-atomic-context";
import RegisterContainer from "../../app-module-register-container/default";
/* module  */

const index = (props) => {
  /* data props */
  const formData = { props };
  /* data props */

  return (
    <GlobalProvider>
      <RegisterContainer {...props}>
        <RegisterForm {...formData} />
      </RegisterContainer>
    </GlobalProvider>
  );
};

export default index;
