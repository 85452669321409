import React, { Fragment } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

/* module */
import PDFProductsItem from "./products-item";
/* module */

const productsBody = ({ products }) => {
  return products.map((product, index) => {
    return <PDFProductsItem key={index} {...product} />;
  });
};

export default productsBody;
