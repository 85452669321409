import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Report 404";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: null,
        endpoints: null,
      },
    },
  },
  routes: {
    label: "No Data",
    prefix: "/corporate/404",
  },
  setup,
  environment,
};

export default config;
