import React from "react";

/* mui */
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const text = (props) => {
  /* mui */
  const classes = useStyles();
  /* mui */

  return (
    <Typography variant="body2" className={classes.text}>
      {props.children}
    </Typography>
  );
};

export default text;
