import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_ORDER_DETAIL: "FETCH_ORDER_DETAIL"
};

const fetchOrderDetail = async (state, orderdetail) => {
  state = {
    ...state,
    isStatus: orderdetail.status,
    expireToken: sanitizeExpire({
      status: orderdetail.status,
      data: orderdetail.data,
      name: "orderdetailobj"
    }),
    data: sanitizeData({
      status: orderdetail.status,
      data: orderdetail.data,
      name: "orderdetailobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_DETAIL:
      return fetchOrderDetail(state, action.orderdetail);
    default:
      return state;
  }
};

const orderdetail = {
  init,
  actionTypes,
  reducer
};

export default orderdetail;
