import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */
import postcodelist from "./postcodelist";

const index = (props) => {
  const productsLS = LocalStorage.getLS().products;
  const promocodeLS = LocalStorage.getLS().promocode;
  const feesLS = LocalStorage.getLS().fees;
  const feesDetailsLS = LocalStorage.getLS().feesDetails;
  const { product, fees, promocode } = useContext(GlobalContext);
  const [feeMethod, setFeeMethod] = useState("");
  const [feeAmount, setFeeAmount] = useState(10.0);
  const [feeExtra, setFeeExtra] = useState(0.0);

  useEffect(() => {
    handleFeeTotal();
  }, [promocode, product, productsLS]);

  const handleFeeTotal = () => {
    const infoLS = LocalStorage.getLS().user
      ? LocalStorage.getLS().user
      : LocalStorage.getLS().guest;
    const cardsLS = LocalStorage.getLS().products;
    const isGreetingFound =
      cardsLS && cardsLS.some((item) => item.carrieroption === "1");
    let noOfCards = 0;
    let noOfGreetingCards = 0;
    cardsLS &&
      cardsLS.map((item) => {
        if (item.classification === "card") {
          noOfCards += parseInt(item.quantity);
        }
        if (item.classification === "carrier" && item.carrieroption === "1") {
          noOfGreetingCards += parseInt(item.quantity);
        }
      });
    const isRural = postcodelist.some((i) => i === infoLS.physicalPostcode);

    // FEES MORE DETAILS
    const allproductsLS = LocalStorage.getLS().productall;
    const idFoundForCourier = allproductsLS.filter((prod) =>
      isRural
        ? prod.productName === "Courier Rural"
        : prod.productName === "Courier Standard"
    );
    const idForPackagingAndHandling = allproductsLS.filter(
      (prod) => prod.productName === "Packaging & Handling"
    );
    console.log({ idFoundForCourier });
    if (
      idFoundForCourier.length === 1 &&
      idForPackagingAndHandling.length === 1
    ) {
      let promocodeProductId = 0;
      let promocodePrice = 0;
      if (promocode.state.data.status) {
        promocodeProductId = promocode.state.data.data.json.promos[0].productId;
        promocodePrice = promocode.state.data.data.json.promos[0].newPrice;
      } else {
        if (promocodeLS) {
          promocodeProductId = promocodeLS.productId;
          promocodePrice = promocodeLS.newPrice;
        }
      }
      // const courierFee = 10;
      // const phFee = 0;

      // const feeCalculated = {
      //   courier: courierFee,
      //   pAndH: phFee,
      // };
      // LocalStorage.addLS("fees", feeCalculated);
      LocalStorage.addLS("feesDetails", {
        courierProductId: idFoundForCourier[0].productId,
        courier: feeAmount,
        pAndHProductId: idForPackagingAndHandling[0].productId,
        pAndH: feeExtra,
      });
      // setFeeAmount(courierFee);
      // setFeeExtra(phFee);
    }

    // END: FEES MORE DETAILS
  };

  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Courier Fee {feeMethod !== "" && "(" + feeMethod + ")"}</b>
            </ModuleSubTitle>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "35%", paddingLeft: 20 }}>
                <ModuleText>
                  <b>Courier Cost</b>
                </ModuleText>
              </div>
              <div style={{ width: "65%" }}>
                <ModuleText>
                  <b>{`$${feeAmount.toFixed(2)}`}</b>
                </ModuleText>
              </div>
            </div>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "35%", paddingLeft: 20 }}>
                <ModuleText>
                  <b>Packaging & Handling</b>
                </ModuleText>
              </div>
              <div style={{ width: "65%" }}>
                <ModuleText>
                  <b>{`$${feeExtra.toFixed(2)}`}</b>
                  <small> *Complimentary handling fee</small>
                </ModuleText>
              </div>
            </div>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
