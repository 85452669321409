import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_UPDATE_DETAIL: "FETCH_UPDATE_DETAIL"
};

const fetchCardActivate = async (state, updatedetail) => {
  state = {
    ...state,
    isStatus: updatedetail.status,
    expireToken: sanitizeExpire({
      status: updatedetail.status,
      data: updatedetail.data,
      name: "updatedetailobj"
    }),
    data: sanitizeData({
      status: updatedetail.status,
      data: updatedetail.data,
      name: "updatedetailobj"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UPDATE_DETAIL:
      return fetchCardActivate(state, action.updatedetail);
    default:
      return state;
  }
};

const updatedetail = {
  init,
  actionTypes,
  reducer
};

export default updatedetail;
