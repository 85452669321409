import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Re Order - Order Details";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    onlineorder: {
      api: {
        endpoints: {
          orderdetail: {
            headers: {
              appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "GET",
          },
        },
        objects: {
          search: {
            name: "search",
            initial: "",
            label: "Search order number",
            helperText: false,
            rules: false,
          },
        },
      },
    },
  },
  routes: {
    label: "Re Order - Order Details",
    prefix: "/corporate/order/section/list/total",
  },
  setup,
};

export default config;
