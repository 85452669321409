import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CARRIERS = CONFIG.setup.assets.carriers;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_carriers;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { handleNext, handleBack, handleReset } = props.handleSteps;

  /* state */
  const [submitted, setSubmitted] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  const [currMaxLimit, setCurrMaxLimit] = useState(0);
  const [quantityChange, setQuantityChange] = useState(false);
  /* --- product name useState */
  /* -------------- */
  const [productName, setProductName] = useState(724988);
  /* --- product name useState */
  /* -------------- */
  /* --- product */
  /* -------------- */
  const { productall, product } = useContext(GlobalContext);
  const execProductAddLS = (item) => {
    product.dispatch({
      type: product.actionTypes.ADD_PRODUCT_LS,
      product: item,
    });
  };
  const execProductAdd = (item) => {
    product.dispatch({
      type: product.actionTypes.ADD_CARRIER,
      product: item,
    });
  };
  useEffect(() => {
    console.log("check..carr.");
    // fetch local storage and put it inside context
    const productsLS = LocalStorage.getLS().products;
    if (productsLS) {
      execProductAddLS(productsLS);
    }
  }, []);
  useEffect(() => {
    if (product.state.data && isTriggerCreate) {
      console.log(product.state.data.productlist, " going... ");
      LocalStorage.addLS("products", product.state.data.productlist);
      setIsTriggerCreate(false);
    }
  }, [product]);
  /* -------------- */
  /* --- product */
  /* state */

  const handleCreate2 = async () => {};
  useEffect(() => {
    if (submitted) {
      setSubmitted(false);
      handleCreate();
    }
  }, [submitted]);
  const handleCreate = async () => {
    console.log("adding...", currMaxLimit);
    const productImage = productall.state.data.productallobj.products.filter(
      (product) => product.productId === productName
    );
    const item = {
      ...values,
      value: "0",
      product: productName,
      classification: "carrier",
      image: productImage[0].path,
    };
    console.log(
      "item to add: ",
      item,
      "  existingItems: ",
      product.state.data.productlist
    );
    let countOfItemAlreadyInList = 0;
    let existingItemInList = 0;
    product.state.data.productlist.map((i) => {
      console.log(i.product, productcardcarrierMap, item.product);
      if (
        productcardcarrierMap[i.product] &&
        productcardcarrierMap[i.product].toString() === item.product.toString()
      ) {
        countOfItemAlreadyInList += parseInt(i.quantity);
      }
      if (i.product.toString() === item.product.toString()) {
        existingItemInList += parseInt(i.quantity);
      }
    });
    if (
      countOfItemAlreadyInList - existingItemInList - parseInt(item.quantity) >=
      0
    ) {
      // item.quantity = (existingItemInList + parseInt(item.quantity)).toString();
      execProductAdd([item]);
    }
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
    // await createMethod({body: JSON.stringify(values)});
  };

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate2,
    PRODUCTOBJECT
  );

  // /* data props */
  // const product = {
  //   ...PRODUCTOBJECT.product,
  //   error: error.product,
  //   value: values.product,
  //   onChange
  // };
  // const value = {
  //   ...PRODUCTOBJECT.value,
  //   error: error.value,
  //   value: values.value,
  //   onChange
  // };
  let quantity = {
    ...PRODUCTOBJECT.quantity,
    maxLimit: currMaxLimit,
    error: error.quantity,
    value: values.quantity,
    onChange,
  };
  const actionButton = {
    size: "large",
    onClick: () => {
      setSubmitted(true);
    },
  };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  // const productcardsMap = {
  //   "724988": "df",
  //   "724989": "hb",
  //   "724987": "ty",
  // };
  // const productcardcarrierMap = {
  //   "380172": "724988",
  //   "724986": "724989",
  //   "724985": "724987",
  // };
  const productcardsMap = {
    724988: "df",
    724986: "hb",
    724987: "ty",
  };
  const productcardcarrierMap = {
    380172: "724988",
    724984: "724989",
    724985: "724987",
  };

  const pickedcards = LocalStorage.getLS().products;

  useEffect(() => {
    const cardPicedExist = pickedcards.filter((i) => {
      return productcardcarrierMap[i.product] === productName.toString();
    });
    const carrierAlreadyAdded = pickedcards.filter((i) => {
      return i.product.toString() === productName.toString();
    });
    let num = 0;
    if (cardPicedExist.length > 0) {
      cardPicedExist.map((i) => {
        if (i.quantity) {
          num = num + parseInt(i.quantity);
        }
      });
    }
    if (carrierAlreadyAdded.length > 0) {
      carrierAlreadyAdded.map((i) => {
        if (i.quantity) {
          num = num - parseInt(i.quantity);
        }
      });
    }
    // cardPicedExist.length > 0 ? parseInt(cardPicedExist[0].quantity) : 0;
    setCurrMaxLimit(num);
    quantity.maxLimit = num;
  }, [productName, pickedcards]);

  useEffect(() => {
    values.quantity = "";
    delete error["quantity"];
  }, [quantityChange]);

  const productcards = productall.state.data.productallobj.products.filter(
    (product) => product.subclassId === 2
  );

  const productcarrier = [];
  productcards.map((product, index) => {
    const cardPicedExist = pickedcards.filter((i) => {
      return productcardcarrierMap[i.product] === product.productId.toString();
    });
    if (cardPicedExist.length > 0) {
      const returnVal = {
        ...product,
        maxLimit:
          cardPicedExist.length > 0 ? parseInt(cardPicedExist[0].quantity) : 0,
      };
      console.log(returnVal);
      productcarrier.push(returnVal);
    }
  });
  console.log(productcarrier);
  /* data props */
  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemLongWrapper>
          <ModuleSubTitle>Choose your desired greeting cards</ModuleSubTitle>
        </ModuleFormItemLongWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}

        {productcarrier.map((card) => {
          let classactive = "";
          if (productName === card.productId) {
            classactive = productcardsMap[productName];
            PRODUCTOBJECT["quantity"]["maxLimit"] = currMaxLimit;
          }

          return (
            <ModuleFormItemWrapper>
              <ModulePaperWrapper>
                <div
                  onClick={() => {
                    setProductName(card.productId);
                    setQuantityChange(!quantityChange);
                  }}
                  className={
                    "productSelect " +
                    "productSelect-active-" +
                    // productSelectActiveDf
                    classactive
                  }
                >
                  <img src={card.path} width="100%" />
                </div>
              </ModulePaperWrapper>
            </ModuleFormItemWrapper>
          );
        })}

        {/* <ModuleFormItemWrapper>
          <ModulePaperWrapper>
            <div
              onClick={() => setProductName("df")}
              className={
                "productSelect " +
                "productSelect-active-" +
                productSelectActiveDf
              }
            >
              <img src={CARRIERS.df.image} width="100%" />
            </div>
          </ModulePaperWrapper>
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <ModulePaperWrapper>
            <div
              onClick={() => setProductName("hb")}
              className={
                "productSelect " +
                "productSelect-active-" +
                productSelectActiveHb
              }
            >
              <img src={CARRIERS.hb.image} width="100%" />
            </div>
          </ModulePaperWrapper>
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <ModulePaperWrapper>
            <div
              onClick={() => setProductName("ty")}
              className={
                "productSelect " +
                "productSelect-active-" +
                productSelectActiveTy
              }
            >
              <img src={CARRIERS.ty.image} width="100%" />
            </div>
          </ModulePaperWrapper>
        </ModuleFormItemWrapper> */}
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text {...quantity} />

          <small>Quantity from 0 to {currMaxLimit}</small>
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Buttons.Normal {...actionButton}>Add</Buttons.Normal>
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Buttons.Text {...backButton}>Back</Buttons.Text>
          <Buttons.Text {...nextButton}>Next</Buttons.Text>
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>
    </form>
  );
};

export default index;
