import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

import { GlobalContext } from "../../lib-atomic-context";

const index = (props) => {
  const addressLS = LocalStorage.getLS().billingAddress;
  const infoLS = LocalStorage.getLS().user;
  const { address } = useContext(GlobalContext);

  return (
    <ModuleFormItemLongWrapper>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              <b>Company Details</b>
            </ModuleSubTitle>
            {/* <ModuleTitle>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {infoLS.name}
              </div>
            </ModuleTitle> */}
            <ModuleText>
              <b>{infoLS.name}</b>
            </ModuleText>
            <ModuleText>{addressLS.physicalAddress1}</ModuleText>
            <ModuleText>{addressLS.physicalSuburb}</ModuleText>
            <ModuleText>{addressLS.physicalCity}</ModuleText>
            <ModuleText>{addressLS.physicalPostcode}</ModuleText>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemLongWrapper>
  );
};

export default index;
