const environment = process.env.NODE_ENV;
const project = "Reporting";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: "ACCEPTING RETAILERS",
    prefix: "/corporate/accepting_retailers",
  },
};

export default config;
