import React from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

const productsItem = (product) => {
  console.log(product);
  const total = (product.quantity * product.value).toFixed(2);
  return (
    <View style={[styles.container, styles.itemsWrapper]}>
      <View style={styles.itemContainer}>
        <Text></Text>
      </View>
      <View style={styles.totalContainer}>
        <Text></Text>
      </View>
      <View style={styles.otherContainer}>
        <Text>{product.details.productName}</Text>
      </View>
      <View style={[styles.totalContainer, styles.alignRightContainer]}>
        <Text>${total}</Text>
      </View>
    </View>
  );
};

export default productsItem;
