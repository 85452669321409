import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_PRODUCT_LS: "ADD_PRODUCT_LS",
  ADD_PRODUCT: "ADD_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  ADD_CARRIER: "ADD_CARRIER",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  EDITCANCEL_PRODUCT: "EDITCANCEL_PRODUCT",
  EDITUPDATE_PRODUCT: "EDITUPDATE_PRODUCT",
  DISPLAY_ITEMS: "DISPLAY_ITEMS",
};

const addProductLS = async (state, product) => {
  const productlist = product;
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist },
  };
  return state;
};

const addProduct = async (state, product) => {
  const productlist = [...state.data.productlist, ...product];
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist },
  };
  return state;
};

const addCarrier = async (state, product) => {
  let existing = !state.data ? [] : state.data.productlist;
  let isAdded = false;
  existing.map((i) => {
    if (
      i.product.toString() === product[0].product.toString() &&
      i.value === product[0].value
    ) {
      i.quantity = (
        parseInt(i.quantity) + parseInt(product[0].quantity)
      ).toString();
      isAdded = true;
    }
  });
  const final = !isAdded ? [...state.data.productlist, ...product] : existing;
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist: final },
  };
  return state;
};

const deleteProduct = async (state, product) => {
  const { productIndex } = product;
  const newproduct = state.data.productlist.filter((prod) => {
    if (prod.productIndex !== productIndex) {
      return prod;
    } else {
      if (
        state.data.productlist.length === 2 ||
        state.data.productlist.length === 1
      ) {
        return undefined;
      }
    }
  });
  const productlist = [...newproduct];
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist },
  };
  return state;
};

const editProduct = async (state, product) => {
  const { productIndex } = product;
  const newproduct = state.data.productlist.filter((prod) => prod.productIndex === productIndex);
  const productEditlist = [...newproduct];
  state = {
    ...state,
    isStatus: true,
    isEdit: true,
    expireToken: false,
    data: { productlist: state.data.productlist, productEditList: productEditlist },
  };
  return state;
};

const editCancelProduct = async (state, product) => {
  const newproduct = state.data.productlist;
  const productlist = [...newproduct];
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist },
  };
  return state;
};

const editUpdateProduct = async (state, product, productIndex) => {
  const filteredProductList = state.data.productlist.filter(item => item.productIndex !== productIndex)
  const productlist = [...product, ...filteredProductList];
  state = {
    ...state,
    isStatus: true,
    isEdit: false,
    expireToken: false,
    data: { productlist },
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_LS:
      return addProductLS(state, action.product);
    case actionTypes.ADD_PRODUCT:
      return addProduct(state, action.product);
    case actionTypes.ADD_CARRIER:
      return addCarrier(state, action.product);
    case actionTypes.DELETE_PRODUCT:
      return deleteProduct(state, action.product);
    case actionTypes.EDIT_PRODUCT:
      return editProduct(state, action.product);
    case actionTypes.EDITCANCEL_PRODUCT:
      return editCancelProduct(state, action.product);
    case actionTypes.EDITUPDATE_PRODUCT:
      return editUpdateProduct(state, action.product, action.productIndex);
    default:
    return state;
  }
};

const register = {
  init,
  actionTypes,
  reducer,
};

export default register;
