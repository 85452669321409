import React, { useContext, useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Recaptcha from "react-recaptcha";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { FormHook, LocalStorage } from "../../lib-atomic-functions";
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2 } from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";

/* packages */

/* stores */
import { AuthLogin } from "../../app-module-admin-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.api.objects;
const ENDPOINTS = CONFIG.application.account.api.endpoints;
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: "json",
};
const MEMBERENQUIRYCONFIG = {
  url: ENDPOINTS.memberenquiry.url,
  method: ENDPOINTS.memberenquiry.method,
  headers: ENDPOINTS.memberenquiry.headers,
  responseType: "json",
};
const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified;
const INACTIVEUSERRESPONSE = CONFIG.application.account.responses.inactiveuser;
/* CONSTANTS */

const login = (props) => {
  console.log(props);
  const { token, memberenquiry } = useContext(GlobalContext);
  // const { token } = LocalStorage.getLS().login;
  /* state */
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  /* --- token */
  const [fetchTokenObj] = FetchContextApiV2(LOGINOBJECTSCONFIG);
  const fetchTokenExecute = async ({ body }) => {
    token.dispatch({
      type: token.actionTypes.FETCH_TOKEN,
      token: await fetchTokenObj({ body }, token),
    });
  };
  /* --- token */

  /* --- member enquiry */
  const [fetchMemberEnquiryObj] = FetchContextApiV2(MEMBERENQUIRYCONFIG);
  const fetchMemberEnquiryExecute = async ({ newheaders }) => {
    memberenquiry.dispatch({
      type: memberenquiry.actionTypes.FETCH_MEMBER_ENQUIRY,
      memberenquiry: await fetchMemberEnquiryObj({ newheaders }, memberenquiry),
    });
  };
  /* --- member enquiry */

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleLogin = async () => {
    setLoaded(true);
    if (verified) {
      var body = {
        username: values.username.toLowerCase(),
        password: values.password,
      };
      await fetchTokenExecute({ body });
    }
  };

  useEffect(() => {
    if (token.state.data) {
      if (!token.state.isStatus) {
        return;
      }
      const { tokenobject } = token.state.data;
      const newheaders = {
        ...MEMBERENQUIRYCONFIG.headers,
        // Authorization: `Bearer ${tokenobject.token}`
        Authorization: tokenobject.token,
      };
      fetchMemberEnquiryExecute({ newheaders });
    }
  }, [token.state]);

  useEffect(() => {
    if (memberenquiry.state.data) {
      const { tokenobject } = token.state.data;
      const { memberenquiryobj } = memberenquiry.state.data;
      const { statusId } = memberenquiryobj.client;
      console.log("TESTING ... REDIRECT... 2", memberenquiryobj.client);

      if (statusId !== undefined && [1, 4].includes(statusId)) {
        // statusId = 1 means the user has been activated
        LocalStorage.addLS("login", { ...tokenobject });
        LocalStorage.addLS("user", { ...memberenquiryobj.client });
        LocalStorage.addLS("guest", false);
        if (props.location.state) {
          if (props.location.state.orderIdPassed) {
            props.history.push("/corporate/order/sucess", {
              orderid: props.location.state.orderIdPassed,
            });
          } else if (props.location.state.returnUrl) {
            props.history.push(props.location.state.returnUrl);
          } else {
            props.history.push("/corporate");
          }
        } else {
          props.history.push("/corporate");
        }
      }
    }
  }, [memberenquiry.state]);

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  );

  const username = {
    ...LOGINOBJECTS.username,
    error: error.username,
    value: values.username,
    onChange,
  };
  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
  };
  const submitButton = { size: "medium" };
  const failLogin = { error: [LOGINFAILRESPONSE] };
  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };
  const inactiveUser = { error: [INACTIVEUSERRESPONSE] };

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Log In</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          {token.state &&
            token.state.data &&
            token.state.data.tokenobject.outcomeCode && (
              <Fragment>
                <Invalid {...failLogin} />
                <br />
              </Fragment>
            )}
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
              <br />
            </Fragment>
          )}
          {memberenquiry.state &&
            memberenquiry.state.data &&
            memberenquiry.state.data.memberenquiryobj.client.statusId === 3 && (
              <Fragment>
                <Invalid {...inactiveUser} />
                <br />
              </Fragment>
            )}
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper></ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Email {...username} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper></ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Password {...password} />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper></ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <div style={{ alignItems: "flex-start", paddingLeft: 15 }}>
          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
        <ModuleFormItemWrapper></ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <p
        onClick={() => {
          props.history.push("/corporate/forgot_password/request");
        }}
        style={{
          justifyContent: "center",
          color: "#0645AD",
          cursor: "pointer",
          paddingTop: 10,
          paddingLeft: 5,
        }}
      >
        Forgot Password?
      </p>
      <br />
      <ModuleFormWrapper>
        <ModuleFormItemShortWrapper>
          <Buttons.Normal {...submitButton}>Log In</Buttons.Normal>
        </ModuleFormItemShortWrapper>
      </ModuleFormWrapper>
    </form>
  );
};

export default login;
