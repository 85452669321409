import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_CHECKOUT: "FETCH_CHECKOUT",
};

const fetchCheckout = async (state, checkout) => {
  state = {
    ...state,
    isStatus: checkout.status,
    expireToken: sanitizeExpire({
      status: checkout.status,
      data: checkout.data,
      name: "checkoutobject",
    }),
    data: sanitizeData({
      status: checkout.status,
      data: checkout.data,
      name: "checkoutobject",
    }),
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT:
      return fetchCheckout(state, action.checkout);
    default:
      return state;
  }
};

const checkout = {
  init,
  actionTypes,
  reducer,
};

export default checkout;
