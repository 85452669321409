import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";
import appConf from "../../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];

const actionTypes = {
  SET_PRODUCT_BREAK: "SET_PRODUCT_BREAK",
};

const setFilter = ({ products, cat }) => {
  return products.filter((prod) => prod.subclassId === cat);
};

const setMapper = ({ cards, carriers }) => {
  console.log(cards, carriers);
  const MappedCards = [];
  // mapper for uat
  // const mapper = {
  //   380172: 724988,
  //   724986: 724989,
  //   724985: 724987,
  // };
  // const mapper = {
  //   380172: 724988,
  //   724984: 724986,
  //   724985: 724987,
  // };
  const mapper = setup.setup.reducerMapper;

  cards.map((card, index) => {
    const mapped_carrier_id = mapper[card.productId];
    const carrier_id = carriers[index].productId;
    if (carrier_id === mapped_carrier_id) {
      MappedCards.push({
        card,
        carrier: carriers[index],
        carriernone: carriers[3],
      });
    }
  });
  return MappedCards;
};
const setProductBreak = async (state, products) => {
  const cards = setFilter({ products, cat: 3 });
  const carriers = setFilter({ products, cat: 2 });
  const couriers = setFilter({ products, cat: 5 });
  const mapped = setMapper({ cards, carriers });
  console.log(mapped, "mappedmappedmappedmapped");
  const data = {
    cards,
    carriers,
    couriers,
    mapped,
  };
  state = {
    ...state,
    data,
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCT_BREAK:
      return setProductBreak(state, action.products);
    default:
      return state;
  }
};

const productbreak = {
  init,
  actionTypes,
  reducer,
};

export default productbreak;
