const moduleHandler = (LocalStorage, roles) => {
  const { login, info } = LocalStorage.getLS();
  const info2 = !info ? { flHeadOffice: 0 } : info;
  if (!login) LocalStorage.addLS("login", { username: "guest" });
  LocalStorage.addLS("info", info2);
  const { flHeadOffice } = info2;
  return roles[flHeadOffice];
};

export default moduleHandler;
