const isMaxTotalLS = (data) => {
  let value = parseFloat(data.value);
  const config = data.config;
  const limit = config.isMaxTotalLimitLS;
  const suffix = config.suffix;
  const totalValue = config.totalValue;
  const overallValue = totalValue;
  return overallValue <= limit
    ? true
    : "overall must not exceed " +
        suffix +
        parseFloat(limit).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
};

export default isMaxTotalLS;
