import React from 'react';

/* mui  */
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
/* mui  */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const stepper = (props) => {

  /* mui */
  const classes = useStyles();
  /* mui */
  
  /* data props */
  const { steps, activeStep, children } = props;
  /* data props */

  return <Stepper activeStep={activeStep} orientation="vertical">
    {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel>
          {/* label */}
          <Typography variant="subtitle1" className={classes.title}>
          { label }
          </Typography>
          {/* label */}
        </StepLabel>
        <StepContent>
          {/* content */}
          { children }
          {/* content */}
        </StepContent>
      </Step>
    ))}
  </Stepper>
}

export default stepper;
