import { createContext } from 'react';
import { decorate, observable, computed } from 'mobx';

export class Store {

  loading = false;
  data = false;
  status = true;
  expireToken = false;

  setLoading = ({loading, status, data}) => {
    this.loading = loading;
    this.status = status;
    this.data = status ? this.sortData(data) : false;
    this.expireToken = data.json.status === 203 ? true : false;
  }

}

decorate(Store, {
  loading: observable,
  data: observable,
  status: observable,
  expireToken: observable,
})


export default createContext(new Store())
