import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { LocalStorage, FetchContextApiV2 } from "../../lib-atomic-functions";

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import { GlobalContext } from "../../lib-atomic-context";

import CONFIG from "../config";

const ENDPOINTS = CONFIG.application.config.api.endpoints;
const NZBNKEYCONFIG = {
  url: ENDPOINTS.nzbnkey.url,
  method: ENDPOINTS.nzbnkey.method,
  headers: ENDPOINTS.nzbnkey.headers,
  responseType: "json",
};

const index = (props) => {
  const isNormUser = LocalStorage.getLS().user;
  const login = LocalStorage.getLS().login;
  const tokenLS = login.token;
  if (isNormUser && tokenLS) {
    return <Redirect to="/corporate/account_profile/update" />;
  }

  // NO NEED FOR NZBN API KEY CALL - TO BE REMOVED
  // const { nzbnkey } = useContext(GlobalContext);

  // /* --- nzbn key */
  // const [fetchNzbnKey] = FetchContextApiV2(NZBNKEYCONFIG);
  // const fetchNzbnKeyExe = async ({}) => {
  //   nzbnkey.dispatch({
  //     type: nzbnkey.actionTypes.FETCH_NZBN_KEY,
  //     nzbnkey: await fetchNzbnKey({}, nzbnkey),
  //   });
  // };
  // /* --- nzbn key */

  // useEffect(() => {
  //   // const query = "?Environment=SANDBOX";
  //   fetchNzbnKeyExe({});
  // }, []);

  // // useEffect(() => {
  // //   if (nzbnkey.state.data) {
  // //     console.log(nzbnkey);
  // //   }
  // // }, [nzbnkey]);

  // const isNzbnKeyReady =
  //   !nzbnkey.state.isLoading &&
  //   nzbnkey.state.data &&
  //   nzbnkey.state.data.nzbnkeyobj &&
  //   nzbnkey.state.isStatus
  //     ? true
  //     : false;

  // if (!isNzbnKeyReady) return <div>sample</div>;

  return props.children;
};

export default index;
