import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2, LocalStorage } from "../../lib-atomic-functions";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const BUYNOWENDPOINTS = CONFIG.application.activation.api.endpoints;
const REGISTERACTIVATIONCONFIG = {
  url: BUYNOWENDPOINTS.account_activation.url,
  method: BUYNOWENDPOINTS.account_activation.method,
  headers: BUYNOWENDPOINTS.account_activation.headers,
  responseType: "json"
};
/* CONSTANTS */

const index = props => {
  const activationToken = props.match.params.activationToken;
  /* state */
  /* -------------- */
  /* --- registration activation */
  /* -------------- */

  const [fetchRegisterActivation] = FetchContextApiV2(REGISTERACTIVATIONCONFIG);
  const { registeractivation } = useContext(GlobalContext);
  const execProductAll = async () => {
    registeractivation.dispatch({
      type: registeractivation.actionTypes.ADD_REGISTER_ACTIVATION,
      registeractivation: await fetchRegisterActivation(
        { query: null, body: { token: activationToken } },
        registeractivation
      )
    });
  };

  useEffect(() => {
    // fetch local storage and put it inside context
    execProductAll();
  }, []);
  /* -------------- */
  /* --- registration activation */
  /* state */

  const isRegisterActivation = registeractivation.state.data ? true : false;

  if (!isRegisterActivation) return null;

  return props.children;
};

export default index;
