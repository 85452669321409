import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

/* module */
import CMSRegisterHeader from "../../app-module-cms-section-home-header/default";
import ForgotPasswordContainer from "../../app-module-forgot-password-container/default";
import SuccessPopUp from "../popup";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSRegisterHeader />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ForgotPasswordContainer {...props}>
            <SuccessPopUp {...props} />
          </ForgotPasswordContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
