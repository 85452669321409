import { useState, useEffect } from "react";

import Validation from "../form-validation";

const Initial = (config) => {
  const initial = {};
  Object.keys(config).map((data, index) => {
    initial[data] = config[data].initial;
  });
  return initial;
};

const useFormHook = (callback, config) => {
  // debugger;

  const [values, setValues] = useState(Initial(config));
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      callback();
      setIsSubmit(false);
    }
  }, [errors, values, isSubmit]);

  const handleFirstSubmit = () => {
    // check if this is ok  ////////////////////////////////
    const { dataError, dataset } = Validation.multiValidation(
      config,
      values,
      errors,
      values
    );
    setErrors(dataError);
    ////////////////////////////////////////////////////////
  };
  const handleReset = (initial) => {
    setValues(Initial(initial));
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const { dataError, dataset } = Validation.multiValidation(
      config,
      values,
      errors,
      values
    );
    setErrors(dataError);
    setIsSubmit(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    const { dataError, dataset } = Validation.singleValidation(
      config,
      { [event.target.name]: event.target.value },
      errors,
      values
    );
    setErrors(dataError);
    setIsSubmit(false);
  };

  const handlePick = (name, value) => {
    //event.persist();
    setValues((values) => ({ ...values, [name]: value }));
    const { dataError, dataset } = Validation.singleValidation(
      config,
      { [name]: value },
      errors,
      values
    );
    setErrors(dataError);
    setIsSubmit(false);
  };

  const handleCheck = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
    const { dataError, dataset } = Validation.singleValidation(
      config,
      { [event.target.name]: event.target.checked },
      errors,
      values
    );
    setErrors(dataError);
    setIsSubmit(false);
  };

  const handleValue = (key, value) => {
    setValues((values) => ({ ...values, [key]: value }));
    const { dataError, dataset } = Validation.singleValidation(
      config,
      { [key]: value },
      errors
    );
    setErrors(dataError);
    setIsSubmit(false);
  };

  return {
    // addressChange:handleChange2,
    onFirstSub: handleFirstSubmit,
    onReset: handleReset,
    onChange: handleChange,
    onPick: handlePick,
    onCheck: handleCheck,
    onSubmit: handleSubmit,
    handleValue,
    values,
    setValues,
    error: errors,
  };
};

export default {
  useFormHook,
};
