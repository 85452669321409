import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import { GlobalContext } from "../../lib-atomic-context";
import {
  LocalStorage,
  FetchContextApiV2,
  FormHook,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleText,
  ModuleTextSmall,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
  ModuleTextProductTotal,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const OBJECTS = CONFIG.application.onlineorder.api.objects;
const ORDERDETAILSENDPOINTS = CONFIG.application.onlineorder.api.endpoints;
const ORDERDETAILCONFIG = {
  url: ORDERDETAILSENDPOINTS.orderdetail.url,
  method: ORDERDETAILSENDPOINTS.orderdetail.method,
  headers: ORDERDETAILSENDPOINTS.orderdetail.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const tokenLS = LocalStorage.getLS().login.token;
  ORDERDETAILCONFIG.headers.Authorization = tokenLS;
  const [fetchOrderDetail] = FetchContextApiV2(ORDERDETAILCONFIG);
  const { transactions, transactionsdetails, productall } =
    useContext(GlobalContext);
  const { transactionsobj } = transactions.state.data;
  const { orders } = transactionsobj;
  const [filtered, setFiltered] = useState([]);
  const execReorder = async (id) => {
    const query = "/" + id;
    transactionsdetails.dispatch({
      type: transactionsdetails.actionTypes.FETCH_TRANSACTIONS_DETAILS,
      transactionsdetails: await fetchOrderDetail(
        { query },
        transactionsdetails,
      ),
    });
  };
  const execTransactionDetail = async (id) => {
    props.history.push("/corporate/account_profile/transactions/detail", {
      orderid: id,
    });
  };

  useEffect(() => {
    // filtered with status activated
    setFiltered(orders.filter((item) => item.orderStatusId === 5));
    // setFiltered(orders);
  }, []);

  useEffect(() => {
    if (transactionsdetails.state.data.transactionsdetailobj) {
      let fees = false;
      const detailProducts =
        transactionsdetails.state.data.transactionsdetailobj.detail;
      const allProducts = productall.state.data.productallobj.products;
      detailProducts.map((data, index) => {
        fees =
          data.subClassId > 4
            ? allProducts.filter(
                (product) => data.productId === product.productId,
              )[0]
            : false;
        const details = allProducts.filter(
          (product) => data.productId === product.productId,
        );
        detailProducts[index] = {
          ...detailProducts[index],
          image: details[0].path,
          index,
        };
      });
      const cleanDetailProduct = detailProducts.filter(
        (data) => data.subClassId < 5,
      );

      const reorderedProducts = [];
      cleanDetailProduct.map((item) => {
        if (item.subClassId === 3) {
          const productIndex = Date.now();
          const filtered = cleanDetailProduct.filter(
            (itemf) =>
              itemf.quantity === item.quantity && itemf.subClassId === 2,
          )[0];
          item["productIndex"] = productIndex;
          filtered["productIndex"] = productIndex;
          let carrieroption = "0";
          if (filtered.productId === 509370) carrieroption = "1";
          item["carrieroption"] = carrieroption;
          item["classification"] = "card";
          filtered["carrieroption"] = carrieroption;
          filtered["classification"] = "carrier";
          reorderedProducts.push(item);
          reorderedProducts.push(filtered);
        }
      });
      LocalStorage.addLS("fees", fees);
      console.log(reorderedProducts);
      LocalStorage.addLS("products", reorderedProducts);
      props.history.push("/corporate/order/items");
    }
  }, [transactionsdetails]);

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(() => {},
  OBJECTS);

  useEffect(() => {
    if (values.search) {
      console.log(values.search, orders);
      setFiltered(
        orders.filter((i) =>
          i.id.toString().includes(values.search.toString()),
        ),
      );
    }
    // setFiltered(orders);
  }, [values]);

  const search = {
    ...OBJECTS.search,
    error: error.search,
    value: values.search,
    onChange,
  };

  /* data props */
  const reorderButton = {
    size: "small",
  };
  /* data props */

  const filteredOrders = filtered.filter((item) => item.orderStatusId === 5)  

  return (
    // <form onSubmit={onSubmit} noValidate>
    <Fragment>
      <ModuleFormItemLongWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...search} />
        </ModuleFormItemWrapper>
      </ModuleFormItemLongWrapper>

      {filteredOrders && filteredOrders.length === 0 ? (
        <ModuleFormItemWrapper>
          <ModuleText>
            <b style={{ padding: 20 }}>No Transactions Found</b>
          </ModuleText>
        </ModuleFormItemWrapper>
      ) : null}
      {filteredOrders &&
        filteredOrders.map((order) => {
          return (
            <ModuleFormItemWrapper>
              <ModulePaperWrapper>
                <ModuleFormWrapper>
                  <ModuleFormItemWrapper>
                    <ModuleText>
                      ORDER #: <b>{order.id}</b>
                    </ModuleText>
                    <ModuleText>
                      Total: <b>${order.totalAmount}</b>
                    </ModuleText>
                    <ModuleText>
                      Status:{" "}
                      <b>
                        {order.paymentType === 23 &&
                        order.orderStatusName === "ONHOLD"
                          ? "Awaiting Approval"
                          : order.orderStatusName}
                      </b>
                    </ModuleText>
                    <div style={{ height: 15 }} />
                    {/* <Buttons.Normal
                      {...reorderButton}
                      onClick={() => {
                        execReorder(order.id);
                      }}
                    >
                      Re-Order
                    </Buttons.Normal> */}
                    {/* <div style={{ width: 150, display: "inline" }} /> */}
                    <Buttons.Normal
                      {...reorderButton}
                      onClick={() => {
                        execTransactionDetail(order.id);
                      }}
                    >
                      View Detail
                    </Buttons.Normal>
                  </ModuleFormItemWrapper>
                </ModuleFormWrapper>
              </ModulePaperWrapper>
            </ModuleFormItemWrapper>
          );
        })}
    </Fragment>
    // </form>
  );
};

export default index;
