import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold,
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */
import QueryString from "query-string";

/* module */
import CMSActivateHeader from "../../app-module-cms-section-activate-header/default";
import CardActivationContainer from "../../app-module-card-activation-container/default";
/* module */

const index = (props) => {
  // const parsed = QueryString.parse(props.location.search);
  // const { session_id } = parsed;
  const { user, login } = LocalStorage.getLS();
  console.log(props);
  const { orderdetail, stripepayment } = useContext(GlobalContext);

  const handleClearLS = () => {
    LocalStorage.addLS("products", false);
    LocalStorage.addLS("payment", false);
    LocalStorage.addLS("fees", false);
    LocalStorage.addLS("notes", false);

    const { guest } = LocalStorage.getLS();
    if (guest) {
      LocalStorage.addLS("guest", false);
      LocalStorage.addLS("tempGuestCheckoutBody", false);
      LocalStorage.addLS("login", { username: "guest" });
    }
  };
  useEffect(() => {
    handleClearLS();
  }, []);

  const handleBack = () => {
    // handleClearLS();
    props.history.push("/corporate/order");
  };
  const handleDetail = () => {
    props.history.push("/corporate/account_profile/transactions/detail", {
      orderid: orderdetail.state.data.orderdetailobj.id,
    });
  };
  /* data props */
  const backButton = { size: "medium", onClick: handleBack };
  const detailButton = { size: "medium", onClick: handleDetail };
  console.log(orderdetail);
  return (
    <ModuleFormItemWrapper>
      <ModulePaperWrapper>
        {/* <ModuleTitle>Order has been proccessed</ModuleTitle> */}
        <ModuleText>
          Your order number{" "}
          <b>
            {orderdetail.state.data
              ? orderdetail.state.data.orderdetailobj.id
                ? orderdetail.state.data.orderdetailobj.id
                : stripepayment.state.data &&
                  stripepayment.state.data.stripepaymentobj
                ? stripepayment.state.data.stripepaymentobj.merchantRef
                : "N/A"
              : stripepayment.state.data &&
                stripepayment.state.data.stripepaymentobj
              ? stripepayment.state.data.stripepaymentobj.merchantRef
              : "N/A"}
          </b>{" "}
          {orderdetail.state &&
          orderdetail.state.data &&
          orderdetail.state.data.orderdetailobj.paymentType === 22
            ? "is currently awaiting payment. You will receive an order confirmation email, once payment is processed."
            : "has been successfully proccessed."}
        </ModuleText>
        <br />
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <div style={{ display: "flex" }}>
              <Buttons.Normal {...backButton}>BACK</Buttons.Normal>
              <div style={{ padding: 5 }} />
              {user &&
                login &&
                orderdetail.state.data &&
                orderdetail.state.data.orderdetailobj.id && (
                  <Buttons.Normal {...detailButton}>VIEW DETAIL</Buttons.Normal>
                )}
            </div>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      </ModulePaperWrapper>
    </ModuleFormItemWrapper>
  );
};

export default index;
