import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Main from "./layout/main";

const App = (props) => {
  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/corporate" component={Main} />
      </Route>
      <Route path="/corporate/:module" component={Main} />
      <Route exact path="/:module" component={Main} />
      <Route path="/:module">
        <Redirect to="/corporate" component={Main} />
      </Route>

      {/* <ScrollToTop /> */}
    </Switch>
  );
};

export default App;
