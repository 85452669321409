import React, { Fragment } from "react";
import moment from "moment";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import styles from "./styles";

import CONFIG from "../../../config";

const body = ({ order, company, contact }) => {
  console.log(order, company, contact);
  const date = moment(order.createDate).format("DD/MM/YYYY");
  const time = moment(order.createDate).format("h:mm:ss a");
  return (
    <Fragment>
      <View style={[styles.container, styles.titleWrapper]}>
        <View style={styles.titleContainer}>
          <View style={styles.columnDirection}>
            <Text
              style={[
                styles.SectionTitleMain,
                { paddingTop: 3, paddingBottom: 0 },
              ]}
            >
              QUOTE
            </Text>
            <Text>GST No: 57-630-167</Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "70%",
                marginTop: 15,
              }}
            >
              {/* key */}
              <Text style={[styles.defaultBold, { width: "40%" }]}>
                Order Date:
              </Text>
              {/* value */}
              <Text style={{ width: "60%" }}>
                {date} {time}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "70%",
                marginTop: 7,
              }}
            >
              {/* key */}
              <Text style={[styles.defaultBold, { width: "40%" }]}>
                Order Number:
              </Text>
              {/* value */}
              <Text style={{ width: "60%" }}>{order.id}</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "70%",
                marginTop: 7,
              }}
            >
              {/* key */}
              <Text style={[styles.defaultBold, { width: "40%" }]}>
                Purchaser:
              </Text>
              {/* value */}
              <Text style={{ width: "70%" }}>
              {company.firstName} {company.lastName}
              </Text>
            </View>

            <View
              style={{ display: "flex", flexDirection: "row", width: "70%" }}
            >
              {/* value */}
              <Text style={{ width: "40%", marginLeft: "40%" }}>
                {order.clientName}
              </Text>
            </View>
            <View
              style={{ display: "flex", flexDirection: "row", width: "70%" }}
            >
              {/* value */}
              <Text style={{ width: "40%", marginLeft: "40%" }}>
                {order.physicalAddress1}
              </Text>
            </View>
            <View
              style={{ display: "flex", flexDirection: "row", width: "70%" }}
            >
              {/* value */}
              <Text style={{ width: "40%", marginLeft: "40%" }}>
                {order.physicalSuburb}
              </Text>
            </View>
            <View
              style={{ display: "flex", flexDirection: "row", width: "70%" }}
            >
              {/* value */}
              <Text style={{ width: "40%", marginLeft: "40%" }}>
                {order.physicalCity} {order.physicalPostCode}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              marginTop: 7,
            }}
          >
            {/* key */}
            <Text style={[styles.defaultBold, { width: "40%" }]}>
              Customer PO:
            </Text>
            {/* value */}
            <Text style={{ width: "70%" }}>
              {order.flCorporate.toString() === "1"
                ? order.notes && order.notes !== "false" && order.notes
                : order.purchaseOrder}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              marginTop: 7,
            }}
          >
            {/* key */}
            <Text style={[styles.defaultBold, { width: "40%" }]}>Email:</Text>
            {/* value */}
            <Text
              style={{
                width: "60%",
                color: "#4da0db",
                textDecoration: "underline",
              }}
              target="_blank"
              // onClick={() => {
              //   window.open = "https://www.google.com";
              // }}
            >
              {company.emailAddress}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              marginTop: 7,
            }}
          >
            {/* key */}
            <Text
              style={[styles.defaultBold, { width: "40%", marginBottom: 10 }]}
            >
              Purchase Information:
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default body;
