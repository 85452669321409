import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleSubNote,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CARRIERS = CONFIG.setup.assets.carriers;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_fees;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  const FeesLS = LocalStorage.getLS().fees ? true : false;
  /* state */
  const [isFees, setIsFees] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- fees */
  /* -------------- */
  const { productall, fees } = useContext(GlobalContext);
  const execFeesAdd = (feesselected) => {
    fees.dispatch({
      type: fees.actionTypes.ADD_FEES,
      fees: feesselected,
    });
  };
  useEffect(() => {
    // fetch local storage and put it inside context
    const FeesLS = LocalStorage.getLS().fees;
    if (FeesLS) {
      execFeesAdd({ feesoption: FeesLS });
    }
  }, []);
  useEffect(() => {
    if (fees.state.data && isTriggerCreate) {
      LocalStorage.addLS("fees", fees.state.data);
      setIsTriggerCreate(false);
    }
  }, [fees]);
  useEffect(() => {
    if (FeesLS) {
      setIsFees(FeesLS);
    }
  }, [FeesLS]);
  /* -------------- */
  /* --- fees */
  /* state */

  const handleCreate = async () => {
    const fees = productall.state.data.productallobj.products.filter(
      (product) => product.productId.toString() === values.feesoption
    )[0];
    const item = { ...fees };
    console.log(item);
    // const productcourier = productall.state.data.productallobj.products.filter(
    //   product => product.subclassId === 5
    // );
    // await createMethod({body: JSON.stringify(values)});
    execFeesAdd(item);
    LocalStorage.addLS("fees", item);
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
  };

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    PRODUCTOBJECT
  );

  // /* data props */
  // const product = {
  //   ...PRODUCTOBJECT.product,
  //   error: error.product,
  //   value: values.product,
  //   onChange
  // };
  // const value = {
  //   ...PRODUCTOBJECT.value,
  //   error: error.value,
  //   value: values.value,
  //   onChange
  // };
  const productcourier = productall.state.data.productallobj.products
    .filter(
      (product) =>
        product.subclassId === 5 && product.productId.toString() !== "725011"
    )
    .sort((a, b) => (b.value - a.value ? 1 : -1));
  const option = productcourier.map((product) => {
    return {
      name: product.productName + " " + product.description,
      value: product.productId.toString(),
    };
  });
  console.log(option);
  PRODUCTOBJECT.feesoption.initial = option[0].value;
  PRODUCTOBJECT.feesoption.options = option;
  const paymentoption = {
    ...PRODUCTOBJECT.feesoption,
    error: error.feesoption,
    value: values.feesoption,
    onChange,
  };
  const actionButton = { size: "large" };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              Please select your preferred courier from the option below.
            </ModuleSubTitle>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Inputs.Select {...paymentoption} />
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubNote>
              ** Standard delivery 2-3 business days. Tracking details provided.
              NOTE: You may have your identity verified before your gift cards
              are dispatched, which may affect delivery times{" "}
            </ModuleSubNote>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Buttons.Normal {...actionButton}>Add</Buttons.Normal>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Buttons.Text {...backButton}>Back</Buttons.Text>
            {isFees && <Buttons.Normal {...nextButton}>Next</Buttons.Normal>}
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
