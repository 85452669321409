import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../lib-atomic-context";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubNote,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.config.objects;
const ENDPOINTS = CONFIG.application.config.api.endpoints;
const REGISTERCONFIG = {
  url: ENDPOINTS.register.url,
  method: ENDPOINTS.register.method,
  headers: ENDPOINTS.register.headers,
  responseType: "json",
};
const TOKENCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: "json",
};
const SEARCHCONFIG = {
  url: ENDPOINTS.search.url,
  method: ENDPOINTS.search.method,
  headers: ENDPOINTS.search.headers,
  responseType: "json",
};
const SEARCHADDYCONFIG = {
  url: ENDPOINTS.searchaddy.url,
  method: ENDPOINTS.searchaddy.method,
  headers: ENDPOINTS.searchaddy.headers,
  responseType: "json",
};
const SEARCHADDYWITHIDCONFIG = {
  url: ENDPOINTS.searchaddywithid.url,
  method: ENDPOINTS.searchaddywithid.method,
  headers: ENDPOINTS.searchaddywithid.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.config.responses.unverified;
const EMAILALREADYEXISTMSG = CONFIG.application.config.responses.emailexist;
const TANDCACCEPTMSG = CONFIG.application.config.responses.tandcaccept;
const TRADERALREADYEXIST = CONFIG.application.config.responses.traderexist;
const MUSTSELECTMULTIREFERRAL =
  CONFIG.application.config.responses.mustSelectReferral;

/* CONSTANTS */

const register = ({ props }) => {
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [hideNzbn, setHideNzbn] = useState(false);
  const [nzbnSelected, setNzbnSelected] = useState(false);
  const [nzbnChanged, setNzbnChanged] = useState(false);

  const [resetNzbnSearch, setResetNzbnSearch] = useState(false);

  const [hideBilling, setHideBilling] = useState(false);
  const [addySelected, setAddySelected] = useState(false);
  const [addyChanged, setAddyChanged] = useState(false);
  const [addressAssigned, setAddressAssigned] = useState(false);

  const [resetAddySearch, setResetAddySearch] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);

  const [otherReferral, otherReferralSet] = useState(false);
  const [multiReferral, multiReferralSet] = useState([]);
  const handleReferralAdd = useCallback(
    (selectedItem) => {
      multiReferralSet([...new Set([...multiReferral, selectedItem])]);
    },
    [multiReferral, multiReferralSet]
  );
  const handleReferralRemove = useCallback(
    (selectedItem) => {
      // [...new Set(arr)];
      multiReferralSet([
        ...multiReferral.filter((item) => item !== selectedItem),
      ]);
    },
    [multiReferral, multiReferralSet]
  );

  const classes = useStyles();

  const { token, register, nzbnsearch, nzbnkey, addysearch, addysearchwithid } =
    useContext(GlobalContext);

  /* --- login token */
  const [fetchToken] = FetchContextApiV2(TOKENCONFIG);
  const fetchTokenExecute = async ({ body }) => {
    token.dispatch({
      type: token.actionTypes.FETCH_TOKEN,
      token: await fetchToken({ body }, token),
    });
  };
  /* --- login token */

  /* --- nzbn */
  const [fetchNzbnSearch] = FetchContextApiV2(SEARCHCONFIG);
  const fetchNzbnSearchExecute = async ({ query, newheaders }) => {
    nzbnsearch.dispatch({
      type: nzbnsearch.actionTypes.FETCH_NZBN,
      nzbnsearch: await fetchNzbnSearch({ query, newheaders }, nzbnsearch),
    });
  };
  /* --- nzbn */

  /* --- addy */
  const [fetchAddySearch] = FetchContextApiV2(SEARCHADDYCONFIG);
  const fetchAddySearchExecute = async ({ query, newheaders }) => {
    addysearch.dispatch({
      type: addysearch.actionTypes.FETCH_ADDRESS,
      addysearch: await fetchAddySearch({ query, newheaders }, addysearch),
    });
  };
  /* --- addy */

  /* --- addy with id */
  const [fetchAddySearchWithId] = FetchContextApiV2(SEARCHADDYWITHIDCONFIG);
  const fetchAddySearchWithIdExecute = async ({ query, newheaders }) => {
    addysearchwithid.dispatch({
      type: addysearchwithid.actionTypes.FETCH_ADDRESS_WITH_ID,
      addysearchwithid: await fetchAddySearchWithId(
        { query, newheaders },
        addysearchwithid
      ),
    });
  };
  /* --- addy with id */

  /* --- register */
  const [fetchRegister] = FetchContextApiV2(REGISTERCONFIG);
  const fetchRegisterExecute = async ({ body }) => {
    register.dispatch({
      type: register.actionTypes.FETCH_REGISTER,
      register: await fetchRegister({ body }, register),
    });
  };
  const resetRegisterExecute = () => {
    register.dispatch({
      type: register.actionTypes.RESET,
    });
  };
  /* --- register */

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleCreate = async (e) => {
    // console.log(textInput);
    // textInput.current.focus();
    setLoaded(true);
    if (verified && values.tandc) {
      setWarningMessage("");
      setWarningMessageVisible(false);
      const referralStr = [
        multiReferral.filter((item) => item !== "Other (Please specify)"),
        values.referralSourceOther,
      ]
        .filter((item) => item !== "")
        .join(", ");

      if (multiReferral.length < 3) return;

      values.referralSource = referralStr;
      // const referralOther =
      //   values.referralSource === "Other (Please specify)"
      //     ? ` - ${values.referralSourceOther}`
      //     : "";

      var body = {
        name: values.companyname,
        externalId: values.nzbn,
        firstName: values.firstname,
        lastName: values.lastname,
        phone1: values.contactno,
        mobileNumber: values.mobileno,
        companyDetail: values.industrytype,
        titleId: null,
        mailPref: values.gdpr ? 1 : 0,
        emailAddress: values.email,
        password: values.password,
        physicalAddress1: values.address1,
        physicalAddress2: "",
        physicalSuburb: values.suburb,
        physicalCity: values.city,
        physicalState: "",
        physicalPostcode: values.postcode,
        referralSource: values.referralSource,
      };

      await fetchRegisterExecute({ body });
    }
  };

  const handleCancel = async () => {
    props.history.push("/corporate/config");
  };
  const handleAddyClick = async (entity) => {
    const newheaders = {
      ...SEARCHADDYWITHIDCONFIG.headers,
      // "x-api-key": nzbnkey.state.data.nzbnkeyobj.accessToken,
      "x-api-key": "910da42d-d6c4-4d9b-a4c8-fe1a57f9dfaf",
    };
    await fetchAddySearchWithIdExecute({
      query: "?id=" + entity.id,
      newheaders,
    });
  };
  const {
    values,
    onFirstSub,
    onReset,
    onCheck,
    onChange,
    onPasswordChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONFIGOBJECT);

  // useEffect(() => {
  //   if (token.state.data) {
  //     if (!token.state.isStatus) props.history.push("/corporate/u/token_expire");
  //     const { tokenobject } = token.state.data;
  //     LocalStorage.addLS("login", { ...tokenobject });

  //     var body = {
  //       name: values.companyname,
  //       externalId: values.nzbn,
  //       firstName: values.firstname,
  //       lastName: values.lastname,
  //       phone1: values.contactno,
  //       mobileNumber: values.mobileno,
  //       titleId: 1,
  //       mailPref: values.gdpr ? 1 : 0,
  //       emailAddress: values.email,
  //       physicalAddress1: values.address1,
  //       physicalAddress2: "",
  //       physicalSuburb: values.suburb,
  //       physicalCity: "",
  //       physicalState: "",
  //       physicalPostcode: values.postcode
  //     };
  //     LocalStorage.addLS("user", { ...body });
  //     console.log(tokenobject);
  //     props.history.push("/corporate/register/success");
  //   }
  // }, [token.state]);

  useEffect(() => {
    if (register.state.data) {
      if (!register.state.isStatus) {
        const { registerdetail } = register.state.data;
        // console.log(registerdetail);
        if (
          registerdetail.outcomeCode
            .split("↵")
            .join("")
            .split("\n")
            .join("") === "WSF:CRT_CORP_MMB:5" &&
          registerdetail.outcomeDeveloperMessage ===
            "This email address is already registered"
        ) {
          setWarningMessage("The email already exist");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else if (
          registerdetail.outcomeCode
            .split("↵")
            .join("")
            .split("\n")
            .join("") === "WSF:CRT_CORP_MMB:3" &&
          registerdetail.outcomeDeveloperMessage ===
            "Invalid email address format"
        ) {
          setWarningMessage("Invalid email address format");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else if (
          registerdetail.outcomeCode.replace(/[\r\n]+/gm, "") ===
          "WSF:CRT_CORP_MMB:6WSF:INS_MMB:7TRD:INSTRD:10"
        ) {
          setWarningMessage("NZBN already registered");
          resetRegisterExecute({});
          setWarningMessageVisible(true);
        } else {
          resetRegisterExecute({});
          props.history.push("/corporate/register/fail");
        }
      } else {
        props.history.push("/corporate/register/success");
      }
    }
  }, [register.state]);

  useEffect(() => {
    if (!nzbnSelected) setNzbnChanged(true);
    if (nzbnChanged) {
      const newheaders = {
        ...SEARCHCONFIG.headers,
        // "x-api-key": nzbnkey.state.data.nzbnkeyobj.accessToken,
        "x-api-key": "910da42d-d6c4-4d9b-a4c8-fe1a57f9dfaf",
      };
      fetchNzbnSearchExecute({
        // query: "?search-term=" + values.nzbn,
        // query: `nzbn.tr4ns.com/entities/search?searchTerm=Tech&pageSize=10&pageNumber=1`
        query: `?searchTerm=${values.nzbn}&pageSize=10&pageNumber=0`,
        newheaders,
      });
    }
  }, [values.nzbn]);

  useEffect(() => {
    if (resetNzbnSearch) {
      setResetAddySearch(false);
      nzbnsearch.dispatch({
        type: nzbnsearch.actionTypes.RESET,
      });
    }
  }, [resetNzbnSearch]);

  useEffect(() => {
    if (!addyChanged) setAddyChanged(true);
    if (addyChanged && values.address1.replace(" ", "").toString().length > 1) {
      const newheaders = {
        ...SEARCHADDYCONFIG.headers,
        // "x-api-key": nzbnkey.state.data.nzbnkeyobj.accessToken,
        "x-api-key": "910da42d-d6c4-4d9b-a4c8-fe1a57f9dfaf",
      };
      fetchAddySearchExecute({
        query: "?s=" + values.address1,
        newheaders,
      });
    }
  }, [hideBilling, values.address1]);

  useEffect(() => {
    if (resetAddySearch) {
      setResetAddySearch(false);
      addysearchwithid.dispatch({
        type: addysearchwithid.actionTypes.RESET,
      });
      addysearch.dispatch({
        type: addysearch.actionTypes.RESET,
      });
    }
  }, [resetAddySearch]);

  useEffect(() => {
    if (addysearchwithid.state.data) {
      const { addysearchwithidobj } = addysearchwithid.state.data;
      console.log("Test", addysearchwithidobj);
      values.address1 = addysearchwithidobj.displayline;
      values.suburb = addysearchwithidobj.suburb;
      values.city = addysearchwithidobj.city;
      values.postcode = addysearchwithidobj.postcode;
      setAddressAssigned(true);
    }
  }, [addysearchwithid]);

  useEffect(() => {
    if (addressAssigned) {
      setAddressAssigned(false);
      setAddyChanged(false);
      setAddySelected(true);
    }
  }, [addressAssigned]);

  useEffect(() => {
    if (multiReferral.length < 3 && values["referralSource"]) {
      setWarningMessage("Please select 3 referal!");
      setWarningMessageVisible(true);
    } else {
      setWarningMessageVisible(false);
    }
  }, [multiReferral, values]);

  const { nzbndetail } = nzbnsearch.state.data;
  const { addysearchobj } = addysearch.state.data;

  const checkResult = () => {
    return !nzbndetail ||
      nzbndetail.items === undefined ||
      nzbndetail.length < 1
      ? false
      : true;
  };
  const checkAddressSearchResult = () => {
    return !addysearchobj || addysearchobj.length < 1 ? false : true;
  };

  /* data props */
  const companyname = {
    ...CONFIGOBJECT.companyname,
    inputRef: useRef(null),
    error: error.companyname,
    value: values.companyname,
    onChange,
  };
  const nzbn = {
    ...CONFIGOBJECT.nzbn,
    inputRef: useRef(null),
    autoComplete: "off",
    error: error.nzbn,
    value: values.nzbn,
    onChange,
  };
  const firstname = {
    ...CONFIGOBJECT.firstname,
    inputRef: useRef(null),
    error: error.firstname,
    value: values.firstname,
    onChange,
  };
  const lastname = {
    ...CONFIGOBJECT.lastname,
    inputRef: useRef(null),
    error: error.lastname,
    value: values.lastname,
    onChange,
  };
  const contactno = {
    ...CONFIGOBJECT.contactno,
    inputRef: useRef(null),
    error: error.contactno,
    value: values.contactno,
    onChange,
  };
  const mobileno = {
    ...CONFIGOBJECT.mobileno,
    inputRef: useRef(null),
    error: error.mobileno,
    value: values.mobileno,
    onChange,
  };
  const jobtitle = {
    ...CONFIGOBJECT.jobtitle,
    inputRef: useRef(null),
    error: error.jobtitle,
    value: values.jobtitle,
    onChange,
  };
  const gdpr = {
    ...CONFIGOBJECT.gdpr,
    inputRef: useRef(null),
    error: error.gdpr,
    value: values.gdpr,
    onChange: onCheck,
  };

  const industrytype = {
    ...CONFIGOBJECT.industrytype,
    error: error.industrytype,
    value: values.industrytype,
    onChange,
  };

  const referralSource = {
    ...CONFIGOBJECT.referralSource,
    error: error.referralSource,
    value: values.referralSource,
    onChange,
  };

  const referralSourceOther = {
    ...CONFIGOBJECT.referralSourceOther,
    error: error.referralSourceOther,
    value: values.referralSourceOther,
    onChange,
  };

  const email = {
    ...CONFIGOBJECT.email,
    inputRef: useRef(null),
    error: error.email,
    value: values.email,
    onChange,
  };
  const password = {
    ...CONFIGOBJECT.password,
    inputRef: useRef(null),
    error: error.password,
    value: values.password,
    onChange,
  };
  const passwordconfirm = {
    ...CONFIGOBJECT.passwordconfirm,
    inputRef: useRef(null),
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
  };

  const address1 = {
    ...CONFIGOBJECT.address1,
    inputRef: useRef(null),
    autoComplete: "off",
    error: error.address1,
    value: values.address1,
    onChange,
  };
  const suburb = {
    ...CONFIGOBJECT.suburb,
    inputRef: useRef(null),
    error: error.suburb,
    value: values.suburb,
    onChange,
  };
  const city = {
    ...CONFIGOBJECT.city,
    inputRef: useRef(null),
    error: error.city,
    value: values.city,
    onChange,
  };
  // const state = {
  //   ...CONFIGOBJECT.state,
  //   error: error.state,
  //   value: values.state,
  //   onChange
  // };
  const postcode = {
    ...CONFIGOBJECT.postcode,
    inputRef: useRef(null),
    error: error.postcode,
    value: values.postcode,
    onChange,
  };

  const tandc = {
    ...CONFIGOBJECT.tandc,
    inputRef: useRef(null),
    error: error.tandc,
    value: values.tandc,
    onChange: onCheck,
  };
  const formObj = {
    companyname,
    nzbn,
    firstname,
    lastname,
    contactno,
    mobileno,
    industrytype,
    jobtitle,
    gdpr,
    email,
    password,
    passwordconfirm,
    address1,
    suburb,
    city,
    postcode,
    tandc,
  };
  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };
  const warningMsg = { error: [EMAILALREADYEXISTMSG] };
  const warningMsgReferral = { error: [MUSTSELECTMULTIREFERRAL] };
  const warningMsgTAndCAccept = { error: [TANDCACCEPTMSG] };
  const warningMsgTraderExist = { error: [TRADERALREADYEXIST] };
  const submitButton = {
    size: "medium",
    onClick: () => {
      onFirstSub();
      const fieldNameList = Object.keys(error);
      const firstErrorField =
        fieldNameList.length > 0 ? fieldNameList[0] : null;
      if (firstErrorField !== null) {
        formObj[firstErrorField].inputRef.current.focus();
      }
      // textInput.current.focus();
    },
  };
  const submitDisabledButton = {
    size: "medium",
    // disabled: true,
    onClick: () => {
      onFirstSub();
      const fieldNameList = Object.keys(error);
      const firstErrorField =
        fieldNameList.length > 0 ? fieldNameList[0] : null;
      if (firstErrorField !== null) {
        formObj[firstErrorField].inputRef.current.focus();
      }
      if (!values["tandc"]) {
        setWarningMessageVisible(true);
        setWarningMessage("Terms and Conditions should be agreed");
      }
      if (!values["referralSource"]) {
        if (multiReferral.length < 3) {
          setWarningMessage("Please select 3 referal!");
          setWarningMessageVisible(true);
        } else {
          setWarningMessageVisible(false);
        }
      }
      // textInput.current.focus();
    },
  };
  const cancelButton = { size: "medium", onClick: handleCancel };
  const nzbnSearchClick = (e, entity) => {
    // values.nzbn = entity.nzbn;
    e.target.name = "nzbn";
    e.target.value = entity.nzbn;
    nzbn.onChange(e);
    setResetNzbnSearch(true);
    setNzbnChanged(false);
    setNzbnSelected(true);
    setHideNzbn(true);
  };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Company Details</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...companyname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...nzbn}
            onFocus={() => {
              setHideNzbn(false);
              setNzbnChanged(true);
              setNzbnSelected(false);
              setResetNzbnSearch(true);
            }}
            onBlur={() => {
              setNzbnSelected(true);
            }}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
          {values.nzbn.length > 12 &&
          !hideNzbn &&
          (nzbnChanged || !nzbnSelected) ? (
            <Paper className={classes.paper} square>
              {!checkResult() || nzbnsearch.state.isLoading ? (
                <CircularProgress
                  className={classes.throbber}
                  size={30}
                  thickness={5}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  top: -30,
                  right: 2,
                  backgroundColor: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
                onClick={() => {
                  setHideNzbn(true);
                  setResetNzbnSearch(true);
                }}
              >
                <CloseIcon
                  style={{
                    color: "black",
                    fontSize: 25,
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </div>
              {checkResult() &&
                nzbnsearch.state.isStatus &&
                !nzbnsearch.state.isLoading &&
                nzbnsearch.state.data.nzbndetail &&
                nzbnsearch.state.data.nzbndetail.items !== undefined &&
                nzbnsearch.state.data.nzbndetail.items.map((entity, index) => {
                  return (
                    <MenuItem
                      key={entity.nzbn}
                      component="div"
                      style={{
                        fontWeight: 400,
                      }}
                      onClick={(e) => nzbnSearchClick(e, entity)}
                    >
                      {entity.entityName}({entity.nzbn})
                    </MenuItem>
                  );
                })}
              {checkResult() &&
              !nzbnsearch.state.isLoading &&
              nzbnsearch.state.data.nzbndetail &&
              nzbnsearch.state.data.nzbndetail.items.length === 0 ? (
                <MenuItem
                  component="div"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  No matching NZBN found
                </MenuItem>
              ) : null}
            </Paper>
          ) : null}
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...firstname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...lastname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...contactno}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>

        <ModuleFormItemWrapper>
          <Inputs.Text
            {...mobileno}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>

        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Select {...industrytype} />
        </ModuleFormItemWrapper>

        <ModuleFormItemWrapper>
          <Inputs.MultiSelect
            {...{
              ...referralSource,
              disabled:
                multiReferral.length > 2 ||
                (multiReferral.length < 3 &&
                  [multiReferral].includes("Other (Please specify)"))
                  ? true
                  : false,
            }}
            onChange={(e) => {
              referralSource.onChange(e);
              handleReferralAdd(e.target.value);
            }}
            style={{ zIndex: 100 }}
          />
          <div>
            {warningMessageVisible &&
              warningMessage === "Please select 3 referal!" && (
                <Fragment>
                  <Invalid {...warningMsgReferral} />
                </Fragment>
              )}
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {multiReferral.map((item, index) => {
              return (
                <span
                  key={item}
                  style={{
                    padding: "5px 10px 5px 10px",
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: 20,
                  }}
                >
                  {item}
                  <span
                    onClick={() => handleReferralRemove(item)}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    x
                  </span>
                </span>
              );
            })}
          </div>
        </ModuleFormItemWrapper>

        <ModuleFormItemWrapper>&nbsp;</ModuleFormItemWrapper>

        {multiReferral.includes("Other (Please specify)") ? (
          <ModuleFormItemWrapper>
            <Inputs.Text
              {...referralSourceOther}
              style={{ marginTop: -10, marginBottom: 3 }}
            />
          </ModuleFormItemWrapper>
        ) : null}

        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Account Details</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Email
            {...email}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemLongWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.PasswordValidate
            {...password}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.PasswordValidate
            {...passwordconfirm}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <ModuleSubNote>
            The password must be minimum of 10 character and contain a mix of
            upper case, lower case letters, special characters and numbers.
          </ModuleSubNote>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Billing Details</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Text
            {...address1}
            onFocus={() => {
              setAddyChanged(false);
              setHideBilling(true);
              setAddySelected(false);
              setResetAddySearch(true);
            }}
            onBlur={() => {
              setAddySelected(true);
            }}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
          {values.address1.length > 0 &&
          hideBilling &&
          (addyChanged || !addySelected) ? (
            <Paper className={classes.paper2} square>
              {!checkAddressSearchResult() || addysearch.state.isLoading ? (
                <CircularProgress
                  className={classes.throbber}
                  size={30}
                  thickness={5}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  top: -30,
                  right: 2,
                  backgroundColor: "white",
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
                onClick={() => {
                  if (addySelected) {
                    setHideBilling(false);
                    setAddySelected(false);
                    setResetAddySearch(true);
                  }
                }}
              >
                <CloseIcon
                  style={{
                    color: "black",
                    fontSize: 25,
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </div>
              {checkAddressSearchResult() &&
                !addysearch.state.isLoading &&
                addysearch.state.data.addysearchobj &&
                addysearch.state.data.addysearchobj.addresses.map(
                  (entity, index) => {
                    return (
                      <MenuItem
                        key={entity.id}
                        component="div"
                        style={{
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          setHideBilling(false);
                          setAddySelected(false);
                          setAddyChanged(true);
                          handleAddyClick(entity);
                        }}
                      >
                        {entity.a}
                      </MenuItem>
                    );
                  }
                )}
              {checkAddressSearchResult() &&
              !addysearch.state.isLoading &&
              addysearch.state.data.addysearchobj &&
              addysearch.state.data.addysearchobj.addresses.length === 0 ? (
                <MenuItem
                  component="div"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  No matching Address found
                </MenuItem>
              ) : null}
            </Paper>
          ) : null}
        </ModuleFormItemLongWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...suburb}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text {...city} style={{ marginTop: -10, marginBottom: 3 }} />
        </ModuleFormItemWrapper>

        <ModuleFormItemShortWrapper>
          <Inputs.Text
            {...postcode}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemShortWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemLongWrapper>
          <Grid container>
            <Inputs.Checkbox {...tandc} />
            <ModuleSubTitle>
              &nbsp;&nbsp;I accept the{" "}
              <a
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3898ff",
                }}
                onClick={() => {
                  window.open(
                    "https://www.westfield.co.nz/terms-and-conditions#westfield-gift-card-terms-and-conditions",
                    "_blank"
                  );
                }}
              >
                Terms and Conditions
              </a>
              {/* <span> and </span>
              <a
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3898ff",
                }}
                onClick={() => {
                  window.open(
                    "https://www.westfield.co.nz/privacy-policy#scentre-group-privacy-policies",
                    "_blank"
                  );
                }}
              >
                Privacy Policy
              </a> */}
            </ModuleSubTitle>
          </Grid>

          {/* line */}
          <Grid container>
            <Inputs.Checkbox {...gdpr} />
            <ModuleSubTitle>
              &nbsp;&nbsp;I’d like to receive Westfield marketing offers
            </ModuleSubTitle>
          </Grid>
          {/* line */}
        </ModuleFormItemLongWrapper>
        {/* line */}

        <div className="mt-4 mb-4">
          The personal information you provide as part of this process will be
          used to set up and administer a Corporate Gift Card retail account.
          Additionally, we may also use your information to inform you of
          information and offers we believe may be of interest to you. You can
          opt out of this by emailing giftcardsnzcorporate@scentregroup.com.
          General information about how Scentre Group handles personal
          information can be viewed in its privacy policy here -{" "}
          <a
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3898ff",
            }}
            onClick={() => {
              window.open(
                "https://www.westfield.co.nz/privacy-policy",
                "_blank"
              );
            }}
          >
            {" "}
            https://www.westfield.co.nz/privacy-policy
          </a>
        </div>

        <div style={{ alignSelf: "left" }}>
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
            </Fragment>
          )}
          {warningMessageVisible &&
            warningMessage === "The email already exist" && (
              <Fragment>
                <Invalid {...warningMsg} />
                <br />
              </Fragment>
            )}
          {warningMessageVisible &&
            warningMessage === "Invalid email address format" && (
              <Fragment>
                <Invalid {...warningMsg} />
                <br />
              </Fragment>
            )}

          {warningMessageVisible &&
            warningMessage === "Terms and Conditions should be agreed" && (
              <Fragment>
                <Invalid {...warningMsgTAndCAccept} />
                <br />
              </Fragment>
            )}

          {warningMessageVisible &&
            warningMessage === "NZBN already registered" && (
              <Fragment>
                <Invalid {...warningMsgTraderExist} />
                <br />
              </Fragment>
            )}

          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
      </ModuleFormWrapper>
      <br />
      <br />
      {verified && loaded && values["tandc"] ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>REGISTER</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>REGISTER</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default register;
