import React, { useState, useContext, Fragment, useEffect } from "react";
import { GlobalContext } from "../../lib-atomic-context";

import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import Recaptcha from "react-recaptcha";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleText,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Contact } from "../../app-module-contact-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.contact.objects;
const CONTACTENDPOINTS = CONFIG.application.contact.api.endpoints;
const SENDCONTACTFORM = {
  url: CONTACTENDPOINTS.sendcontactform.url,
  method: CONTACTENDPOINTS.sendcontactform.method,
  headers: CONTACTENDPOINTS.sendcontactform.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.contact.responses.unverified;
/* CONSTANTS */

const register = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { token } = LocalStorage.getLS().login;

  const { sendcontactus } = useContext(GlobalContext);
  /* order detail */
  const [fetchSendContactForm] = FetchContextApiV2(SENDCONTACTFORM);
  const fetchSendContactFormExecute = async ({ body }) => {
    sendcontactus.dispatch({
      type: sendcontactus.actionTypes.FETCH_SEND_CONTACT_US,
      sendcontactus: await fetchSendContactForm({ body }, sendcontactus),
    });
  };

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };
  useEffect(() => {
    if (confirmMessage !== "") {
      setConfirmMessage("");
    }
  }, []);
  const handleCreate = async (e) => {
    console.log(values);
    // await createMethod({body: JSON.stringify(values)});
    // onReset(CONFIGOBJECT);
    const body = {
      name: values.yourname,
      email: values.emailaddress,
      phone: values.phonenumber,
      subject: values.subject,
      message: values.message,
      action: "111020101",
    };
    await fetchSendContactFormExecute({ body });
  };
  useEffect(() => {
    if (sendcontactus.state.data) {
      if (!sendcontactus.state.isStatus) {
        // error handle
        setConfirmMessage("Sorry, your message could not be delivered");
      } else {
        setConfirmMessage("Your Message has been successfully sent");
      }

      onReset(CONFIGOBJECT);
    }
  }, [sendcontactus.state]);

  // useEffect(() => {
  //   if (confirmMessage.length !== 0) {
  //     setTimeout(() => {
  //       setConfirmMessage("");
  //     }, 5000);
  //   }
  // }, [confirmMessage]);

  const handleCancel = async () => {
    props.history.push("/corporate");
  };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    CONFIGOBJECT
  );

  /* data props */
  const yourname = {
    ...CONFIGOBJECT.yourname,
    error: error.yourname,
    value: values.yourname,
    onChange,
  };
  const phonenumber = {
    ...CONFIGOBJECT.phonenumber,
    error: error.phonenumber,
    value: values.phonenumber,
    onChange,
  };
  const emailaddress = {
    ...CONFIGOBJECT.emailaddress,
    error: error.emailaddress,
    value: values.emailaddress,
    onChange,
  };
  const subject = {
    ...CONFIGOBJECT.subject,
    error: error.subject,
    value: values.subject,
    onChange,
  };
  const message = {
    ...CONFIGOBJECT.message,
    error: error.message,
    value: values.message,
    onChange,
  };

  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  const submitButton = { size: "medium" };
  const submitDisabledButton = { size: "medium", disabled: true };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          Call the Gift Card Help Line on:
          <br />
          0800 WESTFIELD or 0800 937 834
          <br />
          Between 10am - 5pm Mon - Fri (excluding Public Holidays)
          <br />
          <br />
          Or contact us using the form below.
          <br />
          <br />{" "}
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        {confirmMessage.length > 0 ? (
          <ModuleFormItemLongWrapper>
            <p
              style={{
                padding: 8,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 8,
                color: "white",
                display: "inline-block",
                backgroundColor:
                  sendcontactus &&
                  sendcontactus.state &&
                  sendcontactus.state.isStatus
                    ? "#15c39a"
                    : "red",
              }}
            >
              {confirmMessage}
            </p>
          </ModuleFormItemLongWrapper>
        ) : null}
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...yourname}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...phonenumber}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...emailaddress}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...subject}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Text
            {...message}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemLongWrapper>
        {/* line */}

        <div style={{ alignSelf: "left", paddingLeft: 10 }}>
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
            </Fragment>
          )}

          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
      </ModuleFormWrapper>

      {verified && loaded ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>SEND MESSAGE</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>
              SEND MESSAGE
            </Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default register;
