import React from "react";

import { PDFDownloadLink, pdf } from "@react-pdf/renderer";

/* module */
import PDFDocument from "./document";
/* module */

const PDFLink = async props => {
  const { pdfFilename, pdfPreviewData, pdfPreviewSize } = props;
  const myDoc = <PDFDocument {...pdfPreviewData} />;
  const blob = await pdf(myDoc).toBlob();
  return blob;
};

export default PDFLink;
