import React from "react";

/* mui */
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
import { Skeletons } from "../../lib-atomic";
/* mui */

/* packages */
import { WindowSize, WindowLayout } from "../../lib-atomic-functions";
/* packages */

/* constant */
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  }
}));
/* constant */

const index = props => {
  const classes = useStyles();

  const { width, height } = WindowSize();
  const {
    layoutWidth,
    layoutHeight,
    layoutCols,
    getColsWidth,
    getColsHeight
  } = WindowLayout(width, height);

  const boxes = [
    { width: 3, height: 2 },
    { width: 2, height: 1 },
    { width: 1, height: 1 },
    { width: 1, height: 1 },
    { width: 2, height: 1 }
  ];

  return (
    <div className={classes.root}>
      <GridList
        style={{ width: layoutWidth + "px" }}
        cellHeight={layoutHeight}
        cols={layoutCols}
      >
        <ModuleContainerLg>
          <ModulePaddedWrapperLg>
            <Skeletons.Xlarge />
            <Skeletons.Xlarge />
            <Skeletons.Xlarge />
            <Skeletons.Xlarge />
            <Skeletons.Large />
            <Skeletons.Medium />
            <Skeletons.Large />
          </ModulePaddedWrapperLg>
        </ModuleContainerLg>
      </GridList>
    </div>
  );
};

export default index;
