import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

/* packages */
import Privilege from '../app-conf-privilege';
import { LocalStorage } from '../lib-atomic-functions';
/* packages */

const Routes = props => {
  const { rolesHandler, rolesModules, rolesSubModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableModules = rolesHandler(LocalStorage, rolesSubModules);
  return availableModules.home.map((Module, i) => {
    return <Module.Routes key={i} />
  });
}
export default Routes;
