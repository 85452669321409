import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  ADD_PROMOCODE: "ADD_PROMOCODE"
};

const addPromoCode = async (state, promocode) => {
  const promoCode = promocode;
  state = {
    ...state,
    isStatus: true,
    expireToken: false,
    data: promoCode
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_PROMOCODE:
      return addPromoCode(state, action.promocode);
    default:
      return state;
  }
};

const promocode = {
  init,
  actionTypes,
  reducer
};

export default promocode;
