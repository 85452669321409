import React from "react";

/* mui */
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  smallTitle: {
    fontSize: 20,
    fontWeight: 700,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
}));
/* CONSTANTS */

const title = (props) => {
  /* mui */
  const classes = useStyles();
  /* mui */

  return (
    <Typography className={classes.smallTitle} variant="body2">
      {props.children}
    </Typography>
  );
};

export default title;
