import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import ForgotPasswordContainer from "../../app-module-forgot-password-container/default";
/* container */

/* module */
import CMSRegisterHeader from "../../app-module-cms-section-home-header/default";
import ForgotPasswordSubmitForm from "../form/updateProfile";
// import AccountActivationPopUp from "../../app-module-forgot-password-page-popup/default";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSRegisterHeader />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ForgotPasswordContainer {...props}>
            <ForgotPasswordSubmitForm {...props} />
          </ForgotPasswordContainer>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
