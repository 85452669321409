import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { WindowSize } from "../../lib-atomic-functions";
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleSmallTitle,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
import OrderContainer from "../../app-module-order-container/default";
/* container */

/* module */
import CMSBuynowHeader from "../../app-module-cms-section-buynow-header/default";
import OrderForm from "../../app-module-order-form-order/default";
import OrderList from "../../app-module-order-section-list/default";
import CarrierSelected from "../../app-module-order-section-carrier/default";
import OrderInfo from "../../app-module-order-section-info/default";
import FeesMethod from "../../app-module-order-section-fees/default";
import OrderTotal from "../../app-module-order-section-total/default";
import PaymentMethod from "../../app-module-order-section-payment/default";
import Notes from "../../app-module-order-section-notes/default";
import PaymentReason from "../../app-module-order-section-payment-reason/default";
import Checkout from "../../app-module-order-section-checkout/default";
import Company from "../../app-module-order-section-company/default";
import DeliveryAddress from "../../app-module-order-section-deliveryAddress/default";
import PromoCode from "../../app-module-order-page-promo-code/default";
/* module */

const index = (props) => {
  const { width, height } = WindowSize();
  return (
    <GlobalProvider>
      {/* <CMSContainer>
        <CMSBuynowHeader />
      </CMSContainer> */}
      <OrderContainer {...props}>
        <ModuleContainerLg>
          <ModulePaddedWrapperLg>
            <ModuleFormWrapper>
              <ModuleFormItemWrapper>
                <OrderForm {...props} />
              </ModuleFormItemWrapper>

              <ModuleFormItemWrapper>
                <div
                  style={{
                    height: 1,
                    margin: 0,
                    marginTop: width > 960 ? 0 : 10,
                    marginBottom: width > 960 ? 14 : 20,
                    backgroundColor: width > 960 ? "transparent" : "#bbb",
                  }}
                />
                <ModuleSmallTitle>
                  <b>Order Summary</b>
                </ModuleSmallTitle>
                <Company {...props} />
                <br />
                <OrderList {...props} />
                <br />
                <DeliveryAddress {...props} />
                <br />
                <CarrierSelected {...props} />
                <br />
                <FeesMethod {...props} />
                <br />
                <PromoCode {...props} />
                <br />
                <OrderTotal {...props} />
                <br />
                <PaymentMethod {...props} />
                <br />
                <Notes {...props} />
                <br />
                <PaymentReason {...props} />
                <br />
                <Checkout {...props} />
              </ModuleFormItemWrapper>
            </ModuleFormWrapper>
          </ModulePaddedWrapperLg>
        </ModuleContainerLg>
      </OrderContainer>
    </GlobalProvider>
  );
};

export default index;
