import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSContactHeader from "../../app-module-cms-section-contact-header/default";
import ContactForm from "../../app-module-contact-form-contact/default";
/* module */

const index = observer(props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSContactHeader />
      </CMSContainer>

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ContactForm {...props} />
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
});

export default index;
