import React, { useContext, useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../lib-atomic-context";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  WindowSize,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubNote,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.config.objects;
const ENDPOINTS = CONFIG.application.config.api.endpoints;
const FORGOTPASSWORDUPDATECONFIG = {
  url: ENDPOINTS.forgotpasswordsubmitupdate.url,
  method: ENDPOINTS.forgotpasswordsubmitupdate.method,
  headers: ENDPOINTS.forgotpasswordsubmitupdate.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.config.responses.unverified;
/* CONSTANTS */

const updateProfile = (props) => {
  const { width, height } = WindowSize();

  const updateToken = props.match
    ? props.match.params
      ? props.match.params.activationToken
      : ""
    : "";
  const login = LocalStorage.getLS().login;

  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);

  const { forgotpasswordupdate } = useContext(GlobalContext);

  /* --- update */
  const [fetchForgotPasswordUpdate] = FetchContextApiV2(
    FORGOTPASSWORDUPDATECONFIG
  );
  const fetchForgotPasswordUpdateExecute = async ({ body }) => {
    forgotpasswordupdate.dispatch({
      type: forgotpasswordupdate.actionTypes.FORGOT_PASSWORD_UPDATE,
      forgotpasswordupdate: await fetchForgotPasswordUpdate(
        { body },
        forgotpasswordupdate
      ),
    });
  };
  /* --- update */

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleCreate = async (e) => {
    setLoaded(true);
    if (verified) {
      console.log(values);
      var body = {
        password: values.password,
        token: updateToken,
      };
      await fetchForgotPasswordUpdateExecute({ body });
    }
  };
  const {
    values,
    onReset,
    onCheck,
    onChange,
    onPasswordChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONFIGOBJECT);

  useEffect(() => {
    if (forgotpasswordupdate.state.data) {
      const isSuccessfullyUpdated =
        forgotpasswordupdate.state.data &&
        forgotpasswordupdate.state.data.forgotpasswordupdateobj.outcomeCode ===
          "0"
          ? true
          : false;
      const isAlreadyUsed =
        forgotpasswordupdate.state.data &&
        forgotpasswordupdate.state.data.forgotpasswordupdateobj.outcomeCode ===
          "AUTH:FP_NEW_PASS:8"
          ? true
          : false;
      const isAlreadyExpired =
        forgotpasswordupdate.state.data &&
        forgotpasswordupdate.state.data.forgotpasswordupdateobj.outcomeCode ===
          "AUTH:FP_NEW_PASS:7"
          ? true
          : false;
      props.history.push("/corporate/forgot_password/confirm", {
        isSuccessfullyUpdated,
        isAlreadyUsed,
        isAlreadyExpired,
      });
    }
  }, [forgotpasswordupdate.state]);

  /* data props */
  const password = {
    ...CONFIGOBJECT.password,
    error: error.password,
    value: values.password,
    onChange,
  };
  const passwordconfirm = {
    ...CONFIGOBJECT.passwordconfirm,
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
  };

  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  const submitButton = { size: "medium" };
  const submitDisabledButton = { size: "medium", disabled: true };
  /* data props */

  return (
    <form
      style={{
        width: width < 500 ? "100%" : width < 1300 ? "70%" : "60%",
        padding: 5,
      }}
      onSubmit={onSubmit}
      noValidate
    >
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Update Password</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Password
            {...password}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Password
            {...passwordconfirm}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleSubNote>
            The password must be a minimum of 10 characters and contain a
            combination of uppercase letters, lowercase letters, numbers and
            symbols.
          </ModuleSubNote>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>
      <ModuleFormWrapper>
        <br />
        <br />
        <div
          style={{
            alignSelf: "left",
            paddingLeft: 10,
          }}
        >
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
            </Fragment>
          )}

          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
      </ModuleFormWrapper>
      <br />
      <br />
      {true ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>Submit</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>Submit</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default updateProfile;
