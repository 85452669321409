import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleSubNote,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CARRIERS = CONFIG.setup.assets.carriers;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_carriers;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const carrierLS = LocalStorage.getLS().carrieroption
    ? LocalStorage.getLS().carrieroption
    : null;
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  /* state */
  const [isCarrier, setIsCarrier] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- fees */
  /* -------------- */
  const { product, productall, fees, productbreak } = useContext(GlobalContext);
  console.log(productbreak.state.data);
  const execFeesAdd = (feesselected) => {
    fees.dispatch({
      type: fees.actionTypes.ADD_FEES,
      fees: feesselected,
    });
  };
  const execProductAdd = (item) => {
    product.dispatch({
      type: product.actionTypes.ADD_PRODUCT,
      product: item,
    });
  };
  // useEffect(() => {
  //   // fetch local storage and put it inside context
  //   const FeesLS = LocalStorage.getLS().fees;
  //   if (FeesLS) {
  //     execFeesAdd({ feesoption: FeesLS });
  //   }
  // }, []);
  useEffect(() => {
    const carrierLS = LocalStorage.getLS().carrieroption
      ? LocalStorage.getLS().carrieroption
      : null;
    if (carrierLS) {
      setIsCarrier(carrierLS);
    }
  }, []);
  useEffect(() => {
    const carrierLS = LocalStorage.getLS().carrieroption
      ? LocalStorage.getLS().carrieroption
      : null;
    if (carrierLS) {
      setIsCarrier(carrierLS);
    } else {
      setIsCarrier(false);
    }
  }, [carrierLS]);
  /* -------------- */
  /* --- fees */
  /* state */

  const handleCreate = async () => {
    console.log("create..", values, product, productbreak.state.data);
    const { productlist } = product.state.data;
    const productIndex = Date.now();
    LocalStorage.addLS("carrieroption", values.carrieroption);
    if (productlist && productlist.length > 0) {
      let finalCarrierList = [];
      productlist.map((productChosen) => {
        const selectedproduct = productbreak.state.data.mapped.filter(
          (product) => product.card.productId === productChosen.product
        )[0];
        if (selectedproduct) {
          const carrier_image = selectedproduct.carrier.path;
          const carrier = selectedproduct.carrier;
          const carriernone_image = selectedproduct.carriernone.path;
          const item_carrier = {
            quantity: productChosen.quantity,
            productIndex,
            product: carrier.productId,
            classification: "carrier",
            image:
              values.carrieroption === "1" ? carrier_image : carriernone_image,
            value: 0,
          };
          finalCarrierList.push(item_carrier);
        }
      });
      console.log(finalCarrierList);
      execProductAdd(finalCarrierList);
    }

    onReset(PRODUCTOBJECT);
  };

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    PRODUCTOBJECT
  );

  // /* data props */
  // const product = {
  //   ...PRODUCTOBJECT.product,
  //   error: error.product,
  //   value: values.product,
  //   onChange
  // };
  // const value = {
  //   ...PRODUCTOBJECT.value,
  //   error: error.value,
  //   value: values.value,
  //   onChange
  // };
  // const productcourier = productall.state.data.productallobj.products
  //   .filter(
  //     (product) =>
  //       product.subclassId === 5 && product.productId.toString() !== "725011"
  //   )
  //   .sort((a, b) => (b.value - a.value ? 1 : -1));
  // const option = productcourier.map((product) => {
  //   return {
  //     name: product.productName + " " + product.description,
  //     value: product.productId.toString(),
  //   };
  // });
  // console.log(option);
  // PRODUCTOBJECT.feesoption.initial = option[0].value;
  // PRODUCTOBJECT.feesoption.options = option;
  // const paymentoption = {
  //   ...PRODUCTOBJECT.feesoption,
  //   error: error.feesoption,
  //   value: values.feesoption,
  //   onChange,
  // };
  const carrieroption = {
    ...PRODUCTOBJECT.carrieroption,
    error: error.carrieroption,
    value: values.carrieroption,
    onChange,
  };
  const actionButton = {
    size: "large",
    onClick: (e) => {
      e.preventDefault();
      handleCreate();
    },
  };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>
              Please select your preferred carrier from the option below.
            </ModuleSubTitle>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Inputs.Select {...carrieroption} />
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Buttons.Normal {...actionButton}>Add</Buttons.Normal>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Buttons.Text {...backButton}>Back</Buttons.Text>
            {isCarrier && <Buttons.Normal {...nextButton}>Next</Buttons.Normal>}
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
