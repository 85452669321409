import React from "react";

import { Skeletons } from "../../lib-atomic";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingBottom: 150
  },
  paper1: {
    height: 440,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#000"
  },
  title: {
    color: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));
const Loader = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Skeletons.CmsHeader />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeletons.CmsHeader />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeletons.CmsHeader />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeletons.CmsHeader />
        </Grid>
      </Grid>
    </div>
  );
};

export default Loader;
