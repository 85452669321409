import React from "react";

/* mui */
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
/* mui */

/* CONSTANTS */
const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: theme.spacing(0),
    marginTop: -10,
    paddingBottom: theme.spacing(0),
    color: theme.palette.text.secondary,
    display: "inline-block",
    paddingLeft: 10,
  },
}));
/* CONSTANTS */

const text = (props) => {
  /* mui */
  const classes = useStyles();
  /* mui */

  return (
    <Typography variant="body2" className={classes.text}>
      {props.children}
    </Typography>
  );
};

export default text;
