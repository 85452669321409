import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* module  */
import GuestInfoForm from '../form/info';
/* module  */

const index = observer((props) => {
  
  /* data props */
  const formData = { ...props }
  /* data props */

  return <GuestInfoForm { ...formData } />

})

export default index;
