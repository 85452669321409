import Text from "./text";
import Email from "./email";
import TextNoBorder from "./textNoBorder";
import Password from "./password";
import PasswordValidate from "./passwordWithValidation";
import Textarea from "./textarea";
import Suggest from "./suggest";
import Checkbox from "./checkbox";
import Select from "./select";
import MultiSelect from "./multi-select";
import Date from "./date";
import DateModal from "./date-modal";

export default {
  Text,
  Email,
  TextNoBorder,
  Password,
  PasswordValidate,
  Textarea,
  Suggest,
  Checkbox,
  Select,
  MultiSelect,
  Date,
  DateModal,
};
