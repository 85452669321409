import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2, LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* module */
import Spinner from "../../app-module-cms-section-layout-spinner/default";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const ENDPOINTS = CONFIG.application.onlineorder.api.endpoints;
const PRODUCTALLCONFIG = {
  url: ENDPOINTS.productall.url,
  method: ENDPOINTS.productall.method,
  headers: ENDPOINTS.productall.headers,
  responseType: "json",
};
const NZBNKEYCONFIG = {
  url: ENDPOINTS.nzbnkey.url,
  method: ENDPOINTS.nzbnkey.method,
  headers: ENDPOINTS.nzbnkey.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const login = LocalStorage.getLS() && LocalStorage.getLS().login;
  if (!login || !login.token) {
    LocalStorage.removeLS();
    return <Redirect to="/corporate/login" />;
  }
  const tokenLS = login.token;

  /* state */
  const { productall, productbreak, nzbnkey } = useContext(GlobalContext);
  /* -------------- */
  /* --- product */
  /* -------------- */
  PRODUCTALLCONFIG.headers.Authorization = tokenLS;
  const [fetchProductAll] = FetchContextApiV2(PRODUCTALLCONFIG);
  const execProductAll = async () => {
    productall.dispatch({
      type: productall.actionTypes.FETCH_PRODUCT_ALL,
      productall: await execProductAllCompound(),
    });
  };
  const execProductBreak = (products) => {
    productbreak.dispatch({
      type: productbreak.actionTypes.SET_PRODUCT_BREAK,
      products,
    });
  };
  const execProductAllCompound = async () => {
    let productAll = [];
    try {
      const cat2 = await fetchProductAll(
        { query: "?categoryId=2" },
        productall,
      );
      const cat3 = await fetchProductAll(
        { query: "?categoryId=3" },
        productall,
      );
      const cat5 = await fetchProductAll(
        { query: "?categoryId=5" },
        productall,
      );
      cat2.data.json.products.push(...cat3.data.json.products);
      cat2.data.json.products.push(...cat5.data.json.products);
      return cat2;
    } catch (e) {
      console.log(e);
      LocalStorage.removeLS();
      return <Redirect to="/corporate/login" />;
    }
    //return productAll;
  };

  /* --- nzbn key */
  const [fetchNzbnKey] = FetchContextApiV2(NZBNKEYCONFIG);
  const fetchNzbnKeyExe = async ({}) => {
    nzbnkey.dispatch({
      type: nzbnkey.actionTypes.FETCH_NZBN_KEY,
      nzbnkey: await fetchNzbnKey({}, nzbnkey),
    });
  };
  /* --- nzbn key */

  if (props.match.path === "/corporate/order/guest/infos") {
    useEffect(() => {
      fetchNzbnKeyExe({});
    }, []);
    const isNzbnKeyReady =
      !nzbnkey.state.isLoading &&
      nzbnkey.state.data &&
      nzbnkey.state.data.nzbnkeyobj &&
      nzbnkey.state.isStatus
        ? true
        : false;
    if (!isNzbnKeyReady) return <Spinner />;
    return props.children;
  } else {
    useEffect(() => {
      execProductAll();
    }, []);

    useEffect(() => {
      if (productall.state.data) {
        if (!productall.state.isStatus) {
          LocalStorage.removeLS();
          return <Redirect to="/corporate/login" />;
        }
        console.log(productall.state.data, "PRODUCT AL");
        LocalStorage.addLS(
          "productall",
          productall.state.data.productallobj.products,
        );
        if (!productbreak.state.data) {
          execProductBreak(productall.state.data.productallobj.products);
        }
      }
    }, [productall]);

    useEffect(() => {
      if (productbreak.state.data) {
        LocalStorage.addLS("productbreak", productbreak.state.data);
      }
    }, [productbreak]);

    /* -------------- */
    /* --- notes */
    /* state */

    const isProductAll = productall.state.data ? true : false;
    const isProductBreak = productbreak.state.data ? true : false;

    if (!isProductAll || !isProductBreak) return null;

    return props.children;
  }
};

export default index;
