import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "cms";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: false,
  routes: {
    label: "CMS",
    prefix: "/corporate/cms/home/header",
  },
  setup,
};

export default config;
