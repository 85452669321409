import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_SEND_CONTACT_US: "FETCH_SEND_CONTACT_US",
  RESET: "RESET",
};
const fetchReset = async (state) => {
  state = {
    ...state,
    isLoading: false,
    isStatus: true,
    expireToken: false,
    data: false,
  };
  return state;
};
const fetchFunc = async (state, sendcontactus) => {
  state = {
    ...state,
    isStatus: sendcontactus.status,
    expireToken: sanitizeExpire({
      status: sendcontactus.status,
      data: sendcontactus.data,
      name: "sendcontactusobj",
    }),
    data: sanitizeData({
      status: sendcontactus.status,
      data: sendcontactus.data,
      name: "sendcontactusobj",
    }),
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEND_CONTACT_US:
      return fetchFunc(state, action.sendcontactus);
    case actionTypes.RESET:
      return fetchReset(state);
    default:
      return state;
  }
};

const index = {
  init,
  actionTypes,
  reducer,
};

export default index;
