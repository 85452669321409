import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";

/* container */
import { GlobalProvider } from "../../lib-atomic-context";
import CMSContainer from "../../app-module-cms-container/default";
import OrderPopUpContainer from "../../app-module-order-popup-container/default";
/* container */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModulePaddedWrapperLg,
  ModulePaperWrapper,
  ModuleTitle,
  ModuleText,
  ModuleTextBold
} from "../../lib-atomic-design-section";
import { Buttons } from "../../lib-atomic";
/* packages */

/* module */
import CMSBuynowHeader from "../../app-module-cms-section-buynow-header/default";
import PopUp from "../popup";
/* module */

const index = props => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSBuynowHeader />
      </CMSContainer>

      <OrderPopUpContainer {...props}>
        <ModuleContainerLg>
          <ModulePaddedWrapperLg>
            <PopUp {...props} />
          </ModulePaddedWrapperLg>
        </ModuleContainerLg>
      </OrderPopUpContainer>
    </GlobalProvider>
  );
};

export default index;
