const isValidData = ({ status, data, name }) => {
  if (!status && !data.json) return { [name]: false };
  return { [name]: data.json };
};

const isValidToken = ({ status, data, name }) => {
  const expireLookup = [203];
  const cleanData = isValidData({ status, data, name });
  if (expireLookup.includes(cleanData[name].status)) return true;
  return false;
};

const SanitizeExec = ({ status, data, name }) => {
  const cleanStatus = status;
  const cleanData = isValidData({ status, data, name });
  const cleanExpireToken = isValidToken({ status, data, name });
  return {
    isStatus: cleanStatus,
    data: cleanData,
    expireToken: cleanExpireToken,
    status: {
      default: cleanStatus,
      expireToken: cleanExpireToken,
    },
  };
};

export default SanitizeExec;
