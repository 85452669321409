import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const CARRIERS = CONFIG.setup.assets.carriers;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_address;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  const isNoteLS = LocalStorage.getLS().notes ? true : false;

  /* state */
  const [isNote, setIsNote] = useState(false);
  const [isTickAddress, isTickAddressSet] = useState(true);
  const [isDeliveryAddress, isDeliveryAddressSet] = useState(true);

  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- notes */
  /* -------------- */
  const { notes, address } = useContext(GlobalContext);

  const execNotesAdd = (customnotes) => {
    notes.dispatch({
      type: notes.actionTypes.ADD_NOTES,
      notes: customnotes,
    });
  };
  useEffect(() => {
    // fetch local storage and put it inside context
    const notesLS = LocalStorage.getLS().notes;
    if (notesLS) {
      execNotesAdd({ companyNamenotes: notesLS });
      execNotesAdd({ streetnotes: notesLS });
      execNotesAdd({ suburb: notesLS });
      execNotesAdd({ city: notesLS });
      execNotesAdd({ postcode: notesLS });
    }
  }, []);
  useEffect(() => {
    if (notes.state.data && isTriggerCreate) {
      LocalStorage.addLS("notes", notes.state.data.companyNamenotes);
      LocalStorage.addLS("notes", notes.state.data.streetnotes);
      LocalStorage.addLS("notes", notes.state.data.suburb);
      LocalStorage.addLS("notes", notes.state.data.city);
      LocalStorage.addLS("notes", notes.state.data.postcode);
      setIsTriggerCreate(false);
    }
  }, [notes]);
  useEffect(() => {
    setIsNote(isNoteLS);
  }, [isNoteLS]);
  /* -------------- */
  /* --- notes */
  /* state */

  const execAddressAdd = (address) => {
    notes.dispatch({
      type: notes.actionTypes.ADD_NOTES,
      address: address,
    });
  };

  const handleCreate = async () => {
    const item = { ...values };
    const userDeliveryAddress = {
      firstname: item.firstname,
      // lastname: item.lastname,
      companyName: item.companyName,
      physicalAddress1: item.street,
      physicalCity: item.city,
      physicalPostcode: item.postcode,
      physicalState: null,
      physicalSuburb: item.suburb,
    };
    LocalStorage.addLS("deliveryAddress", userDeliveryAddress);
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
    isDeliveryAddressSet(true);
    execAddressAdd(userDeliveryAddress);
  };

  const handleTickAddress = useCallback(() => {
    isTickAddressSet(!isTickAddress);
    isDeliveryAddressSet(!isDeliveryAddress);
    if (!isTickAddress) {
      const useruserBillingAddressLS = LocalStorage.getLS().billingAddress;
      const userDeliveryAddress = { ...useruserBillingAddressLS };
      LocalStorage.addLS("deliveryAddress", userDeliveryAddress);
      execAddressAdd(userDeliveryAddress);
    } else {
      const userDeliveryAddress = {
        companyName: "",
        physicalAddress1: "",
        physicalCity: "",
        physicalPostcode: "",
        physicalState: "",
        physicalSuburb: "",
      };
      LocalStorage.addLS("deliveryAddress", userDeliveryAddress);
      execAddressAdd(userDeliveryAddress);
    }
  }, [
    isTickAddress,
    isTickAddressSet,
    isDeliveryAddress,
    isDeliveryAddressSet,
  ]);

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    PRODUCTOBJECT
  );

  /* data props */
  // const product = {
  //   ...PRODUCTOBJECT.product,
  //   error: error.product,
  //   value: values.product,
  //   onChange
  // };
  // const value = {
  //   ...PRODUCTOBJECT.value,
  //   error: error.value,
  //   value: values.value,
  //   onChange
  // };
  const firstname = {
    ...PRODUCTOBJECT.firstname,
    error: error.firstname,
    value: values.firstname,
    onChange,
  };
  // const lastname = {
  //   ...PRODUCTOBJECT.lastname,
  //   error: error.lastname,
  //   value: values.lastname,
  //   onChange,
  // };
  const companyName = {
    ...PRODUCTOBJECT.companyName,
    error: error.companyName,
    value: values.companyName,
    onChange,
  };
  const street = {
    ...PRODUCTOBJECT.street,
    error: error.street,
    value: values.street,
    onChange,
  };
  const suburb = {
    ...PRODUCTOBJECT.suburb,
    error: error.suburb,
    value: values.suburb,
    onChange,
  };
  const postcode = {
    ...PRODUCTOBJECT.postcode,
    error: error.postcode,
    value: values.postcode,
    onChange,
  };
  const city = {
    ...PRODUCTOBJECT.city,
    error: error.city,
    value: values.city,
    onChange,
  };
  // const state = {
  //   ...PRODUCTOBJECT.state,
  //   error: error.state,
  //   value: values.state,
  //   onChange,
  // };
  const tickAddress = {
    name: "state",
    initial: "",
    label: "Use billing address as delivery address",
    helperText: false,
    rules: false,
    value: isTickAddress,
    onChange: handleTickAddress,
  };

  const actionButton = { size: "large" };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModulePaperWrapper>
        {!isTickAddress && (
          <>
            <ModuleFormWrapper>
              <ModuleFormItemLongWrapper>
                <ModuleSubTitle>
                  Please enter your delivery address
                </ModuleSubTitle>
              </ModuleFormItemLongWrapper>
            </ModuleFormWrapper>

            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...companyName} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...firstname} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
            {/* <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Inputs.Text {...lastname} />
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Inputs.Text {...companyName} />
          </ModuleFormItemWrapper>
        </ModuleFormWrapper> */}
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...street} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...suburb} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...city} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Inputs.Text {...postcode} />
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>

            <ModuleFormWrapper>
              {/* line */}
              <ModuleFormItemWrapper>
                <Buttons.Normal {...actionButton}>Add Address</Buttons.Normal>
              </ModuleFormItemWrapper>
              {/* line */}
            </ModuleFormWrapper>
          </>
        )}

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Inputs.Checkbox {...tickAddress} />
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            ** Standard Delivery should be within 2 – 3 business days of order
            being accepted.
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Buttons.Text {...backButton}>Back</Buttons.Text>
            {isDeliveryAddress && (
              <Buttons.Normal {...nextButton}>Next</Buttons.Normal>
            )}
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
