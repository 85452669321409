import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Activation Form";
const server = null;
const service = null;
const options = {
  preference_options: [
    { name: "Preference Type 1", value: "1" },
    { name: "Preference Type 2", value: "2" },
  ],
};
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: false,
  routes: {
    label: "Account Activation Form",
    prefix: "/corporate/account_activation/form",
  },
  setup,
};

export default config;
