import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

/* atoms */
import { Invalid } from "../feedbacks";
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const useStyles = makeStyles((theme) => ({
  input: {
    "&:placeholder": {
      overflow: "ellipsis !important",
    },
  },
}));

const text = (props) => {
  const classes = useStyles();
  const disabled = props.disabled ? true : false;
  return (
    <Fragment>
      <TextField
        {...props}
        disabled={disabled}
        fullWidth
        variant="outlined"
        margin="dense"
        type="text"
        InputLabelProps={{
          style: {
            color: "grey",
            overflow: "ellipsis !important",
          },
        }}
      />
      <Invalid {...props} />
    </Fragment>
  );
};

export default text;
