import React, { useEffect, useContext } from "react";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApiV2 } from "../../lib-atomic-functions";
/* packages */

// /* module */
import Spinner from "../loader/generalCMSLoader";
// /* module */

import CONFIG from "../config";

/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints;
const CMSMASTERBOXESCONFIG = {
  url: CMSENDPOINTS.pages.url,
  method: CMSENDPOINTS.pages.method,
  headers: CMSENDPOINTS.pages.headers,
  responseType: "json"
};
/* CONSTANTS */

const index = props => {
  /* state */
  const { cms } = useContext(GlobalContext);

  /* --- cms */
  /* -------------- */
  const [fetchMasterBoxes] = FetchContextApiV2(CMSMASTERBOXESCONFIG);
  const fetchCMSExecute = async () => {
    cms.dispatch({
      type: cms.actionTypes.FETCH_CMS,
      cms: await fetchMasterBoxes({}, cms)
    });
  };
  /* --- cms */

  useEffect(() => {
    fetchCMSExecute();
  }, []);
  /* -------------- */
  /* state */

  const isResultReady =
    !cms.state.data || cms.state.data.length < 1 || cms.state.isLoading
      ? false
      : true;

  if (!isResultReady) {
    return <Spinner />;
  }

  return props.children;
};

export default index;
