const list = [
  "0170",
  "0171",
  "0172",
  "0173",
  "0174",
  "0175",
  "0176",
  "0178",
  "0179",
  "0181",
  "0182",
  "0184",
  "0185",
  "0192",
  "0193",
  "0272",
  "0281",
  "0282",
  "0283",
  "0293",
  "0294",
  "0295",
  "0370",
  "0371",
  "0372",
  "0373",
  "0374",
  "0376",
  "0377",
  "0379",
  "0381",
  "0391",
  "0472",
  "0473",
  "0474",
  "0475",
  "0476",
  "0478",
  "0479",
  "0481",
  "0482",
  "0483",
  "0484",
  "0486",
  "0491",
  "0492",
  "0494",
  "0496",
  "0571",
  "0573",
  "0582",
  "0583",
  "0587",
  "0588",
  "0589",
  "0591",
  "0592",
  "0593",
  "0594",
  "0772",
  "0781",
  "0782",
  "0792",
  "0793",
  "0794",
  "0871",
  "0873",
  "0874",
  "0875",
  "0881",
  "0882",
  "0883",
  "0891",
  "0892",
  "0972",
  "0973",
  "0974",
  "0975",
  "0977",
  "0981",
  "0982",
  "0983",
  "0984",
  "0985",
  "0986",
  "0991",
  "0992",
  "0993",
  "0994",
  "1971",
  "2471",
  "2472",
  "2473",
  "2474",
  "2571",
  "2576",
  "2577",
  "2578",
  "2579",
  "2580",
  "2582",
  "2583",
  "2584",
  "2585",
  "2675",
  "2676",
  "2677",
  "2678",
  "2679",
  "2681",
  "2682",
  "2683",
  "2684",
  "2693",
  "2694",
  "2695",
  "2696",
  "2697",
  "3072",
  "3073",
  "3074",
  "3076",
  "3077",
  "3078",
  "3079",
  "3081",
  "3083",
  "3096",
  "3097",
  "3170",
  "3171",
  "3172",
  "3173",
  "3174",
  "3175",
  "3176",
  "3177",
  "3178",
  "3179",
  "3180",
  "3181",
  "3182",
  "3183",
  "3186",
  "3187",
  "3188",
  "3189",
  "3191",
  "3192",
  "3193",
  "3194",
  "3196",
  "3197",
  "3198",
  "3199",
  "3281",
  "3282",
  "3283",
  "3284",
  "3285",
  "3286",
  "3287",
  "3288",
  "3289",
  "3290",
  "3293",
  "3295",
  "3296",
  "3297",
  "3371",
  "3372",
  "3373",
  "3374",
  "3375",
  "3377",
  "3378",
  "3379",
  "3380",
  "3381",
  "3382",
  "3384",
  "3385",
  "3391",
  "3392",
  "3393",
  "3471",
  "3472",
  "3473",
  "3474",
  "3475",
  "3481",
  "3482",
  "3483",
  "3484",
  "3485",
  "3491",
  "3492",
  "3493",
  "3494",
  "3495",
  "3496",
  "3574",
  "3575",
  "3576",
  "3577",
  "3578",
  "3579",
  "3581",
  "3582",
  "3583",
  "3584",
  "3591",
  "3592",
  "3597",
  "3671",
  "3672",
  "3673",
  "3674",
  "3681",
  "3682",
  "3691",
  "3771",
  "3772",
  "3781",
  "3782",
  "3784",
  "3791",
  "3792",
  "3793",
  "3794",
  "3872",
  "3873",
  "3874",
  "3875",
  "3876",
  "3877",
  "3878",
  "3879",
  "3880",
  "3881",
  "3882",
  "3883",
  "3885",
  "3886",
  "3889",
  "3894",
  "3895",
  "3970",
  "3971",
  "3972",
  "3973",
  "3974",
  "3975",
  "3976",
  "3977",
  "3978",
  "3979",
  "3980",
  "3981",
  "3982",
  "3983",
  "3985",
  "3986",
  "3987",
  "3988",
  "3989",
  "3990",
  "3991",
  "3992",
  "3993",
  "3994",
  "3995",
  "3996",
  "3997",
  "3998",
  "4071",
  "4072",
  "4073",
  "4075",
  "4077",
  "4078",
  "4079",
  "4081",
  "4082",
  "4083",
  "4086",
  "4087",
  "4091",
  "4092",
  "4093",
  "4094",
  "4171",
  "4172",
  "4174",
  "4175",
  "4178",
  "4179",
  "4180",
  "4181",
  "4182",
  "4183",
  "4184",
  "4186",
  "4188",
  "4189",
  "4191",
  "4193",
  "4195",
  "4196",
  "4197",
  "4198",
  "4271",
  "4272",
  "4273",
  "4274",
  "4275",
  "4276",
  "4277",
  "4278",
  "4279",
  "4281",
  "4282",
  "4283",
  "4284",
  "4285",
  "4286",
  "4287",
  "4288",
  "4291",
  "4292",
  "4293",
  "4294",
  "4295",
  "4371",
  "4372",
  "4373",
  "4374",
  "4375",
  "4376",
  "4377",
  "4378",
  "4379",
  "4381",
  "4382",
  "4383",
  "4386",
  "4387",
  "4388",
  "4389",
  "4390",
  "4391",
  "4392",
  "4393",
  "4394",
  "4395",
  "4396",
  "4397",
  "4398",
  "4399",
  "4470",
  "4471",
  "4472",
  "4473",
  "4474",
  "4475",
  "4476",
  "4477",
  "4478",
  "4479",
  "4481",
  "4571",
  "4572",
  "4573",
  "4574",
  "4575",
  "4576",
  "4577",
  "4578",
  "4581",
  "4582",
  "4584",
  "4585",
  "4586",
  "4587",
  "4588",
  "4591",
  "4592",
  "4597",
  "4598",
  "4671",
  "4672",
  "4673",
  "4674",
  "4675",
  "4678",
  "4679",
  "4681",
  "4682",
  "4684",
  "4685",
  "4691",
  "4694",
  "4696",
  "4771",
  "4774",
  "4775",
  "4777",
  "4779",
  "4780",
  "4781",
  "4782",
  "4783",
  "4784",
  "4785",
  "4786",
  "4787",
  "4788",
  "4789",
  "4791",
  "4792",
  "4793",
  "4794",
  "4795",
  "4796",
  "4797",
  "4884",
  "4891",
  "4893",
  "4894",
  "4970",
  "4971",
  "4972",
  "4973",
  "4974",
  "4975",
  "4976",
  "4977",
  "4978",
  "4979",
  "4981",
  "4982",
  "4983",
  "4984",
  "4985",
  "4986",
  "4987",
  "4988",
  "4989",
  "4990",
  "4991",
  "4992",
  "4993",
  "4994",
  "4995",
  "4996",
  "4997",
  "4998",
  "4999",
  "5371",
  "5372",
  "5373",
  "5381",
  "5391",
  "5570",
  "5571",
  "5572",
  "5573",
  "5574",
  "5575",
  "5581",
  "5582",
  "5583",
  "5771",
  "5772",
  "5773",
  "5781",
  "5782",
  "5783",
  "5784",
  "5791",
  "5792",
  "5794",
  "5871",
  "5872",
  "5881",
  "5882",
  "5883",
  "5884",
  "5885",
  "5886",
  "5887",
  "5888",
  "5889",
  "5890",
  "5894",
  "6972",
  "7071",
  "7072",
  "7073",
  "7077",
  "7081",
  "7091",
  "7095",
  "7096",
  "7173",
  "7175",
  "7178",
  "7182",
  "7183",
  "7192",
  "7193",
  "7194",
  "7195",
  "7196",
  "7197",
  "7198",
  "7271",
  "7272",
  "7273",
  "7274",
  "7275",
  "7276",
  "7281",
  "7282",
  "7284",
  "7285",
  "7371",
  "7373",
  "7374",
  "7379",
  "7381",
  "7382",
  "7383",
  "7384",
  "7385",
  "7387",
  "7391",
  "7392",
  "7395",
  "7471",
  "7472",
  "7473",
  "7475",
  "7476",
  "7477",
  "7481",
  "7482",
  "7483",
  "7491",
  "7495",
  "7571",
  "7572",
  "7580",
  "7581",
  "7582",
  "7583",
  "7591",
  "7670",
  "7671",
  "7672",
  "7673",
  "7674",
  "7675",
  "7676",
  "7677",
  "7678",
  "7681",
  "7682",
  "7683",
  "7691",
  "7692",
  "7771",
  "7772",
  "7773",
  "7774",
  "7775",
  "7776",
  "7777",
  "7778",
  "7781",
  "7782",
  "7783",
  "7784",
  "7791",
  "7871",
  "7872",
  "7873",
  "7875",
  "7881",
  "7882",
  "7883",
  "7884",
  "7885",
  "7886",
  "7891",
  "7892",
  "7893",
  "7895",
  "7971",
  "7972",
  "7973",
  "7974",
  "7975",
  "7977",
  "7978",
  "7979",
  "7980",
  "7982",
  "7983",
  "7984",
  "7985",
  "7986",
  "7987",
  "7988",
  "7990",
  "7991",
  "7992",
  "8971",
  "8972",
  "9073",
  "9074",
  "9076",
  "9077",
  "9081",
  "9082",
  "9085",
  "9091",
  "9092",
  "9271",
  "9272",
  "9273",
  "9274",
  "9281",
  "9282",
  "9291",
  "9292",
  "9371",
  "9372",
  "9376",
  "9377",
  "9382",
  "9383",
  "9384",
  "9386",
  "9387",
  "9391",
  "9392",
  "9393",
  "9395",
  "9396",
  "9397",
  "9398",
  "9471",
  "9472",
  "9481",
  "9482",
  "9483",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9491",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9492",
  "9493",
  "9493",
  "9493",
  "9493",
  "9493",
  "9493",
  "9493",
  "9493",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9494",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9495",
  "9498",
  "9571",
  "9572",
  "9583",
  "9584",
  "9585",
  "9586",
  "9587",
  "9591",
  "9593",
  "9596",
  "9597",
  "9598",
  "9672",
  "9679",
  "9682",
  "9683",
  "9689",
  "9691",
  "9771",
  "9772",
  "9773",
  "9774",
  "9775",
  "9776",
  "9777",
  "9778",
  "9779",
  "9781",
  "9782",
  "9783",
  "9791",
  "9792",
  "9793",
  "9794",
  "9871",
  "9872",
  "9874",
  "9875",
  "9876",
  "9877",
  "9879",
  "9881",
  "9883",
  "9884",
  "9891",
  "9892",
  "9893",
];
export default list;
