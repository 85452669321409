import { init } from "./init";
import { defaultReset } from "../useReset";
import SanitizeExec from "../Sanitize";

const actionTypes = {
  FETCH_TOKEN: "FETCH_TOKEN",
};

const fetchReset = async (state) => {
  const resetState = defaultReset({ init, state });
  state = {
    ...state,
    ...resetState,
  };
  return state;
};

const fetchToken = async (state, token) => {
  const sanitize = SanitizeExec({
    status: token.status,
    data: token.data,
    name: "tokenobject",
  });
  state = {
    ...state,
    ...sanitize,
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN:
      return fetchToken(state, action.token);
    default:
      return state;
  }
};

const token = {
  init,
  actionTypes,
  reducer,
};

export default token;
