import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import {
  ModuleContainerLg,
  ModulePaddedWrapperLg,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSFaqHeader from "../../app-module-cms-section-faq-header/default";
import ContentsBody from "../contents/contents";
/* module */

const index = (props) => {
  return (
    <GlobalProvider>
      {/* <CMSContainer>
        <CMSFaqHeader />
      </CMSContainer> */}

      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ContentsBody />
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
};

export default index;
