import React, { Fragment, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grow from "@material-ui/core/Grow";
import { Grid } from "@material-ui/core";

/*
: properties
children
size
fullWidth
*/

const normal = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupDisplayId, setPopupDisplayId] = useState(null);

  const open = Boolean(anchorEl);
  const idOpen = open ? "simple-popover" : undefined;

  const handleOpen = (event, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPopupDisplayId(id);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setPopupDisplayId(null);
  };

  const { mainButton, rightButtons, id } = props;

  return (
    <Fragment>
      <ButtonGroup
        style={{ boxShadow: "none" }}
        variant="contained"
        color="primary"
        aria-label="split button"
      >
        <Button
          {...mainButton}
          disableElevation={true}
          variant="contained"
          color="primary"
          type="submit"
        >
          {mainButton.text}
        </Button>

        <Button
          disableElevation={true}
          color="primary"
          onClick={(e) => {
            handleOpen(e, id);
          }}
          style={{
            ...mainButton.style,
            ...{
              margin: 0,
              padding: 0,
              minWidth: 0,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        id={idOpen}
        open={open && id === popupDisplayId}
        anchorEl={anchorEl}
        onClose={(e) => {
          handleClose(e);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          borderWidth: 1,
          borderColor: "red",
          borderStyle: "none",
          padding: 0,
        }}
      >
        {/* <Paper style={{ padding: 0, margin: 0 }}> */}
        {rightButtons.map((option, index) => (
          <Button
            {...option}
            key={index}
            disableElevation={true}
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => {
              handleClose(e);
              option.onClick(e);
            }}
            style={option.style}
            disableElevation={true}
          >
            {option.text}
          </Button>
        ))}
        {/* </Paper> */}
      </Popover>
    </Fragment>
  );
};

export default normal;
