import React, { useContext, useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../lib-atomic-context";

/* packages */
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  WindowSize,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 400,
    backgroundColor: "white",
    padding: 10,
  },
  paper2: {
    position: "absolute",
    zIndex: 99,
    marginTop: theme.spacing(1),
    width: 700,
    backgroundColor: "white",
    padding: 10,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  throbber: {
    color: "#000000",
    margin: "auto",
  },
}));

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.config.objects;
const ENDPOINTS = CONFIG.application.config.api.endpoints;
const FORGOTPASSWORDCONFIG = {
  url: ENDPOINTS.forgotpassword.url,
  method: ENDPOINTS.forgotpassword.method,
  headers: ENDPOINTS.forgotpassword.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.config.responses.unverified;
/* CONSTANTS */

const updateProfile = (props) => {
  const { width, height } = WindowSize();
  const login = LocalStorage.getLS().login;

  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);

  const { forgotpassword } = useContext(GlobalContext);

  /* --- update */
  const [fetchForgotPassword] = FetchContextApiV2(FORGOTPASSWORDCONFIG);
  const fetchForgotPasswordExecute = async ({ body }) => {
    forgotpassword.dispatch({
      type: forgotpassword.actionTypes.FORGOT_PASSWORD,
      forgotpassword: await fetchForgotPassword({ body }, forgotpassword),
    });
  };
  /* --- update */

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const handleCreate = async (e) => {
    setLoaded(true);
    if (verified) {
      console.log(values);
      var body = {
        email: values.email,
      };
      await fetchForgotPasswordExecute({ body });
    }
  };
  const {
    values,
    onReset,
    onCheck,
    onChange,
    onPasswordChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleCreate, CONFIGOBJECT);

  useEffect(() => {
    if (forgotpassword.state.data) {
      if (!forgotpassword.state.isStatus) {
        props.history.push("/corporate/forgot_password/request/fail");
      }
      const { updatedetailobj } = forgotpassword.state.data;
      console.log(updatedetailobj);

      props.history.push("/corporate/forgot_password/request/success");
      // const authBody = {
      //   username: values.email,
      //   password: values.password
      // };
      // fetchTokenExecute({ body: authBody });
    }
  }, [forgotpassword.state]);

  /* data props */
  const email = {
    ...CONFIGOBJECT.email,
    error: error.email,
    value: values.email,
    onChange,
  };

  const verifyLogin = { error: [UNVERIFIEDRESPONSE] };

  const submitButton = { size: "medium" };
  const submitDisabledButton = { size: "medium", disabled: true };
  /* data props */

  return (
    <form
      style={{
        width: width < 500 ? "100%" : width < 1300 ? "60%" : "45%",
        padding: 5,
        // whiteSpace: "nowrap",
      }}
      onSubmit={onSubmit}
      noValidate
    >
      <ModuleFormWrapper>
        <ModuleFormItemWrapper>
          <ModuleTitle>Forgot Password</ModuleTitle>
        </ModuleFormItemWrapper>
      </ModuleFormWrapper>
      <ModuleFormWrapper>
        <ModuleFormItemLongWrapper>
          <ModuleSubTitle>
            <div
              style={{
                paddingLeft: 5,
                paddingBottom: 20,
              }}
            >
              Request to Reset Your Password
              <br />
              Provide your account email address to receive an email with link
              to reset your password
            </div>
          </ModuleSubTitle>
        </ModuleFormItemLongWrapper>
      </ModuleFormWrapper>
      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemLongWrapper>
          <Inputs.Text {...email} style={{ marginTop: -10, marginBottom: 3 }} />
        </ModuleFormItemLongWrapper>
        {/* line */}
      </ModuleFormWrapper>

      <ModuleFormWrapper>
        <br />
        <br />
        <div
          style={{
            alignSelf: "left",
            paddingLeft: 10,
          }}
        >
          {!verified && loaded && (
            <Fragment>
              <Invalid {...verifyLogin} />
            </Fragment>
          )}

          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
      </ModuleFormWrapper>
      <br />
      <br />
      {true ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>Submit</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>Submit</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default updateProfile;
