import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

import { makeStyles } from "@material-ui/core/styles";

/* package */
import { GlobalProvider } from "../../lib-atomic-context";
import { ModuleContainerLg } from "../../lib-atomic-design-section";
/* package */

/* container */
import HomeContainer from "../../app-module-home-container/default";
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSHomeHeader from "../../app-module-cms-section-home-header/default";
import CMSHomeBody from "../../app-module-cms-section-home-body/default";
/* module */
import ScrollToTop from "../../../ScrollToTop";

const index = observer((props) => {
  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSHomeHeader {...props} />
      </CMSContainer>
      <ScrollToTop {...props} />

      <ModuleContainerLg>
        <CMSHomeBody {...props} />
      </ModuleContainerLg>
    </GlobalProvider>
  );
});

export default index;
