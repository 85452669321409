import React from "react";
import { ModuleMenuBtn } from "../lib-atomic-design-section";
import { LocalStorage } from "../lib-atomic-functions";

import CONFIG from "./config";

const App = (props) => {
  const isGuestLS = LocalStorage.getLS().guest ? true : false;
  const isUserLS = LocalStorage.getLS().user ? true : false;
  return isUserLS ? null : (
    <div>
      <ModuleMenuBtn {...{ props, ...CONFIG.routes }} />
    </div>
  );
};

export default App;
