import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";

/* packages */
import { LocalStorage } from "../../lib-atomic-functions";
/* packages */

/* stores */
import { CorporateCreate } from "../stores";
/* stores */

const index = observer((props) => {
  /* ---------------- */
  /* stores contexts */
  const corporateRegisterContext = useContext(CorporateCreate);
  /* stores contexts */

  /* logout if login not available */
  // const { login } = LocalStorage.getLS();
  // if (!login) return <Redirect to='/u/logout' />

  useEffect(() => {
    console.log("create context changed: ", corporateRegisterContext);
  }, [corporateRegisterContext]);
  /* logout > token expire */
  useEffect(() => {
    if (corporateRegisterContext.expireToken) {
      console.log("token Expired resetting from config module");
      // props.history.push('/corporate/u/landing-token-expire');
      // do I return function like this if I want to reset store?
      return (corporateRegisterContext) => {
        console.log("executing reset process...");
        corporateRegisterContext.setLoading({
          loading: false,
          status: true,
          data: { blob: false, json: false },
          expireToken: false,
        });
      };
    }
  }, [corporateRegisterContext.expireToken]);
  /* ---------------- */

  return props.children;
});

export default index;
