import React, { useContext, useState, useEffect, Fragment } from "react";
import Recaptcha from "react-recaptcha";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  FetchContextApiV2,
  LocalStorage,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemLongWrapper,
} from "../../lib-atomic-design-section";
/* packages */

import CONFIG from "../config";

/* CONSTANTS */
const CONFIGOBJECT = CONFIG.application.cardactivate.objects;
const ENDPOINTS = CONFIG.application.cardactivate.api.endpoints;
const ACTIVATECONFIG = {
  url: ENDPOINTS.activate.url,
  method: ENDPOINTS.activate.method,
  headers: ENDPOINTS.activate.headers,
  responseType: "json",
};
const UNVERIFIEDRESPONSE = CONFIG.application.cardactivate.responses.unverified;
/* CONSTANTS */

const index = (props) => {
  /* state */
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  /* -------------- */
  /* --- activation */
  /* -------------- */
  const [fetchCardActivation] = FetchContextApiV2(ACTIVATECONFIG);
  const { activate } = useContext(GlobalContext);
  const execCardActivation = async ({ body, query }) => {
    activate.dispatch({
      type: activate.actionTypes.FETCH_CARD_ACTIVATE,
      activate: await fetchCardActivation({ body, query }, activate),
    });
  };
  // when activation state changes
  useEffect(() => {
    if (activate.state.data) {
      console.log(activate.state.data);
      if (activate.state.data.activatedetail.outcomeCode === "0") {
        console.log("changes in activate...");
        props.history.push("/corporate/card_activation/success");
      } else {
        props.history.push("/corporate/card_activation/fail");
      }
    }
  }, [activate]);
  /* -------------- */
  /* --- activation */
  /* state */

  const handleCreate = async (e) => {
    setLoaded(true);
    if (verified) {
      console.log(values);
      await execCardActivation({
        query: values.orderNumber + "/activation",
        body: {
          emailAddress: values.emailAddress,
          cardNumber: "636315" + values.cardNumber,
        },
      });
    }
  };

  const callback = () => {
    console.log("loaded recaptcha...");
  };

  // specifying verify callback function
  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
      setLoaded(true);
    }
  };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    CONFIGOBJECT
  );

  /* data props */
  const cardNumber = {
    ...CONFIGOBJECT.cardNumber,
    error: error.cardNumber,
    value: values.cardNumber,
    onChange,
  };
  const emailAddress = {
    ...CONFIGOBJECT.emailAddress,
    error: error.emailAddress,
    value: values.emailAddress,
    onChange,
  };
  const orderNumber = {
    ...CONFIGOBJECT.orderNumber,
    error: error.orderNumber,
    value: values.orderNumber,
    onChange,
  };

  const submitButton = { size: "large" };
  const submitDisabledButton = { size: "large", disabled: true };
  const verifyRecaptcha = { error: [UNVERIFIEDRESPONSE] };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModuleFormWrapper>
        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...cardNumber}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...emailAddress}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <ModuleFormItemWrapper>
          <Inputs.Text
            {...orderNumber}
            style={{ marginTop: -10, marginBottom: 3 }}
          />
        </ModuleFormItemWrapper>
        {/* line */}
        {/* line */}
        <ModuleFormItemWrapper></ModuleFormItemWrapper>
        {/* line */}

        {/* line */}
        <br />
        <div style={{ alignSelf: "left", paddingLeft: 10 }}>
          <Recaptcha
            sitekey="6Le-u7UUAAAAAFtIWVsYzu8cpqQAY1sVwdT0vDyE"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </div>
        {!verified && loaded && (
          <Fragment>
            <Invalid {...verifyRecaptcha} />
            <br />
          </Fragment>
        )}
      </ModuleFormWrapper>

      <br />
      {verified &&
      loaded &&
      values["cardNumber"] &&
      values["emailAddress"] &&
      values["orderNumber"] ? (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitButton}>ACTIVATE CARDS</Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      ) : (
        <ModuleFormWrapper>
          <ModuleFormItemWrapper>
            <Buttons.Normal {...submitDisabledButton}>
              ACTIVATE CARDS
            </Buttons.Normal>
          </ModuleFormItemWrapper>
        </ModuleFormWrapper>
      )}
    </form>
  );
};

export default index;
