import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
/* mui */
import Grid from "@material-ui/core/Grid";
/* mui */

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { Inputs, Buttons, Invalid } from "../../lib-atomic";
import {
  FormHook,
  LocalStorage,
  FetchContextApi,
} from "../../lib-atomic-functions";
import {
  ModuleTitle,
  ModuleSubTitle,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
  ModuleFormItemShortWrapper,
  ModuleFormItemLongWrapper,
  ModulePaperWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* stores */
import { Order } from "../../app-module-order-container/stores";
/* stores */

import CONFIG from "../config";

/* CONSTANTS */
const CARRIERS = CONFIG.setup.assets.carriers;
const PRODUCTOBJECT = CONFIG.application.buynow.objects_payment;
const BUYNOWENDPOINTS = CONFIG.application.buynow.api.endpoints;
const PRODUCTCONFIG = {
  url: BUYNOWENDPOINTS.create.url,
  method: BUYNOWENDPOINTS.create.method,
  headers: BUYNOWENDPOINTS.create.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { handleNext, handleBack, handleReset } = props.handleSteps;
  const isPaymentLS = LocalStorage.getLS().payment ? true : false;

  /* state */
  const [isPayment, setIsPayment] = useState(false);
  const [isTriggerCreate, setIsTriggerCreate] = useState(false);
  /* -------------- */
  /* --- payment */
  /* -------------- */
  const { payment } = useContext(GlobalContext);
  const execPaymentAdd = (paymentselected) => {
    payment.dispatch({
      type: payment.actionTypes.ADD_PAYMENT,
      payment: paymentselected,
    });
  };
  useEffect(() => {
    // fetch local storage and put it inside context
    console.log("example....................");
    const paymentLS = LocalStorage.getLS().payment;
    if (paymentLS) {
      execPaymentAdd({ paymentoption: paymentLS });
    }
  }, []);
  useEffect(() => {
    if (payment.state.data && isTriggerCreate) {
      LocalStorage.addLS("payment", payment.state.data.paymentoption);
      setIsTriggerCreate(false);
    }
  }, [payment]);
  useEffect(() => {
    if (isPaymentLS) {
      setIsPayment(isPaymentLS);
    }
  }, [isPaymentLS]);
  /* -------------- */
  /* --- payment */
  /* state */

  const handleCreate = async () => {
    const item = { ...values };
    // await createMethod({body: JSON.stringify(values)});
    execPaymentAdd(item);
    LocalStorage.addLS("payment", item.paymentoption);
    onReset(PRODUCTOBJECT);
    setIsTriggerCreate(true);
  };

  // const handleCancel = async () => {
  //   props.history.push("/corporate");
  // };

  const { values, onReset, onChange, onSubmit, error } = FormHook.useFormHook(
    handleCreate,
    PRODUCTOBJECT
  );

  // /* data props */
  // const product = {
  //   ...PRODUCTOBJECT.product,
  //   error: error.product,
  //   value: values.product,
  //   onChange
  // };
  // const value = {
  //   ...PRODUCTOBJECT.value,
  //   error: error.value,
  //   value: values.value,
  //   onChange
  // };
  const paymentoption = {
    ...PRODUCTOBJECT.paymentoption,
    error: error.paymentoption,
    value: values.paymentoption,
    onChange,
  };
  const actionButton = { size: "large" };
  const nextButton = { size: "large", onClick: handleNext };
  const backButton = { size: "large", onClick: handleBack };
  /* data props */

  return (
    <form onSubmit={onSubmit} noValidate>
      <ModulePaperWrapper>
        <ModuleFormWrapper>
          <ModuleFormItemLongWrapper>
            <ModuleSubTitle>Please select your payment method</ModuleSubTitle>
          </ModuleFormItemLongWrapper>
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Inputs.Select {...paymentoption} />
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>

        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemWrapper>
            <Buttons.Normal {...actionButton}>Add</Buttons.Normal>
          </ModuleFormItemWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </ModulePaperWrapper>
      <div style={{ padding: 15, paddingTop: 20 }}>
        <ModuleFormWrapper>
          {/* line */}
          <ModuleFormItemLongWrapper>
            <Buttons.Text {...backButton}>Back</Buttons.Text>
            {isPayment && <Buttons.Normal {...nextButton}>Next</Buttons.Normal>}
          </ModuleFormItemLongWrapper>
          {/* line */}
        </ModuleFormWrapper>
      </div>
    </form>
  );
};

export default index;
