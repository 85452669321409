import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Section Lists";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    onlineorder: {
      api: {
        endpoints: {
          checkout: {
            headers: {
              appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "PUT",
          },
          getorder: {
            headers: {
              // appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/orders",
            method: "GET",
          },
          generatepayment: {
            headers: {
              "Content-Type": "application/json",
            },
            doc: "https://tr4ns10.tr4ns.com/tx-payment/payment",
            // url:
            //   setup.setup.api.tranxactor_service.url[environment] +
            //   "/tx-payment/stripe",
            url:
              setup.setup.api.tranxactor_service.url[environment] +
              "/westfield-payment/stripe",
            method: "POST",
          },
          getcontact: {
            headers: {
              appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/clients",
            method: "GET",
          },
          createcontact: {
            headers: {
              appName: "corp",
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ',
            },
            doc: "/pages",
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.tranxactor_westfield_bulkorder.url[environment] +
              "/clients",
            method: "PUT",
          },
        },
      },
    },
  },
  routes: {
    label: "Order Section Lists",
    prefix: "/corporate/order/section/list/total",
  },
  setup,
};

export default config;
