import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* module  */
import CardActivateForm from "../form/activate";
/* module  */

const index = props => {
  return <CardActivateForm {...props} />;
};

export default index;
