import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import { Buttons } from "../../lib-atomic";
import { LocalStorage } from "../../lib-atomic-functions";
import {
  ModuleText,
  ModuleContainerLg,
  ModulePaddedWrapperLg,
  ModuleFormWrapper,
  ModuleFormItemWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* container */
import CMSContainer from "../../app-module-cms-container/default";
/* container */

/* module */
import CMSBuynowHeader from "../../app-module-cms-section-buynow-header/default";
/* module */

const index = observer((props) => {
  const tokenLS = LocalStorage.getLS().login.token;

  console.log(tokenLS);

  const handleLinkLogin = () => {
    props.history.push("/corporate/login");
  };

  const handleLinkOrderGuest = () => {
    props.history.push("/corporate/order/guest/items");
    // props.history.push("/corporate/order/guest/infos");
  };

  /* data props */
  const loginBtnData = { size: "large", onClick: handleLinkLogin };
  const guestBtnData = { size: "large", onClick: handleLinkOrderGuest };
  /* data props */

  if (tokenLS) {
    props.history.push("/corporate/order/items");
  }

  return (
    <GlobalProvider>
      <CMSContainer>
        <CMSBuynowHeader />
      </CMSContainer>
      <ModuleContainerLg>
        <ModulePaddedWrapperLg>
          <ModuleFormWrapper>
            <ModuleFormItemWrapper>
              <ModuleText>
                <Buttons.Normal {...loginBtnData}>Login</Buttons.Normal>
              </ModuleText>
            </ModuleFormItemWrapper>
          </ModuleFormWrapper>
        </ModulePaddedWrapperLg>
      </ModuleContainerLg>
    </GlobalProvider>
  );
});

export default index;
